import { z } from 'zod';
import { SlideType } from '../../enums';
import { baseSlideSchema } from './baseSlideSchema';
import { infoSlideItemSchema } from './slideItemTypes/infoSlideItemSchema';

export const infoSlideSchema = baseSlideSchema.and(
  z.object({
    type: z.literal(SlideType.NORMAL),

    items: z.array(infoSlideItemSchema),
  })
);

export type InfoSlideType = z.infer<typeof infoSlideSchema>;
