import {
  CreateCaseAssetViewInput,
  CreateCaseAssetViewMutation,
  CreateCaseSlideStatsInput,
  CreateCaseSlideStatsMutation,
  CreateCaseStatsInput,
  CreateCaseStatsMutation,
  CreateMeetingNPSScoreInput,
  CreateMeetingNPSScoreMutation,
  CreateMeetingStatsInput,
  CreateMeetingStatsMutation,
} from '@/services/API';
import {
  createCaseAssetView,
  createCaseSlideStats,
  createCaseStats,
  createMeetingNPSScore,
  createMeetingStats,
} from '@/services/graphql/mutations';
import {
  CASE_ASSET_VIEW_VERSION,
  CASE_SLIDE_STATS_VERSION,
  CASE_STATS_VERSION,
  MEETING_STATS_VERSION,
  NPS_SCORE_STATS_VERSION,
} from '@/utils/constants/versions';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { GraphQLResult } from '@aws-amplify/api';
import { checkIfUserHasCreatedNPS } from './queries';

/*
NOTE: Should be done only by admin
*/
export async function mutationCreateCaseStats(
  meetingID: string,
  caseID: string,
  caseDetails: NewIPatientCase
) {
  const input: CreateCaseStatsInput = {
    caseId: caseID,
    meetingID: meetingID,
    version: CASE_STATS_VERSION,
  };
  const result = await callGraphQLApi<GraphQLResult<CreateCaseStatsMutation>>(
    createCaseStats,
    {
      input,
    }
  );

  if (!result.data?.createCaseStats) {
    throw new Error('Error while creating caseStats');
  }

  // create slideStats
  const slideStatsPromises =
    caseDetails.slides?.map((item) => {
      return mutationCreateCaseSlideStats(
        result.data?.createCaseStats?.id as string,
        item.id,
        0
      );
    }) ?? [];

  // slideStatsPromises.push(
  //   ...SPECIAL_SLIDE_IDS.map((item) => {
  //     return mutationCreateCaseSlideStats(
  //       result.data?.createCaseStats?.id as string,
  //       item,
  //       0
  //     );
  //   })
  // );
  await Promise.all(slideStatsPromises);
  return result;
}

export async function mutationCreateCaseAssetView(
  caseStatsId: string,
  assetId: string,
  userID: string,
  timeWatched: number
) {
  const input: CreateCaseAssetViewInput = {
    caseStatsAssetViewsId: caseStatsId,
    assetId: assetId,
    userID,
    timeWatched,
    version: CASE_ASSET_VIEW_VERSION,
  };
  const result = await callGraphQLApi<
    GraphQLResult<CreateCaseAssetViewMutation>
  >(createCaseAssetView, { input });
  return result.data;
}

export async function mutationCreateCaseSlideStats(
  caseStatsId: string,
  slideId: string,
  time: number
) {
  const input: CreateCaseSlideStatsInput = {
    caseStatsSlidesId: caseStatsId,
    slideId,
    time,
    version: CASE_SLIDE_STATS_VERSION,
  };
  const result = await callGraphQLApi<
    GraphQLResult<CreateCaseSlideStatsMutation>
  >(createCaseSlideStats, { input });
  return result.data;
}

export async function mutationCreateNPSScoreIfNotExists(
  meetingID: string,
  userID: string,
  score: number[]
) {
  const result = await checkIfUserHasCreatedNPS(meetingID, userID);
  if (result) {
    console.log('NPSScore: user has already voted');
    return;
  }
  await mutationCreateNPSScore(meetingID, userID, score);
}

export async function mutationCreateNPSScore(
  meetingID: string,
  userID: string,
  scores: number[]
) {
  const input: CreateMeetingNPSScoreInput = {
    meetingID,
    userID,
    scores,
    version: NPS_SCORE_STATS_VERSION,
  };
  const result = await callGraphQLApi<
    GraphQLResult<CreateMeetingNPSScoreMutation>
  >(createMeetingNPSScore, { input });
  return result.data;
}

export async function mutationCreateMeetingStats(meetingID: string) {
  const input: CreateMeetingStatsInput = {
    id: meetingID,
    meetingID,
    casePresentationHistory: [],
    amountParticipants: 0,
    version: MEETING_STATS_VERSION,
  };
  const result = await callGraphQLApi<
    GraphQLResult<CreateMeetingStatsMutation>
  >(createMeetingStats, { input });
  return result.data;
}
