import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import { useQuery } from '@tanstack/react-query';
import { assertIsTruthy } from '@/utils/types/type-assertion/generic/assertIsTruthy';
import extractThemeIdFromProductId from './extractThemeIdFromProductId';
import extractBrandNameFromProductId from './extractBrandNameFromProductId';

export type LorealBrandStats = {
  totalProductsSelected: number;
  productToAmtSelected: Map<string, number>;
};

export const createDefaultLorealBrandStats = (): LorealBrandStats => {
  return {
    totalProductsSelected: 0, // set your desired default value for totalProductsSelected
    productToAmtSelected: new Map<string, number>(), // initialize an empty Map
  };
};

export default function useStarProductAnswers(
  meetingID: string | null | undefined,
  currentCase: string
) {
  const slideID = '37-68-5F';
  const parsedStats = useQuery({
    queryKey: ['starProducts', slideID],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      assertIsTruthy(meetingID);
      const questionID = composeQuestionID(meetingID, currentCase, slideID);
      const answers = (await listAnswersByQuestionID(questionID)) ?? [];
      const answersPerBrandPerTheme = answers.reduce((acc, curr) => {
        curr.answerTexts.forEach((productID, productIdx) => {
          if (productID === '') return;
          const themeId = extractThemeIdFromProductId(productID);
          const brandName = extractBrandNameFromProductId(productID);
          const key = `${themeId}_${brandName}`;
          if (!acc.has(key)) {
            acc.set(key, createDefaultLorealBrandStats());
          }
          const brandStats = acc.get(key)!;
          // NOTE: a star product has a voteWeight of 2 compared to a co star product
          const voteWeight = productIdx <= 2 ? 2 : 1;
          brandStats.totalProductsSelected += voteWeight;
          brandStats.productToAmtSelected.set(
            productID,
            (brandStats.productToAmtSelected.get(productID) || 0) + voteWeight
          );
          acc.set(key, brandStats);
        });

        return acc;
      }, new Map<string, LorealBrandStats>());
      return answersPerBrandPerTheme;
    },
  });
  return parsedStats;
}
