import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { ROUTES } from '@/routes/Routes';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function RateMeetingHostView() {
  const { meeting } = useCurrentMeetingDataContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      flex="auto"
      height="100%"
      direction="column"
      justifyContent="center"
      boxShadow="0px 0px 2px #eee"
      borderRadius="5px"
      padding="10px"
      textAlign="center"
    >
      <VStack
        background="white"
        justifyContent="center"
        alignItems="center"
        userSelect="none"
      >
        <Heading color="livelinx.purple200" fontWeight="200em" fontSize="2em">
          {t('npsScore.title.host')}
        </Heading>
        <Text fontSize="1.5em" color="livelinx.grey200">
          {t('npsScore.description.host')}
        </Text>
      </VStack>

      <Flex
        background="white"
        padding="20px"
        justifyContent="center"
        alignItems="center"
        gap="20px"
        flexDir="column"
      >
        <Text userSelect="none" fontSize="1.5em" color="livelinx.grey200">
          {t('npsScore.instruction.host')}
        </Text>
        <Button
          onClick={() => {
            if (!meeting?.id) {
              captureSentry({
                title: 'RateMeetingHostView: meeting is null',
                detail: {
                  meeting,
                },
              });
              return;
            }
            navigate(`${ROUTES.MEETING_STATS}/${meeting?.id}`);
          }}
          variant="primary"
        >
          {t('npsScore.viewsStats.btn.title')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default RateMeetingHostView;
