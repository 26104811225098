import { User } from '@/services/API';
import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';

const useAdminUserTableStore = createTanstackTableDataStore<User>({
  storeName: 'admin-user-table-store',
  defaultColumnSizing: {
    email: 174,
    // HACK: because we don't store the clients inside the user object
    ['clients' as keyof User]: 194,
    last_seen: 111,
    createdAt: 127,
  },
  defaultColumnVisibility: {
    id: false,
  },
});

export default useAdminUserTableStore;
