import { Flex } from '@chakra-ui/react';

const MainFooter = () => (
  <Flex
    color="grey"
    justifyContent="flex-end"
    height="auto"
    fontSize="11px"
    margin="0"
    padding="12px 25px"
    boxShadow="2xl"
  >
    v{import.meta.env.VITE_VERSION} @copyright 2021 Livelinx
  </Flex>
);

export default MainFooter;
