import { ColumnFilter, SortingState } from '@tanstack/react-table';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type TableStore<TData> = {
  //NOTE: in case I need to migrate the store to a new version
  version: string;
  columnSorting: SortingState;
  setColumnSorting: (columnSorting: SortingState) => void;
  columnFilters: ColumnFilter[];
  setColumnFilters: (columnFilters: ColumnFilter[]) => void;
  columnVisibility: { [K in keyof TData]?: boolean };
  setColumnVisibility: (columnVisibility: {
    [K in keyof TData]?: boolean;
  }) => void;
  columnSizing: { [K in keyof TData]?: number };
  setColumnSizing: (columnSizing: { [K in keyof TData]?: number }) => void;
};

type Props<TData> = {
  storeName: string;
  defaultColumnSorting?: SortingState;
  defaultColumnFilters?: ColumnFilter[];
  defaultColumnVisibility?: { [K in keyof TData]?: boolean };
  defaultColumnSizing?: { [K in keyof TData]?: number };
};

function createTanstackTableDataStore<TData>({
  storeName,
  defaultColumnSorting,
  defaultColumnFilters,
  defaultColumnVisibility,
  defaultColumnSizing,
}: Props<TData>) {
  return create<TableStore<TData>>()(
    persist(
      (set) => ({
        /* 1.0.1: Adding columnSorting and setColumnSorting fields*/
        version: '1.0.1',
        columnFilters: defaultColumnFilters || [],
        setColumnFilters: (columnFilters: ColumnFilter[]) => {
          set({ columnFilters });
        },
        columnVisibility: defaultColumnVisibility || {},
        setColumnVisibility: (columnVisibility: {
          [K in keyof TData]?: boolean;
        }) => {
          set({ columnVisibility });
        },
        columnSizing: defaultColumnSizing || {},
        setColumnSizing: (columnSizing: { [K in keyof TData]?: number }) => {
          set({ columnSizing });
        },
        columnSorting: defaultColumnSorting || [],
        setColumnSorting: (columnSorting: SortingState) => {
          set({ columnSorting });
        },
      }),
      {
        name: storeName,
      }
    )
  );
}

export default createTanstackTableDataStore;
