import {
  GetMeetingStatsQuery,
  GetMeetingStatsQueryVariables,
  MeetingStats,
  OnUpdateMeetingStatsSubscription,
} from '@/services/API';
import { getMeetingStats } from '@/services/graphql/queries';
import { onUpdateMeetingStats } from '@/services/graphql/subscriptions';
import useNewSubscriptions, {
  SubscriptionOptions,
} from '@/services/subscriptions/useNewSubscriptions';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export default function useMeetingStatsData(
  meetingID: string | null | undefined,
  live: boolean
): MeetingStats | undefined | null {
  const meetingDataQuery = useQuery({
    queryKey: ['useMeetingStatsData', meetingID],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      if (!meetingID) throw new Error('No meeting ID in useMeetingStatsData');
      const variables: GetMeetingStatsQueryVariables = {
        id: meetingID,
      };
      return await callGraphQLApi<GraphQLResult<GetMeetingStatsQuery>>(
        getMeetingStats,
        variables
      );
      // return await callGraphQLApi<GraphQLResult<MeetingStatsByMeetingQuery>>(
      //   meetingStatsByMeeting,
      //   {
      //     meetingID: meetingID,
      //   }
      // );
    },
  });

  if (live) {
    const subscription = useMemo<Array<SubscriptionOptions<any>>>(() => {
      if (!meetingID) return [];

      //TODO: the filters don't work here for some reason

      // const variables: OnUpdateMeetingStatsSubscriptionVariables = {
      //   filter: {
      //     meetingID: {
      //       eq: meetingID,
      //     },
      //   },
      // };
      return [
        {
          query: onUpdateMeetingStats,
          variables: {},
          callback: (data: OnUpdateMeetingStatsSubscription) => {
            if (data.onUpdateMeetingStats?.meetingID !== meetingID) return;
            meetingDataQuery.refetch();
          },
        },
      ];
    }, [meetingID]);

    useNewSubscriptions(subscription, 'useMeetingStatsData');
  }

  return meetingDataQuery.data?.data?.getMeetingStats ?? null;
}
