import { createXAmountOfUsers } from './mockUser/users.create';
import { pureFetchCaseList } from '@/store/thunk/caseCluster';
import initializeMockMeeting from './general/initializeMockMeeting';
import mockCaseCluster from './general/mockCaseCluster';
import mockMeeting from './general/mockMeeting';
import { ROUTES } from '@/routes/Routes';

// Amplify.configure(awsExports);

export async function startMock(openMeeting = false) {
  const testID = Math.floor(Math.random() * 1000);
  const hostID = '42a7f392-9c69-4726-85c3-372dbcd996be';

  // const caseIds = [
  //   '0009_IPSEN_SHAFAK_20230531T082406Z-001',
  //   '0010_IPSEN_MELIANTHE_20230531T082413Z-001',
  // ];
  // const caseCluster = await mockCaseCluster(testID, caseIds);
  // const caseClusterID = caseCluster.id;
  // const mockedUsers = await createXAmountOfUsers(10);

  // const mockedMeeting = await initializeMockMeeting(
  //   testID,
  //   caseClusterID,
  //   hostID
  // );

  // const caseStatId_case1 = mockedMeeting.caseStats?.items.find(
  //   (cs) => cs?.caseId === caseIds[0]
  // )?.id;
  // const caseStatId_case2 = mockedMeeting.caseStats?.items.find(
  //   (cs) => cs?.caseId === caseIds[1]
  // )?.id;

  // if (!caseStatId_case1 || !caseStatId_case2) {
  //   throw new Error(
  //     `Could not find caseStatId ${caseStatId_case1} ${caseStatId_case2}`
  //   );
  // }
  // await addCustomData(mockedUsers, caseStatId_case1, caseStatId_case2);
  //I want some random caseIds
  const allCaseIdList = (await pureFetchCaseList())
    .map((elem: any) => elem.id)
    .filter((elem: string) => elem !== 'lorealFR' && elem !== 'lorealNL');
  const randomCaseIds = allCaseIdList
    .sort(() => Math.random() - Math.random())
    .slice(0, 4);
  // const randomCaseIds = [
  //   '1',
  // ]
  const newCaseCluster = await mockCaseCluster(testID, randomCaseIds);
  console.log('randomCaseIds', randomCaseIds);
  const caseClusterID = newCaseCluster.id;

  const mockedUsers = await createXAmountOfUsers(15);
  const mockedMeeting = await initializeMockMeeting(
    testID,
    caseClusterID,
    hostID
  );
  const timePassedPerSlide = 2000;

  if (openMeeting) {
    //TODO: make it so that localhost isn't hard coded
    window.open(
      `http://localhost:3333${ROUTES.LIVE_MEETING}?meeting=${mockedMeeting.id}`
    );
    // window.open(
    //   `http://localhost:3333${ROUTES.MEETING_STATS}/${mockedMeeting.id}`
    // )
  }
  await mockMeeting(timePassedPerSlide, mockedMeeting, hostID, mockedUsers);
  console.log('Mocking is done');
}

// main();
