import { useEffect } from 'react';

function useConditionalTimeout(
  callback: () => void,
  delayInMs: number,
  shouldStart: boolean
) {
  useEffect(() => {
    if (!shouldStart) return;
    const timer = setTimeout(() => {
      callback();
    }, delayInMs);

    return () => {
      clearTimeout(timer);
    };
  }, [shouldStart, delayInMs]);
}

export default useConditionalTimeout;
