import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { Auth } from 'aws-amplify';
import { Observable } from 'zen-observable-ts';
import { IsLocalHost } from './helpers/isLocalHost';

//NOTE: Je mets ça en false, en esperant que je me souviendrais de remettre en true avant de push x)
const shouldLogAPICalls = IsLocalHost();
const shouldLogSubscriptions = false;

export const callGraphQLApi = async <T>(
  query: string,
  variables: object
): Promise<T> => {
  const queryNameRegex = /(query|mutation|subscription)\s+(\w+)/;
  const queryName = query.match(queryNameRegex);

  if (shouldLogAPICalls) console.log(`callGraphQLApi | ${queryName}`);
  try {
    await Auth.currentAuthenticatedUser();
    const res = (await API.graphql({
      query,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as T;
    return res;
  } catch (_) {
    const credentials = await Auth.currentCredentials();
    const res = (await API.graphql({
      query,
      variables,
      authToken: credentials.sessionToken,
    })) as T;
    return res;
  }
};

export const graphqlSubscriber = async <T>(query: any, variables?: object) => {
  const queryNameRegex = /(query|mutation|subscription)\s+(\w+)/;
  const queryName = query.match(queryNameRegex);

  if (shouldLogSubscriptions) console.log(`graphqlSubscriber ${queryName}`);
  try {
    await Auth.currentAuthenticatedUser();
    const res = (await API.graphql({
      query,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as Observable<T>;
    return res;
  } catch (err) {
    const credentials = await Auth.currentCredentials();
    const res = (await API.graphql({
      query,
      variables,
      authToken: credentials.sessionToken,
    })) as Observable<T>;
    return res;
  }
};
