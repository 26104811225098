import { Flex, FlexProps } from '@chakra-ui/react';

function ItemListContainer({ children, ...other }: FlexProps) {
  return (
    <Flex
      justifyContent="space-evenly"
      flexWrap="wrap"
      alignItems="center"
      alignContent="center"
      height="100%"
      width="100%"
      p="20px"
      gap={3}
      data-test="slide-item-container"
      {...other}
      mb="-1000px" // TODO: this is a hack to fix the height of the container, I don't know why but this fixes everything..
    >
      {children}
    </Flex>
  );
}

export default ItemListContainer;
