import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store/StoreHooks';
import { useForm } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import { createACaseCluster } from '@/store/thunk/caseCluster';
import ItemList from '@/ui/ItemList/ItemList';

type Props = {
  closeForm: () => void;
};

type FormData = {
  name: string;
  caseClusterID: string;
  date: string;
  host: string;
  invites: {
    firstName: string;
    email: string;
  }[];
};

const CreateCaseClusterForm = ({ closeForm }: Props) => {
  const dispatch = useAppDispatch();
  const caseList = useCaseList();
  const { t } = useTranslation();
  const [caseClustName, setCaseClusterName] = useState<string>('');
  const [casesAddedList, setCaseAddedList] = useState<string[]>([]);
  const [currentSelectedCase, setCurrentSelectedCase] = useState<
    string | undefined
  >(caseList?.[0].id);

  const {
    register,
    handleSubmit,
    // control,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  // --------------------- handlers ------------------------

  const addCurrentSelectedCase = () => {
    if (
      currentSelectedCase !== undefined &&
      !casesAddedList.includes(currentSelectedCase)
    ) {
      setCaseAddedList([...casesAddedList, ...[currentSelectedCase]]);
    }
  };
  const removeCase = (caseIDToRemove: string) => {
    setCaseAddedList(
      casesAddedList.filter((caseId) => caseId !== caseIDToRemove)
    );
  };

  const onSubmit = (values: FormData) => {
    dispatch(createACaseCluster(values.name, casesAddedList));
    closeForm();
    setCaseAddedList([]);
    setCaseClusterName('');
    if (caseList && caseList.length > 0)
      setCurrentSelectedCase(caseList?.[0].id);
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    //reset  fields when component unmounts
    return () => reset();
  }, [closeForm]);

  useEffect(() => {
    if (caseList && caseList.length > 0) {
      setCurrentSelectedCase(caseList[0].id);
    }
  }, [caseList]);

  // --------------------- render ------------------------
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* NAME */}
      <FormControl isRequired isInvalid={Boolean(errors.name)}>
        <FormLabel fontSize="sm" fontWeight="normal" mb="1">
          {t('admin.caseClusters.createForm.field.name')}:
        </FormLabel>
        <Input
          type="text"
          value={caseClustName}
          {...register('name', { required: true })}
          onChange={(e) => setCaseClusterName(e.target.value)}
          placeholder="Name"
        />
        {errors.name && (
          <FormHelperText color="red">
            {t('admin.caseClusters.createForm.name.error')}
          </FormHelperText>
        )}
      </FormControl>

      {/* CASE LIST */}
      <FormLabel fontSize="sm" fontWeight="normal" mb="1">
        {t('admin.caseClusters.createForm.field.cases')}:
      </FormLabel>
      <VStack p={2} align="flex-start">
        {casesAddedList && (
          <ItemList itemList={casesAddedList} removeItemFunc={removeCase} />
        )}
      </VStack>

      <HStack>
        <Select
          defaultValue={caseList?.[0].id}
          onChange={(el) => setCurrentSelectedCase(el.currentTarget.value)}
        >
          {caseList &&
            caseList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.id}
              </option>
            ))}
        </Select>
        <Button
          size="sm"
          rightIcon={<PlusOutlined />}
          type="button"
          p="8px 30px"
          onClick={addCurrentSelectedCase}
        >
          {t('admin.caseClusters.createForm.field.caseAdd')}
        </Button>
      </HStack>

      {/* SUBMIT */}
      <Button
        isDisabled={casesAddedList.length < 1}
        onClick={handleSubmit(onSubmit)}
        p="2"
        mx="2"
        mt="6"
        w="20%"
        background="blue.400"
        color="white"
        variant="solid"
      >
        {t('common.create')}
      </Button>
    </form>
  );
};

export default CreateCaseClusterForm;
