import { parseDate } from '@/utils/helpers/parseDate';
import { FilterType } from '../NewFilterComponent';
import { DateFilterValue } from '../date/DateInRangeFilter';

type Props = {
  value: unknown;
  filterType: FilterType;
};

const FilterValue = ({ value, filterType }: Props): JSX.Element => {
  switch (filterType) {
    case FilterType.BOOLEAN:
      return <>{value ? 'True' : 'False'}</>;
    case FilterType.DATE: {
      const [startDate, endDate] = value as DateFilterValue;
      if (startDate && endDate) {
        return (
          <>{`${parseDate(startDate.toString())} - ${parseDate(
            endDate.toString()
          )}`}</>
        );
      }
      return <>Invalid date range</>;
    }
    case FilterType.STRING:
      return <>{value}</>;
    case FilterType.SEARCHABLE_SELECT:
      return <>{value}</>;
    default:
      return <>Unsupported filter type</>;
  }
};

export default FilterValue;
