import { ROUTES } from '@/routes/Routes';
import { Meeting } from '@/services/API';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Args = {
  meeting: Meeting | undefined | null;
};

export default function useRedirectHomeIfMeetingIsArchived({ meeting }: Args) {
  const navigate = useNavigate();

  useEffect(() => {
    if (meeting?.isArchived) {
      navigate(ROUTES.HOME);
    }
  }, [meeting?.isArchived, navigate]);
}
