const themeLetterToThemeId: Record<string, string> = {
  A: 'F9-0C-8D',
  B: '61-3E-5A',
  C: 'B7-12-6F',
  D: 'A8-36-19',
  E: '2D-FC-47',
  F: '5E-91-AB',
};

export default function extractThemeIdFromProductId(productId: string): string {
  const letter = productId[0];
  return themeLetterToThemeId[letter];
}
