import * as Sentry from '@sentry/browser';
import { IsLocalHost } from './isLocalHost';

type Args = {
  title: string;
  error?: any;
  detail?: any;
};

export const captureSentry = ({ title, error, detail }: Args) => {
  if (IsLocalHost()) {
    alert(`Sentry Error: ${title}`);
  }
  Sentry.setContext('Title', {
    title: title,
  });
  if (detail) {
    console.warn(`Sentry Detail:`, detail);
    Sentry.setContext('Error Detail', detail);
  }
  console.error(`Sentry Error:`, title, error, detail);
  if (error && error instanceof Error) Sentry.captureException(error);
  else Sentry.captureMessage(title);
};
