import { CheckCircleTwoTone } from '@ant-design/icons';
import UserIcon from '@/components/UserIcon';
import {
  Box,
  keyframes,
  Button,
  Heading,
  Flex,
  Radio,
  Progress,
  Text,
  HStack,
} from '@chakra-ui/react';
import { SlideItem } from '@/utils/types/zod/slideTypes/slideItemTypes/slideItem';
import { SelectableAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/selectableAnswerSlideItemSchema';
import CircleNumberIndicator from './CircleNumberIndicator';
import { useQuestionContext } from '../context/QuestionContext';

const cardGlowAnimation = keyframes`
  0% {
    box-shadow: 0 0 3px rgba(47, 0, 255, 0);
  }
  50% {
    box-shadow: 0 0 30px rgba(47, 0, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 3px rgba(47, 0, 255, 0);
  }
`;

type Props = {
  item: SelectableAnswerSlideItemType;
  isSelected: boolean;
  answerIndex?: number;
  isConfirmed: boolean;
  isGlowing: boolean;
  glowDuration?: number;
  // callbacks
  onMoreInfoCallback: (item: SlideItem) => void;
  onSelectCallback?: () => void;
  onConfirmCallback?: () => void;

  // host part
  isHost: boolean;
  containsHostAsset: boolean;
  // hostAnswersStats?: { total: number; userNames: string[] };
};

const SelectableAnswerItem = ({
  item,
  isSelected,
  isConfirmed,
  onMoreInfoCallback,
  onSelectCallback,
  isHost,
  // hostAnswersStats,
  isGlowing,
  answerIndex,
  containsHostAsset,
  glowDuration = 2000,
}: Props) => {
  const { hostStats: allAnswersHostStats } = useQuestionContext();
  const hostStats = allAnswersHostStats.selectableAnswers.get(item.id);
  const totalAnswers = allAnswersHostStats.totalSelectableAnswerVotes;
  const votingPercentage =
    hostStats && totalAnswers && hostStats.userNames.length > 0
      ? Math.floor(
          (hostStats.userNames.length /
            allAnswersHostStats.totalSelectableAnswerVotes) *
            100
        )
      : 0;

  const voteLabel = isHost
    ? `${hostStats?.userNames.length} / ${totalAnswers} votes (${votingPercentage}%)`
    : `${votingPercentage}%`;
  const showStats = hostStats && (isHost || isConfirmed);

  console.log('DEBUG >>', containsHostAsset);
  return (
    <Box
      animation={
        isGlowing
          ? `${cardGlowAnimation}  ${glowDuration}ms infinite`
          : undefined
      }
      position="relative"
      maxWidth="30vw"
      width="90%"
      minWidth="200px"
      minHeight="0px"
      backgroundColor={isSelected ? 'livelinx.blue50' : 'white'}
      border="solid 1px"
      borderColor={isSelected ? 'livelinx.blue200' : 'livelinx.blue100'}
      padding="2vmin"
      borderRadius="10px"
      cursor="pointer"
      transition="all 0.5s"
      paddingTop={isHost ? '5px' : ''}
      _hover={
        !isHost
          ? { boxShadow: '0 0px 20px 0px rgba(47, 0, 255, 0.2)' }
          : isConfirmed
          ? { boxShadow: 'none' }
          : {}
      }
      onClick={() => {
        if (!isConfirmed && !isHost) onSelectCallback?.();
      }}
    >
      <>
        {/* NOTE: isCorrect has been removed because no case uses it*/}
        {/* {isHost && item.isCorrect && (
          <Box paddingLeft="28px" fontSize="1em" color="livelinx.green">
            {t('correctAnswer')}
          </Box>
        )} */}

        <Flex flexWrap="nowrap" marginLeft="-2.5px" marginRight="-2.5px">
          <Box pr="10px" alignItems="start" pointerEvents="none">
            {!isHost && !isConfirmed && (
              <Radio size="lg" isChecked={isSelected} />
            )}
            {!isHost &&
              !isConfirmed &&
              answerIndex !== undefined &&
              answerIndex !== -1 && (
                <CircleNumberIndicator number={answerIndex + 1} />
              )}
            {!isHost && isConfirmed && isSelected && (
              <CheckCircleTwoTone
                style={{ fontSize: '25px' }}
                twoToneColor="#52c41a"
              />
            )}
          </Box>
          <Flex
            paddingLeft="2.5px"
            padding-right="2.5px"
            flexDirection="column"
            w="full"
          >
            <Heading
              color="livelinx.purple200"
              fontSize="1.2em"
              className="title"
            >
              {item.title}
            </Heading>{' '}
            <Box
              fontSize="2vmin"
              paddingBottom="1vmin"
              color="livelinx.grey200"
              className="description"
            >
              {item.description}
            </Box>
            {item.assetRefs.length > 0 && (
              <Button
                display="block"
                size="sm"
                color="primary"
                background="white"
                fontSize="0.8em"
                borderRadius="5px"
                visibility="visible"
                border="1px solid"
                borderColor="primary"
                transition="all 0.2s ease-out"
                transform="scale(1)"
                marginBottom="0.5em"
                onClick={(event) => {
                  event.stopPropagation();
                  onMoreInfoCallback(item);
                }}
                className="moreInfoButton"
              >
                {item.assetRefs.length > 1
                  ? `Open ${item.assetRefs.length} documents`
                  : `Open ${item.assetRefs.length} document`}
              </Button>
            )}
            {/* {!isHost && !isConfirmed && (

              <ConfirmButton isVisible={isSelected}/>
            )} */}
            {showStats && (
              <Box>
                <Box display="flex" alignItems="center">
                  <Box w="full" position="relative">
                    <Progress
                      variant="answers"
                      width="100%"
                      height="25px"
                      borderRadius={10}
                      value={votingPercentage}
                    />
                    <Text
                      left="10px"
                      top="1px"
                      position="absolute"
                      fontSize="15px"
                      color={
                        votingPercentage > 0 ? 'white' : 'livelinx.grey200'
                      }
                      textShadow={
                        votingPercentage > 0
                          ? '0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black'
                          : ''
                      }
                    >
                      {voteLabel}
                    </Text>
                  </Box>
                </Box>
                <HStack flexDirection="row" pt="5px" spacing="0" wrap="wrap">
                  {hostStats?.userNames.map((username) => (
                    <UserIcon key={username} username={username} variant="xs" />
                  ))}
                </HStack>
              </Box>
            )}
          </Flex>
        </Flex>
      </>
    </Box>
  );
};

export default SelectableAnswerItem;
