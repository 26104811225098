import { useQuery } from '@tanstack/react-query';
import queryUserList from '../graphql/queryUserList';
import { useState } from 'react';
import { ListUsersQuery, User } from '../API';
import { GraphQLResult } from '@aws-amplify/api';

/**
 * use global user list (should be only used in admin actually, or must be filtered somehow)
 */
export function useUserList() {
  const [userList, setUserList] = useState<User[]>([]);
  useQuery({
    queryKey: ['userList'],
    refetchOnWindowFocus: false,
    queryFn: queryUserList,
    onSuccess: (response: GraphQLResult<ListUsersQuery>) => {
      const items = response?.data?.listUsers?.items;
      if (items) {
        setUserList(items as User[]);
      }
    },
    onError: (error) => {
      console.error(`error with userlist:${JSON.stringify(error)}`);
    },
  });

  return userList;
}
