import { ReactNode } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/layout';
import MainFooter from '@/components/MainFooter';
import Header from '@/components/Header/Header';

type Props = {
  showHeader?: boolean;
  showFooter?: boolean;
  footerElem?: React.ReactNode;
  children: ReactNode;
};
export default function PageLayout({
  children,
  showFooter = true,
  footerElem = <MainFooter />,
  showHeader = true,
  ...rest
}: Props & BoxProps) {
  return (
    <Box
      style={{ height: '100vh' }}
      display="flex"
      flex="auto"
      flexDirection="column"
      minHeight="0"
      overflow="hidden"
      {...rest}
    >
      {showHeader && <Header />}
      <Flex direction="column" flex="auto" overflowY="auto">
        {children}
      </Flex>
      {showFooter && footerElem}
    </Box>
  );
}
