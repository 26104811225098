import { getUserClients } from '@/features/userClients/utils/getUserClients';
import { useEffect, useMemo, useState } from 'react';
import {
  OnCreateUserClientSubscription,
  OnCreateUserClientSubscriptionVariables,
  OnDeleteUserClientSubscription,
  UserClient,
} from '../API';
import {
  onCreateUserClient,
  onDeleteUserClient,
} from '../graphql/subscriptions';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';

type Props = {
  userID: string | undefined;
  live?: boolean;
};

/*
Note: the live flag uses subscriptions, do not make the 'live' flag dynamic or you'll get react errors
it should be either true or false, not a variable
*/
export default function useUserClients({ userID, live }: Props) {
  const [userClients, setUserClients] = useState<UserClient[]>([]);

  useEffect(() => {
    if (!userID) {
      return;
    }
    getUserClients(userID)
      .then((clients) => {
        setUserClients(clients);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  if (live) {
    const subscriptions = useMemo(() => {
      if (!userID) return [];
      const variables: OnCreateUserClientSubscriptionVariables = {
        //TODO: Figure out why filters don't seem to work
        // filter: {
        //   userID: {
        //     eq: userID,
        //   },
        // },
      };
      return [
        {
          query: onCreateUserClient,
          variables,
          callback: (data: OnCreateUserClientSubscription) => {
            if (data.onCreateUserClient?.userID !== userID) return;
            setUserClients((prev) => [
              ...prev,
              data.onCreateUserClient as UserClient,
            ]);
          },
        },
        {
          query: onDeleteUserClient,
          variables,
          callback: (data: OnDeleteUserClientSubscription) => {
            if (data.onDeleteUserClient?.userID !== userID) return;
            setUserClients((prev) => {
              const newClients = [...prev];
              const deletedClient = data.onDeleteUserClient as UserClient;
              const index = newClients.findIndex(
                (c) => c.id === deletedClient.id
              );
              if (index !== -1) {
                newClients.splice(index, 1);
              }
              return newClients;
            });
          },
        },
      ];
    }, [userID]);

    useNewSubscriptions(subscriptions, 'useUserClients');
  }

  return userClients;
}
