import { SlideType } from '../enums';
import { AnamneseSlideType } from '../zod/slideTypes/anamneseSlideSchema';
import { NewISlide } from '../zod/slideTypes/slideSchema';

export default function isSlideAnamneseSlide(
  slide: NewISlide
): slide is AnamneseSlideType {
  return slide.type === SlideType.ANAMNESE;
}

export function isSlideNotAnamneseSlide(
  slide: NewISlide
): slide is Exclude<NewISlide, AnamneseSlideType> {
  return slide.type !== SlideType.ANAMNESE;
}
