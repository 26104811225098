import {
  AccessLink,
  ListAccessLinksQuery,
  ListAccessLinksQueryVariables,
} from '@/services/API';
import { listAccessLinks } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { AccessLinkType } from '@/utils/types/enums';
import { GraphQLResult } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';

export default function useCasePreviewLinks(caseId: string | null) {
  const query = useQuery({
    queryKey: ['getCasePreviewLinks', caseId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const variables: ListAccessLinksQueryVariables = {
        filter: {
          purpose: {
            eq: AccessLinkType.CASE_PREVIEW,
          },
        },
      };
      const result = await callGraphQLApi<GraphQLResult<ListAccessLinksQuery>>(
        listAccessLinks,
        variables
      );
      const links: AccessLink[] =
        (result.data?.listAccessLinks?.items as AccessLink[]) || [];
      if (!caseId) return links;
      return links.filter((link) => {
        const data = JSON.parse(link?.data || '{}');
        return data?.caseId === caseId;
      });
    },
  });

  return query;
}
