import useAllClientList from '@/features/clients/hooks/useAllClientList';
import { addUserToClient } from '@/features/userClients/utils/addUserToClient';
import { getUserClients } from '@/features/userClients/utils/getUserClients';
import { removeUserFromClient } from '@/features/userClients/utils/removeUserFromClient';
import { User, UserClient } from '@/services/API';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Select, useToast } from '@chakra-ui/react';
import { Button, Divider, HStack, Tag, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  user: User;
};

export default function ManageUserClientsView({ user }: Props) {
  const { t } = useTranslation();
  //used to force a refetch of the selected user's clients
  const [refetchSelectedUserClients, setRefetchSelectedUserClients] = useState(
    new Date()
  );
  //the clients that this user is already part of
  const [selectedUserClients, setSelectedUserClients] = useState<UserClient[]>(
    []
  );
  //gets all the clients that the user isn't already part of
  const allUserClients = useAllClientList()?.filter((client) => {
    return (
      selectedUserClients.filter((selectedClient) => {
        return selectedClient.client?.clientName === client.clientName;
      }).length === 0
    );
  });
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const toast = useToast();

  useEffect(() => {
    getUserClients(user.id).then((clients) => {
      setSelectedUserClients(clients);
    });
  }, [user.id, refetchSelectedUserClients]);

  const handleAddToClient = () => {
    if (!selectedClient) {
      toast({
        description: t('admin.manageUserView.clients.error.invalid'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    addUserToClient(user.id, selectedClient)
      .then(() => {
        setRefetchSelectedUserClients(new Date());
        toast({
          description: t('admin.manageUserView.clients.success.added'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error: Error) => {
        console.error('addUserToClient error: ', error);
        toast({
          description: t('admin.manageUserView.clients.error.added'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleRemoveFromClient = (client: UserClient) => {
    removeUserFromClient(client)
      .then(() => {
        setSelectedUserClients(
          selectedUserClients.filter((selectedClient) => {
            return selectedClient.id !== client.id;
          })
        );
        toast({
          description: t('admin.manageUserView.clients.success.removed'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          description: t('admin.manageUserView.clients.error.removed'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Text as="b">Clients:</Text>
      <Divider marginY="10px" />
      {selectedUserClients.length === 0 ? (
        <Text>No clients</Text>
      ) : (
        <Flex flexWrap="wrap" gap="10px">
          {selectedUserClients.map((client) => (
            <Tag size="lg" key={client.id} variant="solid">
              {client.client?.clientName}
              {/* <PopConfirm
                title={
                  <div>
                    {t('admin.clients.delete.confirmText')}
                    <br />
                    {t('meeting.header.confirmRestart')}
                  </div>
                }
                onConfirm={() => handleRemoveFromClient(client)}
                okText={t('common.yes') as string}
                cancelText={t('common.no') as string}
              > */}
              <IconButton
                aria-label="delete-client-user"
                icon={<CloseIcon />}
                size="xs"
                style={{ background: 'transparent' }}
                onClick={() => handleRemoveFromClient(client)}
              />
              {/* </PopConfirm> */}
            </Tag>
          ))}
        </Flex>
      )}
      <Divider marginY="10px" />
      {allUserClients && (
        <HStack spacing="5px" justifyContent="space-between" w="full">
          <Select
            flex="1 0 100px"
            placeholder={
              allUserClients.length === 0 ? 'No clients' : 'Select client'
            }
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            {allUserClients.map((client) => {
              return (
                <option key={client.id} value={client.id}>
                  {client.clientName}
                </option>
              );
            })}
          </Select>
          <Button
            variant="secondary"
            title="Add-Client"
            onClick={() => handleAddToClient()}
          >
            {t('admin.manageUserView.button.addClient')}
          </Button>
        </HStack>
      )}
    </>
  );
}
