import useDeviceSize from '@/services/hooks/useDeviceSize';
import { PropsWithChildren } from 'react';
import EmptyPageWithTitle from '../page404/EmptyPageWithTitle.tsx';
import { useTranslation } from 'react-i18next';
import useAdminAutoArchiveMeetings from '@/backend/hooks/useAdminAutoArchiveMeetings.js';

/*
NOTE: This component contains code that should run on any page just like
the layout in nextjs. This is a good place to put global guards
*/
export const GlobalGuard = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();

  useAdminAutoArchiveMeetings();

  if (isMobile) {
    return <EmptyPageWithTitle title={t('mobileview.title')} subtitle="" />;
  }
  return children;
};
