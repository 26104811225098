import APP_CONSTANTS from '@/utils/constants/app.constants';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

type PageTrackProps = {
  pageUrl: string;
  pageTitle: string;
  userId: string;
  userType?: 'Host' | 'User';
  hostId?: string;
  meetingName?: string;
  meetingId?: string;
  caseClusterName?: string;
  patientCase?: string;
};

export const trackPageView = ({
  pageUrl,
  pageTitle,
  userType,
  userId,
  hostId,
  meetingId,
  meetingName,
  caseClusterName,
  patientCase,
}: PageTrackProps) => {
  const data = {
    event: 'VirtualPageview',
    virtualPageURL: pageUrl, // '/caseCluster name/patientcase/slidenumber',
    virtualPageTitle: pageTitle, // 'xxxx',
    userType,
    userId,
    hostId,
    meetingId,
    meetingName,
    caseClusterName,
    patientCase,
  };
  // console.log(`trackView: ${JSON.stringify(data)}`);
  if (!APP_CONSTANTS.IS_LOCALHOST) window.dataLayer.push(data);
};

export const trackData = (data: object) => {
  const eventData = {
    ...{
      event: 'GAData',
    },
    ...data,
  };
  // console.log(`trackData: ${JSON.stringify(eventData)}`);
  if (!APP_CONSTANTS.IS_LOCALHOST) window.dataLayer.push(eventData);
};

export const trackEvent = (
  category: string,
  action: string,
  label = '',
  // value: number | undefined = undefined
  other: object = {}
) => {
  const data = {
    ...{
      event: 'GAEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    },
    ...other,
  };
  // console.log(`trackEvent: ${JSON.stringify(data)}`);
  if (!APP_CONSTANTS.IS_LOCALHOST) window.dataLayer.push(data);
};
