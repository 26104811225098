import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { fetchClientList } from '@/store/thunk/clients';
import { ClientsSelectors } from '@/store/slices/clients';

export default function useAllClientList() {
  const clientsList = useAppSelector(ClientsSelectors.clientsListSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!clientsList) {
      dispatch(fetchClientList());
    }
  }, [clientsList, dispatch]);

  return clientsList;
}
