import ProgressBar from '@/features/meeting-stats/components/ProgressBar';
import { MeetingStatsViewContext } from '@/features/meeting-stats/context/MeetingStatsViewContext';
import { PageLoading } from '@/ui/PageLoading';
import isSlideItemSelectableAnswerItem from '@/utils/types/type-validation/isSlideItemSelectableAnswerItem';
import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { clamp } from 'lodash';
import React, { useContext, useState } from 'react';
import * as skinExpertData from '../../SkinExpertSlide/LorealSkinExpertSlideData.json';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { useParams } from 'react-router-dom';
import { assertIsTruthy } from '@/utils/types/type-assertion/generic/assertIsTruthy';
import { SelectableAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/selectableAnswerSlideItemSchema';
import getSlideVotingStats, {
  SlideVotingStats,
} from '@/features/meeting/answer/getSlideVotingStats';
import StepIndicator from './StepIndicator';
import useStarProductAnswers, {
  createDefaultLorealBrandStats,
} from './useStarProductAnswers';
import { themeIdToProductList } from '../../SkinExpertSlide/LorealSkinExpertSlide';
import extractThemeIdFromProductId from './extractThemeIdFromProductId';
import OptionContainer from './OptionContainer';
import { fetchCaseData } from '@/features/caseCluster/helpers/fetchCaseData';
import { pureFetchCaseList } from '@/store/thunk/caseCluster';

function showTopProducts(
  maxAmt: number,
  map: Map<string, number>,
  language: string
) {
  // NOTE: This creates an array of the ids in descending order
  // NOTE: limit the length of the array to 6 elements
  const descendingIds: string[] = Array.from(map.entries())
    .map(([key, value]) => ({ key, value }))
    .sort((a, b) => b.value - a.value)
    .map((x) => x.key);
  if (descendingIds.length > 4) {
    descendingIds.length = 4;
  }
  return descendingIds.map((id) => {
    const productData = themeIdToProductList[
      extractThemeIdFromProductId(id)
    ].find((x) => x.id === id);
    const title =
      language === 'fr' ? productData?.name_FR : productData?.name_NL;
    const value = map.get(id) || 0;
    const percentage = Math.round((value / maxAmt) * 100);
    return (
      <OptionContainer
        key={id}
        isCurrentSelectedItem={false}
        onClick={() => {}}
        pointerEvents="none"
        direction="row"
        // w="full"
        // height="full"
        height="150px"
        width="375px"
        overflow="hidden"
        bgColor="white"
        borderRadius="10px"
        color="black"
        justifyContent="end"
        justifyItems="center"
        alignItems="center"
        boxShadow="lg"
        position="relative"
      >
        <Stack
          flexDir="row"
          width="100%"
          position="relative"
          display="flex"
          alignItems="center"
          height="100%"
        >
          <Text maxWidth="50%" zIndex={5}>
            {title}
          </Text>
          <Image
            position="absolute"
            right="5px"
            pointerEvents="none"
            userSelect="none"
            src={`/loreal/skinExpert/productImages/${id}.jpg`}
            w="40%"
            h="90%"
            fit="contain"
          />
        </Stack>
        <Stack flexDir="row" width="100%">
          <ProgressBar
            progress={clamp(percentage, 0, 100)}
            width="100%"
            height="20px"
            color="livelinx.purple100"
            bgColor="#2A2E3422"
          />
          <Text>
            {value}/{maxAmt}
          </Text>
          <Text>{percentage}%</Text>
        </Stack>
      </OptionContainer>
    );
  });
}

type Props = {
  themeSelectedCallback: () => void;
  brandSelectedCallback: () => void;
};

function ThemeDistributionPage({
  themeSelectedCallback,
  brandSelectedCallback,
}: Props) {
  const themeQuestionSlideID = '4A-7B-2E';
  const [currentThemeID, setCurrentThemeID] = useState<string>('');
  const [currentBrand, setCurrentBrand] = useState<string>('');
  const { currentCase } = useContext(MeetingStatsViewContext);
  const { meetingID } = useParams();
  const themeOptionsQuery = useQuery<SelectableAnswerSlideItemType[]>({
    queryKey: ['themeOptionsQuery', meetingID],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      const caseList = (await pureFetchCaseList()).filter(
        (elem) => elem.id === currentCase
      );
      // const patientCases = fetchCaseData()
      const patientCase = (
        await fetchCaseData(
          caseList.map((elem) => elem.id),
          caseList.map((elem) => elem.path)
        )
      ).find((elem) => elem.id === currentCase)!;
      const themeOptions = patientCase.slides
        .filter(isSlideQuestionSlide)
        .find((elem) => elem.id === themeQuestionSlideID)!
        .items.filter(isSlideItemSelectableAnswerItem);
      return themeOptions;
    },
  });
  const themeAnswersQuery = useQuery<SlideVotingStats>({
    queryKey: ['themeAnswersQuery', themeOptionsQuery.data],
    refetchOnWindowFocus: false,
    enabled:
      !themeOptionsQuery.isLoading &&
      !themeOptionsQuery.isError &&
      !!themeOptionsQuery.data,
    queryFn: async () => {
      assertIsTruthy(meetingID);
      assertIsTruthy(themeOptionsQuery.data);
      const questionID = composeQuestionID(
        meetingID,
        currentCase,
        themeQuestionSlideID
      );
      const answers = (await listAnswersByQuestionID(questionID)) ?? [];
      const votingStats = getSlideVotingStats(themeOptionsQuery.data, answers);
      return votingStats;
    },
  });
  const productDistributionStats = useStarProductAnswers(
    meetingID,
    currentCase
  );

  if (themeAnswersQuery.isLoading || productDistributionStats.isLoading)
    return <PageLoading />;
  const themeAndBrandKey = `${currentThemeID}_${currentBrand}`;
  const productStatsForCurrentThemeCurrentBrand =
    productDistributionStats.data!.get(themeAndBrandKey) ||
    createDefaultLorealBrandStats();

  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(7, 1fr)"
      gap="5px"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      paddingX="25px"
      marginTop="20px"
    >
      <GridItem rowSpan={2} colSpan={7}>
        <HStack className="theme-selector">
          <StepIndicator title="STEP 1" subtitle="Select a theme" />
          {themeOptionsQuery.data?.map((themeOption) => {
            const isCurrentTheme = currentThemeID == themeOption.id;
            const hasSelectedATheme = currentThemeID !== '';
            const percentage = Math.round(
              ((themeAnswersQuery.data?.selectableAnswers.get(themeOption.id)
                ?.userNames.length || 0) /
                (themeAnswersQuery.data?.totalSelectableAnswerVotes || 1)) *
                100
            );
            return (
              <OptionContainer
                key={themeOption.id}
                isCurrentSelectedItem={isCurrentTheme}
                onClick={() => {
                  setCurrentThemeID(themeOption.id);
                  themeSelectedCallback();
                }}
                opacity={isCurrentTheme || !hasSelectedATheme ? 1 : 0.5}
              >
                <Text noOfLines={2}>{themeOption.title}</Text>
                <Stack flexDir="row" width="100%">
                  <ProgressBar
                    progress={clamp(percentage, 0, 100)}
                    width="90%"
                    height="20px"
                    color="livelinx.purple100"
                    bgColor="#2A2E3422"
                  />
                  <Text>{percentage}%</Text>
                </Stack>
              </OptionContainer>
            );
          })}
        </HStack>
      </GridItem>
      <GridItem rowSpan={1} colSpan={5}>
        <HStack
          className="brand-selector"
          visibility={currentThemeID === '' ? 'hidden' : 'visible'}
          opacity={currentThemeID === '' ? 0 : 1}
          transition="all 1s ease-out"
        >
          <StepIndicator title="FINAL STEP 2" subtitle="Select a brand" />

          {skinExpertData.tree.map((brand) => {
            const key = `${currentThemeID}_${brand.brand}`;
            const brandStats =
              productDistributionStats.data!.get(key) ||
              createDefaultLorealBrandStats();
            const totalProductsSelectedInTheme =
              skinExpertData.tree.reduce((acc, curr) => {
                const stats =
                  productDistributionStats.data!.get(
                    `${currentThemeID}_${curr.brand}`
                  ) || createDefaultLorealBrandStats();
                return acc + stats.totalProductsSelected;
              }, 0) || 1;
            const percentage = Math.round(
              (brandStats.totalProductsSelected /
                totalProductsSelectedInTheme) *
                100
            );
            const isCurrentBrand = currentBrand === brand.brand;
            const hasSelectedABrand = currentBrand !== '';
            return (
              <OptionContainer
                isCurrentSelectedItem={isCurrentBrand}
                onClick={() => {
                  setCurrentBrand(brand.brand);
                  brandSelectedCallback();
                }}
                key={brand.brand}
                justifyContent="end"
                opacity={isCurrentBrand || !hasSelectedABrand ? 1 : 0.5}
              >
                <Box
                  width="100%"
                  height="80%"
                  backgroundImage={`/loreal/skinExpert/logos/${brand.brand}.jpg`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="50%"
                />
                <Stack flexDir="row" width="100%">
                  <ProgressBar
                    progress={clamp(percentage, 0, 100)}
                    width="90%"
                    height="20px"
                    color="livelinx.purple100"
                    bgColor="#2A2E3422"
                  />
                  <Text>{percentage}%</Text>
                </Stack>
              </OptionContainer>
            );
          })}
        </HStack>
      </GridItem>
      <GridItem rowSpan={1} colSpan={7}>
        <HStack
          className="product-results"
          visibility={currentBrand === '' ? 'hidden' : 'visible'}
          opacity={currentBrand === '' ? 0 : 1}
          transition="all 1s ease-out"
        >
          <StepIndicator
            title="RESULTS"
            subtitle="View the top selected products for this theme and brand"
            height="150px"
          />

          {productStatsForCurrentThemeCurrentBrand.totalProductsSelected ===
          0 ? (
            <Text>No products for this theme and brand combination</Text>
          ) : (
            showTopProducts(
              productStatsForCurrentThemeCurrentBrand.totalProductsSelected,
              productStatsForCurrentThemeCurrentBrand.productToAmtSelected,
              currentCase === 'lorealFR' ? 'fr' : 'nl'
            )
          )}
        </HStack>
      </GridItem>
    </Grid>
  );
}

export default ThemeDistributionPage;
