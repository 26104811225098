import LiveMeetingFooter from '@/components/LiveMeetingFooter';
import { ROUTES } from '@/routes/Routes';
import { useVideoCall } from '@/features/call/useVideoCall';
import { MeetingSelectors } from '@/store/slices/meeting';
import { trackEvent } from '@/features/tracking/trackingHelpers';
import CaseDisclaimerView from '@/pages/caseCluster/disclaimer/CaseDisclaimerView';
import { useUserMeetingPing } from '@/services/hooks/useUserMeetingPing';
import APP_CONSTANTS, { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import { CustomSlideIndex, MeetingErrorType, TABS } from '@/utils/types/enums';
import useBrowserLeaveCallback from '@/utils/helpers/hooks/useBrowserLeaveCallback';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CaseSelectorView from './CaseSelectorView';
import LiveMeetingSider from './Sider/LiveMeetingSider';
import { leaveAMeeting } from '@/store/thunk/meeting';
import Result from '@/ui/Result/Result';
import PageLayout from '@/ui/Layout/PageLayout';
import LiveMeetingHeader from './LiveMeetingHeader';
import { PageLoading } from '@/ui/PageLoading';
import { useMeetingStatsCreationContext } from '@/features/meeting-stats/context/MeetingStatsCreationContext';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { isEmpty } from 'lodash';
import { reloadApp } from '@/utils/reloadApp';
import useMeetingParticipants from '@/features/meeting/hooks/useMeetingParticipantsList';
import useChatMessages from '@/features/call/useChatMessages';
import MeetingRightSideBar, {
  MeetingRightSidebarProps,
} from './MeetingRightSideBar/MeetingRightSideBar';
import PictureInPictureFeature from '@/features/PictureInPictureFeature/PictureInPictureFeature';
import useShowDisclaimer from '@/services/hooks/useShowDisclaimer';
import CaseExplorerClient from './CaseExplorer/CaseExplorerClient';
import AssetPreloader from '@/features/assetPreloader/AssetPreloader';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import RateMeeting from './ratecaseCluster/RateMeeting';
import useNavigateHomeOnMeetingDeletion from './hooks/useNavigateHomeOnMeetingDeletion';
/* --------------------- comp ------------------------ */

function LiveMeetingView() {
  const [currentTab, setCurrentTab] = useState(TABS.USERS);
  const { user: currentUser, isAdmin } = useUserAuthenticationContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    // trackAnswer,
    trackAssetSwitch,
    trackMeetingLeave,
    trackCaseClusterStop,
  } = useMeetingStatsCreationContext();

  const {
    meeting: currentMeeting,
    caseCluster,
    caseMap,
    currentCase,
    currentCaseIndex,
    isCaseLoading,
    isUserHost: isHost,
    meetingInteractions,
    isPreview,
  } = useCurrentMeetingDataContext();

  const error = useAppSelector(MeetingSelectors.getError);
  const participants = useMeetingParticipants(currentMeeting?.id);
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  const useChatMessageUtils = useChatMessages(currentMeeting?.id);
  const [isInPipMode, setIsInPipMode] = useState(false);

  const userID = currentUser?.id;
  const { leaveCall: leaveVideoCall } = useVideoCall();

  const isNPSScorePage: boolean =
    currentMeeting?.currentSlide === CustomSlideIndex.NPS_SCORE || false;

  const showDisclaimer = useShowDisclaimer({
    currentCase: currentCase,
    isNPSScorePage: isNPSScorePage,
  });

  useNavigateHomeOnMeetingDeletion({
    meetingID: new URLSearchParams(location.search).get('meeting'),
  });

  // --------------------- handlers ------------------------

  const changeTab = (newTab: TABS) => {
    // setSiderCollapsed(!siderCollapsed);
    if (currentTab !== newTab) {
      setCurrentTab(newTab);
    }
    setSiderCollapsed(false);
  };

  // handle meeting restart
  const handleMeetingRestart = () => {
    if (!currentMeeting) {
      captureSentry({
        title: 'handleMeetingRestart: no current meeting',
        detail: {
          currentMeeting,
        },
      });
      return;
    }
    trackEvent('meeting', 'restart');
    meetingInteractions.restartMeeting(currentMeeting.id);
  };

  const handleMeetingLeave = () => {
    leaveVideoCall();
    trackMeetingLeave();
    if (currentMeeting && currentUser) {
      dispatch(leaveAMeeting(currentUser.id, currentMeeting.id));
      // setLeaveMeeting(true);
      navigate(ROUTES.HOME, { replace: true });
    }
  };

  const closeMeetingSider = () => {
    setSiderCollapsed(true);
    // setCurrentTab(TABS.USERS);
  };

  const forceRefreshLiveMeeting = () => {
    captureSentry({
      title: 'Force refresh Live Meeting',
      detail: {
        currentMeeting,
        caseCluster,
        caseMap,
        currentCase,
        currentCaseIndex,
        isCaseLoading,
        isHost,
      },
    });
    reloadApp();
  };

  const meetingRightSidebarProps: MeetingRightSidebarProps = {
    onParticipantsClick: () => {
      if (currentTab === TABS.USERS && !siderCollapsed) {
        closeMeetingSider();
        return;
      }
      changeTab(TABS.USERS);
    },
    onChatClick: () => {
      if (currentTab === TABS.CHAT && !siderCollapsed) {
        closeMeetingSider();
        return;
      }
      changeTab(TABS.CHAT);
      useChatMessageUtils.markAllMessagesAsRead();
    },
    onVideoClick: () => {
      setIsInPipMode(!isInPipMode);
    },
    currentTab: currentTab,
    amtUnreadMessages: useChatMessageUtils.unreadMessages,
  };

  // --------------------- effect ------------------------

  // ping user meeting
  useUserMeetingPing(currentUser, currentMeeting);

  // Join the meeting from parameter ID
  useEffect(() => {
    if (!currentUser) return;
    if (!location.search) {
      navigate(ROUTES.HOME, { replace: true });
      return;
    }
    const meetingID = new URLSearchParams(location.search).get('meeting');
    if (!currentMeeting || currentMeeting.id !== meetingID) {
      if (meetingID) {
        dispatch(
          meetingInteractions.joinAMeeting(currentUser.id, meetingID, false)
        );
      }
    }
    /*
    //Note here we are checking when the user's id changes and not just the user because otherwise we would be calling joinAMeeting everytime the user changes
    */
  }, [currentUser?.id, currentMeeting?.id]);

  // -> track leaving meeting on Browser leave
  useBrowserLeaveCallback(() => {
    leaveVideoCall();
    //NOTE: This does not work properly (but we have the ping system instead)
    // trackMeetingLeave();
  });

  // --------------------- RENDER ------------------------

  // --> Redirect to home if no meeting
  if (!new URLSearchParams(location.search).get('meeting')) {
    return <Navigate replace to={ROUTES.HOME} />;
  }

  // --> Render Loading
  if (isCaseLoading || !currentMeeting || !caseMap || isEmpty(caseMap)) {
    return <PageLoading />;
  }

  // --> Render ERROR with meeting
  if (error && error.toString().includes(MeetingErrorType.error)) {
    return (
      <>
        <Result
          type="warning"
          title={t('home.noMeetingFound')}
          extra={
            <Button
              variant="blue"
              key="console"
              onClick={() => navigate('/', { replace: true })}
            >
              {t('common.goBack')}{' '}
            </Button>
          }
        />
      </>
    );
  }

  // --------------------- RENDER ------------------------

  return (
    <PageLayout showHeader={false} showFooter={false} bgColor="white">
      {/* HEADER */}
      <LiveMeetingHeader
        currentMeeting={currentMeeting}
        currentCaseIndex={currentCaseIndex}
        currentUser={currentUser}
        isHost={isHost}
        isAdmin={isAdmin}
        onRestartMeeting={handleMeetingRestart}
      />

      {/* CONTENT */}
      <Flex
        flex="auto"
        direction="row"
        overflow="hidden"
        position="relative"
        className="pip-container"
      >
        <>
          {userID && currentCase?.id && !isCaseLoading && !isNPSScorePage && (
            <>
              {currentCase.disclaimer && !!showDisclaimer ? (
                <CaseDisclaimerView
                  disclaimer={currentCase.disclaimer}
                  assetPath={`${APP_CONSTANTS.PATIENT_CASES_REPO_PATH}${currentCase.path}`}
                />
              ) : (
                <CaseExplorerClient
                  patientCaseIndex={currentCaseIndex}
                  patientCase={currentCase}
                  meeting={currentMeeting}
                  isHost={isHost}
                  trackAssetSwitch={isPreview ? undefined : trackAssetSwitch}
                />
              )}
            </>
          )}

          {!currentCase && caseCluster && !isNPSScorePage && (
            <CaseSelectorView
              isHost={isHost}
              caseIDList={caseCluster.cases}
              caseMap={caseMap}
              onCaseSelected={(caseID) => {
                if (isHost) {
                  meetingInteractions.updateCurrentCase(
                    currentMeeting.id,
                    caseID
                  );
                }
              }}
            />
          )}
          {isNPSScorePage && <RateMeeting isHost={isHost} />}
        </>

        <Box
          h="full"
          position="absolute"
          top="0"
          right={siderCollapsed ? -350 : 0}
          transition="all 0.2s ease-in-out"
        >
          <Box
            display={siderCollapsed ? 'none' : 'block'}
            zIndex={0}
            position="fixed"
            w="100vw"
            h="100vh"
            bg="#ffffff66"
            left="0"
            onClick={closeMeetingSider}
          />
          <LiveMeetingSider
            currentTab={currentTab}
            siderCollapsed={siderCollapsed}
            closeMeetingSider={closeMeetingSider}
            useChatMessageUtils={useChatMessageUtils}
          />

          <MeetingRightSideBar {...meetingRightSidebarProps} />
        </Box>

        <PictureInPictureFeature
          isInPipMode={isInPipMode}
          meeting={currentMeeting}
          user={currentUser}
          isHost={isHost}
          isAdmin={isAdmin}
        />
      </Flex>

      {/* MENU BAR / FOOTER */}

      <LiveMeetingFooter
        isAdmin={isAdmin}
        isHost={isHost}
        isAllowedToNavigate={
          (!currentCase?.disclaimer || !showDisclaimer) && !isNPSScorePage
        }
        leaveWarningTitle={
          isHost && !isNPSScorePage
            ? t('meeting.leave.warn.host.title')
            : t('meeting.leave.warn.title')
        }
        onLeaveMeeting={() => {
          // CASE GO TO NPS
          if (
            isHost &&
            participants.length > 1 &&
            currentMeeting.currentSlide !== CustomSlideIndex.NPS_SCORE
          ) {
            trackCaseClusterStop();
            meetingInteractions.showNPSScoreView(currentMeeting, NULL_ID_VALUE);
          } else {
            handleMeetingLeave();
          }
        }}
        onRefresh={forceRefreshLiveMeeting}
      />

      {caseMap &&
        currentMeeting &&
        currentMeeting.meetingType === MeetingTypes.LIVE && (
          <AssetPreloader caseMap={caseMap} />
        )}
    </PageLayout>
  );
}

export default LiveMeetingView;
