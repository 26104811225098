export const isUrl = (data: string): boolean => {
  const pattern = new RegExp(
    '^' +
      // Protocol
      '(https?:\\/\\/)' +
      // Domain name or IP address
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      // Port (optional)
      '(\\:\\d+)?' +
      // Path (optional)
      '(\\/[-a-z\\d%_.~+]*)*' +
      // Query string (optional)
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      // Fragment (optional)
      '(\\#[-a-z\\d_]*)?' +
      '$',
    'i'
  ); // fragment locator
  return pattern.test(data);
};
