import { GraphQLResult } from '@aws-amplify/api';
import { actions } from '@/store/slices/clients';
import { AppDispatch } from '@/store/Store';
import { listClients } from '@/services/graphql/queries';
import { ListClientsQuery, Client } from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export function fetchClientList() {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setLoading(true));
    try {
      const response = await callGraphQLApi<GraphQLResult<ListClientsQuery>>(
        listClients,
        {}
      );
      const items = response?.data?.listClients?.items as Client[];
      if (items) {
        dispatch(actions.setClientsList(items));
      }
    } catch (error) {
      console.error(`error at fetchClientList:`);
      dispatch(actions.setError(error));
    }
  };
}
