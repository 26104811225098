import { CaseStats } from '@/services/API';

export function getCaseIdFromCaseStatsList(
  caseStatsID: string,
  caseStatsList: CaseStats[]
) {
  return (
    caseStatsList.find((caseStats) => caseStats.id === caseStatsID)?.caseId ||
    null
  );
}
