import { getUserClients } from '@/features/userClients/utils/getUserClients';
import { useEffect, useState } from 'react';
import { UserClient } from '../API';

type Props = {
  userIDs: string[] | undefined;
};

export default function useUserClientsForUserList({ userIDs }: Props) {
  const [userClients, setUserClients] = useState<Record<string, UserClient[]>>(
    {}
  );
  const isLoading = !userClients || !Object.keys(userClients).length;

  useEffect(() => {
    if (!userIDs || !userIDs.length) {
      return;
    }
    const promises = userIDs.map((userID) => {
      return getUserClients(userID);
    });
    const fetchClients = async () => {
      const clients = await Promise.all(promises);
      const clientsMap: Record<string, UserClient[]> = {};
      userIDs.forEach((userID, index) => {
        clientsMap[userID] = clients[index];
      });
      setUserClients(clientsMap);
    };
    fetchClients();
  }, [userIDs]);
  return { data: userClients, isLoading };
}
