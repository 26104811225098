import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';
import { ClickupTask } from './clickup.types';

const useAdminClickupTableStore = createTanstackTableDataStore<ClickupTask>({
  storeName: 'admin-clickup-table-store',
  defaultColumnSizing: {},
  defaultColumnVisibility: {},
});

export default useAdminClickupTableStore;
