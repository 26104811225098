import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  number: number;
} & FlexProps;

function CircleNumberIndicator({ number, ...rest }: Props) {
  return (
    <Flex
      background="livelinx.purple100"
      width="40px"
      // height="50px"
      aspectRatio={1}
      position="absolute"
      top="-20px"
      right="-20px"
      borderRadius="100%"
      border="3px solid"
      borderColor="livelinx.purple200"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Text color="white">{number}</Text>
    </Flex>
  );
}

export default CircleNumberIndicator;
