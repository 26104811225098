import { ROUTES } from '@/routes/Routes';

type Args = {
  meetingID: string;
  testMode: boolean;
};

export const goToLiveMeetingPage = (args: Args) => {
  let newUrl = `${ROUTES.LIVE_MEETING}?meeting=${args.meetingID}`;
  if (args.testMode) {
    newUrl = `${newUrl}&testMode=true`;
  }
  window.location.href = newUrl;
};
