export enum SlideType {
  NORMAL = 'simple',
  ANAMNESE = 'anamnese',
  QUESTION_SINGLE = 'question_single_choice',
  QUESTION_MULTIPLE = 'question_multiple_choice',
  QUESTION_MULTIPLE_ORDERED = 'question_multiple_choice_ordered',
  CUSTOM = 'custom',
  IMAGE = 'image',
}

export type QuestionTypes =
  | SlideType.QUESTION_SINGLE
  | SlideType.QUESTION_MULTIPLE
  | SlideType.QUESTION_MULTIPLE_ORDERED;

export enum SlideItemType {
  INFO = 'info',
  ANSWER_SELECTABLE = 'answer_selectable',
  ANSWER_TEXT = 'answer_text',
  ANAMNESE = 'anamnese',
  IMAGE = 'image',
}

export enum AssetType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum MeetingErrorType {
  error = 'No meeting',
}

export enum CustomSlideIndex {
  CASE_SELECTOR = 0,
  NPS_SCORE = 999,
}

export enum TABS {
  VIDEO = 'video',
  CHAT = 'chat',
  USERS = 'users',
}

export enum CustomSlides {
  LOREAL_DRAG_AND_DROP = 1,
  LOREAL_CUSTOM_2 = 2,
  LOREAL_CUSTOM_3 = 3,
  LOREAL_CUSTOM_4 = 4,
  LOREAL_CUSTOM_5 = 5,
  LOREAL_AS_PROMOTER_STATS = 6,
}

export enum CaseSelectorItemOverrideType {
  TEXT = 'text',
  // in the future image ?
}

export enum DisclaimerType {
  POWERED_BY = 'powered_by',
  IMAGE = 'image',
}

export enum TopRightType {
  TEXT = 'text',
  QUESTION_ANSWER = 'question_answer',
}

export enum AccessLinkType {
  CASE_PREVIEW = 'case_preview',
}
