import { isSlideNotAnamneseSlide } from '@/utils/types/type-validation/isSlideAnamneseSlide';

import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import { ParsedAssetViews } from '../parseAssetViewsPerCase';
import { isSlideNotImageSlide } from '@/utils/types/type-validation/isSlideImageSlide';
import { SlideType } from '@/utils/types/enums';

export class CaseAssetViewStats {
  avgOpenRate: AssetOpenRate;
  avgViewDuration: AssetViewDuration;

  slideOpenRates: SlideAssetOpenRate[];
  slideViewDurations: SlideAssetViewDuration[];

  constructor() {
    this.avgOpenRate = new AssetOpenRate();
    this.avgViewDuration = new AssetViewDuration();

    this.slideOpenRates = [];
    this.slideViewDurations = [];
  }
}

export class SlideAssetOpenRate {
  slideId: string;
  avgOpenRate: AssetOpenRate;
  participantOpenRatesForSlide: ParticipantAssetOpenRate[];

  constructor() {
    this.avgOpenRate = new AssetOpenRate();
    this.participantOpenRatesForSlide = [];
    this.slideId = '-1';
  }
}

export class SlideAssetViewDuration {
  avgViewDuration: AssetViewDuration;
  participantViewDurationsForSlide: ParticipantAssetViewDuration[];

  constructor() {
    this.avgViewDuration = new AssetViewDuration();
    this.participantViewDurationsForSlide = [];
  }
}

export class ParticipantAssetOpenRate {
  avgOpenRate: AssetOpenRate;
  assetOpenRatesForParticipant: AssetOpenRate[];

  constructor() {
    this.avgOpenRate = new AssetOpenRate();
    this.assetOpenRatesForParticipant = [];
  }
}

export class ParticipantAssetViewDuration {
  avgViewDuration: AssetViewDuration;
  assetViewDurationsForParticipant: AssetViewDuration[];

  constructor() {
    this.avgViewDuration = new AssetViewDuration();
    this.assetViewDurationsForParticipant = [];
  }
}

export class AssetOpenRate {
  cappedAssetOpenRate: number;
  uncappedAssetOpenRate: number;

  constructor() {
    this.cappedAssetOpenRate = 0;
    this.uncappedAssetOpenRate = 0;
  }

  calculateAvgOpenRate(openRates: AssetOpenRate[]) {
    const cappedAvg =
      openRates.reduce((acc, curr) => {
        acc += curr.cappedAssetOpenRate;
        return acc;
      }, 0) / openRates.length;
    const uncappedAvg =
      openRates.reduce((acc, curr) => {
        acc += curr.uncappedAssetOpenRate;
        return acc;
      }, 0) / openRates.length;
    this.cappedAssetOpenRate = cappedAvg;
    this.uncappedAssetOpenRate = uncappedAvg;
  }
}

export class AssetViewDuration {
  cappedAssetViewDuration: number;
  uncappedAssetViewDuration: number;

  constructor() {
    this.cappedAssetViewDuration = 0;
    this.uncappedAssetViewDuration = 0;
  }

  calculateAvgViewDuration(viewDurations: AssetViewDuration[]) {
    const cappedAvg =
      viewDurations.reduce((acc, curr) => {
        acc += curr.cappedAssetViewDuration;
        return acc;
      }, 0) / viewDurations.length;
    const uncappedAvg =
      viewDurations.reduce((acc, curr) => {
        acc += curr.uncappedAssetViewDuration;
        return acc;
      }, 0) / viewDurations.length;
    this.cappedAssetViewDuration = cappedAvg;
    this.uncappedAssetViewDuration = uncappedAvg;
  }
}

/*
Explanation:
  per slide:
    per participant:
      per asset in slide:
        amtUserAssetOpensForThisAsset (cap at 1)
      sum for all assets
      divide by amt of assets
      = assetOpenRate for this participant for this slide
    sum for all participants
    divide by amt of participants
    = assetOpenRate for this slide
  sum for all slides
  divide by amt of slides
  = assetOpenRate for this meeting
*/
export default function calculateAssetViewStats(
  participantIDs: string[],
  slides: NewISlide[],
  parsedAssetViews: ParsedAssetViews[]
): CaseAssetViewStats {
  //get all assets per slide, and remove slides with no assets
  const assetsIdsPerSlide = slides
    .filter(isSlideNotAnamneseSlide)
    .filter(isSlideNotImageSlide)
    .map((slide) => {
      //NOTE: Typescript doesn't know that we filtered out the anamnese and image slides (it can't chain filter type narrowing...)
      const directClickableAssets: string[] = (
        slide as NewISlide & {
          type: Exclude<SlideType, SlideType.ANAMNESE | SlideType.IMAGE>;
        }
      ).items.flatMap((item) => item.assetRefs);

      const libraryAssets: string[] = slide.libraryRefs;
      return {
        slideId: slide.id,
        assets: [...directClickableAssets, ...libraryAssets],
      };
    })
    .filter((info) => info.assets.length > 0);

  const caseViewStats: CaseAssetViewStats = new CaseAssetViewStats();

  for (const slideInfo of assetsIdsPerSlide) {
    const slideOpenRate: SlideAssetOpenRate = new SlideAssetOpenRate();
    const slideViewDuration: SlideAssetViewDuration =
      new SlideAssetViewDuration();
    slideOpenRate.slideId = slideInfo.slideId;
    const slideAssets = slideInfo.assets;
    for (const participantID of participantIDs) {
      const participantOpenRate: ParticipantAssetOpenRate =
        new ParticipantAssetOpenRate();
      const participantViewDuration: ParticipantAssetViewDuration =
        new ParticipantAssetViewDuration();
      for (const assetID of slideAssets) {
        const currentAssetStats = parsedAssetViews.find(
          (elem) => elem.id === assetID
        );
        if (!currentAssetStats) continue;
        //amount of times this participant opened this asset
        const amtParticipantAssetOpens =
          currentAssetStats.viewDistribution?.get(participantID) ?? 0;
        //time in seconds spent on avg for this participant on this asset
        const participantAssetOpenDuration =
          currentAssetStats.timeDistribution?.get(participantID) ?? 0;
        const assetOpenRate: AssetOpenRate = new AssetOpenRate();
        const assetViewDuration: AssetViewDuration = new AssetViewDuration();

        //Note: saving capped and uncapped values
        assetOpenRate.cappedAssetOpenRate = Math.min(
          1,
          amtParticipantAssetOpens
        );
        assetOpenRate.uncappedAssetOpenRate = amtParticipantAssetOpens;
        participantOpenRate.assetOpenRatesForParticipant.push(assetOpenRate);

        assetViewDuration.cappedAssetViewDuration = Math.min(
          9,
          participantAssetOpenDuration
        );
        assetViewDuration.uncappedAssetViewDuration =
          participantAssetOpenDuration;
        participantViewDuration.assetViewDurationsForParticipant.push(
          assetViewDuration
        );
      }
      //calculate the averages for participants
      participantOpenRate.avgOpenRate.calculateAvgOpenRate(
        participantOpenRate.assetOpenRatesForParticipant
      );
      participantViewDuration.avgViewDuration.calculateAvgViewDuration(
        participantViewDuration.assetViewDurationsForParticipant
      );
      slideOpenRate.participantOpenRatesForSlide.push(participantOpenRate);
      slideViewDuration.participantViewDurationsForSlide.push(
        participantViewDuration
      );
    }
    //calculate averages for slides
    /*
    TODO Note: Potential error: we divide by the participantsIDs length, but there is
    no guarantee that all participants have opened the slide/ an asset on this slide
    */
    slideOpenRate.avgOpenRate.cappedAssetOpenRate =
      slideOpenRate.participantOpenRatesForSlide.reduce((acc, curr) => {
        acc += curr.avgOpenRate.cappedAssetOpenRate;
        return acc;
      }, 0) / participantIDs.length;
    slideOpenRate.avgOpenRate.uncappedAssetOpenRate =
      slideOpenRate.participantOpenRatesForSlide.reduce((acc, curr) => {
        acc += curr.avgOpenRate.uncappedAssetOpenRate;
        return acc;
      }, 0) / participantIDs.length;
    slideViewDuration.avgViewDuration.cappedAssetViewDuration =
      slideViewDuration.participantViewDurationsForSlide.reduce((acc, curr) => {
        acc += curr.avgViewDuration.cappedAssetViewDuration;
        return acc;
      }, 0) / participantIDs.length;
    slideViewDuration.avgViewDuration.uncappedAssetViewDuration =
      slideViewDuration.participantViewDurationsForSlide.reduce((acc, curr) => {
        acc += curr.avgViewDuration.uncappedAssetViewDuration;
        return acc;
      }, 0) / participantIDs.length;
    caseViewStats.slideOpenRates.push(slideOpenRate);
    caseViewStats.slideViewDurations.push(slideViewDuration);
  }
  //Note: It's important to divide by the amount of slides that have assets and not the total amt of slides
  caseViewStats.avgOpenRate.cappedAssetOpenRate =
    caseViewStats.slideOpenRates.reduce((acc, curr) => {
      acc += curr.avgOpenRate.cappedAssetOpenRate;
      return acc;
    }, 0) / assetsIdsPerSlide.length;
  caseViewStats.avgOpenRate.uncappedAssetOpenRate =
    caseViewStats.slideOpenRates.reduce((acc, curr) => {
      acc += curr.avgOpenRate.uncappedAssetOpenRate;
      return acc;
    }, 0) / assetsIdsPerSlide.length;
  caseViewStats.avgViewDuration.cappedAssetViewDuration =
    caseViewStats.slideViewDurations.reduce((acc, curr) => {
      acc += curr.avgViewDuration.cappedAssetViewDuration;
      return acc;
    }, 0) / assetsIdsPerSlide.length;
  caseViewStats.avgViewDuration.uncappedAssetViewDuration =
    caseViewStats.slideViewDurations.reduce((acc, curr) => {
      acc += curr.avgViewDuration.uncappedAssetViewDuration;
      return acc;
    }, 0) / assetsIdsPerSlide.length;

  //make sure that there are no NaN
  caseViewStats.avgOpenRate.cappedAssetOpenRate =
    caseViewStats.avgOpenRate.cappedAssetOpenRate || 0;
  caseViewStats.avgOpenRate.uncappedAssetOpenRate =
    caseViewStats.avgOpenRate.uncappedAssetOpenRate || 0;
  caseViewStats.avgViewDuration.cappedAssetViewDuration =
    caseViewStats.avgViewDuration.cappedAssetViewDuration || 0;
  caseViewStats.avgViewDuration.uncappedAssetViewDuration =
    caseViewStats.avgViewDuration.uncappedAssetViewDuration || 0;
  return caseViewStats;
}
