import { FilterFn } from '@tanstack/react-table';

export const dateInRangeFilterFn: FilterFn<any> = (
  row,
  columnId,
  filterValue
) => {
  const date = new Date(row.getValue(columnId));
  let [start, end] = filterValue;
  if (typeof start === 'string') start = new Date(start);
  if (typeof end === 'string') end = new Date(end);
  //If one filter defined and date is null filter it
  if ((start || end) && !date) return false;
  if (start && !end) {
    return date.getTime() >= start.getTime();
  } else if (!start && end) {
    return date.getTime() <= end.getTime();
  } else if (start && end) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
  }
  return true;
};

dateInRangeFilterFn.autoRemove;

export default dateInRangeFilterFn;
