import { mutationDeleteAllNPSScoresForMeeting } from '@/features/meeting-stats/graphql/delete';
import { mutationDeleteAllAnswersForMeeting } from '@/features/meeting/answer/mutationDeleteAnswer';
import mutationDeleteAllMessagesForMeeting from '@/features/meeting/messages/mutationDeleteAllMessagesForMeeting';
import { UpdateMeetingInput, UpdateMeetingMutation } from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import pureResetCaseStatsForMeeting from './pureResetMeetingStats';
import mutationResetMeetingStats from './utils/mutationResetMeetingStats';

/*
- Responsible for resetting a meeting to its initial state.
*/
export default async function pureRestartMeeting(meetingID: string) {
  await mutationDeleteAllAnswersForMeeting(meetingID);
  await mutationDeleteAllMessagesForMeeting(meetingID);
  await pureResetCaseStatsForMeeting(meetingID);
  await mutationDeleteAllNPSScoresForMeeting(meetingID);
  await mutationResetMeetingStats(meetingID);

  // reset meeting
  const input: UpdateMeetingInput = {
    id: meetingID,
    currentCase: 'null',
    currentSlide: 0,
  };
  await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(updateMeeting, {
    input,
  });
}
