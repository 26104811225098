import { z } from 'zod';
import { AssetType } from '../enums';

const IBaseAssetSchema = z.object({
  id: z.string(),
  title: z.string(),
  type: z.nativeEnum(AssetType),
  path: z.string(),

  //Optional
  //constructed by front end (casePath + path)
  url: z.string().optional(),
  /*
    constructed by front end
      if video: (casePath + thumbPath)
      if image: url
    */
  thumbUrl: z.string().optional(),
});

const IImageAssetSpecificSchema = IBaseAssetSchema.and(
  z.object({
    type: z.literal(AssetType.IMAGE),
  })
);

const IVideoAssetSpecificSchema = IBaseAssetSchema.and(
  z.object({
    type: z.literal(AssetType.VIDEO),
    thumbPath: z.string(),
  })
);

export const IAssetSchema = z.union([
  IImageAssetSpecificSchema,
  IVideoAssetSpecificSchema,
]); //IImageAssetSpecificSchema.or(IVideoAssetSpecificSchema);

export type NewIAsset = z.infer<typeof IAssetSchema>;
