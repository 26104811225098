import { UserType } from '../types/types';

export const getUserType = (
  isHost: boolean,
  isGuest: boolean,
  isAdmin: boolean
) => {
  if (isAdmin) return UserType.ADMIN;
  if (isHost) return UserType.HOST;
  if (isGuest) return UserType.GUEST;
  return UserType.ALL;
};
