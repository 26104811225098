import { z } from 'zod';
import { TopRightType } from '../../enums';

export const questionAnswerTopRightSchema = z.object({
  type: z.literal(TopRightType.QUESTION_ANSWER),
  questionId: z.string(),
  defaultTitle: z.string(),
});

export type QuestionAnswerTopRightType = z.infer<
  typeof questionAnswerTopRightSchema
>;
