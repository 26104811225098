export type PreviewCaseAccessLinkPayload = {
  caseId: string;
  validUntil: number;
};

export enum UserType {
  ALL = 'all',
  GUEST = 'guest',
  HOST = 'host',
  ADMIN = 'admin',
}
