import { Avatar, Flex, Tag, Text } from '@chakra-ui/react';
import ChunkContainer from './ChunkContainer';
// import defaultAvatar from '@/assets/images/Host.png';
import useDeviceSize from '@/services/hooks/useDeviceSize';
import { MeetingStatsViewContext } from '../context/MeetingStatsViewContext';
import { useContext } from 'react';
import { DEFAULT_HOST_NAME } from '@/utils/constants/app.constants';

//MEETING_STATS_TODO: Make this dynamic when we have the host info
export default function Host() {
  const { isDesktopOrLaptop } = useDeviceSize();
  const { meetingHostName } = useContext(MeetingStatsViewContext);
  return (
    <ChunkContainer
      columnSpan={2}
      alignItems="center"
      padding="20px"
      flexDir="row"
    >
      <Avatar
        name="host-name"
        // src={defaultAvatar}
        color="white"
        backgroundColor="livelinx.purple200"
        boxSize="65px"
        marginRight="15px"
        loading="lazy"
      />
      <Flex flexDir="column" justifyContent="center">
        <Tag
          fontSize="12px"
          fontFamily="poppins-bold"
          color="livelinx.blue300"
          backgroundColor="livelinx.grey300"
          borderRadius="50px"
          paddingX="15px"
          paddingY="7px"
          width="min-content"
          marginBottom="5px"
        >
          HOST
        </Tag>
        <Flex flexDir={isDesktopOrLaptop ? 'row' : 'column'}>
          <Text fontSize="17px" variant="purpleTitleBold">
            {meetingHostName || DEFAULT_HOST_NAME}
          </Text>
          {/* <Text
            marginLeft={isDesktopOrLaptop ? '10px' : ''}
            variant="graySubTitle"
          >
            Vascular surgeon UZ Ghent
          </Text> */}
        </Flex>
      </Flex>
    </ChunkContainer>
  );
}
