const LOCALSTORAGE_CHAT_LAST_READ_MESSAGE_ID = 'chat:channel';

export default function getLastReadChatMessageKey(
  channelID: string | undefined
) {
  return `${LOCALSTORAGE_CHAT_LAST_READ_MESSAGE_ID.replace(
    'channel',
    channelID || ''
  )}`;
}
