import { EyeOutlined } from '@ant-design/icons';
import { Box, Flex, Heading, keyframes } from '@chakra-ui/react';
import { InfoSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/infoSlideItemSchema';
import { getAssetByRefOrThrow } from '@/pages/caseCluster/utils/getAssetByRef';
import { zoomIn } from 'react-animations';
import { NewIAsset } from '@/utils/types/zod/assetSchema';
import ImageWithLoader from '@/components/ImageWithLoader/ImageWithLoader';
import HostImageWrapper from '@/pages/caseCluster/HostImageWrapper/HostImageWrapper';
const startAnimation = keyframes`${zoomIn}`;

type Props = {
  item: InfoSlideItemType;
  assetList: NewIAsset[];
  containsHostAsset: boolean;
  onMoreInfoCallback: (item: InfoSlideItemType) => void;
};

const InfoItem = ({
  item,
  assetList,
  containsHostAsset,
  onMoreInfoCallback,
}: Props) => {
  const thumbUrl =
    item.assetRefs.length > 0
      ? getAssetByRefOrThrow(item.assetRefs[0], assetList).thumbUrl
      : undefined;

  return (
    <Box
      data-test="slide-item"
      onClick={() => item.assetRefs && onMoreInfoCallback?.(item)}
      height="100%"
      maxWidth="40vw"
      maxHeight="50vh"
      cursor="pointer"
      padding="0px"
      position="relative"
      animation={`0.5s ${startAnimation}`}
    >
      {
        //-- CASE Thumb
        thumbUrl ? (
          <>
            <Flex
              // NOTE: We are still using thumbContainer class because it is used in pdf generation & cypress
              className="thumbContainer"
              userSelect="none"
              width="100%"
              height="80%"
              borderRadius={2.5}
              border="1px solid #d8d8d8"
              overflow="hidden"
              backgroundColor="white"
              justifyContent="center"
              alignItems="center"
              _hover={{
                border: '1px solid',
                borderColor: 'primary',
              }}
            >
              <HostImageWrapper isActive={containsHostAsset}>
                <ImageWithLoader
                  maxW="100%"
                  maxH="100%"
                  width="100%"
                  height="100%"
                  fit="contain"
                  alt={item.title}
                  src={thumbUrl}
                />
              </HostImageWrapper>
            </Flex>
            {/* // TODO: reset bookmark funcitonality when developped */}
            {/* <div className="bookmark" /> */}
            <Heading
              color="livelinx.purple200"
              padding="5px 35px 0px 5px"
              // fontSize={{ base: '0.8em', md: '1.4em', lg: '0.8em' }}
              fontSize="0.8em"
              width="100%"
              className="title"
            >
              {item.title}
            </Heading>
            <Box
              color="livelinx.black100"
              padding="0px 5px 15px 5px"
              fontSize="0.7em"
              paddingBottom={2.5}
              className="description"
            >
              {item.assetRefs &&
                item.assetRefs.length > 1 &&
                `${item.assetRefs.length} documents`}
              {item.assetRefs &&
                item.assetRefs.length <= 1 &&
                `${item.assetRefs.length} document`}
            </Box>
            <Box
              visibility="hidden"
              transition=" all 0.3s ease-out"
              position="absolute"
              top="0px"
              left="0px"
              width="100%"
              height="80%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="2em"
              color="primary"
              backgroundColor="#ffffff84"
              opacity="0"
              borderRadius={2.5}
              userSelect="none"
              _hover={{
                visibility: 'visible',
                opacity: 1,
              }}
            >
              <EyeOutlined />
            </Box>
          </>
        ) : (
          // -- CASE Text Only
          <Flex
            userSelect="none"
            height="100%"
            justifyContent="center"
            flexDir="column"
          >
            <Box
              display="block"
              color="livelinx.purple200"
              fontFamily="poppins-bold"
              borderRadius={5}
              height="auto"
              padding={5}
              fontSize="1.5em"
              textAlign="center"
              border="solid 1px #d8d8d8"
            >
              {item.title}
            </Box>
          </Flex>
        )
      }
    </Box>
  );
};

export default InfoItem;
