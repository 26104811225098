import { FilterFn } from '@tanstack/react-table';

export const stringArrayFilterFn: FilterFn<any> = (row, columnId, value) => {
  const arrayColumn = row.getValue(columnId) as string[];
  return arrayColumn.some((element) =>
    element.toLowerCase().includes(value.toLowerCase())
  );
};

export function createArrayFilterFn<T>(field: keyof T) {
  return (row: any, columnId: any, value: any) => {
    const arrayColumn = row.getValue(columnId) as T[];
    return arrayColumn.some((element) =>
      String(element[field]).toLowerCase().includes(value.toLowerCase())
    );
  };
}
