import {
  MeetingStatsByMeetingQuery,
  MeetingStatsByMeetingQueryVariables,
  UpdateMeetingStatsMutation,
  UpdateMeetingStatsMutationVariables,
} from '@/services/API';
import { updateMeetingStats } from '@/services/graphql/mutations';
import { meetingStatsByMeeting } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { GraphQLResult } from '@aws-amplify/api';

export default async function mutationResetMeetingStats(meetingID: string) {
  const variables: MeetingStatsByMeetingQueryVariables = {
    meetingID,
  };
  const result = await callGraphQLApi<
    GraphQLResult<MeetingStatsByMeetingQuery>
  >(meetingStatsByMeeting, variables);
  const meetingStatsId = result.data?.meetingStatsByMeeting?.items?.[0]?.id;

  if (!meetingStatsId) {
    throw new Error(
      `Could not find meetingStats for meeting with id ${meetingID}`
    );
  }

  try {
    const updateMeetingVariables: UpdateMeetingStatsMutationVariables = {
      input: {
        id: meetingStatsId,
        casePresentationHistory: [],
        amountParticipants: 0,
      },
    };
    await callGraphQLApi<GraphQLResult<UpdateMeetingStatsMutation>>(
      updateMeetingStats,
      updateMeetingVariables
    );
  } catch (error) {
    // TODO: niko: restart meeting is not working for non admin.. reseting stats doesn't work
    captureSentry({
      title: 'Error in reset of meeting stats',
      error: error instanceof Error ? error : undefined,
    });
  }
}
