import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';

export function parseCasePaths(
  caseList: UnloadedIPatientCase[],
  caseIds: string[]
) {
  return caseList
    .filter((item) => caseIds.includes(item.id))
    .map((item) => item.path);
}
