import { Meeting } from '@/services/API';
import { TFunction } from 'i18next';

export default function getJoinMeetingButtonText(
  t: TFunction<'translation', undefined, 'translation'>,
  isHost: boolean,
  statsExist: boolean,
  meeting: Meeting
) {
  /* If he's not the host or he is the host and stats already exist */

  if (!isHost || (isHost && statsExist && !meeting.isDemo))
    return t('admin.home.table.joinMeeting.button');
  if (!meeting.isDemo && isHost && !statsExist)
    return t('admin.home.table.startMeeting.button');
  if (meeting.isDemo && isHost) {
    return t('admin.home.table.startDemo.button');
  }
}
