import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PADDING_X_DESKTOP, PADDING_X_MOBILE } from '../utils/constants';
import ChunkContainer from './ChunkContainer';
import Title from './Title';
import { ParsedAssetViews } from '../utils/parseAssetViewsPerCase';
import { AssetType } from '@/utils/types/enums';

type Props = {
  caseTitle: string;
  // assetImage: string;
  // assetName: string;
  asset: ParsedAssetViews | undefined;
};

export default function BestAsset({ caseTitle, asset }: Props) {
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useDeviceSize();

  return (
    <ChunkContainer>
      <Title text={t('meetings.stats.bestAsset.title')} />
      <Box
        marginY="10px"
        marginBottom="25px"
        width="100%"
        paddingX={isDesktopOrLaptop ? PADDING_X_DESKTOP : PADDING_X_MOBILE}
      >
        <Text variant="graySubTitle" noOfLines={1}>
          {caseTitle}:
        </Text>
        <Text
          variant="purpleTitleBold"
          fontSize="17px"
          noOfLines={1}
          marginBottom="10px"
        >
          {asset ? asset.title : 'No asset for this case'}
        </Text>
        {asset && (
          <Flex
            sx={{
              backgroundColor: 'livelinx.grey300',
              padding: '10px',
              width: 'fit-content',
            }}
          >
            <Image
              src={asset.type === AssetType.IMAGE ? asset.url : asset.thumbUrl}
              alt="best-asset"
              width="130px"
            />
          </Flex>
        )}
      </Box>
    </ChunkContainer>
  );
}
