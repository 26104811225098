import { useEffect } from 'react';
import { graphqlSubscriber } from '@/utils/graphQLAPI';

import {
  DebugSubscription,
  useSubscriptionDebuggerContext,
} from './SubscriptionDebuggerContext';
import { IsLocalHost } from '@/utils/helpers/isLocalHost';
import { reloadApp } from '@/utils/reloadApp';
import { captureSentry } from '@/utils/helpers/sentryHelper';

export type SubscriptionOptions<T> = {
  // type: 'CREATE' | 'UPDATE' | 'DELETE';
  query: string;
  variables: object;
  callback: (result: T) => void;
  onError?: (error: string) => void;
};

//Note: a flaw of this design is that if only one of the subscription options change
//it will have to recreate all the subscriptions instead of only one, which is a pity.
function useNewSubscriptions(
  options: Array<SubscriptionOptions<any>>,
  customName: string
) {
  const {
    registerSubscription,
    unRegisterSubscription,
    getSubscriptionsDetails,
  } = useSubscriptionDebuggerContext();
  // --------------------- subscription effect ------------------------

  useEffect(() => {
    const subscriptions: DebugSubscription[] = [];
    if (!IsLocalHost())
      console.log('RESUBSCRIBING TO NEW SUBSCRIPTIONS', customName);

    const subscribe = async () => {
      options.forEach(async (sub) => {
        const newSub = (
          await graphqlSubscriber(sub.query, sub.variables)
        ).subscribe({
          next: (res: any) => {
            console.log('useNewSubscription got data for ', customName);
            sub.callback(res.value.data);
          },
          error: (err) => {
            if (sub.onError) {
              sub.onError(err);
            }
            captureSentry({
              title: `Subscription error: ${customName}`,
              error: err instanceof Error ? err : undefined,
              detail: getSubscriptionsDetails(),
            });
            // TODO: find a better way than reload in case of subscriptione rror
            console.error('Subscription error: ', customName, err);
            setTimeout(() => reloadApp(), 3000);
          },
        });
        const id = registerSubscription(newSub, customName);
        subscriptions.push({
          id,
          sub: newSub,
          customName,
        });
      });
    };

    subscribe();
    // cancel all subscriptions
    return () => {
      subscriptions.forEach((sub) => {
        unRegisterSubscription(sub.id);
        sub.sub.unsubscribe();
      });
    };
  }, [options]);
}

export default useNewSubscriptions;
