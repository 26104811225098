import { SlideType } from '../enums';
import { ImageSlideType } from '../zod/slideTypes/imageSlideSchema';
import { NewISlide } from '../zod/slideTypes/slideSchema';

export default function isSlideImageSlide(
  slide: NewISlide
): slide is ImageSlideType {
  return slide.type === SlideType.IMAGE;
}

export function isSlideNotImageSlide(
  slide: NewISlide
): slide is Exclude<NewISlide, ImageSlideType> {
  return slide.type !== SlideType.IMAGE;
}
