import { useState, useEffect } from 'react';
type Network = {
  online: boolean;
};
function useNetworkConnection() {
  const [state, setState] = useState<Network>({
    online: navigator.onLine,
  });
  useEffect(() => {
    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
      }));
    };
    const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
      }));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return state;
}
export default useNetworkConnection;
