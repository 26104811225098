import { Answer } from '@/services/API';

export type ConsensusResult = {
  amtAnswers: number;
  mostPopularAnswer: number;
  amtSlides: number;
};

export type ConsensusReturnType = {
  consensusResultsPerCase: Record<string, ConsensusResult>;
  consensusRatePerCase: Record<string, number>;
  consensusRate: number;
};

export function getDefaultConsensusReturn(
  caseIds: string[]
): ConsensusReturnType {
  return {
    consensusRate: 1.0,
    consensusResultsPerCase: caseIds.reduce((acc, caseId) => {
      acc[caseId] = {
        amtAnswers: 1,
        mostPopularAnswer: 1,
        amtSlides: 1,
      };
      return acc;
    }, {} as Record<string, ConsensusResult>),
    consensusRatePerCase: caseIds.reduce((acc, caseId) => {
      acc[caseId] = 1.0;
      return acc;
    }, {} as Record<string, number>),
  };
}

/*
Note: This explains the logic behind the calculation of consensus rate

For each question in a meeting
  calculate how many people answered it
  calculate which is the most popular answer

sum those two values for all questions
divide the sum of most popular answers by the sum of all answers (people who answered the question)

This gives us a percentage of how many people answered the most popular answer in the entire meeting

Also we do all of this per case, in order to have a consensus rate per case
*/
export function calculateConsensusRate(
  caseIds: string[],
  answerList: Answer[]
): ConsensusReturnType {
  const result: Record<string, ConsensusResult> = caseIds.reduce(
    (acc, caseId) => {
      acc[caseId] = {
        amtAnswers: 0,
        mostPopularAnswer: 0,
        amtSlides: 0,
      };
      return acc;
    },
    {} as Record<string, ConsensusResult>
  );

  const qstToAnswerToAmt = new Map<string, Map<string, number>>();
  const qstIds = new Set<string>();

  //special cases
  if (answerList.length === 0) return getDefaultConsensusReturn(caseIds);

  //NIKOTODO: MOCKING MULTIVOTE this needs to be changed, need to talk about how to calc for multiple answers
  answerList.forEach((answer) => {
    const questionID = answer.questionID;
    const answerID = answer.answerIndexes[0];
    // console.log('answerList answer', questionID, answerIdx)
    qstIds.add(questionID);
    if (!qstToAnswerToAmt.has(questionID)) {
      qstToAnswerToAmt.set(questionID, new Map<string, number>());
    }
    const answerToAmt = qstToAnswerToAmt.get(questionID);
    if (!answerToAmt) {
      throw new Error('answerToAmt is undefined');
    }
    answerToAmt.set(answerID, (answerToAmt.get(answerID) || 0) + 1);
  });

  qstIds.forEach((qstId) => {
    /*
    NOTE: If the question Ids ever change I'll add this: 'composeQuestionID'
    so that it's easier to find this place to modify
    */
    const caseId = caseIds.find((elem) => qstId.includes(elem));
    const answerToAmt = qstToAnswerToAmt.get(qstId);
    if (!caseId) {
      throw new Error('caseId is undefined');
    }
    if (!answerToAmt) {
      throw new Error('answerToAmt is undefined');
    }
    const consensusResult = consensusRateOneQuestion(qstId, answerToAmt);

    result[caseId].amtAnswers += consensusResult.amtAnswers;
    result[caseId].mostPopularAnswer += consensusResult.mostPopularAnswer;
    result[caseId].amtSlides += consensusResult.amtSlides;
  });

  const consensusRatePerCase = Object.keys(result).reduce((acc, caseId) => {
    if (result[caseId].amtAnswers === 0) {
      acc[caseId] = 1.0;
      return acc;
    }
    acc[caseId] = result[caseId].mostPopularAnswer / result[caseId].amtAnswers;
    console.log(
      'consensuspercase',
      caseId,
      result[caseId].mostPopularAnswer,
      result[caseId].amtAnswers
    );
    return acc;
  }, {} as Record<string, number>);
  const consensusRate =
    Object.values(consensusRatePerCase).reduce((acc, curr) => acc + curr, 0) /
    Object.keys(consensusRatePerCase).length;
  return {
    consensusResultsPerCase: result,
    consensusRatePerCase,
    consensusRate,
  };
}

//NIKOTODO: check if this still works
function consensusRateOneQuestion(
  questionID: string,
  answerToAmt: Map<string, number>
): ConsensusResult {
  let mostPopularAnswer = 0;
  let amtAnswers = 0;
  answerToAmt.forEach((amt) => {
    if (amt > amtAnswers) {
      mostPopularAnswer = amt;
      amtAnswers += amt;
    }
  });
  return { mostPopularAnswer, amtAnswers, amtSlides: 1 };
}
