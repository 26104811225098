import { z } from 'zod';
import { poweredByDisclaimer } from './poweredByDisclaimerSchema';
import { imageDisclaimer } from './imageDisclaimerSchema';

export const IDisclaimerSchema = z.union([
  poweredByDisclaimer,
  imageDisclaimer,
]);

export type IDisclaimer = z.infer<typeof IDisclaimerSchema>;
