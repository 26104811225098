import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';
import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';

const useCaseListTableStore =
  createTanstackTableDataStore<UnloadedIPatientCase>({
    storeName: 'admin-caselist-table-store',
    defaultColumnSizing: {},
    defaultColumnVisibility: {
      // id: false,
    },
  });

export default useCaseListTableStore;
