import { PageLoading } from '@/ui/PageLoading';
import AdminCaseClusterListView from './AdminCaseClusterListView';
import useCaseClusterList from '@/features/caseCluster/hooks/useCaseClusterList';
import useCaseClusterSubscription from '@/services/subscriptions/useCaseClusterSubscription';
import { MeetingSelectors } from '@/store/slices/meeting';
import { Meeting } from '@/services/API';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import { deleteACaseCluster } from '@/store/thunk/caseCluster/deleteCaseCluster';
import { useCallback, useMemo } from 'react';
import { AdminCaseClusterListItemType } from './columns';

export default function AdminCaseClusterListClient() {
  const caseClusterList = useCaseClusterList();
  const meetingList: Meeting[] = useAppSelector(
    MeetingSelectors.getMeetingList
  );
  const dispatch = useAppDispatch();

  useCaseClusterSubscription();
  useMeetingSubscription();

  const handleDeleteCaseCluster = useCallback((caseClusterID: string) => {
    dispatch(deleteACaseCluster(caseClusterID));
  }, []);

  const data: AdminCaseClusterListItemType[] = useMemo(() => {
    if (!caseClusterList) return [];
    return caseClusterList.map((caseCluster) => {
      return {
        ...caseCluster,
        linkedMeetings: meetingList.filter(
          (meeting) => caseCluster.id === meeting.caseClusterID
        ),
        canBeDeleted: !meetingList.some(
          (s) => s.caseClusterID === caseCluster.id
        ),
      };
    });
  }, [caseClusterList, meetingList]);

  if (!caseClusterList) {
    return <PageLoading />;
  }
  return (
    <>
      <AdminCaseClusterListView
        data={data}
        handleDeleteCaseCluster={handleDeleteCaseCluster}
      />
    </>
  );
}
