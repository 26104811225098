import { Answer } from '@/services/API';
import { SlideItemType } from '@/utils/types/enums';
import isSlideItemTextAnswerItem from '@/utils/types/type-validation/isSlideItemTextAnswerItem';
import { QuestionSlideItemType } from '@/utils/types/zod/slideTypes/questionSlideSchema';

// export type SelectableAnswerStats = {
//   type: SlideItemType.ANSWER_SELECTABLE;
//   total: number;
//   userNames: string[];
// }

// export type TextAnswerStats = {
//   type: SlideItemType.ANSWER_TEXT,

// }

type AnswerStats = {
  userNames: string[];
};

export type SlideVotingStats = {
  totalSelectableAnswerVotes: number;
  selectableAnswers: Map<string, AnswerStats>;
  textAnswers: Map<string, AnswerStats>;
};

const getSlideVotingStats = (
  items: QuestionSlideItemType[],
  answers: Answer[]
) => {
  const stats: SlideVotingStats = {
    totalSelectableAnswerVotes: answers.reduce((acc, curr) => {
      return acc + curr.answerIndexes.length;
    }, 0),
    selectableAnswers: new Map(),
    textAnswers: new Map(),
  };
  const slideTextAnswerItems = items.filter(isSlideItemTextAnswerItem);
  items.forEach((item) => {
    if (item.type === SlideItemType.ANSWER_SELECTABLE) {
      stats.selectableAnswers.set(item.id, {
        userNames: answers
          .filter((answer) => answer.answerIndexes.includes(item.id))
          .map((answer) => answer.user.firstname),
      });
    }
    if (item.type === SlideItemType.ANSWER_TEXT) {
      //NOTE: we need the index of the item in the array ONLY containing texts
      const idx = slideTextAnswerItems.findIndex((i) => i.id === item.id);
      stats.textAnswers.set(item.id, {
        userNames: answers
          .filter((answer) => answer.answerTexts[idx] !== '')
          .map((answer) => answer.user.firstname),
      });
    }
  });

  return stats;
};

export default getSlideVotingStats;
