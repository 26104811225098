import { useEffect, useMemo, useState } from 'react';
import { userMeetingAccessByUserID } from '@/services/graphql/queries';
import {
  onCreateUserMeetingAccess,
  onDeleteUserMeetingAccess,
} from '@/services/graphql/subscriptions';
import {
  OnCreateUserMeetingAccessSubscription,
  OnCreateUserMeetingAccessSubscriptionVariables,
  OnDeleteUserMeetingAccessSubscription,
  UserMeetingAccess,
  UserMeetingAccessByUserIDQuery,
  UserMeetingAccessByUserIDQueryVariables,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';

const useMeetingUserAccessForUser = (userId: string | undefined) => {
  // --------------------- state ------------------------

  const [userAccessList, setUserAccessList] = useState<UserMeetingAccess[]>([]);

  // --------------------- handlers ------------------------

  const refetch = async () => {
    if (!userId) return;
    try {
      // const result = await callGraphQLApi<GraphQLResult<GetMeetingQuery>>(
      //   getMeeting,
      //   {
      //     id: meetingId,
      //   }
      // );
      const variables: UserMeetingAccessByUserIDQueryVariables = {
        userID: userId,
      };
      const result = await callGraphQLApi<
        GraphQLResult<UserMeetingAccessByUserIDQuery>
      >(userMeetingAccessByUserID, variables);
      const userAccessList = result.data?.userMeetingAccessByUserID?.items;
      if (userAccessList) {
        setUserAccessList(userAccessList as UserMeetingAccess[]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // --------------------- effect ------------------------

  const subscriptions = useMemo(() => {
    if (!userId) return [];
    const onCreateVariables: OnCreateUserMeetingAccessSubscriptionVariables = {
      // FILTERS DONT WORK !!!!
      // filter: {
      //   userID: {
      //     eq: userId,
      //   },
      // },
    };
    return [
      {
        query: onCreateUserMeetingAccess,
        variables: onCreateVariables,
        callback: (response: OnCreateUserMeetingAccessSubscription) => {
          const item = response.onCreateUserMeetingAccess;
          if (item && item.userID === userId) {
            setUserAccessList((prev) => [...prev, item as UserMeetingAccess]);
          }
        },
      },
      {
        query: onDeleteUserMeetingAccess,
        //NOTE: I'm just using the same variable
        variables: onCreateVariables,
        callback: (response: OnDeleteUserMeetingAccessSubscription) => {
          const item = response.onDeleteUserMeetingAccess;
          if (item && item.userID === userId) {
            setUserAccessList((prev) =>
              prev.filter((elem) => elem.id !== item.id)
            );
          }
        },
      },
    ];
  }, [userId]);

  useNewSubscriptions(subscriptions, 'useMeetingUserAccessForUser');

  useEffect(() => {
    refetch();
  }, [userId]);

  // --------------------- return ------------------------

  return { userAccessList };
};

export default useMeetingUserAccessForUser;
