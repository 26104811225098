import { useDaily, useLocalParticipant } from '@daily-co/daily-react';
import { useCallback } from 'react';
import api from '../dailyVideoApp/dailyApi';

export const useVideoCall = () => {
  const callObject = useDaily();
  const localParticipant = useLocalParticipant();
  const cameraIsActive = localParticipant?.tracks.video.state === 'playable';

  /**
   * JOIN CALL
   */
  const joinCall = (url: string, userName: string) => {
    if (!callObject) return;
    return callObject.join({ url, userName }).then(
      () => {
        console.log('joined');
      },
      (error: string) => {
        console.log('error', error);
      }
    );
  };

  const initializeCall = useCallback(
    (meetingId: string, userId: string) => {
      if (!callObject)
        throw new Error('Cannot join a call without a valid call object');

      api.createRoom(meetingId).then((res) => {
        if (res.error) {
          api
            .getRoom(meetingId)
            .then((res) => joinCall(res.url, userId))
            .catch((err) => console.log(err));
        } else {
          joinCall(res.url, userId);
        }
      });
    },
    [callObject]
  );

  /**
   * Leave Call
   */
  const leaveCall = useCallback(() => {
    if (!callObject) return undefined;
    if (callObject.meetingState() === 'error') {
      callObject.destroy();
    } else {
      callObject.leave();
    }
  }, [callObject]);

  return {
    state: callObject?.meetingState(),
    initializeCall,
    leaveCall,
    cameraIsActive,
  };
};
