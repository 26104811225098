import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { Meeting } from '@/services/API';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import CaseExplorerView from './CaseExplorerView';
import { useAnswersBySlide } from '@/services/hooks/useAnswersBySlide';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import useMeetingTrackingAssetStats from '@/features/tracking/useMeetingTrackingAssetStats';

export type CaseExplorerClientProps = {
  patientCaseIndex: number;
  patientCase: NewIPatientCase;
  isHost: boolean;

  meeting?: Meeting;
  forcedCurrSlide?: NewISlide | undefined;
  trackAssetSwitch?: ReturnType<
    typeof useMeetingTrackingAssetStats
  >['trackAssetSwitch'];
};

export default function CaseExplorerClient(props: CaseExplorerClientProps) {
  const { userID } = useUserAuthenticationContext();
  const { slideVariationDetails, currentHostAssetID } =
    useCurrentMeetingDataContext();
  const isPreview = !!props.forcedCurrSlide;

  let slideIndex =
    isPreview && !!props.forcedCurrSlide
      ? props.patientCase.slides.indexOf(props.forcedCurrSlide)
      : props.meeting?.currentSlide;
  if (!slideIndex || slideIndex < 0) {
    slideIndex = 0;
  }
  const currentSlide: NewISlide | undefined =
    props.forcedCurrSlide || props.patientCase?.slides?.[slideIndex];

  const answersForSlideDetails = useAnswersBySlide(slideIndex, isPreview);
  return (
    <CaseExplorerView
      {...props}
      currSlideVariationDetails={slideVariationDetails}
      answersForSlideDetails={answersForSlideDetails}
      isPreview={isPreview}
      currentSlide={currentSlide}
      slideIndex={slideIndex}
      userID={userID}
      currentHostAssetID={currentHostAssetID}
    />
  );
}
