import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { CaseCluster, Meeting } from '@/services/API';
import dateInRangeFilterFn from '@/ui/TanstackTable/Filter/date/dateInRangeFilterFn';
import { Button, Flex, Tag, Text } from '@chakra-ui/react';
import { parseDate } from '@/utils/helpers/parseDate';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import { DeleteOutlined } from '@ant-design/icons';
import {
  createArrayFilterFn,
  stringArrayFilterFn,
} from '@/ui/TanstackTable/Filter/array/arrayFilterFns';

export type AdminCaseClusterListItemType = CaseCluster & {
  linkedMeetings: Meeting[];
  canBeDeleted: boolean;
};

const columnHelper = createColumnHelper<AdminCaseClusterListItemType>();

type Args = {
  t: TFunction<'translation', undefined, 'translation'>;
  handleDeleteCaseCluster: (caseClusterID: string) => void;
};

const getColumns = ({ t, handleDeleteCaseCluster }: Args) => {
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    columnHelper.accessor('cases', {
      header: 'Cases Included',
      filterFn: stringArrayFilterFn,
      cell: (props) => {
        const cases = props.getValue();
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {cases.map((elem) => (
              <Tag key={elem} ml={2} colorScheme="red" w="min">
                <Text textOverflow="ellipsis" noOfLines={1}>
                  {elem}
                </Text>
              </Tag>
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.accessor('linkedMeetings', {
      header: 'Linked Meetings',
      filterFn: createArrayFilterFn<Meeting>('name'),
      cell: (props) => {
        const elems = props.getValue();
        return (
          <Flex flexDir="row" gap={2} flexWrap="wrap">
            {elems.map((elem) => (
              <Tag key={elem.id} ml={2} colorScheme="blue" w="min">
                <Text textOverflow="ellipsis" noOfLines={1}>
                  {elem.name}
                </Text>
              </Tag>
            ))}
          </Flex>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (props) => {
        const item = props.row.original;
        return (
          <PopConfirm
            title={
              <div>
                {t('admin.caseClusters.delete.confirmText')}
                <br />
                {t('meeting.header.confirmRestart')}
              </div>
            }
            onConfirm={() => handleDeleteCaseCluster(item.id)}
          >
            <Button
              variant="danger"
              key="delete"
              size="sm"
              isDisabled={!item.canBeDeleted}
              rightIcon={<DeleteOutlined />}
            >
              {t('common.delete')}
            </Button>
          </PopConfirm>
        );
      },
    }),
  ];

  return columns;
};

export default getColumns;
