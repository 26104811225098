import { z } from 'zod';
import { slideSchema } from './slideTypes/slideSchema';

export const variationSchema = z.object({
  variationId: z.string(),
  conditionKey: z.string(),
  conditionValue: z.string(),
  variation: slideSchema,
});

export type Variation = z.infer<typeof variationSchema>;
