import { Box } from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import axios from 'axios';
import APP_CONSTANTS from '@/utils/constants/app.constants';

import { useEffect, useState } from 'react';
import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import { useLocation } from 'react-router-dom';

const CaseEditPage = () => {
  const caseList = useCaseList();
  const { search } = useLocation();
  const [caseJson, setCaseJson] = useState<object | undefined>();

  const queryParam = new URLSearchParams(search);
  const patientCase = caseList?.find((x) => x.id === queryParam.get('id'));

  useEffect(() => {
    if (patientCase) {
      const fetchCase = async () => {
        const result = await axios.get(
          `${APP_CONSTANTS.PATIENT_CASES_REPO_PATH}${
            patientCase.path
          }?t=${Date.now()}`
        );
        setCaseJson(result.data);
      };
      fetchCase();
    }
  }, [patientCase]);

  return (
    <Box width="full" py={10} px={4}>
      {caseJson && (
        <ReactJson
          src={caseJson}
          onEdit={() => {
            console.log('allow edit');
          }}
          onAdd={() => {
            console.log('allow add');
          }}
          onDelete={() => {
            console.log('allow delete');
          }}
          theme="monokai"
        />
      )}
    </Box>
  );
};

export default CaseEditPage;
