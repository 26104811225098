import {
  CaseAssetView,
  DeleteCaseAssetViewMutation,
  DeleteCaseSlideStatsMutation,
  DeleteCaseStatsInput,
  DeleteCaseStatsMutation,
  DeleteMeetingNPSScoreMutation,
  DeleteMeetingStatsMutation,
  ListCaseStatsQuery,
  ListCaseStatsQueryVariables,
  MeetingStatsByMeetingQuery,
  MeetingStatsByMeetingQueryVariables,
  NpsScoresByMeetingQuery,
  NpsScoresByMeetingQueryVariables,
} from '@/services/API';
import {
  deleteCaseAssetView,
  deleteCaseSlideStats,
  deleteCaseStats,
  deleteMeetingNPSScore,
  deleteMeetingStats,
} from '@/services/graphql/mutations';
import {
  listCaseStats,
  meetingStatsByMeeting,
  npsScoresByMeeting,
} from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { customGetCaseStats } from './gql';

export async function mutationDeleteAllCaseStatsForMeeting(meetingID: string) {
  const variables: ListCaseStatsQueryVariables = {
    filter: {
      meetingID: {
        eq: meetingID,
      },
    },
  };
  const result = await callGraphQLApi<GraphQLResult<ListCaseStatsQuery>>(
    listCaseStats,
    variables
  );
  const caseStatsList = result.data?.listCaseStats?.items;
  const promiseList: Promise<GraphQLResult<DeleteCaseStatsMutation>>[] = [];

  if (!caseStatsList) {
    console.log('No case stats to delete');
    return;
  }
  for (const caseStats of caseStatsList) {
    if (!caseStats) continue;
    promiseList.push(mutationDeleteCaseStats(caseStats.id));
  }

  const resolved = await Promise.all(promiseList);
  return resolved;
}

/*
NOTE: This delete the children it owns aswell
*/
export async function mutationDeleteCaseStats(caseStatsId: string) {
  const input: DeleteCaseStatsInput = {
    id: caseStatsId,
  };
  //TODO: I don't want an any here, but I don't know how to type this since it's a custom query.
  //I guess I should write my own types for this.
  const caseStatsData: any = await callGraphQLApi<GraphQLResult<any>>(
    customGetCaseStats,
    {
      id: caseStatsId,
    }
  );
  const promiseList: Promise<any>[] = [];
  const assetViewDeletionList: Promise<DeleteCaseAssetViewMutation>[] =
    await mutationDeleteAllAssetViews(
      caseStatsId,
      caseStatsData.data.getCaseStats?.assetViews?.items,
      caseStatsData.data.getCaseStats?.assetViews?.nextToken
    );
  const slideStatsList = caseStatsData.data.getCaseStats?.slides?.items;

  for (const slideStats of slideStatsList) {
    promiseList.push(mutationDeleteSlideStats(slideStats.id));
  }

  await Promise.all(promiseList);
  await Promise.all(assetViewDeletionList);

  const result = await callGraphQLApi<GraphQLResult<DeleteCaseStatsMutation>>(
    deleteCaseStats,
    { input }
  );
  return result;
}

export async function mutationDeleteAllAssetViews(
  caseStatsId: string,
  assetViews: CaseAssetView[] | undefined | null,
  nextToken: string | undefined | null
) {
  const promiseList: Promise<DeleteCaseAssetViewMutation>[] = [];

  if (assetViews) {
    for (const assetView of assetViews) {
      promiseList.push(mutationDeleteAssetView(assetView.id));
    }
  }

  //Get the rest of the asset views if there is more
  //Since there can be a huge amount of views per case we need to use the next token
  while (nextToken) {
    const caseStatsData = await callGraphQLApi<GraphQLResult<any>>(
      customGetCaseStats,
      {
        id: caseStatsId,
        assetViewsNextToken: nextToken,
      }
    );
    const newAssetViews = caseStatsData.data.getCaseStats?.assetViews?.items;

    if (!newAssetViews) {
      break;
    }
    for (const assetView of newAssetViews) {
      promiseList.push(mutationDeleteAssetView(assetView.id));
    }
    nextToken = caseStatsData.data.getCaseStats?.assetViews?.nextToken;
  }
  return promiseList;
}

export function mutationDeleteAssetView(
  assetViewId: string
): Promise<DeleteCaseAssetViewMutation> {
  return new Promise((resolve, reject) => {
    const input = {
      id: assetViewId,
    };
    callGraphQLApi<GraphQLResult<DeleteCaseAssetViewMutation>>(
      deleteCaseAssetView,
      { input }
    )
      .then((result: GraphQLResult<DeleteCaseAssetViewMutation>) => {
        if (!result.data) {
          reject('No data returned');
          return;
        }
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function mutationDeleteSlideStats(slideStatsId: string) {
  return new Promise((resolve, reject) => {
    const input = {
      id: slideStatsId,
    };
    callGraphQLApi<GraphQLResult<DeleteCaseSlideStatsMutation>>(
      deleteCaseSlideStats,
      { input }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function mutationDeleteAllNPSScoresForMeeting(meetingID: string) {
  const options: NpsScoresByMeetingQueryVariables = {
    meetingID,
  };
  const objects = await callGraphQLApi<GraphQLResult<NpsScoresByMeetingQuery>>(
    npsScoresByMeeting,
    options
  );
  const deletionPromises =
    objects.data?.npsScoresByMeeting?.items?.map(async (item) => {
      if (!item?.id) return;
      return mutationDeleteNpsScore(item.id);
    }) ?? [];
  const resolvedPromises = await Promise.all(deletionPromises);
  return resolvedPromises;
}

export async function mutationDeleteNpsScore(id: string) {
  return callGraphQLApi<GraphQLResult<DeleteMeetingNPSScoreMutation>>(
    deleteMeetingNPSScore,
    {
      input: { id },
    }
  );
}

export async function mutationDeleteMeetingStatsForMeeting(meetingID: string) {
  const options: MeetingStatsByMeetingQueryVariables = {
    meetingID,
  };
  const objects = await callGraphQLApi<
    GraphQLResult<MeetingStatsByMeetingQuery>
  >(meetingStatsByMeeting, options);

  const deletionPromises =
    objects.data?.meetingStatsByMeeting?.items?.map(async (item) => {
      if (!item?.id) return;
      return mutationDeleteOneMeetingStats(item.id);
    }) ?? [];

  const resolvedPromises = await Promise.all(deletionPromises);
  return resolvedPromises;
}

export async function mutationDeleteOneMeetingStats(id: string) {
  const result = await callGraphQLApi<
    GraphQLResult<DeleteMeetingStatsMutation>
  >(deleteMeetingStats, {
    input: {
      id,
    },
  });
  return result;
}
