import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Box, Flex, Text } from '@chakra-ui/react';

export default function Footer() {
  const { isDesktopOrLaptop } = useDeviceSize();
  return (
    <Flex
      color="grey"
      justifyContent="space-between"
      height="auto"
      fontSize="15px"
      fontFamily="poppins-regular"
      margin="0"
      padding="12px 25px"
      flexDir={isDesktopOrLaptop ? 'row' : 'column'}
      alignItems="center"
    >
      <Box
        display="flex"
        flexDir={isDesktopOrLaptop ? 'row' : 'column'}
        alignItems="center"
      >
        <Text marginRight=".5rem">Copyright (C) Livelinx 2023.</Text>
        <Text>All rights reserved. </Text>
      </Box>
      <Text>Contact us : support@livelinxlearning.com</Text>
    </Flex>
  );
}
