/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null;
  firstname: string;
  lastname: string;
  email: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  last_seen?: string | null;
  currentMeetingID: string;
  isTemporary?: boolean | null;
  owner?: string | null;
  isMock?: boolean | null;
};

export type ModelUserConditionInput = {
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  last_seen?: ModelStringInput | null;
  currentMeetingID?: ModelIDInput | null;
  isTemporary?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  isMock?: ModelBooleanInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type User = {
  __typename: 'User';
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  meetingAccess?: ModelUserMeetingAccessConnection | null;
  last_seen?: string | null;
  currentMeetingID: string;
  currentMeeting?: Meeting | null;
  isTemporary?: boolean | null;
  owner?: string | null;
  isMock?: boolean | null;
};

export type ModelUserMeetingAccessConnection = {
  __typename: 'ModelUserMeetingAccessConnection';
  items: Array<UserMeetingAccess | null>;
  nextToken?: string | null;
};

export type UserMeetingAccess = {
  __typename: 'UserMeetingAccess';
  id: string;
  owner?: string | null;
  userID: string;
  meetingID: string;
  createdAt: string;
  updatedAt: string;
};

export type Meeting = {
  __typename: 'Meeting';
  id: string;
  owner?: string | null;
  name: string;
  caseClusterID: string;
  hostID: string;
  host?: User | null;
  hostFullName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  eventDate: string;
  endDate?: string | null;
  eventLocation: string;
  participants?: ModelUserConnection | null;
  userAccess?: ModelUserMeetingAccessConnection | null;
  guestUrlCode?: string | null;
  previewCode?: string | null;
  clientIDs: Array<string>;
  currentCase?: string | null;
  currentSlide: number;
  currentAssetID?: string | null;
  caseCluster?: CaseCluster | null;
  caseStats?: ModelCaseStatsConnection | null;
  meetingStats?: MeetingStats | null;
  purchaseOrderNbr: string;
  meetingNotes: string;
  meetingType: string;
  isMock: boolean;
  isInTesting: boolean;
  isArchived: boolean;
  isDemo?: boolean | null;
  streamUrl?: string | null;
};

export type ModelUserConnection = {
  __typename: 'ModelUserConnection';
  items: Array<User | null>;
  nextToken?: string | null;
};

export type CaseCluster = {
  __typename: 'CaseCluster';
  id: string;
  owner?: string | null;
  name: string;
  cases: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
  isMock?: boolean | null;
};

export type ModelCaseStatsConnection = {
  __typename: 'ModelCaseStatsConnection';
  items: Array<CaseStats | null>;
  nextToken?: string | null;
};

export type CaseStats = {
  __typename: 'CaseStats';
  id: string;
  owner?: string | null;
  meetingID: string;
  caseId: string;
  assetViews?: ModelCaseAssetViewConnection | null;
  slides?: ModelCaseSlideStatsConnection | null;
  version: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelCaseAssetViewConnection = {
  __typename: 'ModelCaseAssetViewConnection';
  items: Array<CaseAssetView | null>;
  nextToken?: string | null;
};

export type CaseAssetView = {
  __typename: 'CaseAssetView';
  id: string;
  owner?: string | null;
  assetId: string;
  userID: string;
  timeWatched: number;
  version: string;
  createdAt: string;
  updatedAt: string;
  caseStatsAssetViewsId: string;
};

export type ModelCaseSlideStatsConnection = {
  __typename: 'ModelCaseSlideStatsConnection';
  items: Array<CaseSlideStats | null>;
  nextToken?: string | null;
};

export type CaseSlideStats = {
  __typename: 'CaseSlideStats';
  id: string;
  owner?: string | null;
  slideId: string;
  time: number;
  version: string;
  createdAt: string;
  updatedAt: string;
  caseStatsSlidesId: string;
};

export type MeetingStats = {
  __typename: 'MeetingStats';
  id: string;
  owner?: string | null;
  meetingID: string;
  casePresentationHistory: Array<string>;
  version: string;
  amountParticipants?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserInput = {
  id: string;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  last_seen?: string | null;
  currentMeetingID?: string | null;
  isTemporary?: boolean | null;
  owner?: string | null;
  isMock?: boolean | null;
};

export type DeleteUserInput = {
  id: string;
};

export type CreateUserClientInput = {
  id?: string | null;
  owner?: string | null;
  clientID: string;
  userID: string;
};

export type ModelUserClientConditionInput = {
  owner?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelUserClientConditionInput | null> | null;
  or?: Array<ModelUserClientConditionInput | null> | null;
  not?: ModelUserClientConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UserClient = {
  __typename: 'UserClient';
  id: string;
  owner?: string | null;
  clientID: string;
  client?: Client | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type Client = {
  __typename: 'Client';
  id: string;
  clientName: string;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateUserClientInput = {
  id: string;
  owner?: string | null;
  clientID?: string | null;
  userID?: string | null;
};

export type DeleteUserClientInput = {
  id: string;
};

export type CreateClientInput = {
  id?: string | null;
  clientName: string;
};

export type ModelClientConditionInput = {
  clientName?: ModelStringInput | null;
  and?: Array<ModelClientConditionInput | null> | null;
  or?: Array<ModelClientConditionInput | null> | null;
  not?: ModelClientConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
};

export type UpdateClientInput = {
  id: string;
  clientName?: string | null;
};

export type DeleteClientInput = {
  id: string;
};

export type CreateUserMeetingAccessInput = {
  id?: string | null;
  owner?: string | null;
  userID: string;
  meetingID: string;
};

export type ModelUserMeetingAccessConditionInput = {
  owner?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  and?: Array<ModelUserMeetingAccessConditionInput | null> | null;
  or?: Array<ModelUserMeetingAccessConditionInput | null> | null;
  not?: ModelUserMeetingAccessConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateUserMeetingAccessInput = {
  id: string;
  owner?: string | null;
  userID?: string | null;
  meetingID?: string | null;
};

export type DeleteUserMeetingAccessInput = {
  id: string;
};

export type CreateMeetingInput = {
  id?: string | null;
  owner?: string | null;
  name: string;
  caseClusterID: string;
  hostID: string;
  hostFullName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  eventDate: string;
  endDate?: string | null;
  eventLocation: string;
  guestUrlCode?: string | null;
  previewCode?: string | null;
  clientIDs: Array<string>;
  currentCase?: string | null;
  currentSlide: number;
  currentAssetID?: string | null;
  purchaseOrderNbr: string;
  meetingNotes: string;
  meetingType: string;
  isMock: boolean;
  isInTesting: boolean;
  isArchived: boolean;
  isDemo?: boolean | null;
  streamUrl?: string | null;
};

export type ModelMeetingConditionInput = {
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  caseClusterID?: ModelIDInput | null;
  hostID?: ModelIDInput | null;
  hostFullName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  eventDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  eventLocation?: ModelStringInput | null;
  guestUrlCode?: ModelStringInput | null;
  previewCode?: ModelStringInput | null;
  clientIDs?: ModelIDInput | null;
  currentCase?: ModelStringInput | null;
  currentSlide?: ModelIntInput | null;
  currentAssetID?: ModelStringInput | null;
  purchaseOrderNbr?: ModelStringInput | null;
  meetingNotes?: ModelStringInput | null;
  meetingType?: ModelStringInput | null;
  isMock?: ModelBooleanInput | null;
  isInTesting?: ModelBooleanInput | null;
  isArchived?: ModelBooleanInput | null;
  isDemo?: ModelBooleanInput | null;
  streamUrl?: ModelStringInput | null;
  and?: Array<ModelMeetingConditionInput | null> | null;
  or?: Array<ModelMeetingConditionInput | null> | null;
  not?: ModelMeetingConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateMeetingInput = {
  id: string;
  owner?: string | null;
  name?: string | null;
  caseClusterID?: string | null;
  hostID?: string | null;
  hostFullName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  eventDate?: string | null;
  endDate?: string | null;
  eventLocation?: string | null;
  guestUrlCode?: string | null;
  previewCode?: string | null;
  clientIDs?: Array<string> | null;
  currentCase?: string | null;
  currentSlide?: number | null;
  currentAssetID?: string | null;
  purchaseOrderNbr?: string | null;
  meetingNotes?: string | null;
  meetingType?: string | null;
  isMock?: boolean | null;
  isInTesting?: boolean | null;
  isArchived?: boolean | null;
  isDemo?: boolean | null;
  streamUrl?: string | null;
};

export type DeleteMeetingInput = {
  id: string;
};

export type CreateUserMeetingJoinInput = {
  id?: string | null;
  owner?: string | null;
  userID: string;
  meetingID: string;
  isCurrentlyInMeeting?: boolean | null;
  lastPingDate?: string | null;
};

export type ModelUserMeetingJoinConditionInput = {
  owner?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  isCurrentlyInMeeting?: ModelBooleanInput | null;
  lastPingDate?: ModelStringInput | null;
  and?: Array<ModelUserMeetingJoinConditionInput | null> | null;
  or?: Array<ModelUserMeetingJoinConditionInput | null> | null;
  not?: ModelUserMeetingJoinConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UserMeetingJoin = {
  __typename: 'UserMeetingJoin';
  id: string;
  owner?: string | null;
  userID: string;
  meetingID: string;
  user: User;
  isCurrentlyInMeeting?: boolean | null;
  lastPingDate?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMeetingJoinInput = {
  id: string;
  owner?: string | null;
  userID?: string | null;
  meetingID?: string | null;
  isCurrentlyInMeeting?: boolean | null;
  lastPingDate?: string | null;
};

export type DeleteUserMeetingJoinInput = {
  id: string;
};

export type CreateAnswerInput = {
  id?: string | null;
  owner?: string | null;
  questionID: string;
  userID: string;
  meetingID: string;
  answerIndexes: Array<string>;
  answerTexts: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelAnswerConditionInput = {
  owner?: ModelStringInput | null;
  questionID?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  answerIndexes?: ModelStringInput | null;
  answerTexts?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAnswerConditionInput | null> | null;
  or?: Array<ModelAnswerConditionInput | null> | null;
  not?: ModelAnswerConditionInput | null;
};

export type Answer = {
  __typename: 'Answer';
  id: string;
  owner?: string | null;
  questionID: string;
  userID: string;
  meetingID: string;
  answerIndexes: Array<string>;
  answerTexts: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
  user: User;
};

export type UpdateAnswerInput = {
  id: string;
  owner?: string | null;
  questionID?: string | null;
  userID?: string | null;
  meetingID?: string | null;
  answerIndexes?: Array<string> | null;
  answerTexts?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAnswerInput = {
  id: string;
};

export type CreateCaseClusterInput = {
  id?: string | null;
  owner?: string | null;
  name: string;
  cases: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
  isMock?: boolean | null;
};

export type ModelCaseClusterConditionInput = {
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  cases?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  isMock?: ModelBooleanInput | null;
  and?: Array<ModelCaseClusterConditionInput | null> | null;
  or?: Array<ModelCaseClusterConditionInput | null> | null;
  not?: ModelCaseClusterConditionInput | null;
};

export type UpdateCaseClusterInput = {
  id: string;
  owner?: string | null;
  name?: string | null;
  cases?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isMock?: boolean | null;
};

export type DeleteCaseClusterInput = {
  id: string;
};

export type CreateMessageInput = {
  id?: string | null;
  owner?: string | null;
  channelID: string;
  author: string;
  body: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelMessageConditionInput = {
  owner?: ModelStringInput | null;
  channelID?: ModelIDInput | null;
  author?: ModelStringInput | null;
  body?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMessageConditionInput | null> | null;
  or?: Array<ModelMessageConditionInput | null> | null;
  not?: ModelMessageConditionInput | null;
};

export type Message = {
  __typename: 'Message';
  id: string;
  owner?: string | null;
  channelID: string;
  author: string;
  body: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateMessageInput = {
  id: string;
  owner?: string | null;
  channelID?: string | null;
  author?: string | null;
  body?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteMessageInput = {
  id: string;
};

export type CreateMeetingStatsInput = {
  id?: string | null;
  owner?: string | null;
  meetingID: string;
  casePresentationHistory: Array<string>;
  version: string;
  amountParticipants?: number | null;
};

export type ModelMeetingStatsConditionInput = {
  owner?: ModelStringInput | null;
  meetingID?: ModelIDInput | null;
  casePresentationHistory?: ModelStringInput | null;
  version?: ModelStringInput | null;
  amountParticipants?: ModelIntInput | null;
  and?: Array<ModelMeetingStatsConditionInput | null> | null;
  or?: Array<ModelMeetingStatsConditionInput | null> | null;
  not?: ModelMeetingStatsConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateMeetingStatsInput = {
  id: string;
  owner?: string | null;
  meetingID?: string | null;
  casePresentationHistory?: Array<string> | null;
  version?: string | null;
  amountParticipants?: number | null;
};

export type DeleteMeetingStatsInput = {
  id: string;
};

export type CreateCaseStatsInput = {
  id?: string | null;
  owner?: string | null;
  meetingID: string;
  caseId: string;
  version: string;
};

export type ModelCaseStatsConditionInput = {
  owner?: ModelStringInput | null;
  meetingID?: ModelIDInput | null;
  caseId?: ModelStringInput | null;
  version?: ModelStringInput | null;
  and?: Array<ModelCaseStatsConditionInput | null> | null;
  or?: Array<ModelCaseStatsConditionInput | null> | null;
  not?: ModelCaseStatsConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateCaseStatsInput = {
  id: string;
  owner?: string | null;
  meetingID?: string | null;
  caseId?: string | null;
  version?: string | null;
};

export type DeleteCaseStatsInput = {
  id: string;
};

export type CreateCaseAssetViewInput = {
  id?: string | null;
  owner?: string | null;
  assetId: string;
  userID: string;
  timeWatched: number;
  version: string;
  caseStatsAssetViewsId: string;
};

export type ModelCaseAssetViewConditionInput = {
  owner?: ModelStringInput | null;
  assetId?: ModelStringInput | null;
  userID?: ModelStringInput | null;
  timeWatched?: ModelIntInput | null;
  version?: ModelStringInput | null;
  and?: Array<ModelCaseAssetViewConditionInput | null> | null;
  or?: Array<ModelCaseAssetViewConditionInput | null> | null;
  not?: ModelCaseAssetViewConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  caseStatsAssetViewsId?: ModelIDInput | null;
};

export type UpdateCaseAssetViewInput = {
  id: string;
  owner?: string | null;
  assetId?: string | null;
  userID?: string | null;
  timeWatched?: number | null;
  version?: string | null;
  caseStatsAssetViewsId?: string | null;
};

export type DeleteCaseAssetViewInput = {
  id: string;
};

export type CreateCaseSlideStatsInput = {
  id?: string | null;
  owner?: string | null;
  slideId: string;
  time: number;
  version: string;
  caseStatsSlidesId: string;
};

export type ModelCaseSlideStatsConditionInput = {
  owner?: ModelStringInput | null;
  slideId?: ModelStringInput | null;
  time?: ModelIntInput | null;
  version?: ModelStringInput | null;
  and?: Array<ModelCaseSlideStatsConditionInput | null> | null;
  or?: Array<ModelCaseSlideStatsConditionInput | null> | null;
  not?: ModelCaseSlideStatsConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  caseStatsSlidesId?: ModelIDInput | null;
};

export type UpdateCaseSlideStatsInput = {
  id: string;
  owner?: string | null;
  slideId?: string | null;
  time?: number | null;
  version?: string | null;
  caseStatsSlidesId?: string | null;
};

export type DeleteCaseSlideStatsInput = {
  id: string;
};

export type CreateMeetingNPSScoreInput = {
  id?: string | null;
  owner?: string | null;
  userID: string;
  meetingID: string;
  scores: Array<number>;
  version: string;
};

export type ModelMeetingNPSScoreConditionInput = {
  owner?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  scores?: ModelIntInput | null;
  version?: ModelStringInput | null;
  and?: Array<ModelMeetingNPSScoreConditionInput | null> | null;
  or?: Array<ModelMeetingNPSScoreConditionInput | null> | null;
  not?: ModelMeetingNPSScoreConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type MeetingNPSScore = {
  __typename: 'MeetingNPSScore';
  id: string;
  owner?: string | null;
  userID: string;
  meetingID: string;
  scores: Array<number>;
  version: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMeetingNPSScoreInput = {
  id: string;
  owner?: string | null;
  userID?: string | null;
  meetingID?: string | null;
  scores?: Array<number> | null;
  version?: string | null;
};

export type DeleteMeetingNPSScoreInput = {
  id: string;
};

export type CreateAccessLinkInput = {
  id?: string | null;
  purpose: string;
  accessCode: string;
  data: string;
};

export type ModelAccessLinkConditionInput = {
  purpose?: ModelStringInput | null;
  accessCode?: ModelStringInput | null;
  data?: ModelStringInput | null;
  and?: Array<ModelAccessLinkConditionInput | null> | null;
  or?: Array<ModelAccessLinkConditionInput | null> | null;
  not?: ModelAccessLinkConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
};

export type AccessLink = {
  __typename: 'AccessLink';
  id: string;
  purpose: string;
  accessCode: string;
  data: string;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateAccessLinkInput = {
  id: string;
  purpose?: string | null;
  accessCode?: string | null;
  data?: string | null;
};

export type DeleteAccessLinkInput = {
  id: string;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  last_seen?: ModelStringInput | null;
  currentMeetingID?: ModelIDInput | null;
  isTemporary?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  isMock?: ModelBooleanInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelUserClientFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserClientFilterInput | null> | null;
  or?: Array<ModelUserClientFilterInput | null> | null;
  not?: ModelUserClientFilterInput | null;
};

export type ModelUserClientConnection = {
  __typename: 'ModelUserClientConnection';
  items: Array<UserClient | null>;
  nextToken?: string | null;
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null;
  clientName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelClientFilterInput | null> | null;
  or?: Array<ModelClientFilterInput | null> | null;
  not?: ModelClientFilterInput | null;
  owner?: ModelStringInput | null;
};

export type ModelClientConnection = {
  __typename: 'ModelClientConnection';
  items: Array<Client | null>;
  nextToken?: string | null;
};

export type ModelUserMeetingAccessFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserMeetingAccessFilterInput | null> | null;
  or?: Array<ModelUserMeetingAccessFilterInput | null> | null;
  not?: ModelUserMeetingAccessFilterInput | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  caseClusterID?: ModelIDInput | null;
  hostID?: ModelIDInput | null;
  hostFullName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  eventDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  eventLocation?: ModelStringInput | null;
  guestUrlCode?: ModelStringInput | null;
  previewCode?: ModelStringInput | null;
  clientIDs?: ModelIDInput | null;
  currentCase?: ModelStringInput | null;
  currentSlide?: ModelIntInput | null;
  currentAssetID?: ModelStringInput | null;
  purchaseOrderNbr?: ModelStringInput | null;
  meetingNotes?: ModelStringInput | null;
  meetingType?: ModelStringInput | null;
  isMock?: ModelBooleanInput | null;
  isInTesting?: ModelBooleanInput | null;
  isArchived?: ModelBooleanInput | null;
  isDemo?: ModelBooleanInput | null;
  streamUrl?: ModelStringInput | null;
  and?: Array<ModelMeetingFilterInput | null> | null;
  or?: Array<ModelMeetingFilterInput | null> | null;
  not?: ModelMeetingFilterInput | null;
};

export type ModelMeetingConnection = {
  __typename: 'ModelMeetingConnection';
  items: Array<Meeting | null>;
  nextToken?: string | null;
};

export type ModelUserMeetingJoinFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  isCurrentlyInMeeting?: ModelBooleanInput | null;
  lastPingDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserMeetingJoinFilterInput | null> | null;
  or?: Array<ModelUserMeetingJoinFilterInput | null> | null;
  not?: ModelUserMeetingJoinFilterInput | null;
};

export type ModelUserMeetingJoinConnection = {
  __typename: 'ModelUserMeetingJoinConnection';
  items: Array<UserMeetingJoin | null>;
  nextToken?: string | null;
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  questionID?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  answerIndexes?: ModelStringInput | null;
  answerTexts?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAnswerFilterInput | null> | null;
  or?: Array<ModelAnswerFilterInput | null> | null;
  not?: ModelAnswerFilterInput | null;
};

export type ModelAnswerConnection = {
  __typename: 'ModelAnswerConnection';
  items: Array<Answer | null>;
  nextToken?: string | null;
};

export type ModelCaseClusterFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  cases?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  isMock?: ModelBooleanInput | null;
  and?: Array<ModelCaseClusterFilterInput | null> | null;
  or?: Array<ModelCaseClusterFilterInput | null> | null;
  not?: ModelCaseClusterFilterInput | null;
};

export type ModelCaseClusterConnection = {
  __typename: 'ModelCaseClusterConnection';
  items: Array<CaseCluster | null>;
  nextToken?: string | null;
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  channelID?: ModelIDInput | null;
  author?: ModelStringInput | null;
  body?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMessageFilterInput | null> | null;
  or?: Array<ModelMessageFilterInput | null> | null;
  not?: ModelMessageFilterInput | null;
};

export type ModelMessageConnection = {
  __typename: 'ModelMessageConnection';
  items: Array<Message | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelMeetingStatsFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  meetingID?: ModelIDInput | null;
  casePresentationHistory?: ModelStringInput | null;
  version?: ModelStringInput | null;
  amountParticipants?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMeetingStatsFilterInput | null> | null;
  or?: Array<ModelMeetingStatsFilterInput | null> | null;
  not?: ModelMeetingStatsFilterInput | null;
};

export type ModelMeetingStatsConnection = {
  __typename: 'ModelMeetingStatsConnection';
  items: Array<MeetingStats | null>;
  nextToken?: string | null;
};

export type ModelCaseStatsFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  meetingID?: ModelIDInput | null;
  caseId?: ModelStringInput | null;
  version?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCaseStatsFilterInput | null> | null;
  or?: Array<ModelCaseStatsFilterInput | null> | null;
  not?: ModelCaseStatsFilterInput | null;
};

export type ModelCaseAssetViewFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  assetId?: ModelStringInput | null;
  userID?: ModelStringInput | null;
  timeWatched?: ModelIntInput | null;
  version?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCaseAssetViewFilterInput | null> | null;
  or?: Array<ModelCaseAssetViewFilterInput | null> | null;
  not?: ModelCaseAssetViewFilterInput | null;
  caseStatsAssetViewsId?: ModelIDInput | null;
};

export type ModelCaseSlideStatsFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  slideId?: ModelStringInput | null;
  time?: ModelIntInput | null;
  version?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCaseSlideStatsFilterInput | null> | null;
  or?: Array<ModelCaseSlideStatsFilterInput | null> | null;
  not?: ModelCaseSlideStatsFilterInput | null;
  caseStatsSlidesId?: ModelIDInput | null;
};

export type ModelMeetingNPSScoreFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  meetingID?: ModelIDInput | null;
  scores?: ModelIntInput | null;
  version?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMeetingNPSScoreFilterInput | null> | null;
  or?: Array<ModelMeetingNPSScoreFilterInput | null> | null;
  not?: ModelMeetingNPSScoreFilterInput | null;
};

export type ModelMeetingNPSScoreConnection = {
  __typename: 'ModelMeetingNPSScoreConnection';
  items: Array<MeetingNPSScore | null>;
  nextToken?: string | null;
};

export type ModelAccessLinkFilterInput = {
  id?: ModelIDInput | null;
  purpose?: ModelStringInput | null;
  accessCode?: ModelStringInput | null;
  data?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAccessLinkFilterInput | null> | null;
  or?: Array<ModelAccessLinkFilterInput | null> | null;
  not?: ModelAccessLinkFilterInput | null;
  owner?: ModelStringInput | null;
};

export type ModelAccessLinkConnection = {
  __typename: 'ModelAccessLinkConnection';
  items: Array<AccessLink | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  firstname?: ModelSubscriptionStringInput | null;
  lastname?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  last_seen?: ModelSubscriptionStringInput | null;
  currentMeetingID?: ModelSubscriptionIDInput | null;
  isTemporary?: ModelSubscriptionBooleanInput | null;
  isMock?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionUserFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionUserClientFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  clientID?: ModelSubscriptionIDInput | null;
  userID?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserClientFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserClientFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  clientName?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionClientFilterInput | null> | null;
  or?: Array<ModelSubscriptionClientFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionUserMeetingAccessFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  userID?: ModelSubscriptionIDInput | null;
  meetingID?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserMeetingAccessFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserMeetingAccessFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionMeetingFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  caseClusterID?: ModelSubscriptionIDInput | null;
  hostID?: ModelSubscriptionIDInput | null;
  hostFullName?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  eventDate?: ModelSubscriptionStringInput | null;
  endDate?: ModelSubscriptionStringInput | null;
  eventLocation?: ModelSubscriptionStringInput | null;
  guestUrlCode?: ModelSubscriptionStringInput | null;
  previewCode?: ModelSubscriptionStringInput | null;
  clientIDs?: ModelSubscriptionIDInput | null;
  currentCase?: ModelSubscriptionStringInput | null;
  currentSlide?: ModelSubscriptionIntInput | null;
  currentAssetID?: ModelSubscriptionStringInput | null;
  purchaseOrderNbr?: ModelSubscriptionStringInput | null;
  meetingNotes?: ModelSubscriptionStringInput | null;
  meetingType?: ModelSubscriptionStringInput | null;
  isMock?: ModelSubscriptionBooleanInput | null;
  isInTesting?: ModelSubscriptionBooleanInput | null;
  isArchived?: ModelSubscriptionBooleanInput | null;
  isDemo?: ModelSubscriptionBooleanInput | null;
  streamUrl?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMeetingFilterInput | null> | null;
  or?: Array<ModelSubscriptionMeetingFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionUserMeetingJoinFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  userID?: ModelSubscriptionIDInput | null;
  meetingID?: ModelSubscriptionIDInput | null;
  isCurrentlyInMeeting?: ModelSubscriptionBooleanInput | null;
  lastPingDate?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserMeetingJoinFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserMeetingJoinFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  questionID?: ModelSubscriptionStringInput | null;
  userID?: ModelSubscriptionIDInput | null;
  meetingID?: ModelSubscriptionIDInput | null;
  answerIndexes?: ModelSubscriptionStringInput | null;
  answerTexts?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAnswerFilterInput | null> | null;
  or?: Array<ModelSubscriptionAnswerFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionCaseClusterFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  cases?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  isMock?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionCaseClusterFilterInput | null> | null;
  or?: Array<ModelSubscriptionCaseClusterFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  channelID?: ModelSubscriptionIDInput | null;
  author?: ModelSubscriptionStringInput | null;
  body?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMessageFilterInput | null> | null;
  or?: Array<ModelSubscriptionMessageFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionMeetingStatsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  meetingID?: ModelSubscriptionIDInput | null;
  casePresentationHistory?: ModelSubscriptionStringInput | null;
  version?: ModelSubscriptionStringInput | null;
  amountParticipants?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMeetingStatsFilterInput | null> | null;
  or?: Array<ModelSubscriptionMeetingStatsFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionCaseStatsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  meetingID?: ModelSubscriptionIDInput | null;
  caseId?: ModelSubscriptionStringInput | null;
  version?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCaseStatsFilterInput | null> | null;
  or?: Array<ModelSubscriptionCaseStatsFilterInput | null> | null;
  caseStatsAssetViewsId?: ModelSubscriptionIDInput | null;
  caseStatsSlidesId?: ModelSubscriptionIDInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionCaseAssetViewFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  assetId?: ModelSubscriptionStringInput | null;
  userID?: ModelSubscriptionStringInput | null;
  timeWatched?: ModelSubscriptionIntInput | null;
  version?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCaseAssetViewFilterInput | null> | null;
  or?: Array<ModelSubscriptionCaseAssetViewFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionCaseSlideStatsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  slideId?: ModelSubscriptionStringInput | null;
  time?: ModelSubscriptionIntInput | null;
  version?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCaseSlideStatsFilterInput | null> | null;
  or?: Array<ModelSubscriptionCaseSlideStatsFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionMeetingNPSScoreFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  userID?: ModelSubscriptionIDInput | null;
  meetingID?: ModelSubscriptionIDInput | null;
  scores?: ModelSubscriptionIntInput | null;
  version?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMeetingNPSScoreFilterInput | null> | null;
  or?: Array<ModelSubscriptionMeetingNPSScoreFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionAccessLinkFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  purpose?: ModelSubscriptionStringInput | null;
  accessCode?: ModelSubscriptionStringInput | null;
  data?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAccessLinkFilterInput | null> | null;
  or?: Array<ModelSubscriptionAccessLinkFilterInput | null> | null;
  owner?: ModelStringInput | null;
};

export type CreateUserMutationVariables = {
  input: CreateUserInput;
  condition?: ModelUserConditionInput | null;
};

export type CreateUserMutation = {
  createUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
  condition?: ModelUserConditionInput | null;
};

export type UpdateUserMutation = {
  updateUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput;
  condition?: ModelUserConditionInput | null;
};

export type DeleteUserMutation = {
  deleteUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type CreateUserClientMutationVariables = {
  input: CreateUserClientInput;
  condition?: ModelUserClientConditionInput | null;
};

export type CreateUserClientMutation = {
  createUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserClientMutationVariables = {
  input: UpdateUserClientInput;
  condition?: ModelUserClientConditionInput | null;
};

export type UpdateUserClientMutation = {
  updateUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserClientMutationVariables = {
  input: DeleteUserClientInput;
  condition?: ModelUserClientConditionInput | null;
};

export type DeleteUserClientMutation = {
  deleteUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateClientMutationVariables = {
  input: CreateClientInput;
  condition?: ModelClientConditionInput | null;
};

export type CreateClientMutation = {
  createClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput;
  condition?: ModelClientConditionInput | null;
};

export type UpdateClientMutation = {
  updateClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput;
  condition?: ModelClientConditionInput | null;
};

export type DeleteClientMutation = {
  deleteClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateUserMeetingAccessMutationVariables = {
  input: CreateUserMeetingAccessInput;
  condition?: ModelUserMeetingAccessConditionInput | null;
};

export type CreateUserMeetingAccessMutation = {
  createUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserMeetingAccessMutationVariables = {
  input: UpdateUserMeetingAccessInput;
  condition?: ModelUserMeetingAccessConditionInput | null;
};

export type UpdateUserMeetingAccessMutation = {
  updateUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserMeetingAccessMutationVariables = {
  input: DeleteUserMeetingAccessInput;
  condition?: ModelUserMeetingAccessConditionInput | null;
};

export type DeleteUserMeetingAccessMutation = {
  deleteUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput;
  condition?: ModelMeetingConditionInput | null;
};

export type CreateMeetingMutation = {
  createMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput;
  condition?: ModelMeetingConditionInput | null;
};

export type UpdateMeetingMutation = {
  updateMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type DeleteMeetingMutationVariables = {
  input: DeleteMeetingInput;
  condition?: ModelMeetingConditionInput | null;
};

export type DeleteMeetingMutation = {
  deleteMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type CreateUserMeetingJoinMutationVariables = {
  input: CreateUserMeetingJoinInput;
  condition?: ModelUserMeetingJoinConditionInput | null;
};

export type CreateUserMeetingJoinMutation = {
  createUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserMeetingJoinMutationVariables = {
  input: UpdateUserMeetingJoinInput;
  condition?: ModelUserMeetingJoinConditionInput | null;
};

export type UpdateUserMeetingJoinMutation = {
  updateUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserMeetingJoinMutationVariables = {
  input: DeleteUserMeetingJoinInput;
  condition?: ModelUserMeetingJoinConditionInput | null;
};

export type DeleteUserMeetingJoinMutation = {
  deleteUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAnswerMutationVariables = {
  input: CreateAnswerInput;
  condition?: ModelAnswerConditionInput | null;
};

export type CreateAnswerMutation = {
  createAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type UpdateAnswerMutationVariables = {
  input: UpdateAnswerInput;
  condition?: ModelAnswerConditionInput | null;
};

export type UpdateAnswerMutation = {
  updateAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type DeleteAnswerMutationVariables = {
  input: DeleteAnswerInput;
  condition?: ModelAnswerConditionInput | null;
};

export type DeleteAnswerMutation = {
  deleteAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type CreateCaseClusterMutationVariables = {
  input: CreateCaseClusterInput;
  condition?: ModelCaseClusterConditionInput | null;
};

export type CreateCaseClusterMutation = {
  createCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type UpdateCaseClusterMutationVariables = {
  input: UpdateCaseClusterInput;
  condition?: ModelCaseClusterConditionInput | null;
};

export type UpdateCaseClusterMutation = {
  updateCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type DeleteCaseClusterMutationVariables = {
  input: DeleteCaseClusterInput;
  condition?: ModelCaseClusterConditionInput | null;
};

export type DeleteCaseClusterMutation = {
  deleteCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput;
  condition?: ModelMessageConditionInput | null;
};

export type CreateMessageMutation = {
  createMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput;
  condition?: ModelMessageConditionInput | null;
};

export type UpdateMessageMutation = {
  updateMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput;
  condition?: ModelMessageConditionInput | null;
};

export type DeleteMessageMutation = {
  deleteMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateMeetingStatsMutationVariables = {
  input: CreateMeetingStatsInput;
  condition?: ModelMeetingStatsConditionInput | null;
};

export type CreateMeetingStatsMutation = {
  createMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateMeetingStatsMutationVariables = {
  input: UpdateMeetingStatsInput;
  condition?: ModelMeetingStatsConditionInput | null;
};

export type UpdateMeetingStatsMutation = {
  updateMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteMeetingStatsMutationVariables = {
  input: DeleteMeetingStatsInput;
  condition?: ModelMeetingStatsConditionInput | null;
};

export type DeleteMeetingStatsMutation = {
  deleteMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateCaseStatsMutationVariables = {
  input: CreateCaseStatsInput;
  condition?: ModelCaseStatsConditionInput | null;
};

export type CreateCaseStatsMutation = {
  createCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateCaseStatsMutationVariables = {
  input: UpdateCaseStatsInput;
  condition?: ModelCaseStatsConditionInput | null;
};

export type UpdateCaseStatsMutation = {
  updateCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteCaseStatsMutationVariables = {
  input: DeleteCaseStatsInput;
  condition?: ModelCaseStatsConditionInput | null;
};

export type DeleteCaseStatsMutation = {
  deleteCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateCaseAssetViewMutationVariables = {
  input: CreateCaseAssetViewInput;
  condition?: ModelCaseAssetViewConditionInput | null;
};

export type CreateCaseAssetViewMutation = {
  createCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type UpdateCaseAssetViewMutationVariables = {
  input: UpdateCaseAssetViewInput;
  condition?: ModelCaseAssetViewConditionInput | null;
};

export type UpdateCaseAssetViewMutation = {
  updateCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type DeleteCaseAssetViewMutationVariables = {
  input: DeleteCaseAssetViewInput;
  condition?: ModelCaseAssetViewConditionInput | null;
};

export type DeleteCaseAssetViewMutation = {
  deleteCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type CreateCaseSlideStatsMutationVariables = {
  input: CreateCaseSlideStatsInput;
  condition?: ModelCaseSlideStatsConditionInput | null;
};

export type CreateCaseSlideStatsMutation = {
  createCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type UpdateCaseSlideStatsMutationVariables = {
  input: UpdateCaseSlideStatsInput;
  condition?: ModelCaseSlideStatsConditionInput | null;
};

export type UpdateCaseSlideStatsMutation = {
  updateCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type DeleteCaseSlideStatsMutationVariables = {
  input: DeleteCaseSlideStatsInput;
  condition?: ModelCaseSlideStatsConditionInput | null;
};

export type DeleteCaseSlideStatsMutation = {
  deleteCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type CreateMeetingNPSScoreMutationVariables = {
  input: CreateMeetingNPSScoreInput;
  condition?: ModelMeetingNPSScoreConditionInput | null;
};

export type CreateMeetingNPSScoreMutation = {
  createMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateMeetingNPSScoreMutationVariables = {
  input: UpdateMeetingNPSScoreInput;
  condition?: ModelMeetingNPSScoreConditionInput | null;
};

export type UpdateMeetingNPSScoreMutation = {
  updateMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteMeetingNPSScoreMutationVariables = {
  input: DeleteMeetingNPSScoreInput;
  condition?: ModelMeetingNPSScoreConditionInput | null;
};

export type DeleteMeetingNPSScoreMutation = {
  deleteMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAccessLinkMutationVariables = {
  input: CreateAccessLinkInput;
  condition?: ModelAccessLinkConditionInput | null;
};

export type CreateAccessLinkMutation = {
  createAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateAccessLinkMutationVariables = {
  input: UpdateAccessLinkInput;
  condition?: ModelAccessLinkConditionInput | null;
};

export type UpdateAccessLinkMutation = {
  updateAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteAccessLinkMutationVariables = {
  input: DeleteAccessLinkInput;
  condition?: ModelAccessLinkConditionInput | null;
};

export type DeleteAccessLinkMutation = {
  deleteAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type GetUserQueryVariables = {
  id: string;
};

export type GetUserQuery = {
  getUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type ListUsersQueryVariables = {
  id?: string | null;
  filter?: ModelUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListUsersQuery = {
  listUsers?: {
    __typename: 'ModelUserConnection';
    items: Array<{
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserClientQueryVariables = {
  id: string;
};

export type GetUserClientQuery = {
  getUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserClientsQueryVariables = {
  filter?: ModelUserClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserClientsQuery = {
  listUserClients?: {
    __typename: 'ModelUserClientConnection';
    items: Array<{
      __typename: 'UserClient';
      id: string;
      owner?: string | null;
      clientID: string;
      client?: {
        __typename: 'Client';
        id: string;
        clientName: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
      userID: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetClientQueryVariables = {
  id: string;
};

export type GetClientQuery = {
  getClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListClientsQuery = {
  listClients?: {
    __typename: 'ModelClientConnection';
    items: Array<{
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserMeetingAccessQueryVariables = {
  id: string;
};

export type GetUserMeetingAccessQuery = {
  getUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserMeetingAccessesQueryVariables = {
  filter?: ModelUserMeetingAccessFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserMeetingAccessesQuery = {
  listUserMeetingAccesses?: {
    __typename: 'ModelUserMeetingAccessConnection';
    items: Array<{
      __typename: 'UserMeetingAccess';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserMeetingAccessByUserIDQueryVariables = {
  userID: string;
  meetingID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserMeetingAccessFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserMeetingAccessByUserIDQuery = {
  userMeetingAccessByUserID?: {
    __typename: 'ModelUserMeetingAccessConnection';
    items: Array<{
      __typename: 'UserMeetingAccess';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserMeetingAccessByMeetingIDQueryVariables = {
  meetingID: string;
  userID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserMeetingAccessFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserMeetingAccessByMeetingIDQuery = {
  userMeetingAccessByMeetingID?: {
    __typename: 'ModelUserMeetingAccessConnection';
    items: Array<{
      __typename: 'UserMeetingAccess';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetMeetingQueryVariables = {
  id: string;
};

export type GetMeetingQuery = {
  getMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListMeetingsQuery = {
  listMeetings?: {
    __typename: 'ModelMeetingConnection';
    items: Array<{
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserMeetingJoinQueryVariables = {
  id: string;
};

export type GetUserMeetingJoinQuery = {
  getUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserMeetingJoinsQueryVariables = {
  filter?: ModelUserMeetingJoinFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserMeetingJoinsQuery = {
  listUserMeetingJoins?: {
    __typename: 'ModelUserMeetingJoinConnection';
    items: Array<{
      __typename: 'UserMeetingJoin';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      user: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      };
      isCurrentlyInMeeting?: boolean | null;
      lastPingDate?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserMeetingJoinByUserIDQueryVariables = {
  userID: string;
  meetingID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserMeetingJoinFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserMeetingJoinByUserIDQuery = {
  userMeetingJoinByUserID?: {
    __typename: 'ModelUserMeetingJoinConnection';
    items: Array<{
      __typename: 'UserMeetingJoin';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      user: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      };
      isCurrentlyInMeeting?: boolean | null;
      lastPingDate?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserMeetingsByMeetingIDQueryVariables = {
  meetingID: string;
  userID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelUserMeetingJoinFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserMeetingsByMeetingIDQuery = {
  userMeetingsByMeetingID?: {
    __typename: 'ModelUserMeetingJoinConnection';
    items: Array<{
      __typename: 'UserMeetingJoin';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      user: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      };
      isCurrentlyInMeeting?: boolean | null;
      lastPingDate?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAnswerQueryVariables = {
  id: string;
};

export type GetAnswerQuery = {
  getAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type ListAnswersQueryVariables = {
  filter?: ModelAnswerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAnswersQuery = {
  listAnswers?: {
    __typename: 'ModelAnswerConnection';
    items: Array<{
      __typename: 'Answer';
      id: string;
      owner?: string | null;
      questionID: string;
      userID: string;
      meetingID: string;
      answerIndexes: Array<string>;
      answerTexts: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      user: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AnswersByQuestionIDQueryVariables = {
  questionID: string;
  userID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAnswerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AnswersByQuestionIDQuery = {
  answersByQuestionID?: {
    __typename: 'ModelAnswerConnection';
    items: Array<{
      __typename: 'Answer';
      id: string;
      owner?: string | null;
      questionID: string;
      userID: string;
      meetingID: string;
      answerIndexes: Array<string>;
      answerTexts: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      user: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AnswersByMeetingIDQueryVariables = {
  meetingID: string;
  userID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelAnswerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AnswersByMeetingIDQuery = {
  answersByMeetingID?: {
    __typename: 'ModelAnswerConnection';
    items: Array<{
      __typename: 'Answer';
      id: string;
      owner?: string | null;
      questionID: string;
      userID: string;
      meetingID: string;
      answerIndexes: Array<string>;
      answerTexts: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      user: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCaseClusterQueryVariables = {
  id: string;
};

export type GetCaseClusterQuery = {
  getCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type ListCaseClustersQueryVariables = {
  filter?: ModelCaseClusterFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCaseClustersQuery = {
  listCaseClusters?: {
    __typename: 'ModelCaseClusterConnection';
    items: Array<{
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetMessageQueryVariables = {
  id: string;
};

export type GetMessageQuery = {
  getMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListMessagesQuery = {
  listMessages?: {
    __typename: 'ModelMessageConnection';
    items: Array<{
      __typename: 'Message';
      id: string;
      owner?: string | null;
      channelID: string;
      author: string;
      body: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type MessagesByChannelIDQueryVariables = {
  channelID: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelMessageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type MessagesByChannelIDQuery = {
  messagesByChannelID?: {
    __typename: 'ModelMessageConnection';
    items: Array<{
      __typename: 'Message';
      id: string;
      owner?: string | null;
      channelID: string;
      author: string;
      body: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetMeetingStatsQueryVariables = {
  id: string;
};

export type GetMeetingStatsQuery = {
  getMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListMeetingStatsQueryVariables = {
  filter?: ModelMeetingStatsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListMeetingStatsQuery = {
  listMeetingStats?: {
    __typename: 'ModelMeetingStatsConnection';
    items: Array<{
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type MeetingStatsByMeetingQueryVariables = {
  meetingID: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelMeetingStatsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type MeetingStatsByMeetingQuery = {
  meetingStatsByMeeting?: {
    __typename: 'ModelMeetingStatsConnection';
    items: Array<{
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCaseStatsQueryVariables = {
  id: string;
};

export type GetCaseStatsQuery = {
  getCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListCaseStatsQueryVariables = {
  filter?: ModelCaseStatsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCaseStatsQuery = {
  listCaseStats?: {
    __typename: 'ModelCaseStatsConnection';
    items: Array<{
      __typename: 'CaseStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      caseId: string;
      assetViews?: {
        __typename: 'ModelCaseAssetViewConnection';
        items: Array<{
          __typename: 'CaseAssetView';
          id: string;
          owner?: string | null;
          assetId: string;
          userID: string;
          timeWatched: number;
          version: string;
          createdAt: string;
          updatedAt: string;
          caseStatsAssetViewsId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      slides?: {
        __typename: 'ModelCaseSlideStatsConnection';
        items: Array<{
          __typename: 'CaseSlideStats';
          id: string;
          owner?: string | null;
          slideId: string;
          time: number;
          version: string;
          createdAt: string;
          updatedAt: string;
          caseStatsSlidesId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      version: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CaseStatsByMeetingQueryVariables = {
  meetingID: string;
  caseId?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCaseStatsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CaseStatsByMeetingQuery = {
  caseStatsByMeeting?: {
    __typename: 'ModelCaseStatsConnection';
    items: Array<{
      __typename: 'CaseStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      caseId: string;
      assetViews?: {
        __typename: 'ModelCaseAssetViewConnection';
        items: Array<{
          __typename: 'CaseAssetView';
          id: string;
          owner?: string | null;
          assetId: string;
          userID: string;
          timeWatched: number;
          version: string;
          createdAt: string;
          updatedAt: string;
          caseStatsAssetViewsId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      slides?: {
        __typename: 'ModelCaseSlideStatsConnection';
        items: Array<{
          __typename: 'CaseSlideStats';
          id: string;
          owner?: string | null;
          slideId: string;
          time: number;
          version: string;
          createdAt: string;
          updatedAt: string;
          caseStatsSlidesId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      version: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCaseAssetViewQueryVariables = {
  id: string;
};

export type GetCaseAssetViewQuery = {
  getCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type ListCaseAssetViewsQueryVariables = {
  filter?: ModelCaseAssetViewFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCaseAssetViewsQuery = {
  listCaseAssetViews?: {
    __typename: 'ModelCaseAssetViewConnection';
    items: Array<{
      __typename: 'CaseAssetView';
      id: string;
      owner?: string | null;
      assetId: string;
      userID: string;
      timeWatched: number;
      version: string;
      createdAt: string;
      updatedAt: string;
      caseStatsAssetViewsId: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCaseSlideStatsQueryVariables = {
  id: string;
};

export type GetCaseSlideStatsQuery = {
  getCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type ListCaseSlideStatsQueryVariables = {
  filter?: ModelCaseSlideStatsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCaseSlideStatsQuery = {
  listCaseSlideStats?: {
    __typename: 'ModelCaseSlideStatsConnection';
    items: Array<{
      __typename: 'CaseSlideStats';
      id: string;
      owner?: string | null;
      slideId: string;
      time: number;
      version: string;
      createdAt: string;
      updatedAt: string;
      caseStatsSlidesId: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetMeetingNPSScoreQueryVariables = {
  id: string;
};

export type GetMeetingNPSScoreQuery = {
  getMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListMeetingNPSScoresQueryVariables = {
  filter?: ModelMeetingNPSScoreFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListMeetingNPSScoresQuery = {
  listMeetingNPSScores?: {
    __typename: 'ModelMeetingNPSScoreConnection';
    items: Array<{
      __typename: 'MeetingNPSScore';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      scores: Array<number>;
      version: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type NpsScoresByUserQueryVariables = {
  userID: string;
  meetingID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelMeetingNPSScoreFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type NpsScoresByUserQuery = {
  npsScoresByUser?: {
    __typename: 'ModelMeetingNPSScoreConnection';
    items: Array<{
      __typename: 'MeetingNPSScore';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      scores: Array<number>;
      version: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type NpsScoresByMeetingQueryVariables = {
  meetingID: string;
  userID?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelMeetingNPSScoreFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type NpsScoresByMeetingQuery = {
  npsScoresByMeeting?: {
    __typename: 'ModelMeetingNPSScoreConnection';
    items: Array<{
      __typename: 'MeetingNPSScore';
      id: string;
      owner?: string | null;
      userID: string;
      meetingID: string;
      scores: Array<number>;
      version: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccessLinkQueryVariables = {
  id: string;
};

export type GetAccessLinkQuery = {
  getAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListAccessLinksQueryVariables = {
  filter?: ModelAccessLinkFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccessLinksQuery = {
  listAccessLinks?: {
    __typename: 'ModelAccessLinkConnection';
    items: Array<{
      __typename: 'AccessLink';
      id: string;
      purpose: string;
      accessCode: string;
      data: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserSubscription = {
  onCreateUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserSubscription = {
  onUpdateUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserSubscription = {
  onDeleteUser?: {
    __typename: 'User';
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    meetingAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    last_seen?: string | null;
    currentMeetingID: string;
    currentMeeting?: {
      __typename: 'Meeting';
      id: string;
      owner?: string | null;
      name: string;
      caseClusterID: string;
      hostID: string;
      host?: {
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null;
      hostFullName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      eventDate: string;
      endDate?: string | null;
      eventLocation: string;
      participants?: {
        __typename: 'ModelUserConnection';
        items: Array<{
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      userAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      guestUrlCode?: string | null;
      previewCode?: string | null;
      clientIDs: Array<string>;
      currentCase?: string | null;
      currentSlide: number;
      currentAssetID?: string | null;
      caseCluster?: {
        __typename: 'CaseCluster';
        id: string;
        owner?: string | null;
        name: string;
        cases: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
        isMock?: boolean | null;
      } | null;
      caseStats?: {
        __typename: 'ModelCaseStatsConnection';
        items: Array<{
          __typename: 'CaseStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          caseId: string;
          version: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      meetingStats?: {
        __typename: 'MeetingStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        casePresentationHistory: Array<string>;
        version: string;
        amountParticipants?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      purchaseOrderNbr: string;
      meetingNotes: string;
      meetingType: string;
      isMock: boolean;
      isInTesting: boolean;
      isArchived: boolean;
      isDemo?: boolean | null;
      streamUrl?: string | null;
    } | null;
    isTemporary?: boolean | null;
    owner?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type OnCreateUserClientSubscriptionVariables = {
  filter?: ModelSubscriptionUserClientFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserClientSubscription = {
  onCreateUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserClientSubscriptionVariables = {
  filter?: ModelSubscriptionUserClientFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserClientSubscription = {
  onUpdateUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserClientSubscriptionVariables = {
  filter?: ModelSubscriptionUserClientFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserClientSubscription = {
  onDeleteUserClient?: {
    __typename: 'UserClient';
    id: string;
    owner?: string | null;
    clientID: string;
    client?: {
      __typename: 'Client';
      id: string;
      clientName: string;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null;
  owner?: string | null;
};

export type OnCreateClientSubscription = {
  onCreateClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null;
  owner?: string | null;
};

export type OnUpdateClientSubscription = {
  onUpdateClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null;
  owner?: string | null;
};

export type OnDeleteClientSubscription = {
  onDeleteClient?: {
    __typename: 'Client';
    id: string;
    clientName: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateUserMeetingAccessSubscriptionVariables = {
  filter?: ModelSubscriptionUserMeetingAccessFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserMeetingAccessSubscription = {
  onCreateUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserMeetingAccessSubscriptionVariables = {
  filter?: ModelSubscriptionUserMeetingAccessFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserMeetingAccessSubscription = {
  onUpdateUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserMeetingAccessSubscriptionVariables = {
  filter?: ModelSubscriptionUserMeetingAccessFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserMeetingAccessSubscription = {
  onDeleteUserMeetingAccess?: {
    __typename: 'UserMeetingAccess';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null;
  owner?: string | null;
};

export type OnCreateMeetingSubscription = {
  onCreateMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type OnUpdateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null;
  owner?: string | null;
};

export type OnUpdateMeetingSubscription = {
  onUpdateMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type OnDeleteMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null;
  owner?: string | null;
};

export type OnDeleteMeetingSubscription = {
  onDeleteMeeting?: {
    __typename: 'Meeting';
    id: string;
    owner?: string | null;
    name: string;
    caseClusterID: string;
    hostID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    } | null;
    hostFullName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    endDate?: string | null;
    eventLocation: string;
    participants?: {
      __typename: 'ModelUserConnection';
      items: Array<{
        __typename: 'User';
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        createdAt?: string | null;
        updatedAt?: string | null;
        meetingAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        last_seen?: string | null;
        currentMeetingID: string;
        currentMeeting?: {
          __typename: 'Meeting';
          id: string;
          owner?: string | null;
          name: string;
          caseClusterID: string;
          hostID: string;
          hostFullName?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          eventDate: string;
          endDate?: string | null;
          eventLocation: string;
          guestUrlCode?: string | null;
          previewCode?: string | null;
          clientIDs: Array<string>;
          currentCase?: string | null;
          currentSlide: number;
          currentAssetID?: string | null;
          purchaseOrderNbr: string;
          meetingNotes: string;
          meetingType: string;
          isMock: boolean;
          isInTesting: boolean;
          isArchived: boolean;
          isDemo?: boolean | null;
          streamUrl?: string | null;
        } | null;
        isTemporary?: boolean | null;
        owner?: string | null;
        isMock?: boolean | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    userAccess?: {
      __typename: 'ModelUserMeetingAccessConnection';
      items: Array<{
        __typename: 'UserMeetingAccess';
        id: string;
        owner?: string | null;
        userID: string;
        meetingID: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    guestUrlCode?: string | null;
    previewCode?: string | null;
    clientIDs: Array<string>;
    currentCase?: string | null;
    currentSlide: number;
    currentAssetID?: string | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      owner?: string | null;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
      isMock?: boolean | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          nextToken?: string | null;
        } | null;
        version: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    meetingStats?: {
      __typename: 'MeetingStats';
      id: string;
      owner?: string | null;
      meetingID: string;
      casePresentationHistory: Array<string>;
      version: string;
      amountParticipants?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    purchaseOrderNbr: string;
    meetingNotes: string;
    meetingType: string;
    isMock: boolean;
    isInTesting: boolean;
    isArchived: boolean;
    isDemo?: boolean | null;
    streamUrl?: string | null;
  } | null;
};

export type OnCreateUserMeetingJoinSubscriptionVariables = {
  filter?: ModelSubscriptionUserMeetingJoinFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserMeetingJoinSubscription = {
  onCreateUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserMeetingJoinSubscriptionVariables = {
  filter?: ModelSubscriptionUserMeetingJoinFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserMeetingJoinSubscription = {
  onUpdateUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserMeetingJoinSubscriptionVariables = {
  filter?: ModelSubscriptionUserMeetingJoinFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserMeetingJoinSubscription = {
  onDeleteUserMeetingJoin?: {
    __typename: 'UserMeetingJoin';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
    isCurrentlyInMeeting?: boolean | null;
    lastPingDate?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null;
  owner?: string | null;
};

export type OnCreateAnswerSubscription = {
  onCreateAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type OnUpdateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null;
  owner?: string | null;
};

export type OnUpdateAnswerSubscription = {
  onUpdateAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type OnDeleteAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null;
  owner?: string | null;
};

export type OnDeleteAnswerSubscription = {
  onDeleteAnswer?: {
    __typename: 'Answer';
    id: string;
    owner?: string | null;
    questionID: string;
    userID: string;
    meetingID: string;
    answerIndexes: Array<string>;
    answerTexts: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    user: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      meetingAccess?: {
        __typename: 'ModelUserMeetingAccessConnection';
        items: Array<{
          __typename: 'UserMeetingAccess';
          id: string;
          owner?: string | null;
          userID: string;
          meetingID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      last_seen?: string | null;
      currentMeetingID: string;
      currentMeeting?: {
        __typename: 'Meeting';
        id: string;
        owner?: string | null;
        name: string;
        caseClusterID: string;
        hostID: string;
        host?: {
          __typename: 'User';
          id: string;
          firstname: string;
          lastname: string;
          email: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          last_seen?: string | null;
          currentMeetingID: string;
          isTemporary?: boolean | null;
          owner?: string | null;
          isMock?: boolean | null;
        } | null;
        hostFullName?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        eventDate: string;
        endDate?: string | null;
        eventLocation: string;
        participants?: {
          __typename: 'ModelUserConnection';
          nextToken?: string | null;
        } | null;
        userAccess?: {
          __typename: 'ModelUserMeetingAccessConnection';
          nextToken?: string | null;
        } | null;
        guestUrlCode?: string | null;
        previewCode?: string | null;
        clientIDs: Array<string>;
        currentCase?: string | null;
        currentSlide: number;
        currentAssetID?: string | null;
        caseCluster?: {
          __typename: 'CaseCluster';
          id: string;
          owner?: string | null;
          name: string;
          cases: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
          isMock?: boolean | null;
        } | null;
        caseStats?: {
          __typename: 'ModelCaseStatsConnection';
          nextToken?: string | null;
        } | null;
        meetingStats?: {
          __typename: 'MeetingStats';
          id: string;
          owner?: string | null;
          meetingID: string;
          casePresentationHistory: Array<string>;
          version: string;
          amountParticipants?: number | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        purchaseOrderNbr: string;
        meetingNotes: string;
        meetingType: string;
        isMock: boolean;
        isInTesting: boolean;
        isArchived: boolean;
        isDemo?: boolean | null;
        streamUrl?: string | null;
      } | null;
      isTemporary?: boolean | null;
      owner?: string | null;
      isMock?: boolean | null;
    };
  } | null;
};

export type OnCreateCaseClusterSubscriptionVariables = {
  filter?: ModelSubscriptionCaseClusterFilterInput | null;
  owner?: string | null;
};

export type OnCreateCaseClusterSubscription = {
  onCreateCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type OnUpdateCaseClusterSubscriptionVariables = {
  filter?: ModelSubscriptionCaseClusterFilterInput | null;
  owner?: string | null;
};

export type OnUpdateCaseClusterSubscription = {
  onUpdateCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type OnDeleteCaseClusterSubscriptionVariables = {
  filter?: ModelSubscriptionCaseClusterFilterInput | null;
  owner?: string | null;
};

export type OnDeleteCaseClusterSubscription = {
  onDeleteCaseCluster?: {
    __typename: 'CaseCluster';
    id: string;
    owner?: string | null;
    name: string;
    cases: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
    isMock?: boolean | null;
  } | null;
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null;
  owner?: string | null;
};

export type OnCreateMessageSubscription = {
  onCreateMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null;
  owner?: string | null;
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null;
  owner?: string | null;
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?: {
    __typename: 'Message';
    id: string;
    owner?: string | null;
    channelID: string;
    author: string;
    body: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateMeetingStatsSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingStatsFilterInput | null;
  owner?: string | null;
};

export type OnCreateMeetingStatsSubscription = {
  onCreateMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateMeetingStatsSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingStatsFilterInput | null;
  owner?: string | null;
};

export type OnUpdateMeetingStatsSubscription = {
  onUpdateMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteMeetingStatsSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingStatsFilterInput | null;
  owner?: string | null;
};

export type OnDeleteMeetingStatsSubscription = {
  onDeleteMeetingStats?: {
    __typename: 'MeetingStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    casePresentationHistory: Array<string>;
    version: string;
    amountParticipants?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateCaseStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCaseStatsFilterInput | null;
  owner?: string | null;
};

export type OnCreateCaseStatsSubscription = {
  onCreateCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateCaseStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCaseStatsFilterInput | null;
  owner?: string | null;
};

export type OnUpdateCaseStatsSubscription = {
  onUpdateCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteCaseStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCaseStatsFilterInput | null;
  owner?: string | null;
};

export type OnDeleteCaseStatsSubscription = {
  onDeleteCaseStats?: {
    __typename: 'CaseStats';
    id: string;
    owner?: string | null;
    meetingID: string;
    caseId: string;
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        owner?: string | null;
        assetId: string;
        userID: string;
        timeWatched: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsAssetViewsId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        owner?: string | null;
        slideId: string;
        time: number;
        version: string;
        createdAt: string;
        updatedAt: string;
        caseStatsSlidesId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateCaseAssetViewSubscriptionVariables = {
  filter?: ModelSubscriptionCaseAssetViewFilterInput | null;
  owner?: string | null;
};

export type OnCreateCaseAssetViewSubscription = {
  onCreateCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type OnUpdateCaseAssetViewSubscriptionVariables = {
  filter?: ModelSubscriptionCaseAssetViewFilterInput | null;
  owner?: string | null;
};

export type OnUpdateCaseAssetViewSubscription = {
  onUpdateCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type OnDeleteCaseAssetViewSubscriptionVariables = {
  filter?: ModelSubscriptionCaseAssetViewFilterInput | null;
  owner?: string | null;
};

export type OnDeleteCaseAssetViewSubscription = {
  onDeleteCaseAssetView?: {
    __typename: 'CaseAssetView';
    id: string;
    owner?: string | null;
    assetId: string;
    userID: string;
    timeWatched: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsAssetViewsId: string;
  } | null;
};

export type OnCreateCaseSlideStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCaseSlideStatsFilterInput | null;
  owner?: string | null;
};

export type OnCreateCaseSlideStatsSubscription = {
  onCreateCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type OnUpdateCaseSlideStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCaseSlideStatsFilterInput | null;
  owner?: string | null;
};

export type OnUpdateCaseSlideStatsSubscription = {
  onUpdateCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type OnDeleteCaseSlideStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCaseSlideStatsFilterInput | null;
  owner?: string | null;
};

export type OnDeleteCaseSlideStatsSubscription = {
  onDeleteCaseSlideStats?: {
    __typename: 'CaseSlideStats';
    id: string;
    owner?: string | null;
    slideId: string;
    time: number;
    version: string;
    createdAt: string;
    updatedAt: string;
    caseStatsSlidesId: string;
  } | null;
};

export type OnCreateMeetingNPSScoreSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingNPSScoreFilterInput | null;
  owner?: string | null;
};

export type OnCreateMeetingNPSScoreSubscription = {
  onCreateMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateMeetingNPSScoreSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingNPSScoreFilterInput | null;
  owner?: string | null;
};

export type OnUpdateMeetingNPSScoreSubscription = {
  onUpdateMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteMeetingNPSScoreSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingNPSScoreFilterInput | null;
  owner?: string | null;
};

export type OnDeleteMeetingNPSScoreSubscription = {
  onDeleteMeetingNPSScore?: {
    __typename: 'MeetingNPSScore';
    id: string;
    owner?: string | null;
    userID: string;
    meetingID: string;
    scores: Array<number>;
    version: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAccessLinkSubscriptionVariables = {
  filter?: ModelSubscriptionAccessLinkFilterInput | null;
  owner?: string | null;
};

export type OnCreateAccessLinkSubscription = {
  onCreateAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateAccessLinkSubscriptionVariables = {
  filter?: ModelSubscriptionAccessLinkFilterInput | null;
  owner?: string | null;
};

export type OnUpdateAccessLinkSubscription = {
  onUpdateAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteAccessLinkSubscriptionVariables = {
  filter?: ModelSubscriptionAccessLinkFilterInput | null;
  owner?: string | null;
};

export type OnDeleteAccessLinkSubscription = {
  onDeleteAccessLink?: {
    __typename: 'AccessLink';
    id: string;
    purpose: string;
    accessCode: string;
    data: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};
