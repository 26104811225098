import { Flex, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ClickupCustomField, ClickupTask } from './clickup.types';
import { capitalize } from 'lodash';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { parseDate } from '@/utils/helpers/parseDate';

const columnHelper = createColumnHelper<ClickupTask>();

// type Args = {
//   t: TFunction<'translation', undefined, 'translation'>;
// };

function EmptyCell() {
  return <>---</>;
}

const getColumns = (data: ClickupTask[]) => {
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('status.status', {
      header: 'Status',
      cell: (props) => <Text>{props.getValue()}</Text>,
    }),
    columnHelper.accessor('due_date', {
      header: 'Due Date',
      cell: (props) => {
        const value = props.getValue();
        if (!value) return <EmptyCell />;
        return <Text>{parseDate(value, true)}</Text>;
      },
    }),
  ];

  const customFields = data.reduce((acc, task) => {
    task.custom_fields.forEach((field) => {
      const found = acc.find((el) => el.id === field.id);
      if (!found) {
        acc.push(field);
      }
    });
    return acc;
  }, [] as ClickupCustomField[]);

  customFields.forEach((cf) => {
    columns.push({
      id: cf.name,
      header: capitalize(cf.name),
      accessorKey: 'custom_fields',
      cell: (props: any) => {
        const row: ClickupTask = props.row.original;
        const field = row.custom_fields.find((field) => field.name === cf.name);
        if (!field) return <EmptyCell />;
        if (!field.value) return <EmptyCell />;

        if (field.type === 'drop_down') {
          const optionValue = field.type_config.options.find((el) => {
            return el.orderindex.toString() == field.value;
          });
          if (!optionValue) {
            captureSentry({
              title: 'Clickup custom field option not found',
              detail: {
                customField: cf,
                field,
                optionValue,
              },
            });
            return <>N/A</>;
          }
          return (
            <Flex
              color="white"
              bg={optionValue.color}
              justifyContent="center"
              borderRadius={4}
            >
              {optionValue.name}
            </Flex>
          );
        }
        if (field.type === 'labels') {
          const elems = field.value.map(
            (labelID) =>
              field.type_config.options.find((el) => el.id === labelID)!
          );
          return (
            <Flex>
              {elems.map((el) => (
                <Flex
                  color="white"
                  bg={el.color}
                  justifyContent="center"
                  borderRadius={4}
                  key={el.id}
                >
                  {el.label}
                </Flex>
              ))}
            </Flex>
          );
        }
        if (field.type === 'date') {
          return <Text>{parseDate(field.value, true)}</Text>;
        }
        return <Text>{field.value}</Text>;
      },
    });
  });

  return columns;
};

export default getColumns;
