import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const textAnswerSlideItemSchema = z.object({
  id: z.string().min(1, { message: 'id must be non empty' }),
  type: z.literal(SlideItemType.ANSWER_TEXT),
  title: z.string(),
  placeholder: z.string(),
  maxChars: z.number().positive(),
  assetRefs: z.array(z.string()),

  //Optional
  description: z
    .string()
    .min(1, { message: 'description must be non empty' })
    .optional(),
});

export type TextAnswerSlideItemType = z.infer<typeof textAnswerSlideItemSchema>;
