import { DailyRoomInfo } from '@daily-co/daily-js';

// TODO: do this serverside so we can hide API KEY
const API_KEY =
  '054798d9109cdb66f31656607f1b27c80b902cedeb3dff46eb665340f24d7138';

type RoomResponse = DailyRoomInfo & {
  api_created: boolean;
  url: string;
  created_at: string;
  privacy: 'private' | 'public';
  error?: string | undefined;
};

const createRoom = async (room: string): Promise<RoomResponse> => {
  const exp = Math.round(Date.now() / 1000) + 60 * 30;

  return fetch('https://api.daily.co/v1/rooms', {
    method: 'POST',
    headers: { Accept: 'application/json', Authorization: `Bearer ${API_KEY}` },

    body: JSON.stringify({
      name: room,
      properties: {
        enable_screenshare: true,
        enable_chat: true,
        start_video_off: false,
        start_audio_off: false,
        lang: 'en',
        exp,
      },
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(json, 'json');
      return json;
    })
    .catch((err) => console.log(`error:${err}`));
};

const getRoom = async (room: string): Promise<RoomResponse> => {
  return fetch(`https://api.daily.co/v1/rooms/${room}`, {
    method: 'GET',
    headers: { Accept: 'application/json', Authorization: `Bearer ${API_KEY}` },
  })
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => err);
};

export default { createRoom, getRoom };
