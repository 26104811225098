import { NewISlide } from '../types/zod/slideTypes/slideSchema';

export default function getSlideWithIDOrThrow(
  slides: NewISlide[],
  id: string
): NewISlide {
  const slide = slides.find((slide) => slide.id === id);
  if (!slide) {
    throw new Error(
      `getSlideWithIDOrThrow | Could not find slide with id ${id}`
    );
  }
  return slide;
}
