import {
  createMeeting,
  createUserMeetingAccess,
} from '@/services/graphql/mutations';
import {
  CaseCluster,
  CreateCaseStatsMutation,
  CreateMeetingInput,
  CreateMeetingMutation,
  CreateUserMeetingAccessInput,
  GetCaseClusterQuery,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import {
  mutationCreateCaseStats,
  mutationCreateMeetingStats,
} from '@/features/meeting-stats/graphql/create';
import { GraphQLResult } from '@aws-amplify/api';
import { getCaseCluster } from '@/services/graphql/queries';
import { parseCaseMapFromCaseCluster } from '@/features/caseCluster/helpers/createCaseMapAccordingToCaseCluster';
import { captureSentry } from '@/utils/helpers/sentryHelper';

//This function's purpose is to be usable without the need of a redux store
//It is used in the mocking tool
//Note: this logic should not be on the client but on a lambda function
export async function pureCreateMeeting(
  input: CreateMeetingInput,
  userAccessList?: string[]
) {
  try {
    const newMeeting = await callGraphQLApi<
      GraphQLResult<CreateMeetingMutation>
    >(createMeeting, {
      input,
    });

    const newMeetingId = newMeeting.data?.createMeeting?.id as string;

    //get the info about the case cluster in order to create the caseStats (one caseStat per case in the case cluster)
    const caseClusterDetails = await callGraphQLApi<
      GraphQLResult<GetCaseClusterQuery>
    >(getCaseCluster, {
      id: input.caseClusterID,
    });
    const caseCluster = caseClusterDetails.data?.getCaseCluster as CaseCluster;

    const caseMap = await parseCaseMapFromCaseCluster({
      caseClusterID: undefined,
      caseCluster: caseCluster,
    });

    //case stats
    const caseStatsPromises =
      caseCluster.cases?.map((caseId) => {
        return mutationCreateCaseStats(
          newMeetingId,
          caseId,
          caseMap[caseId]
        ) as Promise<GraphQLResult<CreateCaseStatsMutation>>;
      }) ?? [];

    //meeting stats
    await mutationCreateMeetingStats(newMeetingId);
    await Promise.all(caseStatsPromises);

    // possible user access to update
    if (userAccessList?.length) {
      const userAccessPromises =
        userAccessList.map((userId) => {
          const input: CreateUserMeetingAccessInput = {
            meetingID: newMeetingId,
            userID: userId,
          };
          return callGraphQLApi(createUserMeetingAccess, { input });
        }) ?? [];
      await Promise.all(userAccessPromises);
    }
    return newMeeting.data?.createMeeting?.id as string;
  } catch (error) {
    captureSentry({
      title: 'Error in createAMeeting',
      error,
      detail: {
        input,
        userAccessList,
      },
    });
  }
}
