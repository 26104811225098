import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import { parseCaseMapFromCaseCluster } from '@/features/caseCluster/helpers/createCaseMapAccordingToCaseCluster';
import { CaseCluster, Meeting } from '@/services/API';
import { MockHost, MockUser } from '../mockUser/MockUser';

/*
  The host 
*/
export default async function mockMeeting(
  timePassedPerSlide: number,
  meeting: Meeting,
  hostID: string,
  mockedUserIds: string[]
) {
  const caseMap: CaseMap = await parseCaseMapFromCaseCluster({
    caseClusterID: undefined,
    caseCluster: meeting.caseCluster as CaseCluster,
  });

  const mockHost = new MockHost(hostID, meeting, meeting.id, caseMap);
  const mockUsers = mockedUserIds.map(
    (id) => new MockUser(id, meeting, meeting.id, caseMap)
  );
  const timePasserOnSlides = [];

  console.log('Waiting a bit... (3s)');
  console.log(meeting.id);

  //making all users join the meeting
  for (const mockUser of mockUsers) {
    await mockUser.joinMeeting();
  }

  await new Promise((resolve) => setTimeout(resolve, 3000));

  console.log('Starting meeting mocking');
  for (const caseID in caseMap) {
    const caseData = caseMap[caseID];
    const { slides } = caseData;

    console.log('Switching to case', caseID);
    await mockHost.switchCase(caseID);

    console.log('all slides of this case', caseID, slides);

    for (const [slideIndex, slideData] of slides.entries()) {
      console.log('Switching to slide', slideData.id);
      const startSlideTime = new Date().getTime();
      const userInteractionPromises = [];

      //host switches to slide
      await mockHost.switchSlide(caseID, slideData.id, slideIndex);

      //users interact with slide
      for (const mockUser of mockUsers) {
        userInteractionPromises.push(
          mockUser.interactWithSlide(caseID, slideData.id)
        );
      }
      await Promise.all(userInteractionPromises);
      const endSlideTime = new Date().getTime();
      const timePassed = endSlideTime - startSlideTime;
      timePasserOnSlides.push(timePassed);
      const timeToWait = timePassedPerSlide - timePassed;
      if (timeToWait > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeToWait));
      }
    }
  }
}
