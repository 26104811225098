import { ROUTES } from '@/routes/Routes';
import PageLayout from '@/ui/Layout/PageLayout';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function MeetingFinishedView() {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        gap="5px"
      >
        <Text fontSize="20px" variant="purpleTitleBold">
          This meeting is finished. You can not join it anymore.
        </Text>
        <Button onClick={() => navigate(ROUTES.HOME)} variant="primary">
          Home
        </Button>
      </Flex>
    </PageLayout>
  );
}

export default MeetingFinishedView;
