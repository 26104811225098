// import { ISlide } from '@/features/caseCluster/caseCluster.types';
import clamp from '@/utils/helpers/clamp';
import { ParsedSlideStats } from '../parseSlideStatsPerCase';
import calculateGaussianScore from './calculateGaussianScore';

/*
Optimal Value: 4.5 mins * nbrSlides
Worst value: optimal value away from optimal value
*/
export default function calculateCaseDurationRatio(
  parsedSlideStats: ParsedSlideStats[]
) {
  const optimalValue = 4.5 * 60 * parsedSlideStats.length;
  const totalTime = parsedSlideStats.reduce((acc, curr) => {
    return acc + curr.time;
  }, 0);
  const score = clamp(
    calculateGaussianScore(totalTime, optimalValue, 27 * 3.5),
    0,
    1
  );
  return score;
}
