export const MEETING_STATS_VERSION = '1.0.1';
export const CASE_STATS_VERSION = '1.0.0';
export const CASE_ASSET_VIEW_VERSION = '1.0.0';
export const CASE_SLIDE_STATS_VERSION = '1.0.0';
export const NPS_SCORE_STATS_VERSION = '1.0.0';

/*
CHANGELOG MEETING_STATS_VERSION:

1.0.1:
- casePresentationHistory stores ids instead of indexes


*/
