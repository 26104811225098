import { useEffect, useState } from 'react';
import {
  onCreateMessage,
  onDeleteMessage,
} from '@/services/graphql/subscriptions';
import { createMessage } from '@/services/graphql/mutations';
import {
  Message,
  OnCreateMessageSubscription,
  OnDeleteMessageSubscription,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { useMemo } from 'react';
import queryListMessagesForMeeting from '../meeting/messages/queryListMessagesForMeeting';
import { useQuery } from '@tanstack/react-query';
import getLastReadChatMessageKey from '@/utils/helpers/getLastReadChatMessageKey';
import { captureSentry } from '@/utils/helpers/sentryHelper';

function useChatMessages(channelID: string | undefined) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [lastReadMessageID, setLastReadMessageID] = useState<string | null>(
    null
  );
  const originalMessageFetchQuery = useQuery({
    queryKey: ['messagesByChannel', channelID],
    refetchOnWindowFocus: false,
    enabled: !!channelID,
    queryFn: async () => {
      try {
        if (!channelID) return [];
        const response = await queryListMessagesForMeeting(channelID);
        const items = response?.data?.messagesByChannelID?.items;
        return items;
      } catch (error) {
        captureSentry({
          title: 'useChatMessages | error loading messages',
          error: error instanceof Error ? error : undefined,
          detail: {
            channelID,
            error,
          },
        });
      }
      return [];
    },
    onSuccess: (data: Message[]) => {
      setMessages(data);
    },
  });
  const amtUnreadMessages = useMemo(() => {
    if (!lastReadMessageID) return messages.length;
    const lastReadMessage = messages.find(
      (elem) => elem.id === lastReadMessageID
    );
    if (!lastReadMessage) {
      if (originalMessageFetchQuery.isLoading) return 0;
      captureSentry({
        title: 'useChatMessages | lastReadMessage not found in messages array',
        detail: {
          lastReadMessageID,
          messages,
          channelID,
        },
      });
      return 0;
    }
    const lastReadMessageIndex = messages.indexOf(lastReadMessage);

    return messages.length - lastReadMessageIndex - 1;
  }, [messages, lastReadMessageID]);

  const subscriptions = useMemo(() => {
    if (!channelID) return [];
    return [
      {
        query: onCreateMessage,
        // variables: {
        //   filter: {
        //     channelID: {
        //       eq: channelID,
        //     },
        //   },
        // },
        // TODO: we need to subscribe to messages from this channel ONLY! But it doesn't work for some reason ?
        variables: {},
        callback: (event: OnCreateMessageSubscription) => {
          if (event.onCreateMessage?.channelID !== channelID) return;
          setMessages((prev) => [...prev, event.onCreateMessage as Message]);
        },
      },
      {
        query: onDeleteMessage,
        variables: {},
        callback: (event: OnDeleteMessageSubscription) => {
          if (event.onDeleteMessage?.channelID !== channelID) return;
          setMessages((prev) =>
            prev.filter((msg) => msg.id !== event.onDeleteMessage?.id)
          );
        },
      },
    ];
  }, [channelID]);

  //NOTE: load from localstorage the last read message for this channel
  useEffect(() => {
    setLastReadMessageID(
      localStorage.getItem(getLastReadChatMessageKey(channelID))
    );
  }, [channelID]);

  useNewSubscriptions(subscriptions, 'useChatMessages');

  return {
    isLoading: originalMessageFetchQuery.isLoading,
    messages: messages,
    unreadMessages: amtUnreadMessages,
    markAllMessagesAsRead: () => {
      if (messages.length === 0) return;
      const messageID = messages[messages.length - 1].id;
      setLastReadMessageID(messageID);
      localStorage.setItem(
        getLastReadChatMessageKey(channelID || ''),
        messageID
      );
    },
  };
}

export const messageActions = {
  addMessage: async ({ author, body, channelID }: Partial<Message>) => {
    const input = {
      channelID,
      author,
      body,
    };
    try {
      await callGraphQLApi(createMessage, { input });
    } catch (error) {
      console.warn(error);
    }
  },
};

export default useChatMessages;
