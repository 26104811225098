import { CiFilter } from 'react-icons/ci';
import {
  Button,
  ButtonGroup,
  Divider,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Column, ColumnFilter, Table } from '@tanstack/react-table';
import { ReactNode, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import NewFilterComonent, { FilterType } from './NewFilterComponent';
import { useTranslation } from 'react-i18next';

type Props<TData> = {
  columnFilters: ColumnFilter[];
  hiddenFilters?: (keyof TData)[];
  setColumnFilters: (columnFilters: ColumnFilter[]) => void;
  table: Table<TData>;
  filterTypeMapping: Partial<Record<keyof TData, FilterType>>;
  filterOptionsMapping: Partial<Record<keyof TData, string[]>>;
};

function Filters<TData>({
  columnFilters,
  hiddenFilters,
  setColumnFilters,
  table,
  filterTypeMapping,
  filterOptionsMapping,
}: Props<TData>) {
  const { t } = useTranslation();
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [selectedColumn, setSelectedColumn] = useState<Column<TData> | null>(
    null
  );

  const closeModal = () => {
    setSelectedColumn(null);
  };

  const removeFilter = (id: string) => {
    setColumnFilters(columnFilters.filter((elem) => elem.id !== id));
  };

  return (
    <>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          aria-label="filters"
          icon={<CiFilter />}
          bgColor="gray.200"
        />
        <MenuList p={2}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="gray" />
            </InputLeftElement>
            <Input
              value={currentSearch}
              onChange={(e) => setCurrentSearch(e.target.value)}
              placeholder="Search for column name"
            />
          </InputGroup>

          <Divider my={2} />
          {table
            .getAllColumns()
            .filter((c) => !currentSearch || c.id.includes(currentSearch))
            .filter((c) => c.getCanFilter())
            .map((c) => {
              const alreadyHasFilter = columnFilters.find(
                (elem) => elem.id === c.id
              );
              if (hiddenFilters?.some((key) => c.id === key)) {
                return null;
              }
              return (
                <Tooltip
                  key={c.id}
                  label={
                    alreadyHasFilter
                      ? t('tables.filters.alreadySelected.title')
                      : ''
                  }
                >
                  <MenuItem
                    gap={2}
                    onClick={() => setSelectedColumn(c)}
                    isDisabled={!!alreadyHasFilter}
                  >
                    <Text>{c.columnDef.header as ReactNode}</Text>
                  </MenuItem>
                </Tooltip>
              );
            })}
        </MenuList>
      </Menu>
      <Modal
        isOpen={!!selectedColumn}
        onClose={() => {
          removeFilter(selectedColumn?.id || '');
          closeModal();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          {selectedColumn && (
            <>
              <ModalHeader>
                <Text>Filter by {selectedColumn.id}</Text>
              </ModalHeader>
              <ModalBody>
                <NewFilterComonent
                  column={selectedColumn}
                  filterType={
                    filterTypeMapping[selectedColumn.id as keyof TData] ||
                    FilterType.STRING
                  }
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                  possibleValues={
                    filterOptionsMapping[selectedColumn.id as keyof TData]
                  }
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    variant="outline"
                    onClick={() => {
                      removeFilter(selectedColumn.id);
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="blue" onClick={closeModal}>
                    Confirm
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default Filters;
