import { Authenticator } from '@aws-amplify/ui-react';
import { ROUTES } from '@/routes/Routes';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { Text, Flex, Card, CardBody, Button } from '@chakra-ui/react';
import AuthGuard from '@/components/Auth/AuthGuard';
import PageLayout from '@/ui/Layout/PageLayout';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { reloadApp } from '@/utils/reloadApp';

function LoginView() {
  const { email, isAdmin } = useUserAuthenticationContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // --------------------- handlers ------------------------

  const handlebackToHomeClick = () => {
    navigate(ROUTES.HOME);
  };
  const handleSignOut = () => {
    Auth.signOut({ global: false }).then(() => {
      navigate(ROUTES.HOME, { replace: true }); // TODO: is this needed?
      reloadApp();
    });
  };

  // --------------------- render ------------------------

  return (
    <AuthGuard>
      <PageLayout>
        <Flex justifyContent="flex-end" alignItems="center">
          {email ? (
            <Card size="sm" background="#FFF" width="300px">
              <CardBody>
                <div>
                  <div>{`${t(
                    'admin.header.profile.greetUser'
                  )}, ${email}`}</div>

                  {isAdmin && (
                    <Text fontWeight="bold" color="primary">
                      🚀 You are admin
                    </Text>
                  )}

                  <Flex justifyContent="space-between" paddingTop="20px">
                    <Flex>
                      <Button
                        variant="blue"
                        fontWeight="normal"
                        onClick={() => {
                          handlebackToHomeClick();
                        }}
                        size="sm"
                      >
                        {t('admin.header.profile.goBack.button')}
                      </Button>
                    </Flex>
                    <Flex>
                      <Button
                        variant="solid"
                        border="1px"
                        borderColor="primary"
                        background="none"
                        color="primary"
                        size="sm"
                        onClick={handleSignOut}
                      >
                        {t('profile.signout.button')}
                      </Button>
                    </Flex>
                  </Flex>
                </div>
              </CardBody>
            </Card>
          ) : (
            // TODO: is this needed?
            <Authenticator />
          )}
        </Flex>
      </PageLayout>
    </AuthGuard>
  );
}

export default LoginView;
