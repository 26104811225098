import { CognitoUserGroups } from '@/utils/helpers/cognitoUserGroups';
import getTemporaryUserMail from '@/utils/helpers/getTemporaryUserMail';
import { ICredentials } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';

export type CognitoMetaData = {
  cognitoUserId: string;
  email: string;
  isTemporary: boolean;
  userGroups: CognitoUserGroups[];
  cognitoResponse: any;
};

export async function getCognitoData(): Promise<CognitoMetaData> {
  try {
    const cognitoResponse = await Auth.currentAuthenticatedUser();
    console.log('Auth | user found in cognito');
    //logged user
    const userID = cognitoResponse.attributes.sub;
    return {
      cognitoResponse,
      cognitoUserId: userID,
      email: cognitoResponse.attributes.email,
      isTemporary: false,
      userGroups:
        cognitoResponse.signInUserSession.accessToken.payload['cognito:groups'],
    };
  } catch (error) {
    //unauth user
    console.log('Auth | unauthenticated user');
    const credentials: ICredentials = await Auth.currentCredentials();
    const userID = credentials.identityId;
    return {
      cognitoResponse: credentials,
      cognitoUserId: userID,
      email: getTemporaryUserMail(),
      isTemporary: true,
      userGroups: [CognitoUserGroups.USER],
    };
  }
}
