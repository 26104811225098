import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const selectableAnswerSlideItemSchema = z.object({
  id: z.string().min(1, { message: 'id must be non empty' }),
  type: z.literal(SlideItemType.ANSWER_SELECTABLE),
  title: z.string().min(1, { message: 'title must be non empty' }),
  assetRefs: z.array(z.string()),

  //Optional
  description: z
    .string()
    .min(1, { message: 'description must be non empty' })
    .optional(),
});

export type SelectableAnswerSlideItemType = z.infer<
  typeof selectableAnswerSlideItemSchema
>;
