import {
  DeleteAccessLinkMutation,
  DeleteAccessLinkMutationVariables,
} from '@/services/API';
import { deleteAccessLink } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export default async function mutationDeleteAccessLink(id: string) {
  const variables: DeleteAccessLinkMutationVariables = {
    input: {
      id,
    },
  };
  const result = await callGraphQLApi<DeleteAccessLinkMutation>(
    deleteAccessLink,
    variables
  );
  return result;
}
