import { CreateUserInput, User } from '@/services/API';
import { pureCreateUser } from '@/features/userAuth/utils/pureCreateUser';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import getTemporaryUserMail from '@/utils/helpers/getTemporaryUserMail';
import { CognitoMetaData } from './getCognitoData';

export async function createUserData(
  userID: string,
  cognitoMetaData: CognitoMetaData
): Promise<User> {
  return new Promise((resolve, reject) => {
    const firstName = cognitoMetaData.isTemporary
      ? `guest${Math.floor(Math.random() * 9000) + 1000}`
      : cognitoMetaData.cognitoResponse.username;
    const email = cognitoMetaData.isTemporary
      ? getTemporaryUserMail().replace('guest', firstName)
      : cognitoMetaData.cognitoResponse.attributes.email;
    const input: CreateUserInput = {
      id: userID,
      firstname: firstName,
      lastname: 'TODO',
      email: email,
      isTemporary: cognitoMetaData.isTemporary,
      currentMeetingID: NULL_ID_VALUE,
    };
    pureCreateUser(input)
      .then((user) => resolve(user.data?.createUser as User))
      .catch((err) => reject(err));
  });
}
