import { Select, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (value: boolean) => void;
};

const BasicBooleanFilter = ({ onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <VStack justifyContent="left" alignItems="flex-start">
      <Text>{t('tables.filters.boolean.title')}</Text>
      <Select
        onChange={(e) => {
          const newValue = e.target.value === 'true';
          onChange(newValue);
        }}
        placeholder="Select"
      >
        <option value="true">True</option>
        <option value="false">False</option>
      </Select>
    </VStack>
  );
};

export default BasicBooleanFilter;
