import {
  ListUserClientsQuery,
  ListUserClientsQueryVariables,
  UserClient,
} from '@/services/API';
import { listUserClients } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export function getUserClients(userID: string): Promise<UserClient[]> {
  return new Promise<UserClient[]>((resolve, reject) => {
    const variables: ListUserClientsQueryVariables = {
      filter: {
        userID: {
          eq: userID,
        },
      },
    };
    callGraphQLApi<GraphQLResult<ListUserClientsQuery>>(
      listUserClients,
      variables
    )
      .then((resp: GraphQLResult<ListUserClientsQuery>) => {
        const userClients = resp.data?.listUserClients?.items;
        if (!userClients) {
          reject(new Error('No user clients found'));
          return;
        }
        resolve(userClients as UserClient[]);
      })
      .catch((error) => reject(error));
  });
}
