import { GraphQLResult } from '@aws-amplify/api';
import { useEffect, useMemo, useState } from 'react';
import {
  ListUserClientsQuery,
  OnCreateUserClientSubscription,
  OnDeleteUserClientSubscription,
  UserClient,
} from '../API';
import { listUserClients } from '../graphql/queries';
import {
  onCreateUserClient,
  onDeleteUserClient,
} from '../graphql/subscriptions';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';

type UserListToClientIds = {
  //key = userID: value = list of clients ids
  [key: string]: string[];
};

type UserListToClientNames = {
  //key = userID: value = list of clients names
  [key: string]: string[];
};

type ReturnType = {
  //key = userID: value = list of clients
  userListToClientIds: UserListToClientIds;
  userListToClientNames: UserListToClientIds;
};

export default function useUserListClients(): ReturnType {
  const [userListToClientIds, setUserListToClientIds] =
    useState<UserListToClientIds>({});
  const [userListToClientNames, setUserListToClientNames] =
    useState<UserListToClientNames>({});

  const handleNewUserClient = (
    userClient: UserClient,
    newUserClientsIds: UserListToClientIds,
    newUserClientsNames: UserListToClientNames
  ) => {
    const userID = userClient?.userID as string;
    const clientID = userClient?.clientID as string;
    const clientName = userClient?.client?.clientName as string;

    if (!newUserClientsIds[userID]) {
      newUserClientsIds[userID] = [];
    }
    if (!newUserClientsNames[userID]) {
      newUserClientsNames[userID] = [];
    }
    newUserClientsIds[userID].push(clientID);
    newUserClientsNames[userID].push(clientName);
  };

  // const refetchUser = async (userID: string) => {
  //   const variables: ListUserClientsQueryVariables = {
  //     filter: {
  //       userID: { eq: userID },
  //     },
  //   };
  //   const resp = await callGraphQLApi<GraphQLResult<ListUserClientsQuery>>(
  //     listUserClients,
  //     variables
  //   );
  //   const userList = resp.data?.listUserClients?.items;

  //   if (userList) {
  //     const newUserClientsIds: UserListToClientIds = {};
  //     const newUserClientsNames: UserListToClientNames = {};

  //     userList.forEach((item: UserClient | null) => {
  //       if (!item) return;
  //       handleNewUserClient(item, newUserClientsIds, newUserClientsNames);
  //     });
  //     setUserListToClientIds(newUserClientsIds);
  //     setUserListToClientNames(newUserClientsNames);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      const resp = await callGraphQLApi<GraphQLResult<ListUserClientsQuery>>(
        listUserClients,
        {}
      );

      if (resp?.data?.listUserClients) {
        const newUserClientsIds: UserListToClientIds = {};
        const newUserClientsNames: UserListToClientNames = {};

        resp.data.listUserClients.items.forEach((item: UserClient | null) => {
          if (!item) return;
          handleNewUserClient(item, newUserClientsIds, newUserClientsNames);
        });
        setUserListToClientIds(newUserClientsIds);
        setUserListToClientNames(newUserClientsNames);
      }
    };
    fetchData();
  }, []);

  const subscriptions = useMemo(() => {
    return [
      {
        query: onCreateUserClient,
        variables: {},
        callback: (data: OnCreateUserClientSubscription) => {
          console.log('NEW USER CLIENT', data);
          const userClient = data.onCreateUserClient as UserClient;
          setUserListToClientIds((prev) => {
            const newUserClientsIds = { ...prev };
            handleNewUserClient(userClient, newUserClientsIds, {});
            return newUserClientsIds;
          });
          setUserListToClientNames((prev) => {
            const newUserClientsNames = { ...prev };
            handleNewUserClient(userClient, {}, newUserClientsNames);
            return newUserClientsNames;
          });
        },
      },
      {
        query: onDeleteUserClient,
        variables: {},
        callback: (data: OnDeleteUserClientSubscription) => {
          console.log('DELETE USER CLIENT', data);
          const userClient = data.onDeleteUserClient as UserClient;
          setUserListToClientIds((prev) => {
            const newUserClientsIds = { ...prev };
            const clientID = userClient?.clientID as string;
            newUserClientsIds[userClient.userID] = newUserClientsIds[
              userClient.userID
            ].filter((id) => id !== clientID);
            return newUserClientsIds;
          });
          setUserListToClientNames((prev) => {
            const newUserClientsNames = { ...prev };
            const clientName = userClient?.client?.clientName as string;
            newUserClientsNames[userClient.userID] = newUserClientsNames[
              userClient.userID
            ].filter((name) => name !== clientName);
            return newUserClientsNames;
          });
        },
      },
    ];
  }, []);

  useNewSubscriptions(subscriptions, 'useUserListClients');

  return {
    userListToClientIds,
    userListToClientNames,
  };
}
