import { useAppDispatch } from '@/store/StoreHooks';
import {
  onCreateMeeting,
  onDeleteMeeting,
  onUpdateMeeting,
} from '@/services/graphql/subscriptions';
import { useEffect, useMemo } from 'react';
import { fetchMeetingList } from '@/store/thunk/meeting';
import useNewSubscriptions from './useNewSubscriptions';
import {
  Meeting,
  OnCreateMeetingSubscription,
  OnDeleteMeetingSubscription,
  OnUpdateMeetingSubscription,
} from '../API';
import { actions } from '@/store/slices/meeting';

function useMeetingSubscription() {
  const dispatch = useAppDispatch();

  const subscriptions = useMemo(() => {
    return [
      {
        query: onCreateMeeting,
        variables: {},
        callback: (data: OnCreateMeetingSubscription) => {
          dispatch(actions.newMeeting(data.onCreateMeeting as Meeting));
        },
      },
      {
        query: onUpdateMeeting,
        variables: {},
        callback: (data: OnUpdateMeetingSubscription) => {
          dispatch(
            actions.receivedMeetingUpdate(data.onUpdateMeeting as Meeting)
          );
        },
      },
      {
        query: onDeleteMeeting,
        variables: {},
        callback: (data: OnDeleteMeetingSubscription) => {
          dispatch(actions.deleteMeeting(data.onDeleteMeeting as Meeting));
        },
      },
    ];
  }, []);

  useNewSubscriptions(subscriptions, 'useMeetingSubscription');

  useEffect(() => {
    // initial fetch
    dispatch(fetchMeetingList());
  }, []);
}

export default useMeetingSubscription;
