import {
  DeleteUserMutation,
  ListUsersQuery,
  ListUsersQueryVariables,
} from '@/services/API';
import { deleteUser } from '@/services/graphql/mutations';
import { listUsers } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export async function clearAllMockUsers() {
  const variables: ListUsersQueryVariables = {
    filter: {
      isMock: {
        eq: true,
      },
    },
  };
  const itemsToDelete = await callGraphQLApi<GraphQLResult<ListUsersQuery>>(
    listUsers,
    variables
  );
  const promises =
    itemsToDelete.data?.listUsers?.items?.map(async (item) => {
      return callGraphQLApi<GraphQLResult<DeleteUserMutation>>(deleteUser, {
        input: { id: item?.id },
      });
    }) ?? [];

  const resolvedPromises = await Promise.all(promises);
  console.log(`Deleted ${resolvedPromises.length} users`);
}
