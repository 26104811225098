import { MotionBox } from '@/ui/Motion/MotionBox';
import APP_CONSTANTS from '@/utils/constants/app.constants';
import { ImageSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/imageSlideItemSchema';

type Props = {
  item: ImageSlideItemType;
  casePath: string;
};

function ImageItem({ item, casePath }: Props) {
  return (
    <MotionBox
      initial={{ opacity: 0, scale: 0.98 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: { duration: 0.7, delay: 0.2, ease: 'easeOut' },
      }}
      display="flex"
      height="100%"
      width="100%"
      overflow="hidden"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundImage={
        APP_CONSTANTS.PATIENT_CASES_REPO_PATH + casePath + item.url
      }
    />
  );
}

export default ImageItem;
