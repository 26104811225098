import MeetingStatsCreationContextProvider from '@/features/meeting-stats/context/MeetingStatsCreationContext';
import CurrentMeetingDataContextProvider from '@/features/meeting/context/CurrentMeetingDataContext';
import LiveMeetingView from '../caseCluster/LiveMeetingView';

function PreviewMeeting() {
  return (
    <CurrentMeetingDataContextProvider isPreview>
      <MeetingStatsCreationContextProvider isPreview>
        <LiveMeetingView />
      </MeetingStatsCreationContextProvider>
    </CurrentMeetingDataContextProvider>
  );
}

export default PreviewMeeting;
