export const SMILEY_SIZE = 38;
export const PROGRESS_SIZE_X = 250;
export const PADDING_X_MOBILE = '30px';
export const PADDING_X_DESKTOP = '40px';

export const CASE_SELECTOR = 'case_selector';

export const SPECIAL_SLIDE_IDS = [
  CASE_SELECTOR,
  'slide_00_patient_profile',
  'nps_score',
];
