import TextAnswerItem from '@/components/CaseSlide/QuestionSlide/components/TextAnswerItem';
import { useQuestionContext } from '@/components/CaseSlide/QuestionSlide/context/QuestionContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import isSlideItemTextAnswerItem from '@/utils/types/type-validation/isSlideItemTextAnswerItem';
import { CustomSlideType } from '@/utils/types/zod/slideTypes/customSlideSchema';
import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import SelectItemAnswer from './SelectItemAnswer';

type Props = {
  slide: CustomSlideType;
  idx: number;
};

export const lorealSelectOptionsFR = [
  'Veuillez sélectionner',
  'Publipostage / impression ',
  'Courriel',
  'Evènement',
  'Synergie de réseau',
  'Contenu en ligne / social',
  'Matériel PDV',
  'Service',
  'Whatsapp / SMS',
  'Other',
];

export const lorealSelectOptionsNL = [
  'Gelieve te selecteren',
  'Direct Mail / print',
  'Email',
  'Event',
  'Netwerk synergie',
  'Online / social content',
  'POS Materiaal',
  'Service',
  'Whatsapp / SMS',
  'Ander',
];

export const lorealTitlesNL = ['Voor', 'Tijdens', 'Na'];
export const lorealTitlesFR = ['Avant', 'Pendant', 'Après'];

export const lorealTitleSuffixNL = 'het apotheekbezoek';
export const lorealTitleSuffixFR = 'la visite à la pharmacie';

function CustomSlide3({ slide, idx }: Props) {
  const { onTextAnswerChange, currentTextAnswers, requestTextAnswerIndex } =
    useQuestionContext();
  const { isUserHost, currentCase } = useCurrentMeetingDataContext();
  const slideTextItems = slide.items.filter(isSlideItemTextAnswerItem);
  const language = currentCase?.language;
  const titles = language === 'fr' ? lorealTitlesFR : lorealTitlesNL;
  const titleSuffix =
    language === 'fr' ? lorealTitleSuffixFR : lorealTitleSuffixNL;
  const selectOptions =
    language === 'fr' ? lorealSelectOptionsFR : lorealSelectOptionsNL;

  return (
    <Flex
      padding="5vh"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Flex
        key={idx}
        width="800px"
        justifyContent="center"
        gap="50px"
        alignItems="center"
        display="flex"
        borderRadius="20px"
      >
        {titles.map((title, localIdx) => (
          <Flex
            width="300px"
            // flexGrow={1}
            background={idx === localIdx ? 'livelinx.purple100' : 'transparent'}
            border="1px solid"
            borderColor={
              idx === localIdx ? 'livelinx.purple100' : 'livelinx.lightgray100'
            }
            color={idx === localIdx ? 'white' : 'livelinx.lightgray100'}
            borderRadius="10px"
            height="75px"
            alignItems="center"
            justifyContent="center"
            key={title}
            padding="20px"
          >
            <Text noOfLines={2} fontSize="24px">
              {title} {idx === localIdx ? titleSuffix : ''}
            </Text>
          </Flex>
        ))}
      </Flex>
      <HStack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        gap="5vw"
      >
        {[...Array(3)].map((_, idx) => {
          const selectIdx = idx * 2 + 0;
          const textIdx = idx * 2 + 1;
          const currentSelectAnswer =
            currentTextAnswers[
              requestTextAnswerIndex(slideTextItems[selectIdx].id)
            ];
          const textAreaDisabled =
            currentSelectAnswer === selectOptions[0] ||
            currentSelectAnswer === '';
          return (
            <VStack key={idx} width="100%" maxWidth="450px">
              <SelectItemAnswer
                key={idx}
                item={slideTextItems[selectIdx]}
                isHost={isUserHost}
                options={selectOptions}
                onChange={(text: string) => {
                  onTextAnswerChange([
                    { answerID: slideTextItems[selectIdx].id, text },
                  ]);
                }}
              />
              <TextAnswerItem
                item={slideTextItems[textIdx]}
                isHost={isUserHost}
                onTextChange={(text: string) => {
                  onTextAnswerChange([
                    { answerID: slideTextItems[textIdx].id, text },
                  ]);
                }}
                nbRows={2}
                width="100%"
                isDisabled={textAreaDisabled}
              />
            </VStack>
          );
        })}
      </HStack>
    </Flex>
  );
}

export default CustomSlide3;
