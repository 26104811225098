import { User } from '@/services/API';
import UserIcon from '@/components/UserIcon';
import { useTranslation } from 'react-i18next';
import { Tag, Box, Heading, HStack, Text, Flex } from '@chakra-ui/react';

type Props = {
  hostID: string;
  currentUserID: string;
  participantsList: User[] | undefined;
};

function ParticipantsView({ hostID, participantsList, currentUserID }: Props) {
  const { t } = useTranslation();
  return (
    <Box padding="20px" height="100%" display="flex" flexDirection="column">
      <Heading as="h2" size="sm" color="livelinx.purple200" marginBottom={3}>
        {t('participants')} ({participantsList?.length})
      </Heading>
      {participantsList &&
        participantsList.map((user) => (
          <HStack key={user.id} paddingBottom="5px" width="100%">
            <UserIcon username={user.firstname} />
            <Text noOfLines={1}>{user.firstname}</Text>
            <Flex flexDir="column" gap="3px">
              {user.id === currentUserID && (
                <Tag
                  color="livelinx.green200"
                  border="1px solid"
                  borderColor="livelinx.green100"
                  background="livelinx.green50"
                  fontWeight="normal"
                  size="sm"
                >
                  {t('common.you')}
                </Tag>
              )}
              {user.id === hostID && (
                <Tag
                  color="livelinx.blue400"
                  background="livelinx.blue50"
                  border="1px solid"
                  borderColor="livelinx.blue150"
                  fontWeight="normal"
                  size="sm"
                >
                  host
                </Tag>
              )}
              {user.isTemporary && (
                <Tag
                  color="livelinx.green200"
                  background="livelinx.green50"
                  border="1px solid"
                  borderColor="livelinx.green100"
                  fontWeight="normal"
                  size="sm"
                >
                  remote
                </Tag>
              )}
            </Flex>
          </HStack>
        ))}
    </Box>
  );
}

export default ParticipantsView;
