import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { SlideType } from '@/utils/types/enums';
import { isTruthy } from '@/utils/types/type-validation/generic/isTruthy';
import isSlideItemSelectableAnswerItem from '@/utils/types/type-validation/isSlideItemSelectableAnswerItem';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';

export default function fillQuestionIdToOptions(
  caseData: NewIPatientCase,
  meetingID: string,
  caseId: string,
  questionIdToOptions: Map<string, string[]>
) {
  const questionSlides = caseData.slides
    .map((slide: NewISlide) => {
      if (slide.type !== SlideType.QUESTION_SINGLE) return undefined;
      return slide;
    })
    .filter(isTruthy);

  questionSlides.forEach((slide) => {
    const questionOptions = slide.items.filter(isSlideItemSelectableAnswerItem);
    const questionID = composeQuestionID(meetingID, caseId, slide.id);

    questionIdToOptions.set(
      questionID,
      questionOptions.map((item) => item.id)
    );
  });
}
