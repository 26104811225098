import { useEffect, useMemo, useState } from 'react';
import {
  Meeting,
  OnCreateUserMeetingJoinSubscription,
  OnUpdateUserMeetingJoinSubscription,
  UpdateUserMeetingJoinMutationVariables,
  User,
  UserMeetingJoin,
  UserMeetingJoinByUserIDQuery,
  UserMeetingJoinByUserIDQueryVariables,
} from '@/services/API';
import {
  updateUser,
  updateUserMeetingJoin,
} from '@/services/graphql/mutations';
import APP_CONSTANTS, { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { userMeetingJoinByUserID } from '../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import {
  onCreateUserMeetingJoin,
  onUpdateUserMeetingJoin,
} from '../graphql/subscriptions';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';
import { captureSentry } from '@/utils/helpers/sentryHelper';

const pingUser = async (
  userId: string | undefined,
  meetingId: string | undefined,
  userMeetingJoin: UserMeetingJoin | undefined
) => {
  if (!userId) {
    return;
  }
  const input = {
    id: userId,
    last_seen: new Date(),
    currentMeetingID: meetingId || NULL_ID_VALUE,
  };
  await callGraphQLApi(updateUser, { input });

  if (userMeetingJoin) {
    const variables: UpdateUserMeetingJoinMutationVariables = {
      input: {
        id: userMeetingJoin.id,
        lastPingDate: new Date().toISOString(),
      },
    };
    await callGraphQLApi(updateUserMeetingJoin, variables);
  }
};

export function useUserMeetingPing(
  user: User | undefined | null,
  meeting?: Meeting | undefined
) {
  const userID = user?.id;
  const meetingID = meeting?.id;
  const [userMeetingJoin, setUserMeetingJoin] = useState<
    UserMeetingJoin | undefined
  >(undefined);

  const subscriptions = useMemo(() => {
    if (!meetingID || !userID) {
      return [];
    }
    return [
      {
        query: onCreateUserMeetingJoin,
        variables: {},
        callback: (result: OnCreateUserMeetingJoinSubscription) => {
          if (result.onCreateUserMeetingJoin?.meetingID !== meetingID) return;
          if (result.onCreateUserMeetingJoin.user?.id !== userID) return;
          setUserMeetingJoin(result.onCreateUserMeetingJoin as UserMeetingJoin);
        },
      },
      {
        query: onUpdateUserMeetingJoin,
        variables: {},
        callback: (result: OnUpdateUserMeetingJoinSubscription) => {
          if (result.onUpdateUserMeetingJoin?.meetingID !== meetingID) return;
          if (result.onUpdateUserMeetingJoin?.user?.id !== userID) return;
          setUserMeetingJoin(result.onUpdateUserMeetingJoin as UserMeetingJoin);
        },
      },
    ];
  }, [userID, meetingID]);

  useNewSubscriptions(subscriptions, 'useUserMeetingPing');

  // initial fetch of userMeetingJoin
  useEffect(() => {
    if (!meetingID || !userID) {
      return;
    }
    const fetchUserMeetingJoin = async () => {
      const variables: UserMeetingJoinByUserIDQueryVariables = {
        userID,
        meetingID: {
          eq: meetingID,
        },
      };
      const userMeetingJoinRequest = await callGraphQLApi<
        GraphQLResult<UserMeetingJoinByUserIDQuery>
      >(userMeetingJoinByUserID, variables);
      if (!userMeetingJoinRequest.data?.userMeetingJoinByUserID?.items) {
        captureSentry({
          title: 'useUserMeetingPing: userMeetingJoinByUserID is undefined',
          detail: {
            userID,
            meetingID,
            uerMeetingJoin: userMeetingJoinRequest,
          },
        });
        return;
      }
      if (
        userMeetingJoinRequest.data.userMeetingJoinByUserID.items.length === 0
      ) {
        captureSentry({
          title: 'useUserMeetingPing: userMeetingJoinByUserID.items is empty',
          detail: {
            userID,
            meetingID,
            uerMeetingJoin: userMeetingJoinRequest,
          },
        });
        return;
      }
      return userMeetingJoinRequest.data.userMeetingJoinByUserID.items[0];
    };

    fetchUserMeetingJoin().then((result) => {
      setUserMeetingJoin(result as UserMeetingJoin);
    });
  }, [userID, meetingID]);

  // initial ping
  useEffect(() => {
    const timer = window.setInterval(() => {
      pingUser(userID, meetingID, userMeetingJoin);
    }, 1000 * APP_CONSTANTS.USER_MEETING_PING_INTERVAL);

    // cleanup function
    return () => {
      // clear interval
      window.clearInterval(timer);
    };
  }, [meetingID, userID, userMeetingJoin]);
}
