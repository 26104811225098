import { GraphQLResult } from '@aws-amplify/api';
import { actions } from '@/store/slices/caseCluster';
import { AppDispatch } from '@/store/Store';
import { listCaseClusters } from '@/services/graphql/queries';
import { ListCaseClustersQuery, CaseCluster } from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export function fetchCaseClusterList() {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setLoading(true));
    try {
      const response = await callGraphQLApi<
        GraphQLResult<ListCaseClustersQuery>
      >(listCaseClusters, {});
      const items = response?.data?.listCaseClusters?.items as CaseCluster[];
      if (items) {
        dispatch(actions.setCaseClusterList(items));
      }
    } catch (error) {
      console.error(`error at fetchCaseClusterList:`);
      dispatch(actions.setError(error));
    }
  };
}
