import useDeviceSize from '@/services/hooks/useDeviceSize';
import { AppSpinner } from '@/ui/AppSpinner';
import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  children: React.ReactNode;
  columnSpan?: number;
}

export function ChunkContainerLoading() {
  return (
    <ChunkContainer padding="20px">
      <AppSpinner />
    </ChunkContainer>
  );
}

function getWidth(isDesktopOrLaptop: boolean, columnSpan?: number) {
  if (columnSpan === 2) {
    if (isDesktopOrLaptop) {
      return '100%';
    }
  }
  return isDesktopOrLaptop ? '470px' : '360px';
}

export default function ChunkContainer({
  columnSpan,
  children,
  ...rest
}: Props) {
  const { isDesktopOrLaptop } = useDeviceSize();
  return (
    <Box
      width={getWidth(isDesktopOrLaptop, columnSpan)}
      borderRadius="10px"
      backgroundColor="#FFFEFE"
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      height="100%"
      style={{
        boxShadow: '5px 5px 15px 0px rgba(53, 40, 170, 0.05)',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
