import { z } from 'zod';
import { SlideType } from '../../enums';
import { baseSlideSchema } from './baseSlideSchema';
import { selectableAnswerSlideItemSchema } from './slideItemTypes/answerSlideItemTypes/selectableAnswerSlideItemSchema';
import { textAnswerSlideItemSchema } from './slideItemTypes/answerSlideItemTypes/textAnswerSlideItemSchema';
import { infoSlideItemSchema } from './slideItemTypes/infoSlideItemSchema';

export const questionSlideItemSchema = z.union([
  selectableAnswerSlideItemSchema,
  textAnswerSlideItemSchema,
  infoSlideItemSchema,
]);

const baseQuestionSlideSchema = baseSlideSchema.and(
  z.object({
    someAnswersHaveLinkedAssets: z.boolean(),
  })
);

const singleQuestionSlideSchema = baseQuestionSlideSchema.and(
  z.object({
    type: z.literal(SlideType.QUESTION_SINGLE),
    items: z.array(questionSlideItemSchema),
  })
);

const multipleQuestionSlideSchema = baseQuestionSlideSchema.and(
  z.object({
    type: z.literal(SlideType.QUESTION_MULTIPLE),
    items: z.array(questionSlideItemSchema),
    minChoices: z.number().positive(),
    maxChoices: z.number().positive(),
  })
);

const multipleOrderedQuestionSlideSchema = baseQuestionSlideSchema.and(
  z.object({
    type: z.literal(SlideType.QUESTION_MULTIPLE_ORDERED),
    items: z.array(questionSlideItemSchema),
    correctOrderIds: z.array(z.string()),
  })
);

export const questionSlideSchema = z.union([
  multipleOrderedQuestionSlideSchema,
  multipleQuestionSlideSchema,
  singleQuestionSlideSchema,
]);

export type QuestionSlideItemType = z.infer<typeof questionSlideItemSchema>;
export type QuestionSlideType = z.infer<typeof questionSlideSchema>;
