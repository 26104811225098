import { AppDispatch } from '@/store/Store';
import { actions } from '@/store/slices/meeting';
import { fetchMeetingList } from './fetchMeetingList';
import { pureDeleteMeeting } from '@/backend/pureDeleteMeeting';

export function deleteAMeeting(meetingID: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setStatus('deleting meeting'));
    try {
      await pureDeleteMeeting(meetingID);
      // retreive updated meeting list
      dispatch(fetchMeetingList());
    } catch (error) {
      console.error(`error at deleteAMeeting:`);
      dispatch(actions.setError(error));
    }
  };
}
