import { Button, ButtonGroup, Flex, Text } from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';

type Props<TData> = {
  table: Table<TData>;
};

function Pagination<TData>({ table }: Props<TData>) {
  return (
    <Flex gap="10px" alignItems="center" justifyContent="space-between">
      <ButtonGroup size="sm" isAttached variant="outline">
        <Button
          onClick={() =>
            table.setPageIndex(
              Math.max(table.getState().pagination.pageIndex - 5, 0)
            )
          }
          isDisabled={!table.getCanPreviousPage()}
          variant="primary"
        >
          {'<<'}
        </Button>

        <Button
          onClick={() => table.previousPage()}
          isDisabled={!table.getCanPreviousPage()}
          variant="primary"
        >
          {'<'}
        </Button>
      </ButtonGroup>

      <Text noOfLines={1}>
        Page {table.getState().pagination.pageIndex + 1} of{' '}
        {table.getPageCount()}
      </Text>
      <ButtonGroup size="sm" isAttached variant="outline">
        <Button
          onClick={() => table.nextPage()}
          isDisabled={!table.getCanNextPage()}
          variant="primary"
        >
          {'>'}
        </Button>
        <Button
          variant="primary"
          isDisabled={!table.getCanNextPage()}
          onClick={() =>
            table.setPageIndex(
              Math.min(
                table.getState().pagination.pageIndex + 5,
                table.getPageCount() - 1
              )
            )
          }
        >
          {'>>'}
        </Button>
      </ButtonGroup>
    </Flex>
  );
}

export default Pagination;
