import { GetUserQuery, User } from '@/services/API';
import { getUser } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { createUserData } from './createUserData';
import { CognitoMetaData } from './getCognitoData';

export async function getOrCreateUserData(
  userID: string,
  cognitoMetaData: CognitoMetaData,
  shouldCreateTemporaryUser: boolean
): Promise<User | null> {
  console.log('Auth | getting user from DB');
  try {
    const gotUser = await callGraphQLApi<GraphQLResult<GetUserQuery>>(getUser, {
      id: userID,
    });
    if (!gotUser.data?.getUser) {
      throw new Error('User not found in DB');
    }
    return gotUser.data?.getUser as User;
  } catch (error) {
    //we don't have a user yet, we should create it
    console.log(
      'Auth | user not found in DB shouldCreateTemporaryUser',
      shouldCreateTemporaryUser
    );
    if (!shouldCreateTemporaryUser && cognitoMetaData.isTemporary) return null;
    console.log('Auth | creating user in DB');
    try {
      const user = await createUserData(userID, cognitoMetaData);
      console.log('Auth | user created in DB');
      return user;
    } catch (error) {
      console.error('Auth | error creating user in DB', error);
      return null;
    }
  }
}
