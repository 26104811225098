import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import { ROUTES } from '@/routes/Routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useCypressScreenshotRedirect() {
  const navigate = useNavigate();
  const caseId = import.meta.env.VITE_CYPRESS_SCREENSHOT;
  const caseList = useCaseList();

  useEffect(() => {
    if (!caseList || caseList.length === 0 || !caseId) return;
    const unloadedPatientCase = caseList.find((elem) => elem.id === caseId);

    if (!unloadedPatientCase) {
      console.error(
        `CYPRESS_SCREENSHOT: Case with id ${caseId} not found in caseList`
      );
      return;
    }
    navigate(`${ROUTES.PREVIEW_CASE}/cypress`, { state: unloadedPatientCase });
  }, [navigate, caseId, caseList]);
}
