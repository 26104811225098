import { GraphQLResult } from '@aws-amplify/api';
import {
  CreateUserMeetingJoinInput,
  Meeting,
  UpdateMeetingMutationVariables,
  UpdateUserMeetingJoinMutationVariables,
  UserMeetingJoinByUserIDQuery,
} from '@/services/API';
import {
  getMeeting,
  userMeetingJoinByUserID,
} from '@/services/graphql/queries';
import {
  createUserMeetingJoin,
  updateMeeting,
  updateUserMeetingJoin,
} from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export async function pureJoinAMeeting(
  userID: string,
  meetingID: string,
  forceRestart: boolean
) {
  const userMeetings = await callGraphQLApi<
    GraphQLResult<UserMeetingJoinByUserIDQuery>
  >(userMeetingJoinByUserID, {
    userID,
  });

  const meetingResponse = await callGraphQLApi<GraphQLResult<Meeting>>(
    getMeeting,
    {
      id: meetingID,
    }
  );

  const existingUserMeetingJoins =
    userMeetings.data?.userMeetingJoinByUserID?.items;
  const alreadyJoined =
    existingUserMeetingJoins &&
    existingUserMeetingJoins.find((elem) => elem?.meetingID === meetingID) !==
      undefined;

  if (!meetingResponse?.data)
    throw new Error(`No meeting found for ID: ${meetingID}`);

  const meeting = meetingResponse?.data;
  // case we are the host -> reset current slide index
  if (meeting.hostID === userID && forceRestart) {
    const variables: UpdateMeetingMutationVariables = {
      input: {
        id: meetingID,
        currentCase: 'null',
        currentSlide: 0,
      },
    };
    await callGraphQLApi(updateMeeting, variables);
  }

  // join
  if (!alreadyJoined) {
    const variables: CreateUserMeetingJoinInput = {
      userID,
      meetingID,
      isCurrentlyInMeeting: true,
    };
    await callGraphQLApi(createUserMeetingJoin, {
      input: variables,
    });
  } else {
    console.log('Already joined this meeting, setting isCurrentlyInMeeting');
    const userMeetingJoin = existingUserMeetingJoins.find(
      (elem) => elem?.meetingID === meetingID
    )!;
    const variables: UpdateUserMeetingJoinMutationVariables = {
      input: {
        id: userMeetingJoin.id,
        isCurrentlyInMeeting: true,
      },
    };
    await callGraphQLApi(updateUserMeetingJoin, variables);
  }
}
