import { CreateUserInput } from '@/services/API';
import { pureCreateUser } from '@/features/userAuth/utils/pureCreateUser';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import getTemporaryUserMail from '@/utils/helpers/getTemporaryUserMail';

export const MOCK_USER_PREFIX = 'MockUser #';

export async function createXAmountOfUsers(amount: number): Promise<string[]> {
  const promises = Array.from({
    length: amount,
  }).map((_, i) => {
    const username = `${MOCK_USER_PREFIX}${i}`;
    const input: CreateUserInput = {
      firstname: username,
      lastname: 'Mock lastname',
      email: getTemporaryUserMail().replace('guest', username),
      //NOTE: maybe later for more advanced mocking we would want some temporary some not
      isTemporary: false,
      isMock: true,
      currentMeetingID: NULL_ID_VALUE,
    };
    return pureCreateUser(input);
  });
  const resolvedPromises = await Promise.all(promises);

  const userIds: string[] = resolvedPromises.map((elem) => {
    const userId = elem.data?.createUser?.id;
    if (!userId) throw new Error('Error while creating a mock user');
    return userId;
  });
  return userIds;
}
