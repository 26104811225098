import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useAppSelector } from '@/store/StoreHooks';
import { FormEvent } from 'react';
import useChatMessages, {
  messageActions,
} from '@/features/call/useChatMessages';
import { Box, Flex, IconButton, Input, Text, useToast } from '@chakra-ui/react';
import { isUrl } from '@/utils/helpers/isUrl';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { RefreshIcon } from '@/components/icons/icons';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import mutationDeleteAllMessagesForMeeting from '@/features/meeting/messages/mutationDeleteAllMessagesForMeeting';
import { AppSpinner } from '@/ui/AppSpinner';
import useMeetingParticipants from '@/features/meeting/hooks/useMeetingParticipantsList';

type Props = {
  isVisible: boolean;
  useChatMessageUtils: ReturnType<typeof useChatMessages>;
};

function ChatView(props: Props) {
  // --------------------- state ------------------------

  const [messageBody, setMessageBody] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const currentMeeting = useAppSelector(MeetingSelectors.getCurrentMeeting);
  const channelID = currentMeeting?.id;
  const userList = useMeetingParticipants(currentMeeting?.id);
  const { user: userInfo, isAdmin } = useUserAuthenticationContext();
  const { t } = useTranslation();
  const toast = useToast();
  const messages = props.useChatMessageUtils.messages;
  const messagesIsLoading = props.useChatMessageUtils.isLoading;

  // --------------------- Handlers ------------------------

  const getUserName = (userID: string) => {
    const user = userList.find((u) => u.id === userID);
    if (user?.id === userInfo?.id) {
      return 'me';
    }
    // case user is not in the participant list anymore
    // TODO: we should manage this in a more robust way (using username in the message info?)
    if (!user) {
      return `unknown-${userID?.substring(4, 8)}`;
    }

    return user?.firstname;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessageBody(event.target.value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const msg = messageBody.trim();
    if (msg === '') {
      return;
    }

    setMessageBody('');
    if (msg && userInfo?.id && channelID)
      messageActions.addMessage({ author: userInfo?.id, body: msg, channelID });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const resetChatHistory = async () => {
    if (!channelID) {
      toast({
        title: t('chat.admin.clearChat.errorTitle'),
        description: t('chat.admin.clearChat.errorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    await mutationDeleteAllMessagesForMeeting(channelID);
    toast({
      title: t('chat.admin.clearChat.successTitle'),
      description: t('chat.admin.clearChat.successDescription'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  // --------------------- effect ------------------------

  useEffect(() => {
    if (props.isVisible) {
      scrollToBottom();
      props.useChatMessageUtils.markAllMessagesAsRead();
    }
  }, [messages, props.isVisible]);

  return (
    <Flex flexDir="column" height="100%" position="relative">
      {isAdmin && (
        <Flex top={2} left={2} position="absolute" zIndex={5}>
          <PopConfirm
            title={
              <Box>
                {t('chat.admin.clearChat.confirmText')}
                <br />
                {t('warning.confirmChoice')}
              </Box>
            }
            onConfirm={resetChatHistory}
          >
            <IconButton
              size="xs"
              borderRadius="full"
              aria-label="Refresh Button"
              border="2px solid"
              color="livelinx.lightgray"
              _hover={{
                transform: 'scale(1.1)',
                transition: 'all 0.2s ease-out',
              }}
              icon={<RefreshIcon size={14} />}
            />
          </PopConfirm>
        </Flex>
      )}
      {messagesIsLoading && (
        <Flex
          width="full"
          height="full"
          alignItems="center"
          justifyContent="center"
        >
          <AppSpinner />
        </Flex>
      )}
      <Box flex="1 1 auto" minHeight="0px" position="relative" flexDir="column">
        <Flex height="100%" flexDir="column" padding="16px" overflowY="scroll">
          {messages &&
            messages.map((message, index, arr) => {
              const isMe = message.author === userInfo?.id;
              const userName = getUserName(message.author);
              const prevUserName = getUserName(arr[index - 1]?.author);
              const showUserName = prevUserName !== userName;
              return (
                <Flex
                  key={message.id}
                  flexDir="column"
                  alignItems={isMe ? 'flex-end' : 'flex-start'}
                >
                  {showUserName && (
                    <Text
                      fontFamily="poppins-regular"
                      color="livelinx.grey200"
                      fontSize="0.8em"
                      marginTop="20px"
                    >
                      {userName}
                    </Text>
                  )}
                  <Box
                    key={message.id}
                    boxShadow="blueShadow"
                    userSelect="none"
                    color="livelinx.darkgrey"
                    fontFamily="nunito-regular"
                    marginTop="4px"
                    padding="8px 12px"
                    maxWidth="240px"
                    background={isMe ? '#e1e7ff' : '#ffffff'}
                    borderRadius={
                      isMe ? '10px 10px 0px 10px' : '0px 10px 10px 10px'
                    }
                    fontSize="1em"
                  >
                    {isUrl(message.body) ? (
                      <a href={message.body} rel="noreferrer" target="_blank">
                        {message.body}
                      </a>
                    ) : (
                      message.body
                    )}
                  </Box>
                </Flex>
              );
            })}

          <div ref={messagesEndRef} />
        </Flex>
      </Box>
      <Box height="64px" borderTop="1px solid #ddd">
        <form
          onSubmit={handleSubmit}
          style={{ height: '100%', padding: '16px' }}
        >
          <Input
            disabled={userInfo === null}
            type="text"
            name="messageBody"
            placeholder={t('chat.typeMessagePlaceHolder') || undefined}
            onChange={handleChange}
            value={messageBody}
            width="100%"
            height="32px"
            padding="8px 16px"
            border="1px solid #ddd"
            borderRadius="16px"
            outline="none"
            _focus={{ border: '1px solid blue' }}
          />
        </form>
      </Box>
    </Flex>
  );
}

export default ChatView;
