import { z } from 'zod';
import { DisclaimerType } from '../../enums';

export const poweredByDisclaimer = z.object({
  type: z.literal(DisclaimerType.POWERED_BY),
  poweredBy: z.string(),
  title: z.string(),
  logos: z.array(z.string()),
  disclaimer: z.string(),
  revision: z.string(),
});

export type PoweredByDisclaimer = z.infer<typeof poweredByDisclaimer>;
