import { CaseCluster, GetCaseClusterQuery } from '@/services/API';
import { getCaseCluster } from '@/services/graphql/queries';
import { pureFetchCaseList } from '@/store/thunk/caseCluster';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { GraphQLResult } from '@aws-amplify/api';
import { CaseMap } from '../caseCluster.types';
import { fetchCaseData } from './fetchCaseData';
import { parseCasePaths } from './parseCasePaths';

type Props =
  | {
      caseClusterID: string;
      caseCluster: undefined;
    }
  | {
      caseClusterID: undefined;
      caseCluster: CaseCluster;
    };

export async function parseCaseMapFromCaseCluster({
  caseClusterID,
  caseCluster,
}: Props): Promise<CaseMap> {
  const caseNameList = await pureFetchCaseList();

  if (caseClusterID && !caseCluster) {
    const caseClusterDetails = await callGraphQLApi<
      GraphQLResult<GetCaseClusterQuery>
    >(getCaseCluster, { id: caseClusterID });
    if (!caseClusterDetails.data?.getCaseCluster)
      throw new Error(
        'Error while fetching caseCluster in parseCaseMapFromCaseCluster'
      );
    caseCluster = caseClusterDetails.data?.getCaseCluster;
  }

  if (!caseCluster) throw new Error('No caseCluster found');

  const caseIds = caseNameList
    .map((item) => item.id)
    .filter((id) => caseCluster?.cases.includes(id));

  const casePaths = parseCasePaths(caseNameList, caseCluster.cases || []);

  if (casePaths.length === 0)
    throw new Error(
      `No cases found in caseCluster with id: ${JSON.stringify(caseClusterID)}`
    );

  const caseResponses: NewIPatientCase[] = await fetchCaseData(
    caseIds,
    casePaths
  );
  const caseMap: CaseMap = caseResponses.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as CaseMap);
  return caseMap;
}
