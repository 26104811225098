import React, { ReactNode, useRef } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  Portal,
} from '@chakra-ui/react';
type Props = {
  children: ReactNode;
  title: string | ReactNode;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export default function PopConfirm({
  children,
  title,
  onConfirm,
  okText,
  cancelText,
  containerRef,
}: Props) {
  const initRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal containerRef={containerRef}>
            <PopoverContent>
              <PopoverBody>{title}</PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <ButtonGroup size="sm">
                  <Button ref={initRef} variant="outline" onClick={onClose}>
                    {cancelText ? cancelText : 'Cancel'}
                  </Button>
                  <Button
                    variant="blue"
                    onClick={() => {
                      onConfirm();
                      onClose();
                    }}
                  >
                    {okText ? okText : 'Ok'}
                  </Button>
                </ButtonGroup>
              </PopoverFooter>{' '}
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
}
