import { UpdateMeetingInput } from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { captureSentry } from '@/utils/helpers/sentryHelper';

export async function updateAMeeting(input: UpdateMeetingInput) {
  try {
    await callGraphQLApi(updateMeeting, { input });
  } catch (error) {
    captureSentry({
      title: 'updateAMeeting',
      error,
      detail: {
        input,
      },
    });
  }
}
