import { z } from 'zod';
import { IAssetSchema } from './assetSchema';
import { caseSelectorItemOverrideSchema } from './caseSelectorItemOverrideSchema';
import { IDisclaimerSchema } from './disclaimerTypes/disclaimerSchema';
import { slideSchema } from './slideTypes/slideSchema';
import { variationSchema } from './variationSchema';

export const newIPatientCaseSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  path: z.string(),
  slides: z.array(slideSchema),
  assets: z.array(IAssetSchema),
  variations: z.array(variationSchema),
  language: z.string(),

  //Optional
  name: z.string().optional(),
  disclaimer: IDisclaimerSchema.optional(),
  caseSelectorItemOverride: caseSelectorItemOverrideSchema.optional(),
});

export type NewIPatientCase = z.infer<typeof newIPatientCaseSchema>;

export const unloadedIPatientCaseSchema = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string(),
});

export type UnloadedIPatientCase = z.infer<typeof unloadedIPatientCaseSchema>;
