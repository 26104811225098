import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Text, Flex, FlexProps } from '@chakra-ui/react';
import { useContext } from 'react';
import { MeetingStatsViewContext } from '../../context/MeetingStatsViewContext';
import BackButton from './BackButton';

function Title() {
  const { isDesktopOrLaptop } = useDeviceSize();
  const { currentCase, caseTitles } = useContext(MeetingStatsViewContext);
  return (
    <Flex
      flexDir={isDesktopOrLaptop ? 'row' : 'column'}
      alignItems="center"
      flexShrink={9999}
    >
      <Text
        fontSize={isDesktopOrLaptop ? '36px' : '25px'}
        variant="purpleTitle"
      >
        Case:
      </Text>
      <Text
        fontSize={isDesktopOrLaptop ? '36px' : '25px'}
        variant="purpleTitleBold"
        marginLeft=".5rem"
        noOfLines={1}
        maxWidth={isDesktopOrLaptop ? '' : '24ch'}
        wordBreak="break-all"
      >
        {caseTitles[currentCase]}
      </Text>
    </Flex>
  );
}

type Props = FlexProps;

export default function CaseHeader({ ...rest }: Props) {
  const { isDesktopOrLaptop } = useDeviceSize();

  return (
    <Flex
      flexDir={isDesktopOrLaptop ? 'row' : 'column'}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...rest}
    >
      {isDesktopOrLaptop && (
        <>
          <Title />
          <BackButton />
        </>
      )}
      {!isDesktopOrLaptop && (
        <>
          <BackButton />
          <Title />
        </>
      )}
    </Flex>
  );
}
