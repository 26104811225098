import { Link } from 'react-router-dom';
import { LivelinxLogo } from '@/ui/Logo';
import ButtonCircle from '@/components/buttons/ButtonCircle';
import UserIcon from '@/components/UserIcon';
import { ROUTES } from '@/routes/Routes';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { FiSettings } from 'react-icons/fi';

const Header = () => {
  const { email, isAdmin } = useUserAuthenticationContext();

  return (
    <Flex
      background="#FFF"
      padding="10px"
      justifyContent="space-between"
      rowGap="10px"
      marginLeft="-5px"
      marginRight="-5px"
      px="20px"
      userSelect="none"
    >
      {/* LEFT part */}
      <HStack>
        <Link to={ROUTES.HOME} reloadDocument>
          <LivelinxLogo />
        </Link>
      </HStack>

      {/* RIGHT part */}
      <HStack>
        {isAdmin && (
          <Link to={ROUTES.ADMIN} draggable="false">
            <HStack
              borderRadius="15px"
              gap="-5px"
              bg="livelinx.blue50"
              pl="15px"
              pr="3px"
              color="livelinx.purple200"
            >
              <Text>Admin</Text>
              <ButtonCircle
                bg="none"
                aria-label="settings-button"
                icon={<FiSettings color="livelinx.purple200" />}
              />
            </HStack>
          </Link>
        )}
        {email && (
          <Link to={ROUTES.LOGIN} draggable="false">
            <UserIcon username={email} />
          </Link>
        )}
      </HStack>
    </Flex>
  );
};

export default Header;
