import { Heading, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { TiWarning } from 'react-icons/ti';

type Props = {
  title: string;
  type: string;
  extra?: ReactNode;
};
export default function Result({ title, type, extra }: Props) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      {type === 'warning' && (
        <TiWarning color="orange" style={{ width: '10rem', height: '10rem' }} />
      )}
      <Heading padding="10px">{title}</Heading>
      {extra && extra}
    </Flex>
  );
}
