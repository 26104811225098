import { z } from 'zod';
import { SlideType } from '../../enums';
import { baseSlideSchema } from './baseSlideSchema';
import { imageSlideItemSchema } from './slideItemTypes/imageSlideItemSchema';

export const imageSlideSchema = baseSlideSchema.and(
  z.object({
    type: z.literal(SlideType.IMAGE),

    items: z.array(imageSlideItemSchema),
  })
);

export type ImageSlideType = z.infer<typeof imageSlideSchema>;
