import { Box } from '@chakra-ui/react';
import { CaseMap } from '../caseCluster/caseCluster.types';
import { useEffect, useMemo, useState } from 'react';

type Props = {
  caseMap: CaseMap;
};

const AssetPreloader = ({ caseMap }: Props) => {
  const [loadedAssets, setLoadedAssets] = useState<number>(0);
  const assetsToLoad = useMemo(() => {
    const toReturn = new Set<string>();
    Object.values(caseMap).forEach((c) => {
      c.assets.forEach((asset) => {
        if (asset.thumbUrl) toReturn.add(asset.thumbUrl);
        if (asset.url) toReturn.add(asset.url);
      });
    });
    return Array.from(toReturn);
  }, [caseMap]);

  useEffect(() => {
    if (assetsToLoad.length === 0) return;

    const imgs: HTMLImageElement[] = [];
    assetsToLoad.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setLoadedAssets((prev) => prev + 1);
      };
      // NOTE: when there is an error, mark the image as loaded because this
      // is a simple preloader, no need to make it more complex
      img.onerror = () => {
        setLoadedAssets((prev) => prev + 1);
      };
      imgs.push(img);
    });

    return () => {
      imgs.forEach((img) => {
        img.onload = null;
      });
      setLoadedAssets(0);
    };
  }, [assetsToLoad]);

  if (assetsToLoad.length === 0 || loadedAssets === assetsToLoad.length)
    return null;
  return (
    <Box
      width={`${(loadedAssets / assetsToLoad.length) * 100}%`}
      height="px"
      bg="livelinx.blue200"
      position="absolute"
      bottom={0}
      left={0}
      zIndex={50}
    ></Box>
  );
};

export default AssetPreloader;
