import { usePrevious } from '@/utils/helpers/react.helper';
import { createContext, useContext, useEffect, useRef } from 'react';
import {
  mutationUpdateCaseSlideStats,
  mutationUpdateMeetingStats,
} from '../graphql/update';
import { useMemo } from 'react';
import { useMeetingCaseStatsDetails } from '../hooks/useMeetingCaseStatsDetails';
import useMeetingTrackingAssetStats from '@/features/tracking/useMeetingTrackingAssetStats';
import useMeetingTracking from '@/features/tracking/useMeetingTracking';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { UpdateMeetingStatsInput } from '@/services/API';
import { captureSentry } from '@/utils/helpers/sentryHelper';

type Props = {
  children: React.ReactNode;
  isPreview?: boolean;
};

type MeetingStatsCreationContextType = {
  trackAssetSwitch: ReturnType<
    typeof useMeetingTrackingAssetStats
  >['trackAssetSwitch'];
  trackMeetingLeave: () => void;
  trackCaseClusterStop: () => void;
  trackAnswer: (answerIndex: number, isConfirmed: boolean) => void;
  trackSlideChange: () => void;
  trackConsensusRate: (consensusRate: number) => void;
  createNPSScoreResult: (
    meetingID: string,
    userID: string,
    score: number[]
  ) => Promise<void>;
};

export const MeetingStatsCreationContext = createContext<
  MeetingStatsCreationContextType | undefined
>(undefined);

export default function MeetingStatsCreationContextProvider({
  children,
  isPreview,
}: Props) {
  const { meeting, currentCaseId, isUserHost, slideID, meetingStatsData } =
    useCurrentMeetingDataContext();
  const meetingID = meeting?.id;
  const previousCaseId = usePrevious(currentCaseId);
  const lastSlideID = usePrevious(slideID);
  const lastCaseId = usePrevious(currentCaseId);
  const startTime = useRef<number>(Date.now());

  const caseStatsDetails = useMeetingCaseStatsDetails(
    meetingID,
    meeting?.caseClusterID,
    true
  );

  const { trackAssetSwitch } = useMeetingTrackingAssetStats(
    meetingID,
    currentCaseId,
    caseStatsDetails,
    isUserHost
  );

  const {
    trackMeetingLeave,
    trackCaseClusterStop,
    trackAnswer,
    trackSlideChange,
    trackConsensusRate,
    createNPSScoreResult,
  } = useMeetingTracking(isPreview ?? false);

  const slideStatsDetailsOfLastCase = useMemo(() => {
    if (!lastCaseId || !caseStatsDetails || !caseStatsDetails[lastCaseId])
      return undefined;
    return caseStatsDetails[lastCaseId]?.slides?.items;
  }, [lastCaseId, caseStatsDetails]);

  //track slide changes
  useEffect(() => {
    if (isPreview) return; // don't track anything in preview mode
    if (lastSlideID === slideID) return;
    if (!isUserHost || meeting?.isInTesting) return;
    console.log(
      'slideChanged lastSlide currentSlide time isHost: ',
      lastSlideID,
      slideID,
      isUserHost
    );
    if (lastSlideID && slideStatsDetailsOfLastCase) {
      const slideItem = slideStatsDetailsOfLastCase.find(
        (item) => item?.slideId == lastSlideID
      );
      if (slideItem?.id) {
        const timeSpentOnSlide =
          Math.floor(Date.now() - startTime.current) / 1000;
        mutationUpdateCaseSlideStats(
          slideItem.id,
          slideItem.time + Math.round(timeSpentOnSlide)
        );
        if (timeSpentOnSlide > 60 * 60) {
          captureSentry({
            title:
              'useMeetingStatsCreation: suspicous timeSpentOnSlide > 1 hour',
            detail: {
              meetingID,
              lastSlideID,
              slideStatsDetailsOfLastCase,
              slideItem,
              timeSpentOnSlide,
              startTime: startTime.current,
              now: Date.now(),
            },
          });
        }
        // console.log('SLIDESTATS UPDATE DONE');
      } else {
        captureSentry({
          title: 'useMeetingStatsCreation: slideStatsID not found',
          detail: {
            lastSlideID,
            slideStatsDetailsOfLastCase,
            slideItem,
          },
        });
      }
    }
    startTime.current = Date.now();
  }, [slideID, slideStatsDetailsOfLastCase, isPreview]);

  //track case changes
  useEffect(() => {
    if (isPreview) return; // don't track anything in preview mode
    // if (currentCaseId === undefined) return;
    if (!currentCaseId) return;
    if (!isUserHost || meeting?.isInTesting) return;
    if (previousCaseId === currentCaseId) return;
    if (!meetingStatsData) {
      console.warn(
        'caseId changed but not all data is ready',
        previousCaseId,
        currentCaseId,
        meetingStatsData
      );
      return;
    }

    console.log('caseId changed, curr', previousCaseId, currentCaseId);
    const newCasePresentationHistory = meetingStatsData.casePresentationHistory;
    // const currentCaseIndex =
    // if (currentCaseIndex === -1) {
    //   newCasePresentationHistory.push(CASE_SELECTOR);
    // } else {
    //   newCasePresentationHistory.push(currentCaseIndex.toString());
    // }
    newCasePresentationHistory.push(currentCaseId);
    const input: UpdateMeetingStatsInput = {
      id: meetingStatsData.id,
      casePresentationHistory: newCasePresentationHistory,
    };
    mutationUpdateMeetingStats(input);
    console.log('MEETINGSTATS UPDATE DONE', newCasePresentationHistory);
  }, [
    currentCaseId,
    previousCaseId,
    meetingStatsData,
    isUserHost,
    meeting?.isInTesting,
    isPreview,
  ]);

  return (
    <MeetingStatsCreationContext.Provider
      value={{
        trackAssetSwitch,
        trackMeetingLeave,
        trackCaseClusterStop,
        trackAnswer,
        trackSlideChange,
        trackConsensusRate,
        createNPSScoreResult,
      }}
    >
      {children}
    </MeetingStatsCreationContext.Provider>
  );
}

export const useMeetingStatsCreationContext = () => {
  const context = useContext(MeetingStatsCreationContext);
  if (context === undefined) {
    throw new Error(
      'useMeetingStatsCreationContext must be used within a MeetingStatsCreationContextProvider'
    );
  }
  return context;
};
