import { CreateUserClientMutation, UserClient } from '@/services/API';
import { deleteUserClient } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export function removeUserFromClient(client: UserClient): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    callGraphQLApi<GraphQLResult<CreateUserClientMutation>>(deleteUserClient, {
      input: {
        id: client.id,
      },
    })
      .then((_) => resolve())
      .catch((error) => reject(error));
  });
}
