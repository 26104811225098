import AuthGuard from '@/components/Auth/AuthGuard';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { PageLoading } from '@/ui/PageLoading';
import { Navigate } from 'react-router-dom';

export default function AdminRoute({ children }: { children: JSX.Element }) {
  const { user, isAdmin } = useUserAuthenticationContext();
  if (!user) return <PageLoading />;
  if (!isAdmin) {
    return <Navigate replace to="/" />;
  } else {
    //all admin routes require the user to be auntenticated
    return <AuthGuard>{children}</AuthGuard>;
  }
}
