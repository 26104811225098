import { actions } from '@/store/slices/clients';
import { AppDispatch } from '@/store/Store';
import { deleteClient, deleteUserClient } from '@/services/graphql/mutations';
import {
  ListClientsQuery,
  ListUserClientsQuery,
  DeleteUserClientMutation,
  ListUserClientsQueryVariables,
  ListClientsQueryVariables,
} from '@/services/API';
import { listClients, listUserClients } from '@/services/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export function deleteAClient(clientName: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const variables: ListClientsQueryVariables = {
        filter: {
          clientName: {
            eq: clientName,
          },
        },
      };
      //Get the id of the client object
      const clientObject = await callGraphQLApi<
        GraphQLResult<ListClientsQuery>
      >(listClients, variables);

      if (clientObject.data?.listClients?.items?.length === 0) {
        dispatch(actions.setError({ message: 'Client does not exist' }));
        return;
      }
      const clientId = clientObject.data?.listClients?.items[0]?.id;

      const variablesUserClients: ListUserClientsQueryVariables = {
        filter: {
          clientID: {
            eq: clientId,
          },
        },
      };
      //Get all the userClients with the clientID
      const userClientsToDelete = await callGraphQLApi<
        GraphQLResult<ListUserClientsQuery>
      >(listUserClients, variablesUserClients);

      //Delete the client with the id
      await callGraphQLApi(deleteClient, { input: { id: clientId } });

      //Note: we don't use a map here otherwise the type would need to include undefined since userClient can be undefined
      const allPromises:
        | Promise<GraphQLResult<DeleteUserClientMutation>>[]
        | undefined = [];

      userClientsToDelete.data?.listUserClients?.items?.forEach(
        (userClient) => {
          if (!userClient?.id) return;
          allPromises.push(
            callGraphQLApi(deleteUserClient, {
              input: { id: userClient.id },
            })
          );
        }
      );
      if (allPromises) await Promise.all(allPromises);
    } catch (error) {
      dispatch(actions.setError(error));
    }
  };
}
