import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const infoSlideItemSchema = z.object({
  type: z.literal(SlideItemType.INFO),
  assetRefs: z.array(z.string()),

  //Optional
  //Note: title is optional because of case 0010_IPSEN_MELIANTHE_20230531T082413Z Slide 4
  title: z.string().min(1, { message: 'title must be non empty' }).optional(),
  description: z
    .string()
    .min(1, { message: 'description must be non empty' })
    .optional(),
});

export type InfoSlideItemType = z.infer<typeof infoSlideItemSchema>;
