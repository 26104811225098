import {
  Meeting,
  MeetingStatsByMeetingQuery,
  MeetingStatsByMeetingQueryVariables,
} from '@/services/API';
import { meetingStatsByMeeting } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { useQueries } from '@tanstack/react-query';

export default function useDoStatsExistForMeetings(meetingList: Meeting[]) {
  const queries = useQueries({
    queries: meetingList.map((meeting) => {
      return {
        queryKey: ['doesMeetingStatsExist', meeting.id],
        refetchOnWindowFocus: false,
        enabled: !!meeting.id,
        queryFn: async () => {
          const variables: MeetingStatsByMeetingQueryVariables = {
            meetingID: meeting.id,
          };
          const result = await callGraphQLApi<
            GraphQLResult<MeetingStatsByMeetingQuery>
          >(meetingStatsByMeeting, variables);
          const thisMeetingStats = result.data?.meetingStatsByMeeting?.items[0];

          return (
            thisMeetingStats &&
            thisMeetingStats.casePresentationHistory.length > 0
          );
        },
      };
    }),
  });

  return queries.reduce((acc, curr, idx) => {
    if (curr.data) {
      const meetingID = meetingList[idx].id;
      acc[meetingID] = curr.data;
    }
    return acc;
  }, {} as Record<string, boolean>);
}
