import { z } from 'zod';
import { SlideType } from '../../enums';
import { baseSlideSchema } from './baseSlideSchema';
import { questionSlideItemSchema } from './questionSlideSchema';

export const customSlideSchema = baseSlideSchema.and(
  z.object({
    type: z.literal(SlideType.CUSTOM),
    customSlide: z.number().positive(),
    //This is here only to satisfy the type checker, we don't use it
    items: z.array(questionSlideItemSchema),
  })
);

export type CustomSlideType = z.infer<typeof customSlideSchema>;
