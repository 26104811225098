import { Flex, Tag } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { motion } from 'framer-motion';

const MotionTag = motion(Tag);

type Props = {
  children: ReactNode;
  isActive: boolean;
};

export default function HostImageWrapper({ children, isActive }: Props) {
  if (!isActive) return children;
  return (
    <Flex w="full" h="full" position="relative">
      <MotionTag
        top={2}
        right={2}
        position="absolute"
        bgColor="primary"
        color="white"
        pointerEvents="none"
        userSelect="none"
        animate={{
          scale: [1, 0.95, 1],
          opacity: [1, 0.9, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
        size="sm"
      >
        Your host is here !
      </MotionTag>
      {children}
    </Flex>
  );
}
