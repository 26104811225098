import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { Client, Meeting } from '@/services/API';
import dateInRangeFilterFn from '@/ui/TanstackTable/Filter/date/dateInRangeFilterFn';
import { Button, Text } from '@chakra-ui/react';
import { parseDate } from '@/utils/helpers/parseDate';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import { DeleteOutlined } from '@ant-design/icons';

const columnHelper = createColumnHelper<Client>();

type Args = {
  t: TFunction<'translation', undefined, 'translation'>;
  onDeleteClientClick: (client: Client) => void;
  meetingList: Meeting[];
};

const getColumns = ({ t, onDeleteClientClick, meetingList }: Args) => {
  const columns = [
    columnHelper.accessor('clientName', {
      header: 'Client Name',
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (props) => {
        const item = props.row.original;
        return (
          <PopConfirm
            title={
              <div>
                {t('admin.clients.delete.confirmText')}
                <br />
                {t('meeting.header.confirmRestart')}
              </div>
            }
            onConfirm={() => onDeleteClientClick(item)}
            okText={t('common.yes') as string}
            cancelText={t('common.no') as string}
          >
            <Button
              disabled={meetingList.some((el) =>
                el.clientIDs?.includes(item.id)
              )}
              variant="danger"
              key="delete"
              size="sm"
              rightIcon={<DeleteOutlined />}
            >
              {t('common.delete')}
            </Button>
          </PopConfirm>
        );
      },
    }),
  ];

  return columns;
};

export default getColumns;
