import {
  CreateAnswerMutation,
  CreateAnswerMutationVariables,
  ListAnswersQuery,
  ListAnswersQueryVariables,
  UpdateAnswerMutation,
  UpdateAnswerMutationVariables,
} from '@/services/API';
import { createAnswer, updateAnswer } from '@/services/graphql/mutations';
import { listAnswers } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { GraphQLResult } from '@aws-amplify/api';

export const composeQuestionID = (
  meetingID: string,
  caseID: string,
  slideID: string
) => `${meetingID}_${caseID}_${slideID}`;

// export const composeQuestionID = (slideID: string) => slideID;

export const mutationCreateAnswer = async (
  userID: string,
  meetingID: string,
  questionID: string,
  answerIndexes: string[],
  answerTexts: string[]
) => {
  try {
    const variables: CreateAnswerMutationVariables = {
      input: {
        questionID,
        userID,
        meetingID,
        answerIndexes,
        answerTexts,
      },
    };
    await callGraphQLApi<GraphQLResult<CreateAnswerMutation>>(
      createAnswer,
      variables
    );
  } catch (error) {
    throw new Error(`answer submit error:${JSON.stringify(error)}`);
  }
};

export const mutationUpdateOrCreateAnswer = async (
  userID: string,
  meetingID: string,
  questionID: string,
  answerIndexes: string[],
  answerTexts: string[]
) => {
  //first check if the user has an existing answer, if yes, update it, if not, create it
  const getAnswerVariables: ListAnswersQueryVariables = {
    filter: {
      userID: {
        eq: userID,
      },
      questionID: {
        eq: questionID,
      },
      meetingID: {
        eq: meetingID,
      },
    },
  };
  const userAnswer = await callGraphQLApi<GraphQLResult<ListAnswersQuery>>(
    listAnswers,
    getAnswerVariables
  );

  console.log('useranswer in createanswer', userAnswer.data);

  if (userAnswer.data?.listAnswers?.items.length) {
    // log possible bug with multiple answers
    if (userAnswer.data.listAnswers.items.length > 1) {
      console.log(userAnswer.data?.listAnswers?.items);
      captureSentry({
        title: 'More than one user answer for a question ID!',
        detail: {
          userAnswer: userAnswer.data?.listAnswers?.items,
          userID,
          meetingID,
          questionID,
        },
      });
    }

    // TODO: NIKO:  there is a bug here.. in the case there are multiple anwsers, we need to use the more recent.
    // we should never have multiple answers for the same questionID though
    // const answer = userAnswer.data.listAnswers.items.map[0];
    const answer = userAnswer.data.listAnswers.items.reduce((prev, current) => {
      return prev &&
        new Date(prev!.updatedAt!).getTime() >
          new Date(current!.updatedAt!).getTime()
        ? prev
        : current;
    });
    console.log('answer', answer);
    console.log('new answer', answerTexts);

    if (!answer) {
      throw new Error(`answerHelper updateOrCreateAnswer, answer not found`);
    }
    const updateAnswerVariables: UpdateAnswerMutationVariables = {
      input: {
        id: answer.id,
        questionID,
        userID,
        meetingID,
        answerIndexes,
        answerTexts,
      },
    };
    await callGraphQLApi<GraphQLResult<UpdateAnswerMutation>>(
      updateAnswer,
      updateAnswerVariables
    );
  } else {
    await mutationCreateAnswer(
      userID,
      meetingID,
      questionID,
      answerIndexes,
      answerTexts
    );
  }
};
