import getAvailableUserMeetings from '@/features/meeting/helpers/getAvailableUserMeetings';
import { User } from '@/services/API';
import useMeetingUserAccessForUser from '@/services/hooks/useMeetingUserAccessForUser';
import useUserClients from '@/services/hooks/useUserClients';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import { useAppSelector } from '@/store/StoreHooks';
import { MeetingSelectors } from '@/store/slices/meeting';
import Table from '@/ui/Table';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Divider, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Column } from 'react-table';

type Props = {
  user: User;
};

type Row = {
  meetingName: string;
  hasExplicitAccess: boolean;
  groupAccesses: string[];
};

export default function UserMeetingAccessView({ user }: Props) {
  const { userAccessList } = useMeetingUserAccessForUser(user?.id);
  const userClients = useUserClients({
    userID: user?.id,
    live: false,
  });
  const originalMeetingList = useAppSelector(MeetingSelectors.getMeetingList);

  const meetingList = useMemo(() => {
    if (!originalMeetingList) return [];
    let toReturn = originalMeetingList.filter(
      (meeting) => meeting.isArchived === false
    );

    toReturn = getAvailableUserMeetings(
      toReturn,
      user,
      userClients,
      userAccessList,
      //NOTE: We are not considering admins in this view.
      false
    );
    return toReturn;
  }, [originalMeetingList, userAccessList.length, user, userClients]);

  const columns: Column<Row>[] = [
    {
      Header: 'Meeting',
      accessor: (item: Row) => {
        return (
          <Tooltip label={item.meetingName} aria-label="meeting name">
            <Text noOfLines={1}>{item.meetingName}</Text>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Explicit Access',
      disableSortBy: true,
      accessor: (item: Row) => {
        return item.hasExplicitAccess ? <CheckIcon /> : <CloseIcon />;
      },
    },
    {
      Header: 'Group accesses',
      accessor: (item: Row) => {
        const txt = item.groupAccesses.join(', ');
        return (
          <Tooltip label={txt} aria-label="meeting name">
            <Text noOfLines={1}>{txt}</Text>
          </Tooltip>
        );
      },
    },
  ];

  const data: Row[] = useMemo(() => {
    const result: Row[] = meetingList.map((meeting) => {
      const hasExplicitAccess = !!userAccessList.find(
        (elem) => elem.meetingID === meeting.id
      );
      const groupAccesses = userClients
        .filter((client) => meeting.clientIDs.includes(client.clientID))
        .map((client) => client.client?.clientName || 'N/A');
      const row: Row = {
        meetingName: meeting.name,
        hasExplicitAccess: hasExplicitAccess,
        groupAccesses: groupAccesses,
      };
      return row;
    });
    return result;
  }, [meetingList, userAccessList, userClients]);

  // usesubscription to be sure everything is updated once updated on backend
  useMeetingSubscription();

  return (
    <>
      <Text as="b">Meetings accesses:</Text>

      {data.length === 0 ? (
        <Flex justifyContent="center">
          <Text>User has access to no meetings.</Text>
        </Flex>
      ) : (
        <>
          <Divider marginY="10px" />
          <Table size="sm" columns={columns} data={data} />
        </>
      )}
    </>
  );
}
