import mutationDeleteOneMessage from './mutationDeleteOneMessage';
import queryListMessagesForMeeting from './queryListMessagesForMeeting';

export default async function mutationDeleteAllMessagesForMeeting(
  meetingID: string
) {
  const allMessagesForMeeting = await queryListMessagesForMeeting(meetingID);

  const deletionPromises =
    allMessagesForMeeting.data?.messagesByChannelID?.items?.map(
      async (message) => {
        if (!message) return;
        const deletionResult = await mutationDeleteOneMessage(message?.id);
        return deletionResult;
      }
    );

  await Promise.all(deletionPromises ?? []);
}
