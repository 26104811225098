import AuthGuard from '@/components/Auth/AuthGuard';
import MeetingListView from '@/features/meeting/MeetingListView';
import useCypressScreenshotRedirect from './hook/useCypressScreenshotRedirect';

function HomeView() {
  useCypressScreenshotRedirect();

  return (
    <AuthGuard>
      <MeetingListView />
    </AuthGuard>
  );
}

export default HomeView;
