import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';
import { AdminMeetingListItemType } from './columns';

const useAdminMeetingTableStore =
  createTanstackTableDataStore<AdminMeetingListItemType>({
    storeName: 'admin-meeting-table-store',
    defaultColumnFilters: [
      {
        id: 'isArchived',
        value: false,
      },
    ],
    defaultColumnSizing: {
      name: 238,
      host: 215,
      eventDate: 131,
      purchaseOrderNbr: 87,
      isInTesting: 119,
      isArchived: 118,
    },
    defaultColumnVisibility: {},
    defaultColumnSorting: [
      {
        id: 'eventDate',
        desc: true,
      },
    ],
  });

export default useAdminMeetingTableStore;
