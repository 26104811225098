import { GraphQLResult } from '@aws-amplify/api';
import { AppDispatch } from '@/store/Store';
import { actions } from '@/store/slices/meeting';
import { listMeetings } from '@/services/graphql/queries';
import { ListMeetingsQuery, Meeting } from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { captureSentry } from '@/utils/helpers/sentryHelper';

export function fetchMeetingList() {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setStatus('loading'));
    try {
      const response = await callGraphQLApi<GraphQLResult<ListMeetingsQuery>>(
        listMeetings,
        {}
      );
      const items = response?.data?.listMeetings?.items as Meeting[];
      if (items) {
        dispatch(actions.setMeetingList(items));
      }
    } catch (error) {
      captureSentry({
        title: 'Error in fetchMeetingList',
        error: error instanceof Error ? error : undefined,
      });
      dispatch(actions.setError(error));
    }
  };
}
