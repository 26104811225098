import { BoxProps, Icon } from '@chakra-ui/react';
import ProgressBar from '@/assets/images/meeting-stats-ProgressBar.svg';
import { useRef, useEffect } from 'react';

interface Props {
  progress: number;
  progressSize: number;
  color: string;
  bgColor: string;
}

// const sweep = -50;
// const ratio = 1.0;

interface CircleCanvasProps extends BoxProps {
  progressSize: number;
  sweep: number;
  ratio: number;
  color: string;
  bgColor: string;
}

const CircleCanvas = ({
  progressSize,
  sweep,
  ratio,
  color,
  bgColor,
}: CircleCanvasProps) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas: any = canvasRef.current;
    const context = canvas.getContext('2d');
    const width = canvas.width; // Width of the canvas

    const centerX = width / 2; // X coordinate of the center of the circle
    const centerY = width / 2; // Y coordinate of the center of the circle
    const radius = width / 2; // Radius of the circle
    const innerRadius = radius * ratio;

    const startAngle = Math.PI; // Starting angle in radians
    const endAngle = startAngle + Math.PI * sweep; // Ending angle in radians

    context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    context.beginPath();
    context.arc(centerX, centerY, radius, startAngle, endAngle);
    context.lineTo(centerX, centerY); // Connect the end point to the center to close the path
    context.fillStyle = color; // Fill color
    context.fill();

    context.beginPath();
    context.arc(
      centerX,
      centerY,
      innerRadius,
      startAngle,
      startAngle + Math.PI
    );
    context.lineTo(centerX, centerY); // Connect the end point to the center to close the path
    context.fillStyle = bgColor; // Fill color
    context.fill();
  }, [canvasRef.current, sweep, ratio, color, bgColor]);

  return (
    <canvas
      style={{
        zIndex: 0,
        position: 'absolute',
        top: '25px',
        left: `100% - (${progressSize / 2}px)`,
        pointerEvents: 'none',
      }}
      ref={canvasRef}
      width={progressSize}
      height={progressSize}
    />
  );
};

export default function HalfCircleProgressBar({
  progress,
  color,
  bgColor,
  progressSize,
}: Props) {
  return (
    <>
      <Icon
        position="absolute"
        top="0"
        left={`100% - (${progressSize / 2}px)`}
        as={ProgressBar}
        width={`${progressSize}px`}
        height="175px"
      />
      <CircleCanvas
        progressSize={progressSize}
        sweep={progress / 100}
        ratio={0.93}
        color={color}
        bgColor={bgColor}
      />
    </>
  );
}
