import { z } from 'zod';
import { SlideItemType } from '../../../enums';

export const anamneseItemSchema = z.object({
  type: z.literal(SlideItemType.ANAMNESE),
  title: z.string().min(1, { message: 'title must be non empty' }),
  gender: z.union([z.literal('man'), z.literal('woman')]),
  descriptionList: z.array(z.string()),
});

export type AnamneseSlideItemType = z.infer<typeof anamneseItemSchema>;
