import {
  CaseCluster,
  CreateCaseClusterInput,
  CreateCaseClusterMutation,
} from '@/services/API';
import { createCaseCluster } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export default async function mockCaseCluster(testID: number, cases: string[]) {
  const caseClusterInput: CreateCaseClusterInput = {
    name: `cc mocking - Test ${testID}`,
    cases,
    isMock: true,
  };
  const ccResult = await callGraphQLApi<
    GraphQLResult<CreateCaseClusterMutation>
  >(createCaseCluster, { input: caseClusterInput });
  return ccResult.data?.createCaseCluster as CaseCluster;
}
