import { deleteMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { DeleteMeetingMutation } from '@/services/API';
import { mutationDeleteAllAnswersForMeeting } from '@/features/meeting/answer/mutationDeleteAnswer';
import {
  mutationDeleteAllCaseStatsForMeeting,
  mutationDeleteAllNPSScoresForMeeting,
  mutationDeleteMeetingStatsForMeeting,
} from '@/features/meeting-stats/graphql/delete';
import mutationDeleteAllMessagesForMeeting from '@/features/meeting/messages/mutationDeleteAllMessagesForMeeting';

export type DeletionMeetingStats = {
  amtAnswersDeleted: number;
  amtCaseStatsDeleted: number;
  amtUserMeetingJoinsDeleted: number;
  amtNpsScoresDeleted: number;
};

/*
Responsible for deleting a meeting and all its associated data
- answers
- case stats
- meeting stats
- nps scores
- messages
*/
export async function pureDeleteMeeting(
  meetingID: string
): Promise<DeletionMeetingStats> {
  const deletionMeetingStats: DeletionMeetingStats = {
    amtAnswersDeleted: 0,
    amtCaseStatsDeleted: 0,
    amtUserMeetingJoinsDeleted: 0,
    amtNpsScoresDeleted: 0,
  };
  const meetingDeletionPromise = callGraphQLApi<
    GraphQLResult<DeleteMeetingMutation>
  >(deleteMeeting, { input: { id: meetingID } });

  const resolvedAnswerDeletions = await mutationDeleteAllAnswersForMeeting(
    meetingID
  );
  await mutationDeleteAllMessagesForMeeting(meetingID);
  await mutationDeleteMeetingStatsForMeeting(meetingID);

  const resolvedCaseStatsDeletions =
    (await mutationDeleteAllCaseStatsForMeeting(meetingID)) ?? [];

  const resolvedNpsScoreDeletions =
    (await mutationDeleteAllNPSScoresForMeeting(meetingID)) ?? [];

  await meetingDeletionPromise;

  deletionMeetingStats.amtAnswersDeleted = resolvedAnswerDeletions.length;
  deletionMeetingStats.amtCaseStatsDeleted = resolvedCaseStatsDeletions.length;
  deletionMeetingStats.amtNpsScoresDeleted = resolvedNpsScoreDeletions.length;
  return deletionMeetingStats;
}
