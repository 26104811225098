import clamp from '@/utils/helpers/clamp';
import { CaseAssetViewStats } from './calculateAssetViewStats';
import calculateLinearScore from './calculateLinearScore';

export default function calculateAssetDuration(
  caseAssetViewStats: CaseAssetViewStats
) {
  const avgTime = caseAssetViewStats.avgViewDuration.cappedAssetViewDuration;
  const score = calculateLinearScore(avgTime, 0, 9, 9, Number.MAX_SAFE_INTEGER);
  return clamp(score, 0, 1);
}
