import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@/store/StoreHooks';
import {
  onCreateCaseCluster,
  onUpdateCaseCluster,
  onDeleteCaseCluster,
} from '../graphql/subscriptions';
import { fetchCaseClusterList } from '@/store/thunk/caseCluster';
import useNewSubscriptions from './useNewSubscriptions';
import {
  OnCreateCaseClusterSubscription,
  OnDeleteCaseClusterSubscription,
  OnUpdateCaseClusterSubscription,
} from '../API';

// --------------------- hook ------------------------

function useCaseClusterSubscription() {
  const [refreshTs, setRefreshTs] = useState(Date.now());
  const dispatch = useAppDispatch();

  // each time the subscription change, we update the resfresh timestamp to force an update of the meeting list
  useEffect(() => {
    dispatch(fetchCaseClusterList());
  }, [refreshTs]);

  // --------------------- subscription effect ------------------------

  const subscriptions = useMemo(() => {
    return [
      {
        query: onCreateCaseCluster,
        variables: {},
        callback: (_result: OnCreateCaseClusterSubscription) => {
          setRefreshTs(Date.now());
        },
      },
      {
        query: onUpdateCaseCluster,
        variables: {},
        callback: (_result: OnUpdateCaseClusterSubscription) => {
          setRefreshTs(Date.now());
        },
      },
      {
        query: onDeleteCaseCluster,
        variables: {},
        callback: (_result: OnDeleteCaseClusterSubscription) => {
          setRefreshTs(Date.now());
        },
      },
    ];
  }, []);

  useNewSubscriptions(subscriptions, 'useCaseClusterSubscriptions');

  return {
    lastRefreshTime: refreshTs,
  };
}

export default useCaseClusterSubscription;
