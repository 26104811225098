import { useTranslation } from 'react-i18next';
import ChunkContainer, { ChunkContainerLoading } from './ChunkContainer';
import { useContext } from 'react';
import Title from './Title';
import { Box, Text, Icon, Button } from '@chakra-ui/react';
import carretRight from '@/assets/images/CaretRight.svg';
import useDeviceSize from '@/services/hooks/useDeviceSize';
import { PADDING_X_DESKTOP, PADDING_X_MOBILE } from '../utils/constants';
import { MeetingStatsViewContext } from '../context/MeetingStatsViewContext';

export default function ViewedCase() {
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useDeviceSize();
  const { setCurrentCase, caseTitles } = useContext(MeetingStatsViewContext);

  if (!caseTitles) {
    return <ChunkContainerLoading />;
  }

  return (
    <ChunkContainer>
      <Title text={t('meetings.stats.viewedCases.title')} />
      <Box
        marginY="10px"
        marginBottom="15px"
        width="100%"
        paddingX={isDesktopOrLaptop ? PADDING_X_DESKTOP : PADDING_X_MOBILE}
      >
        {Object.keys(caseTitles).map((caseId, idx) => (
          <Button
            key={caseId}
            variant="meetingStats"
            width="100%"
            display="flex"
            flexDir="row"
            justifyContent="space-between"
            paddingLeft="15px"
            paddingRight="15px"
            paddingTop="5px"
            paddingBottom="5px"
            alignItems="center"
            marginBottom="10px"
            onClick={() => setCurrentCase(caseId)}
          >
            <Text
              fontSize="17px"
              fontFamily="nunito-semi-bold"
              color="white"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Case {idx + 1}: {caseTitles[caseId]}
            </Text>
            <Icon as={carretRight} boxSize="20px" />
          </Button>
        ))}
      </Box>
    </ChunkContainer>
  );
}
