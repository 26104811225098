import { IsMobile } from '@/utils/helpers/browser.helper';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react';
import { ReactNode, useEffect, useState } from 'react';

type Props = {
  children: ReactNode;
};

export const VideoCallWrapper = ({ children }: Props) => {
  const [callObject, setCallObject] = useState<DailyCall | undefined>();

  // initialize a call object on mount
  useEffect(() => {
    if (!callObject && !IsMobile()) {
      try {
        const newCallObject = DailyIframe.createCallObject({
          dailyConfig: { experimentalChromeVideoMuteLightOff: true },
        });
        if (newCallObject) setCallObject(newCallObject);
      } catch (e) {
        console.error('Error while creating  DailyCall object', e);
      }
    }
  }, []);

  return <DailyProvider callObject={callObject}>{children}</DailyProvider>;
};
