import { Meeting } from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import {
  customGetCaseStats,
  customGetMeeting,
  CustomGetMeetingQuery,
} from '../graphql/gql';

/*
This function uses a custom query to get details about the caseStats, their views and slide stats
*/
export const getMeetingDetails = async (meetingID: string) => {
  const res = await callGraphQLApi<GraphQLResult<CustomGetMeetingQuery>>(
    customGetMeeting,
    {
      id: meetingID,
    }
  );
  const details = res.data?.getMeeting as Meeting;

  if (!details) throw new Error('Error when getting meeting details.');
  if (!details.caseStats)
    throw new Error('Error when getting caseStats of meeting.');

  for (const item of details.caseStats.items) {
    if (!item)
      throw new Error('Error when getting caseStats of meeting. Item is null.');
    let nextToken = item?.assetViews?.nextToken;

    //Fetch more assetViews if there are more
    while (nextToken) {
      const apiRes = await callGraphQLApi<GraphQLResult<any>>(
        customGetCaseStats,
        {
          id: item.id,
          assetViewsNextToken: nextToken,
        }
      );
      const additionalDetails = apiRes.data?.getCaseStats;

      if (
        !additionalDetails ||
        !additionalDetails.assetViews ||
        !item.assetViews
      )
        throw new Error('Error when getting additional meeting details.');

      const additionalAssetViews = additionalDetails.assetViews?.items ?? [];

      //Add the additional assetViews to the original caseStats
      item.assetViews.items = [
        ...item.assetViews.items,
        ...additionalAssetViews,
      ];
      nextToken = additionalDetails.assetViews?.nextToken;
    }
  }

  return details;
};
