import { CreateUserInput, CreateUserMutation } from '@/services/API';
import { createUser } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export async function pureCreateUser(input: CreateUserInput) {
  const resp = await callGraphQLApi<GraphQLResult<CreateUserMutation>>(
    createUser,
    { input }
  );
  return resp;
}
