import { useRef, useEffect, useState } from 'react';
import { Box, BoxProps, Fade, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Timer from '@/assets/images/timer.svg';

type Props = {
  showMilliseconds?: boolean;
  showHours?: boolean;
  showAfterSecond?: number;
} & BoxProps;

const formatMs = (
  milliseconds: number,
  showMs: boolean,
  showHours: boolean
) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  // using the modulus operator gets the remainder if the time roles over
  // we don't do this for hours because we want them to rollover
  // seconds = 81 -> minutes = 1, seconds = 21.
  // 60 minutes in an hour, 60 seconds in a minute, 1000 milliseconds in a second.
  minutes = showHours ? minutes % 60 : minutes;
  seconds = seconds % 60;
  // divide the milliseconds by 10 to get the tenths of a second. 543 -> 54
  const ms = Math.floor((milliseconds % 1000) / 10);

  let result = `${padStart(minutes)}:${padStart(seconds)}`;
  if (showHours) result = `${padStart(hours)}:${result}`;
  if (showMs) result += `.${padStart(ms)}`;

  return result;
};

const padStart = (num: number) => {
  return num.toString().padStart(2, '0');
};

export default function LiveMeetingTimer({
  showMilliseconds = false,
  showHours = false,
  showAfterSecond = 0,
  ...rest
}: Props) {
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const startTime = useRef<number>(0);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const { t } = useTranslation();

  useEffect(() => {
    startTime.current = Date.now();
    interval.current = setInterval(
      () => {
        setTimeElapsed(Math.floor(Date.now() - startTime.current));
      },
      showMilliseconds ? 10 : 100
    );
    return () => {
      clearInterval(interval.current);
      interval.current = undefined;
    };
  }, []);

  return (
    <Fade
      in={timeElapsed / 1000 > showAfterSecond}
      transition={{ enter: { duration: 4 } }}
    >
      <Box
        bgColor="primary"
        mt="6px"
        p="5px"
        background="transparent"
        border="1px solid #FF7F68"
        borderRadius="5"
        width="200px"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...rest}
      >
        <Timer />
        <Heading
          paddingLeft="8px"
          color="primary"
          fontSize="14px"
          fontWeight="700"
          fontStyle="normal"
          lineHeight="20px"
        >
          {t('meeting.header.timer')}{' '}
          {formatMs(timeElapsed, showMilliseconds, showHours)}
        </Heading>
      </Box>
    </Fade>
  );
}
