import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import CaseListView from './CaseListView';
import { PageLoading } from '@/ui/PageLoading';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import { useMemo } from 'react';
import { AdminCaseListRowType } from './columns';

export default function CaseListClient() {
  const caseList = useCaseList();
  const caseIds = useMemo(() => {
    if (!caseList) return [];
    return caseList.map((el) => el.id);
  }, [caseList]);
  const caseContents = useCaseContent(caseIds);

  const data = useMemo(() => {
    if (caseContents.isCaseLoading) return [];
    return Object.keys(caseContents.caseMap).reduce((acc, curr) => {
      const caseData = caseContents.caseMap[curr];
      acc.push({
        ...caseData,
        numberOfSlides: caseData.slides.length,
      });
      return acc;
    }, [] as AdminCaseListRowType[]);
  }, [caseContents]);

  if (!caseList) {
    return <PageLoading />;
  }
  return <CaseListView data={data} unloadedCases={caseList} />;
}
