import { Meeting } from '@/services/API';
import { UserType } from '@/utils/types/types';

export default function shouldActivateUserListFeature(
  meeting: Meeting | undefined,
  userType: UserType
) {
  if (!meeting?.streamUrl) return false;
  return userType === UserType.ADMIN;
}
