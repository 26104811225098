import getHourTimestamp from '@/utils/helpers/getHourTimestamp';
import { PoweredByDisclaimer } from '@/utils/types/zod/disclaimerTypes/poweredByDisclaimerSchema';
import { Fade, Flex, Image } from '@chakra-ui/react';

type Props = {
  disclaimer: PoweredByDisclaimer;
  assetPath: string;
};

function PoweredByDisclaimer({ disclaimer, assetPath }: Props) {
  return (
    <>
      <Flex
        className="powered"
        justifyContent="center"
        color="grey"
        fontWeight="bold"
      >
        {disclaimer.poweredBy}
      </Flex>
      <Flex
        justifyContent="center"
        marginLeft="-25px"
        marginRight="-25px"
        paddingTop="20px"
        rowGap="50px"
      >
        {disclaimer.logos.map((logoPath) => (
          <Fade
            key={logoPath} // use the url as key to force a re-render of the fade
            in={true}
            transition={{ enter: { duration: 1 } }}
          >
            <Image
              height="80px"
              objectFit="contain"
              key={logoPath}
              alt="logo"
              src={`${assetPath + logoPath}?t=${getHourTimestamp()}`}
            />
          </Fade>
        ))}
      </Flex>
      <Flex
        maxWidth="800px"
        textAlign="center"
        paddingTop="10%"
        fontSize="2em"
        className="title"
      >
        {disclaimer.title}
      </Flex>
      <Flex
        position="absolute"
        bottom=" 0px"
        paddingTop=" 50px"
        fontSize="0.8em"
        flexFlow="row wrap"
        className="disclaimer"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Flex flex="0 0 45.833%" display="block">
          <Flex flexFlow="row wrap">DISCLAIMER</Flex>
          <Flex>{disclaimer.disclaimer}</Flex>
        </Flex>
        <Flex textAlign="right" flex="0 0 45.833%">
          {disclaimer.revision}
        </Flex>
      </Flex>
    </>
  );
}

export default PoweredByDisclaimer;
