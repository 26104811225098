import {
  Answer,
  AnswersByMeetingIDQuery,
  OnCreateAnswerSubscription,
  OnDeleteAnswerSubscription,
  OnUpdateAnswerSubscription,
} from '@/services/API';
import { answersByMeetingID } from '@/services/graphql/queries';
import {
  onCreateAnswer,
  onDeleteAnswer,
  onUpdateAnswer,
} from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { useEffect, useMemo, useState } from 'react';

export function useAnswersByMeetingID(meetingID: string | null | undefined) {
  const [answerList, setAnswerList] = useState<Answer[]>([]);
  const [refreshTs, setRefreshTs] = useState(Date.now());

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!meetingID) return;
      try {
        const response = await callGraphQLApi<
          GraphQLResult<AnswersByMeetingIDQuery>
        >(answersByMeetingID, {
          meetingID,
          sortDirection: 'ASC',
        });
        const items = response?.data?.answersByMeetingID?.items as
          | Answer[]
          | undefined;
        if (items) {
          let nextToken = response?.data?.answersByMeetingID?.nextToken;

          while (nextToken) {
            const additionalData = await callGraphQLApi<
              GraphQLResult<AnswersByMeetingIDQuery>
            >(answersByMeetingID, {
              meetingID,
              nextToken,
            });
            if (!additionalData?.data?.answersByMeetingID?.items) break;
            items.push(
              ...(additionalData.data.answersByMeetingID.items as Answer[])
            );
            nextToken = additionalData.data?.answersByMeetingID?.nextToken;
          }
          setAnswerList(items);
        }
      } catch (error) {
        console.error(
          `error with useAnswersByMeetingID:${JSON.stringify(error)}`
        );
      }
    };
    fetchAnswers();
  }, [meetingID, refreshTs]);

  const subscriptions = useMemo(() => {
    if (!meetingID) return [];

    //TODO: filters don't work

    // const variables: OnCreateAnswerSubscriptionVariables = {
    //   filter: {
    //     meetingID: {

    //     }
    //   }
    // }
    return [
      {
        query: onCreateAnswer,
        variables: {},
        callback: (result: OnCreateAnswerSubscription) => {
          if (result.onCreateAnswer?.meetingID !== meetingID) return;
          setRefreshTs(Date.now());
        },
      },
      {
        query: onUpdateAnswer,
        variables: {},
        callback: (result: OnUpdateAnswerSubscription) => {
          if (result.onUpdateAnswer?.meetingID !== meetingID) return;
          setRefreshTs(Date.now());
        },
      },
      {
        query: onDeleteAnswer,
        variables: {},
        callback: (result: OnDeleteAnswerSubscription) => {
          if (result.onDeleteAnswer?.meetingID !== meetingID) return;
          setRefreshTs(Date.now());
        },
      },
    ];
  }, [meetingID]);

  useNewSubscriptions(subscriptions, 'useAnswersByMeetingID');

  return {
    answerList,
  };
}
