import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import mutationArchiveMeeting from '@/pages/admin/graphql/mutationArchiveMeeting';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useAppSelector } from '@/store/StoreHooks';
import { useEffect } from 'react';

export default function useAdminAutoArchiveMeetings() {
  const meetings = useAppSelector(MeetingSelectors.getMeetingList);
  const { isAdmin } = useUserAuthenticationContext();

  useEffect(() => {
    if (!isAdmin) return;
    meetings.forEach((meeting) => {
      if (meeting.isArchived) return;
      if (!meeting.endDate) return;
      if (new Date(meeting.endDate) < new Date()) {
        // archive meeting
        mutationArchiveMeeting(meeting.id);
      }
    });
  }, [meetings, isAdmin]);
}
