import useAllClientList from '@/features/clients/hooks/useAllClientList';
import AdminClientListView from './AdminClientListView';
import { PageLoading } from '@/ui/PageLoading';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { MeetingSelectors } from '@/store/slices/meeting';
import useAllClientsSubscription from '@/services/subscriptions/useAllClientsSubscription';
import { Client } from '@/services/API';
import { deleteAClient } from '@/store/thunk/clients';
import { useCallback } from 'react';

export default function AdminClientListClient() {
  const clientsList = useAllClientList();
  const meetingList = useAppSelector(MeetingSelectors.getMeetingList);
  const dispatch = useAppDispatch();
  useAllClientsSubscription();

  const handleDeleteClient = useCallback((client: Client) => {
    dispatch(deleteAClient(client.clientName));
  }, []);

  if (!clientsList) {
    return <PageLoading />;
  }
  return (
    <AdminClientListView
      data={clientsList}
      meetingList={meetingList}
      handleDeleteClient={handleDeleteClient}
    />
  );
}
