import {
  GetMeetingQuery,
  GetMeetingQueryVariables,
  MeetingStats,
} from '@/services/API';
import { getMeeting } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { GraphQLResult } from '@aws-amplify/api';
import { some } from 'lodash';

function isInteger(value: string): boolean {
  const parsed = parseInt(value, 10);
  return !isNaN(parsed) && Number.isInteger(parsed);
}

export async function migrateMeetingStatsV101(
  input: MeetingStats
): Promise<MeetingStats | null> {
  const targetVersion = '1.0.1';
  if (input.version !== '1.0.0') return null;
  // NOTE: check if any of the indexes are numbers or null
  const needsChange = some(
    input.casePresentationHistory.map((caseId) => {
      return isInteger(caseId) || caseId === null;
    })
  );
  const output = Object.assign({}, input);

  if (!needsChange) {
    output.version = targetVersion;
    return output;
  }

  const variablesGetMeeting: GetMeetingQueryVariables = {
    id: input.meetingID,
  };
  const meeting = await callGraphQLApi<GraphQLResult<GetMeetingQuery>>(
    getMeeting,
    variablesGetMeeting
  );
  if (!meeting.data?.getMeeting) {
    captureSentry({
      title: 'migrateMeetingStats: meeting not found',
      detail: {
        meetingID: input.meetingID,
        input,
      },
    });
    return null;
  }
  const caseCluster = meeting.data.getMeeting.caseCluster;

  if (!caseCluster) {
    captureSentry({
      title: 'migrateMeetingStats: caseCluster not found',
      detail: {
        meetingID: input.meetingID,
        input,
        caseCluster,
      },
    });
    return null;
  }

  //NOTE: remove all nulls and convert indexes to caseIds
  output.casePresentationHistory = input.casePresentationHistory
    .filter((el) => !!el)
    .map((caseId) => {
      if (isInteger(caseId)) {
        return caseCluster.cases[parseInt(caseId)];
      }
      return caseId;
    });
  output.version = targetVersion;
  return output;
}
