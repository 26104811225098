import { DeleteMessageMutation } from '@/services/API';
import { deleteMessage } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export default async function mutationDeleteOneMessage(id: string) {
  const result = await callGraphQLApi<GraphQLResult<DeleteMessageMutation>>(
    deleteMessage,
    {
      input: {
        id,
      },
    }
  );
  return result;
}
