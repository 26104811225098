import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { fetchCaseList } from '@/store/thunk/caseCluster';
import { useEffect } from 'react';
import { CaseClusterSelectors } from '../../../store/slices/caseCluster';

/**
 * retrieve the full list of cases available
 * this does not include the content of the cases (see public/patientCases/caseList.json)
 * content needs to be loaded on demand afterwards
 */
function useCaseList() {
  const caseList = useAppSelector(CaseClusterSelectors.caseListSelector);
  const dispatch = useAppDispatch();

  // fetch caseCluster list if not yet done
  useEffect(() => {
    if (!caseList) {
      dispatch(fetchCaseList());
    }
  }, [caseList]);

  return caseList;
}

export default useCaseList;
