import { useTranslation } from 'react-i18next';
import { useState, Fragment } from 'react';
import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import Rate from '@/ui/Rate/Rate';

export const NpsRatingBox = ({
  userIndex,
  onSubmit,
}: {
  userIndex: number;
  onSubmit: (rating: number) => void;
}) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(-1);
  const [hoverRating, setHoverRating] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);

  // --------------------- handlers ------------------------

  const onMouseOver = (rate: number) => {
    if (!submitted) {
      setHoverRating(rate);
    }
  };
  const onMouseOut = () => setHoverRating(0);
  const onRateClick = (rate: number) => {
    if (!submitted) {
      setRating(rate);
    }
  };
  const handleSubmit = () => {
    setSubmitted(true);
    setTimeout(() => {
      onSubmit(rating * 2);
    }, 3000);
  };

  // --------------------- render ------------------------

  return (
    <VStack padding="20px" margin="10px">
      <Box
        display="flex"
        p="5px"
        gap="25px"
        id="rate-meeting-star-container"
        pointerEvents={submitted ? 'none' : 'auto'}
      >
        {Array.from({ length: 5 }, (v, i) => (
          <Fragment key={i}>
            <Rate
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onRateClick={onRateClick}
              i={i}
              hoverRating={hoverRating}
              rating={rating}
            />
          </Fragment>
        ))}
      </Box>

      <Flex justifyContent="center" pt="50px">
        {submitted ? (
          <HStack
            fontWeight="bold"
            fontSize="2em"
            color="livelinx.green"
            spacing="10px"
          >
            <Fade in transition={{ enter: { duration: 2 } }}>
              <Text>{t('npsScore.vote.thank', { num: userIndex })}</Text>
            </Fade>
          </HStack>
        ) : (
          <Button
            h="50px"
            visibility={rating === -1 ? 'hidden' : 'visible'}
            variant="primary"
            onClick={handleSubmit}
            isDisabled={rating === -1 ? true : false}
          >
            {t('npsScore.vote.button')}
          </Button>
        )}
      </Flex>
    </VStack>
  );
};
