import PageLayout from '@/ui/Layout/PageLayout';
import {
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { InfoIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import useCasePreviewLinks from '../admin/components/CaseList/CasePreviewLinkModal/hooks/useCasePreviewLinks';
import { PreviewCaseAccessLinkPayload } from '@/utils/types/types';
import { ROUTES } from '@/routes/Routes';
import { useNavigate } from 'react-router-dom';
import { LivelinxLogo } from '@/ui/Logo';

type FormData = {
  accessCode: string;
};

export default function PreviewCaseCodeView() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const caseList = useCaseList();
  const links = useCasePreviewLinks(null);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    if (!caseList || caseList.length === 0) {
      toast({
        title: t('previewCasePage.toastErrorTitle'),
        description: t('previewCasePage.toastTechnicalErrorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!links || links.isLoading || links.data?.length === 0) {
      toast({
        title: t('previewCasePage.toastErrorTitle'),
        description: t('previewCasePage.toastTechnicalErrorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    const link = links.data?.find(
      (elem) => elem.accessCode === formData.accessCode
    );

    if (!link) {
      toast({
        title: t('previewCasePage.toastErrorTitle'),
        description: t('previewCasePage.toastInvalidCodeErrorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    //Now we need to retrieve the unloaded patient case from the link
    const data: PreviewCaseAccessLinkPayload = JSON.parse(link.data || '{}');
    const isLinkStillValid =
      data.validUntil === 0 || new Date(data.validUntil) > new Date();

    if (!isLinkStillValid) {
      setIsLoading(false);
      toast({
        title: t('previewCasePage.toastErrorTitle'),
        description: t('previewCasePage.toastExpiredCodeErrorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const linkCase = caseList.find((elem) => elem.id === data.caseId);

    if (!linkCase) {
      setIsLoading(false);
      toast({
        title: t('previewCasePage.toastErrorTitle'),
        description: t('previewCasePage.toastTechnicalErrorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(false);
    navigate(`${ROUTES.PREVIEW_CASE}/${formData.accessCode}`);
  };
  return (
    <PageLayout showHeader={false}>
      <HStack padding={4}>
        <Link to={ROUTES.HOME} reloadDocument>
          <LivelinxLogo />
        </Link>
      </HStack>
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
      >
        <Card maxW={{ base: '80%', md: '40%' }} width="100%" px={4}>
          <CardBody>
            <Text fontSize="2xl" width="100%" textAlign="center">
              {t('previewCasePage.title')}
            </Text>
            <Divider my={4} width="100%" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex width="100%" justifyContent="center">
                <VStack
                  gap="10px"
                  pb="10px"
                  maxW={{ base: '100%', md: '75%' }}
                  width="100%"
                >
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.accessCode)}
                  >
                    <HStack alignItems="top">
                      <FormLabel fontSize="sm" fontWeight="normal">
                        {t('previewCasePage.form.accessCode.label')}
                      </FormLabel>
                      <Tooltip
                        label={t('previewCasePage.form.accessCode.tooltip')}
                      >
                        <InfoIcon
                          color="gray"
                          position="relative"
                          left={-3}
                          top={1}
                          boxSize={3}
                        />
                      </Tooltip>
                    </HStack>
                    <Input
                      type="text"
                      {...register('accessCode', { required: true })}
                      placeholder="ex: vs9asilt4xk"
                    />
                    {errors.accessCode && (
                      <FormHelperText color="red">
                        {t('previewCasePage.form.accessCode.error')}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Button
                    onClick={handleSubmit(onSubmit)}
                    p="2"
                    width="full"
                    background="blue.400"
                    color="white"
                    variant="solid"
                    isDisabled={
                      isLoading || links.isLoading || caseList?.length === 0
                    }
                  >
                    {t('previewCasePage.form.submit.label')}
                  </Button>
                </VStack>
              </Flex>
            </form>
          </CardBody>
        </Card>
      </Flex>
    </PageLayout>
  );
}
