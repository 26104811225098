import { Heading, Flex, Button } from '@chakra-ui/react';

type Props = {
  error: { message?: string };
  resetErrorBoundary: () => void;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  return (
    <Flex
      direction="column"
      width="100%"
      height="60vh"
      alignItems="center"
      justifyContent="center"
    >
      <Heading color="red">Something went wrong:</Heading>
      <pre>{error.message}</pre>
      <Button variant="ghost" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </Flex>
  );
};
