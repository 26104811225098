import EndCallIcon from '@/assets/images/end_call.svg';
import RefreshIcon from '@/assets/images/refresh_icon.svg';
import CaseSlideNavigator from '@/pages/caseCluster/CaseSlideNavigator';
import { Box, Flex, Heading, IconButton } from '@chakra-ui/react';
import Popconfirm from '@/ui/PopConfirm/PopConfirm';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import isSlideAnamneseSlide from '@/utils/types/type-validation/isSlideAnamneseSlide';
import { CustomSlideIndex } from '@/utils/types/enums';
import LiveMeetingInteractionsMenu from './LiveMeetingInteractionsMenu/LiveMeetingInteractionsMenu';

type Props = {
  isHost: boolean;
  isAdmin: boolean;
  isAllowedToNavigate: boolean;
  leaveWarningTitle: string;
  // onMuteToggle?: () => void;
  // onCameraToggle?: () => void;
  // onRaiseHand?: () => void;
  // onShareScreen?: () => void;
  // onMoreOption?: () => void;
  onLeaveMeeting?: () => void;
  onRefresh?: () => void;
};

// --------------------- comp ------------------------
const LiveMeetingBar = ({
  isHost,
  isAdmin,
  // onMuteToggle,
  // onCameraToggle,
  // onRaiseHand,
  // onShareScreen,
  // onMoreOption,
  leaveWarningTitle,
  onLeaveMeeting,
  onRefresh,
  isAllowedToNavigate,
}: Props) => {
  const { currentCase, meeting, slideIndex, meetingInteractions, isPreview } =
    useCurrentMeetingDataContext();
  const { user } = useUserAuthenticationContext();
  // const currentSlide = currentCase?.slides?.[meeting?.currentSlide as number];
  const patientCaseIndex = currentCase?.slides.findIndex(isSlideAnamneseSlide);
  const completion =
    currentCase && slideIndex && currentCase.slides
      ? slideIndex / (currentCase?.slides?.length - 1)
      : 0;

  const onSlideChange = (slide: number) => {
    meetingInteractions.updateCurrentSlide(meeting?.id, slide);
  };
  const onHomeClick = () => {
    meetingInteractions.updateCurrentCase(meeting?.id, undefined);
  };

  return (
    <Flex
      height={isHost ? '68px' : '55px'}
      width="100%"
      maxW="100%"
      overflow="hidden"
      backgroundColor={isHost ? 'livelinx.purple75' : 'white'}
      justifyContent="space-between"
      boxShadow="1px -1px 5px 5px #5e00f611"
      gap="10px"
      pl={isHost ? '5px' : '10px'}
    >
      {currentCase && currentCase.slides && isAllowedToNavigate && (
        <>
          {isHost ? (
            <CaseSlideNavigator
              slideIndex={slideIndex as number}
              slides={currentCase.slides}
              onSlideChange={(slide) => {
                onSlideChange(slide);
              }}
              onHomeClick={() => {
                onHomeClick();
              }}
              isHost={isHost}
            />
          ) : (
            <>
              {/* progress bar */}
              <Box
                position="absolute"
                left="0"
                height="2px"
                bgColor="livelinx.blue100"
                w={`${completion * 100}%`}
                transition="all 0.5s ease-out"
              />

              <Flex alignItems="center" pl="10px">
                <Heading
                  color="livelinx.lightgray"
                  fontSize="16px"
                  verticalAlign="middle"
                >
                  {slideIndex == patientCaseIndex
                    ? 'Patient Profile'
                    : slideIndex === CustomSlideIndex.NPS_SCORE
                    ? 'Rating Screen'
                    : patientCaseIndex !== undefined && patientCaseIndex > -1
                    ? `Slide ${slideIndex} of ${currentCase.slides.length - 1}`
                    : `Slide ${(slideIndex || 0) + 1} of ${
                        currentCase.slides.length
                      }`}
                </Heading>
              </Flex>
              {/* 
              // Slide timeline (stepper) is for now disabled, but might be used later
              <Stepper steps={buttonSlides} activeStep={slideIndex as number} /> 
              */}
            </>
          )}
        </>
      )}

      <Flex
        marginLeft="auto"
        alignItems="center"
        px={4}
        gap={4}
        flexShrink={0}
        minW="max-content"
      >
        {/*  --------------------- ADMIN: live interaction menu ------------------------ */}
        {isAdmin && meeting && (
          <Flex justifyContent="right" alignItems="center">
            <LiveMeetingInteractionsMenu
              buttonProps={{ size: 'sm' }}
              meeting={meeting}
            />
          </Flex>
        )}
        {/*  --------------------- Refresh button ------------------------ */}
        {onRefresh && meeting?.meetingType === MeetingTypes.LIVE && (
          <IconButton
            size="md"
            borderRadius="full"
            aria-label="Refresh Button"
            border="2px solid"
            color={isHost ? 'primary' : 'livelinx.lightgray'}
            _hover={{
              transform: 'scale(1.1)',
              transition: 'all 0.2s ease-out',
            }}
            onClick={() => onRefresh()}
            icon={<RefreshIcon />}
          />
        )}

        {/* NOTE: If it's a livemeeting, then the user needs to be host to have the button */}
        {/* NOTE: If we're in sandbox, we don't show the leave btn */}
        {meeting &&
          meeting.meetingType !== MeetingTypes.LIVE &&
          !meeting.isInTesting &&
          !isPreview &&
          onLeaveMeeting &&
          user && (
            <Popconfirm
              title={leaveWarningTitle}
              onConfirm={() => {
                onLeaveMeeting();
              }}
            >
              <IconButton
                borderRadius="full"
                aria-label="EndCall Button"
                border="2px solid"
                borderColor="primary"
                background="primary"
                _hover={{
                  transform: 'scale(1.1)',
                  transition: 'all 0.2s ease-out',
                }}
                my={2}
                icon={<EndCallIcon />}
              />
            </Popconfirm>
          )}
      </Flex>
    </Flex>
  );
};

export default LiveMeetingBar;
