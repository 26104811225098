import RateMeetingHostView from './RateMeetingHostView';
import RateMeetingUserView from './RateMeetingUserView';

type Props = {
  isHost: boolean;
};

function RateMeeting({ isHost }: Props) {
  if (isHost) {
    return <RateMeetingHostView />;
  }
  return <RateMeetingUserView />;
}

export default RateMeeting;
