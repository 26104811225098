import {
  Box,
  Input,
  InputProps,
  forwardRef,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';

type FormInputProps = {
  label: string;
  errorMessage: any;
} & InputProps;

const FormInputItem = forwardRef<FormInputProps, 'input'>(
  ({ label, errorMessage, ...inputProps }, ref) => (
    <FormControl isInvalid={errorMessage} isRequired={inputProps.required}>
      <Box padding="10px 0px" w="full">
        <FormLabel>{label}</FormLabel>
        <Input
          ref={ref}
          {...inputProps}
          /* NOTE: this is so that chakra would not should an english message but the field is still required in reality
          because of the formControl */
          required={false}
        />
        {errorMessage && <Text color="red">{errorMessage}</Text>}
      </Box>
    </FormControl>
  )
);

export default FormInputItem;
