import { useUserList } from '@/services/hooks/useUserList';
import { AdminUserListView } from './AdminUserListView';
import { MOCK_USER_PREFIX } from '@/features/meeting-stats/mocking-tool/mockUser/users.create';
import { User } from '@/services/API';
import useUserListClients from '@/services/hooks/useUserListClients';

export const AdminUserListClient = () => {
  const { userListToClientNames } = useUserListClients();
  const userList: User[] = useUserList()
    .filter((el) => !el.email.startsWith(MOCK_USER_PREFIX))
    .map((el) => {
      return {
        ...el,
        last_seen: el.last_seen ?? el.createdAt,
      };
    });
  return (
    <AdminUserListView
      userList={userList}
      userListToClientNames={userListToClientNames}
    />
  );
};
