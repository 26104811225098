import {
  DeleteAnswerMutation,
  ListAnswersQuery,
  ListAnswersQueryVariables,
} from '@/services/API';
import { deleteAnswer } from '@/services/graphql/mutations';
import { listAnswers } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export async function queryListAnswersForMeeting(meetingID: string) {
  const variables: ListAnswersQueryVariables = {
    filter: {
      meetingID: { eq: meetingID },
    },
  };
  const answersForThisMeeting = await callGraphQLApi<
    GraphQLResult<ListAnswersQuery>
  >(listAnswers, variables);

  let nextToken = answersForThisMeeting.data?.listAnswers?.nextToken;

  //Note: Since there can be a huge amount of answers ( > 100)
  //we're implementing this logic to get all answers
  while (nextToken) {
    const variables2: ListAnswersQueryVariables = {
      nextToken,
      filter: {
        meetingID: { eq: meetingID },
      },
    };
    const additionalData = await callGraphQLApi<
      GraphQLResult<ListAnswersQuery>
    >(listAnswers, variables2);
    if (
      !answersForThisMeeting.data?.listAnswers?.items ||
      !additionalData.data?.listAnswers?.items
    )
      break;
    answersForThisMeeting.data.listAnswers.items = [
      ...answersForThisMeeting.data.listAnswers.items,
      ...additionalData.data.listAnswers.items,
    ];
    nextToken = additionalData.data?.listAnswers?.nextToken;
  }
  return answersForThisMeeting;
}

export async function mutationDeleteAllAnswersForMeeting(meetingID: string) {
  const answersForThisMeeting = await queryListAnswersForMeeting(meetingID);
  const answersDeletionPromises =
    answersForThisMeeting.data?.listAnswers?.items?.map(async (answer) => {
      if (!answer?.id) return;
      return mutationDeleteAnswer(answer.id);
    }) ?? [];
  const resolvedPromises = await Promise.all(answersDeletionPromises);
  return resolvedPromises;
}

export async function mutationDeleteAnswer(id: string) {
  return callGraphQLApi<GraphQLResult<DeleteAnswerMutation>>(deleteAnswer, {
    input: { id },
  });
}
