import { StringToColor } from '@/utils/helpers/color.helper';
import { Avatar, Spinner, Tooltip } from '@chakra-ui/react';

type Props = {
  username: string;
  variant?: 'md' | 'lg' | 'sm' | 'xs';
};

export const UserIcon = ({ username, variant = 'sm' }: Props) => {
  if (!username) {
    return <Spinner />;
  }

  const getInitials = (name: string) => {
    // TODO: review this rule to use the first letter and the last number if the last char is a number
    // be sure to remove email part
    let displayName = name.toUpperCase().split('@')[0];

    if (displayName.includes('IPAD'))
      displayName = displayName.split('IPAD')[1];
    else if (displayName.includes('BAYER'))
      displayName = `${displayName.split('BAYER')[1]}`;
    else if (displayName.includes('NOVO'))
      displayName = `${displayName.split('NOVO')[1]}`;
    else if (displayName.includes('P01'))
      displayName = `${displayName.split('P01')[1]}`;
    else if (displayName.includes('P02'))
      displayName = `${displayName.split('P02')[1]}`;
    else if (displayName.includes('H01'))
      displayName = `H${displayName.split('H010')[1]}`;
    else if (displayName.includes('H02'))
      displayName = `H${displayName.split('H020')[1]}`;
    else displayName = displayName.substring(0, 2);
    return displayName;
  };

  return (
    <Tooltip label={username}>
      <Avatar
        color="#FFF"
        name={username}
        getInitials={getInitials}
        backgroundColor={StringToColor(username)}
        size={variant}
      ></Avatar>
    </Tooltip>
  );
};

export default UserIcon;
