import {
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
} from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export default async function mutationArchiveMeeting(meetingID: string) {
  const variables: UpdateMeetingMutationVariables = {
    input: {
      id: meetingID,
      isArchived: true,
    },
  };
  await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(
    updateMeeting,
    variables
  );
}
