import {
  ListCaseStatsQuery,
  ListCaseStatsQueryVariables,
} from '@/services/API';
import { listCaseStats } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import mutationResetCaseStats from './utils/mutationResetCaseStats';

/*
Reponsible for resetting the stats without deleting the caseStats objects and other
objects that shouldn't be deleted.
  - caseStats
*/
export default async function pureResetCaseStatsForMeeting(meetingID: string) {
  const variables: ListCaseStatsQueryVariables = {
    filter: {
      meetingID: {
        eq: meetingID,
      },
    },
  };
  const result = await callGraphQLApi<GraphQLResult<ListCaseStatsQuery>>(
    listCaseStats,
    variables
  );
  const caseStatsList = result.data?.listCaseStats?.items;

  if (!caseStatsList) {
    console.log('No case stats to reset');
    return;
  }
  // Reset all caseStats
  const promiseList: Promise<void>[] = [];
  for (const caseStats of caseStatsList) {
    if (!caseStats) continue;
    promiseList.push(mutationResetCaseStats(caseStats.id));
  }

  await Promise.all(promiseList);
}
