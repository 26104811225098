import {
  ListCaseAssetViewsQuery,
  ListCaseAssetViewsQueryVariables,
  ListCaseSlideStatsQuery,
  ListCaseStatsQuery,
  ListMeetingNPSScoresQuery,
  ListMeetingNPSScoresQueryVariables,
  NpsScoresByMeetingQuery,
  NpsScoresByMeetingQueryVariables,
} from '@/services/API';
import {
  listCaseAssetViews,
  listCaseSlideStats,
  listCaseStats,
  listMeetingNPSScores,
  npsScoresByMeeting,
} from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { customGetCaseStats, CustomGetCaseStatsQuery } from './gql';

export async function queryListAssetCaseStats() {
  const result = await callGraphQLApi<GraphQLResult<ListCaseStatsQuery>>(
    listCaseStats,
    {}
  );
  return result.data;
}

export async function queryListCaseAssetViews() {
  const variables: ListCaseAssetViewsQueryVariables = {
    limit: 100000,
  };
  const result = await callGraphQLApi<GraphQLResult<ListCaseAssetViewsQuery>>(
    listCaseAssetViews,
    variables
  );
  return result.data;
}

export async function queryListCaseSlideStats() {
  const result = await callGraphQLApi<GraphQLResult<ListCaseSlideStatsQuery>>(
    listCaseSlideStats,
    {}
  );
  return result.data;
}

export async function getCustomCaseStats(id: string) {
  const result = await callGraphQLApi<GraphQLResult<CustomGetCaseStatsQuery>>(
    customGetCaseStats,
    {
      id,
    }
  );
  return result;
}

export async function queryListNpsScoresForMeeting(meetingID: string) {
  const variables: NpsScoresByMeetingQueryVariables = {
    meetingID,
  };
  const result = await callGraphQLApi<GraphQLResult<NpsScoresByMeetingQuery>>(
    npsScoresByMeeting,
    variables
  );
  return result;
}

export async function checkIfUserHasCreatedNPS(
  meetingID: string,
  userID: string
) {
  const variables: ListMeetingNPSScoresQueryVariables = {
    filter: {
      meetingID: {
        eq: meetingID,
      },
      userID: {
        eq: userID,
      },
    },
  };
  const result = await callGraphQLApi<GraphQLResult<ListMeetingNPSScoresQuery>>(
    listMeetingNPSScores,
    variables
  );

  return (result.data?.listMeetingNPSScores?.items?.length || 0) > 0;
}
