import * as Sentry from '@sentry/browser';
import { User, UserClient } from '@/services/API';
import useUserClients from '@/services/hooks/useUserClients';
import { CognitoUserGroups } from '@/utils/helpers/cognitoUserGroups';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useQuery } from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { CognitoMetaData, getCognitoData } from '../utils/getCognitoData';
import { getOrCreateUserData } from '../utils/getOrCreateUserData';

type Props = {
  children: React.ReactNode;
};

type UserAuthenticationContextType = {
  isUserLoading: boolean;

  email: string;
  isTemporaryUser: boolean;
  userCognitoGroups: CognitoUserGroups[];
  isAdmin: boolean;

  user: User | null | undefined;
  userID: string | undefined;
  userClients: UserClient[];
  createUserIfNecessary: () => Promise<void>;
  refetchUser: () => Promise<void>;
};

export const UserAuthenticationContext = createContext<
  UserAuthenticationContextType | undefined
>(undefined);

export default function UserAuthenticationContextProvider({ children }: Props) {
  const { route } = useAuthenticator((context) => [context.route]);
  const [shouldCreateTemporaryUser, setShouldCreateTemporaryUser] =
    useState<boolean>(false);

  //Step 1: Get Cognito MetaData
  const cognitoUserQuery = useQuery({
    queryKey: ['cognitoUser'],
    refetchOnWindowFocus: false,
    queryFn: getCognitoData,
  });

  //Step 2: Get Our database user if doesn't exist create one
  const userQuery = useQuery({
    queryKey: ['user', cognitoUserQuery.data, shouldCreateTemporaryUser],
    refetchOnWindowFocus: false,
    enabled: !!cognitoUserQuery.data,
    queryFn: async () => {
      //Note: we're allowed to cast here since react query will make sure that the data is loaded with the enabled property
      const user = await getOrCreateUserData(
        cognitoUserQuery.data?.cognitoUserId as string,
        cognitoUserQuery.data as CognitoMetaData,
        shouldCreateTemporaryUser
      );
      console.log('Auth | Result from userQuery: ', user);
      return user;
    },
  });

  const isLoading = cognitoUserQuery.isLoading || userQuery.isLoading;

  const email = cognitoUserQuery.data ? cognitoUserQuery.data.email : '';
  const isTemporaryUser = cognitoUserQuery.data
    ? cognitoUserQuery.data.isTemporary
    : false;
  const userCognitoGroups = cognitoUserQuery.data?.userGroups
    ? cognitoUserQuery.data.userGroups
    : [];
  const isAdmin = userCognitoGroups.includes(CognitoUserGroups.ADMIN);
  const user = userQuery.data;
  const userID = user ? user.id : undefined;

  const userClients = useUserClients({
    userID,
    live: true,
  });

  const createUserIfNecessary = useCallback(async () => {
    if (user || isLoading) return;
    console.log('Auth | Updating shouldCreateTempUser to trigger refetch.');
    setShouldCreateTemporaryUser(true);
  }, [user, isLoading]);

  const refetchUser = useCallback(async () => {
    await userQuery.refetch();
  }, []);

  //Detect signin
  useEffect(() => {
    if (route === 'authenticated' || route === 'verifyUser') {
      cognitoUserQuery.refetch();
    }
  }, [route]);

  const authContextValue = {
    isUserLoading: isLoading,
    email,
    isTemporaryUser,
    userCognitoGroups,
    isAdmin,
    user,
    userID,
    userClients,
    createUserIfNecessary,
    refetchUser,
  };

  Sentry.setUser({
    id: userID,
    ...authContextValue,
  });

  return (
    <UserAuthenticationContext.Provider value={authContextValue}>
      {children}
    </UserAuthenticationContext.Provider>
  );
}

export const useUserAuthenticationContext = () => {
  const context = useContext(UserAuthenticationContext);
  if (context === undefined) {
    throw new Error(
      'useUserAuthenticationContext must be used within a UserAuthenticationContextProvider'
    );
  }
  return context;
};
