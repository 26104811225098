import BackButton from '@/features/meeting-stats/components/CaseHeader/BackButton';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import CarretLeft from '@/assets/images/CaretLeft.svg';
import CarretRight from '@/assets/images/CaretRight.svg';
import { useEffect, useState } from 'react';
import ThemeDistributionPage from './ThemeDistributionPage/ThemeDistributionPage';
import ChannelDistributionPage from './ChannelDistributionPage/ChannelDistributionPage';
import CurrentMeetingDataContextProvider from '@/features/meeting/context/CurrentMeetingDataContext';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { LOREAL_STATS_TUTORIAL_KEY } from '@/utils/constants/app.constants';

function LorealStatsPage() {
  const [runTutorial, setRunTutorial] = useState<boolean>(false);
  //TODO: This is a hack so that it doesn't go forward 2 steps
  const [skipNextStep, setSkipNextStep] = useState<boolean>(false);
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const tutorialSteps: Joyride.Step[] = [
    {
      target: '.header-title',
      content: `You are currently on the statistics page regarding the distribution of the user answers accross themes and products.`,
      disableBeacon: true,
    },
    {
      target: '.theme-selector',
      content:
        'You can view the user answer distribution accross the different themes. Now  in order to continue, select a theme for which you want to see more statistics.',
    },
    {
      target: '.brand-selector',
      content:
        'Here you can see the brands that were selected the most in the current selected theme. To continue, select one of the brands to see which products of that brand where the most popular',
    },
    {
      target: '.product-results',
      content:
        'In this section you can find the most selected products by your users for the selected theme and brand.',
    },
    {
      target: '.next-button',
      content:
        'You are now done with this page, go to the next page to continue the tutorial',
    },
    {
      target: '.promoter-stats-table',
      content:
        'On this page you can view which channels your users selected the most for each step (before, during and after the visit). You are done with the tutorial!',
    },
  ];

  const hiddenNextButtonSteps = [1, 2, 4];

  const handleThemeSelectedCallback = () => {
    console.log('theme selected');
    if (tutorialStep < 2) {
      setSkipNextStep(true);
      setTutorialStep((oldStep) => oldStep + 1);
    }
  };

  const handleBrandSelectedCallback = () => {
    console.log('brand selected');
    if (tutorialStep < 3) {
      setSkipNextStep(true);
      setTutorialStep((oldStep) => oldStep + 1);
    }
  };

  const pages = [
    {
      title: 'User answer distribution per brand per theme',
      renderComponent: (
        <ThemeDistributionPage
          themeSelectedCallback={handleThemeSelectedCallback}
          brandSelectedCallback={handleBrandSelectedCallback}
        />
      ),
    },
    {
      title: 'Channel distribution',
      renderComponent: (
        <CurrentMeetingDataContextProvider>
          <ChannelDistributionPage />
        </CurrentMeetingDataContextProvider>
      ),
    },
  ];

  const handlePageSwitch = (increment: number) => {
    const nextPage = currentPage + increment;
    if (nextPage < 0 || nextPage >= pages.length) return;
    setCurrentPage(nextPage);
  };

  const handleTutorialCallback = (data: Joyride.CallBackProps) => {
    console.log('tutorial', data);
    const { action, status, type } = data;

    if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
      if (!skipNextStep) setTutorialStep((oldStep) => oldStep + 1);
      else setSkipNextStep(false);
    }
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      localStorage.setItem(LOREAL_STATS_TUTORIAL_KEY, 'done');
      setRunTutorial(false);
    }
  };

  useEffect(() => {
    const result = !!localStorage.getItem(LOREAL_STATS_TUTORIAL_KEY) ?? false;
    setRunTutorial(!result);
  }, []);

  return (
    <Flex alignItems="center" flexDir="column">
      <Joyride
        run={runTutorial}
        stepIndex={tutorialStep}
        steps={tutorialSteps}
        callback={handleTutorialCallback}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideCloseButton={true}
        spotlightClicks={true}
        hideBackButton={true}
        styles={{
          options: {
            arrowColor: 'white',
            backgroundColor: 'white',
            overlayColor: 'rgba(0, 0, 0, 0.6)',
            primaryColor: '#000',
            textColor: 'black',
            width: 900,
            zIndex: 1000,
          },
          buttonNext: {
            visibility: hiddenNextButtonSteps.includes(tutorialStep)
              ? 'hidden'
              : 'visible',
          },
        }}
      />
      <Flex
        width="100%"
        position="relative"
        justifyContent="center"
        alignItems="center"
        paddingX="25px"
      >
        <BackButton position="absolute" left="25px" />
        <Flex
          className="header-page-controller"
          maxWidth="60%"
          minWidth="30%"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            variant="meetingStats"
            aria-label="previous"
            icon={<CarretLeft />}
            isDisabled={currentPage === 0}
            onClick={() => handlePageSwitch(-1)}
          />
          <Text
            className="header-title"
            color="livelinx.purple200"
            fontSize="28px"
            marginX="10px"
          >
            {pages[currentPage].title}
          </Text>
          <IconButton
            className="next-button"
            variant="meetingStats"
            aria-label="next"
            icon={<CarretRight />}
            isDisabled={currentPage === pages.length - 1}
            onClick={() => {
              handlePageSwitch(1);
              setSkipNextStep(true);
              setTutorialStep((oldStep) => oldStep + 1);
            }}
          />
        </Flex>
      </Flex>
      {pages[currentPage].renderComponent}
    </Flex>
  );
}

export default LorealStatsPage;
