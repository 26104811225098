import { getEnvironnementFromUrl } from '../helpers/getEnvironnementFromUrl';
import { IsLocalHost } from '../helpers/isLocalHost';

const IS_LOCALHOST = IsLocalHost();

const APP_CONSTANTS = {
  IS_LOCALHOST,
  ENVIRONNEMENT: getEnvironnementFromUrl(window.location.href),
  PATIENT_CASES_REPO_PATH: `${import.meta.env.VITE_ASSETS_REPO}/patientCases/`,
  SHOW_CALL_BUTTONS: false,
  DISCLAIMER_TIMEOUT: {
    localhost: 2000,
    default: 6000,
  },
  //in seconds
  USER_MEETING_PING_INTERVAL: 5,
  SHOW_ANIMATIONS: !import.meta.env.VITE_CYPRESS_SCREENSHOT,
};

export const NULL_ID_VALUE = 'NONE';
export const DEFAULT_HOST_VALUE = 'default';
export const DEFAULT_HOST_NAME = 'John Doe';

export const SYNCHRONISATION_CHECK_INTERVAL = 5000;

export default APP_CONSTANTS;

export const LOREAL_STATS_TUTORIAL_KEY = 'loreal_statsPage_tutorial_finished';

//NOTE: We are absolutely aware that it is not safe to store the token in the frontend.
// we are planning to remove this token once our backend is setup.
// The token has restricted access to only the lists it needs to view
export const CLICKUP_API_TOKEN = 'pk_88697910_WPGS0EN4HHZZYD28TJB0S5IK62Z88S2W';
export const CLICKUP_LIST_IDS = {
  LIVE_EVENT_TEST: '901506536874',
};
