import { CASE_SELECTOR } from '@/features/meeting-stats/utils/constants';
import { NULL_ID_VALUE } from '../constants/app.constants';

export default function getUniquePresentedCases(
  casePresentationHistory: string[] | undefined | null
): string[] {
  if (!casePresentationHistory) return [];
  const uniqueCases = new Set(
    casePresentationHistory.filter(
      //NOTE: I think CASE_SELECTOR is never present, I think we use NULL_ID_VALUE
      (elem) => elem && elem !== CASE_SELECTOR && elem !== NULL_ID_VALUE
    )
  );
  return Array.from(uniqueCases);
}
