import { MessagesByChannelIDQuery } from '@/services/API';
import { messagesByChannelID } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export default async function queryListMessagesForMeeting(meetingID: string) {
  const response = await callGraphQLApi<
    GraphQLResult<MessagesByChannelIDQuery>
  >(messagesByChannelID, {
    channelID: meetingID,
    sortDirection: 'ASC',
  });
  return response;
}
