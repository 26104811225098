import {
  CreateAccessLinkInput,
  CreateAccessLinkMutation,
  CreateAccessLinkMutationVariables,
} from '@/services/API';
import { createAccessLink } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { AccessLinkType } from '@/utils/types/enums';
import { PreviewCaseAccessLinkPayload } from '@/utils/types/types';

export default async function createNewPreviewCaseAccessLink(
  data: PreviewCaseAccessLinkPayload
) {
  const newAccessLink: CreateAccessLinkInput = {
    purpose: AccessLinkType.CASE_PREVIEW,
    accessCode: Math.random().toString(36).substring(2, 18),
    data: JSON.stringify(data),
  };
  const variables: CreateAccessLinkMutationVariables = {
    input: newAccessLink,
  };
  const result = await callGraphQLApi<CreateAccessLinkMutation>(
    createAccessLink,
    variables
  );
  return result;
}
