/* eslint-disable react/jsx-key */
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  TableProps,
} from '@chakra-ui/react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTable, useSortBy, Column } from 'react-table';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  useSort?: boolean;
  size?: TableProps['size'];
  disableSortRemove?: boolean;
};

export default function DataTable<Data extends object>({
  data,
  columns,
  useSort,
  size = 'md',
  disableSortRemove,
}: DataTableProps<Data>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove,
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            const rowOneColumn = row1.values[columnName];
            const rowTwoColumn = row2.values[columnName];
            if (isNaN(rowOneColumn)) {
              return rowOneColumn
                .toUpperCase()
                .localeCompare(rowTwoColumn.toUpperCase());
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          },
        },
      },
      useSortBy
    );

  return (
    <Table
      {...getTableProps()}
      size={size}
      rounded="md"
      background="white"
      width="full"
      whiteSpace="pre-wrap"
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                key={column.id}
                {...(useSort
                  ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
                  : null)}
                isNumeric={column.isNumeric}
              >
                {column.render('Header')}
                <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FaChevronDown aria-label="sorted descending" />
                    ) : (
                      <FaChevronUp aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
