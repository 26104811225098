import { Meeting, User } from '@/services/API';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  VStack,
  Text,
  Divider,
  DrawerFooter,
  Button,
  Heading,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import useMeetingUserAccessForMeeting from '@/services/hooks/useMeetingUserAccessForMeeting';
import { AppSpinner } from '@/ui/AppSpinner';
import useUserClientsForUserList from '@/services/hooks/useUserClientsForUserList';
import ClientTag from '@/components/ClientTag/ClientTag';
import { useUserList } from '@/services/hooks/useUserList';

type Props = {
  meeting: Meeting | undefined;
  onClose: () => void;
};

const UserAccessList = ({ meeting, onClose }: Props) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  let userList: User[] = useUserList();
  userList = userList.filter((user) => !user.isTemporary && !user.isMock);
  const filteredUserList = searchFilter
    ? userList.filter((item) =>
        item.email.toLowerCase().includes(searchFilter.toLowerCase())
      )
    : userList;
  const {
    userAccessList,
    updateUserAccess,
    isLoading: isMeetingAccessLoading,
  } = useMeetingUserAccessForMeeting(meeting?.id);
  const memoizedUserIds = useMemo(() => {
    return userList.map((u) => u.id);
  }, [JSON.stringify(userList.map((user) => user.id))]);
  const userClientsPerUser = useUserClientsForUserList({
    userIDs: memoizedUserIds,
  });
  const usersWithAccess: string[] | undefined = userAccessList?.map(
    (item) => item.userID
  );
  const isLoading =
    isUpdating || isMeetingAccessLoading || userClientsPerUser.isLoading;

  // --------------------- handlers ------------------------

  const onUserAccessChange = async (userId: string, value: boolean) => {
    // alert('user access change:' + userId + ':' + value);
    setIsUpdating(true);
    await updateUserAccess(userId, value);
    //NOTE: doing this because otherwise it doesn't look good on the UI
    setTimeout(() => {
      setIsUpdating(false);
    }, 200);
  };

  // --------------------- render ------------------------
  const handleClose = () => {
    setSearchFilter('');
    onClose();
  };

  return (
    <Drawer
      isOpen={meeting !== undefined}
      placement="right"
      size="md"
      onClose={handleClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading pb="10px" size="md">
            {meeting?.name}
          </Heading>
          <Input
            placeholder={
              t('admin.meetings.list.userAccess.search.user') || undefined
            }
            size="sm"
            onChange={(target) => setSearchFilter(target.currentTarget.value)}
          />
        </DrawerHeader>
        <DrawerBody>
          <VStack p="10px" align="left" divider={<Divider />}>
            {/* <Text>
              {isMeetingAccessLoading ? 'true' : 'false'} && {userClientsPerUser.isLoading ? 'true' : 'false'}
            </Text> */}
            {(isMeetingAccessLoading || userClientsPerUser.isLoading) && (
              <AppSpinner />
            )}
            {!isMeetingAccessLoading &&
              !userClientsPerUser.isLoading &&
              filteredUserList.map((item) => {
                const userClients = userClientsPerUser.data[item.id];
                const userGroupsWithAccess = userClients
                  .filter((userClient) =>
                    meeting?.clientIDs.includes(userClient.clientID)
                  )
                  .map((elem) => elem.client?.clientName);
                const userHasGroupAccess = userGroupsWithAccess.length > 0;
                return (
                  <HStack
                    alignItems="center"
                    justifyContent="left"
                    key={item.id}
                    opacity={userHasGroupAccess ? 0.5 : 1}
                  >
                    <Checkbox
                      size="lg"
                      isDisabled={userHasGroupAccess}
                      isChecked={
                        userHasGroupAccess || usersWithAccess?.includes(item.id)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        onUserAccessChange(item.id, e.currentTarget.checked)
                      }
                    />
                    <Text userSelect="none">{item.email} </Text>
                    {userHasGroupAccess && (
                      <ClientTag client={userGroupsWithAccess.join(', ')} />
                    )}
                  </HStack>
                );
              })}
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Button
            isLoading={isLoading}
            variant="outline"
            mr={3}
            onClick={handleClose}
            w="full"
          >
            Exit
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UserAccessList;
