import { ParsedSlideStats } from '../parseSlideStatsPerCase';
import calculateLinearScore from './calculateLinearScore';

/*
Optimal Value: 4m30 per slide

Score Explanation:
  Starting from 0s to 4m15 gradual to 100%
  Stay at 100% up until 4m45
  Degradation to 0% starting from 4m45 to 9m
*/
export default function calculateTimePassedOnSlides(
  parsedSlideStats: ParsedSlideStats[]
) {
  const scorePerSlide = parsedSlideStats.map((slide) =>
    calculateLinearScore(slide.time, 0, 255, 285, 540)
  );
  const totalScore = scorePerSlide.reduce((acc, cur) => acc + cur, 0);
  const score = totalScore / parsedSlideStats.length;
  return score;
}
