import { Box, BoxProps, Text } from '@chakra-ui/react';

type Props = {
  progress: number;
  color: string;
  bgColor: string;
  width: string;
  height: string;
  showProgressTxt?: boolean;
  progressTxtColor?: string;
};

export default function ProgressBar({
  progress,
  color,
  bgColor,
  width,
  height,
  showProgressTxt = false,
  progressTxtColor = 'white',
  ...rest
}: Props & BoxProps) {
  return (
    <Box width={width} height={height} {...rest} position="relative">
      <Box
        backgroundColor={bgColor}
        width="100%"
        height="100%"
        position="absolute"
        borderRadius="10px"
      />
      <Box
        backgroundColor={color}
        width={`${progress}%`}
        height="100%"
        position="absolute"
        borderRadius="10px"
      />
      {showProgressTxt && progress !== 0 && (
        <Text
          position="absolute"
          color={progressTxtColor}
          left="5px"
          top="50%"
          transform="translateY(-50%)"
        >
          {Math.round(progress)}%
        </Text>
      )}
    </Box>
  );
}
