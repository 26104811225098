import { pureDeleteMeeting } from '@/backend/pureDeleteMeeting';
import {
  DeleteCaseClusterMutation,
  ListCaseClustersQuery,
  ListCaseClustersQueryVariables,
  ListMeetingsQuery,
  ListMeetingsQueryVariables,
} from '@/services/API';
import { deleteCaseCluster } from '@/services/graphql/mutations';
import { listCaseClusters, listMeetings } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export async function clearAllMockCaseClusters() {
  const variables: ListCaseClustersQueryVariables = {
    filter: {
      isMock: {
        eq: true,
      },
    },
  };
  const itemsToDelete = await callGraphQLApi<
    GraphQLResult<ListCaseClustersQuery>
  >(listCaseClusters, variables);
  const promises =
    itemsToDelete.data?.listCaseClusters?.items?.map(async (item) => {
      return callGraphQLApi<GraphQLResult<DeleteCaseClusterMutation>>(
        deleteCaseCluster,
        { input: { id: item?.id } }
      );
    }) ?? [];

  const resolvedPromises = await Promise.all(promises);
  console.log(`Deleted ${resolvedPromises.length} case clusters`);
}

export async function clearAllMockMeetings() {
  const variables: ListMeetingsQueryVariables = {
    filter: {
      isMock: {
        eq: true,
      },
    },
  };
  const itemsToDelete = await callGraphQLApi<GraphQLResult<ListMeetingsQuery>>(
    listMeetings,
    variables
  );
  const meetingDeletionPromises =
    itemsToDelete.data?.listMeetings?.items?.map(async (item) => {
      if (!item?.id) return;
      return pureDeleteMeeting(item?.id);
    }) ?? [];

  const resolvedMeetingDeletionPromises = await Promise.all(
    meetingDeletionPromises
  );

  let amtAnswersDeleted = 0;
  for (const stats of resolvedMeetingDeletionPromises) {
    if (!stats) continue;
    amtAnswersDeleted += stats.amtAnswersDeleted;
  }
  console.log(
    `Deleted ${amtAnswersDeleted} answers for a total of ${resolvedMeetingDeletionPromises.length} meetings`
  );
}
