import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import calculateGaussianScore from './calculateGaussianScore';
/*
Optimal value: 45% of the slides should be voting slides.
*/
export default function calculateVotingSlideRatio(slides: NewISlide[]) {
  const amtOfVotingSlides = slides.filter(isSlideQuestionSlide).length;
  const amtOfSlides = slides.length;
  const ratio = amtOfVotingSlides / amtOfSlides;
  const score = calculateGaussianScore(ratio, 0.45, 0.15);
  return score;
}
