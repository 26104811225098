import { createContext, useContext, useEffect, useState } from 'react';
import { ZenObservable } from 'zen-observable-ts';

type SubscriptionDebuggerContextType = {
  registerSubscription: (
    sub: ZenObservable.Subscription,
    customName: string
  ) => string;
  unRegisterSubscription: (id: string) => void;
  getSubscriptionsDetails: () => any;
};

export const SubscriptionDebuggerContext = createContext<
  SubscriptionDebuggerContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

export type DebugSubscription = {
  id: string;
  customName: string;
  sub: ZenObservable.Subscription;
};

function generateRandomId() {
  const getRandomHex = () =>
    Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, '0');
  const id = [getRandomHex(), getRandomHex(), getRandomHex()].join('-');
  return id.toUpperCase();
}

export default function SubscriptionDebuggerContextProvider({
  children,
}: Props) {
  const [subs, setSubs] = useState<DebugSubscription[]>([]);

  // --------------------- handlers ------------------------

  const registerSubscription = (
    sub: ZenObservable.Subscription,
    customName: string
  ) => {
    const newId = generateRandomId();
    setSubs((oldSubs) => [...oldSubs, { id: newId, customName, sub }]);
    return newId;
  };
  const unRegisterSubscription = (id: string) => {
    setSubs((oldSubs) => oldSubs.filter((sub) => sub.id !== id));
  };
  const getSubscriptionsDetails = () => ({
    detail: subs && JSON.stringify(subs),
  });

  // --------------------- effects ------------------------

  useEffect(() => {
    console.log('SUBS CHANGED, length: ', subs);
  }, [subs.length]);

  // --------------------- render ------------------------

  return (
    <SubscriptionDebuggerContext.Provider
      value={{
        registerSubscription,
        unRegisterSubscription,
        getSubscriptionsDetails,
      }}
    >
      {children}
    </SubscriptionDebuggerContext.Provider>
  );
}

export const useSubscriptionDebuggerContext = () => {
  const context = useContext(SubscriptionDebuggerContext);
  if (context === undefined) {
    throw new Error(
      'useSubscriptionDebuggerContext must be used within a SubscriptionDebuggerContextProvider'
    );
  }
  return context;
};
