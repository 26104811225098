import clamp from '@/utils/helpers/clamp';
import { ConsensusResult } from '../calculateConsensusRate';

export default function calculateVoteRate(
  amtUsers: number,
  consensusResults: ConsensusResult
) {
  const divider = amtUsers * consensusResults.amtSlides;
  //Note: We want them to be punished if they have no voting slides (which make the divider 0)
  if (divider === 0) return 0;
  return clamp(consensusResults.amtAnswers / divider, 0, 1);
}
