import { RootState } from '@/store/Store';
import { createSlice } from '@reduxjs/toolkit';
import { Client } from '../../services/API';
import type { PayloadAction } from '@reduxjs/toolkit';

// --------------------- State ------------------------

type IClientSlice = {
  clientList: Client[] | null;
  isLoading: boolean;
  error: string | null;
};

const initialState: IClientSlice = {
  clientList: null,
  isLoading: false,
  error: null,
};

// --------------------- State ------------------------

export const Slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.message;
      console.error('Error in clientsSlice: ', action.payload);
    },
    setClientsList: (state, action: PayloadAction<Array<Client>>) => {
      state.clientList = action.payload;
    },
  },
});

// --------------------- selectors ------------------------

const stateSelector: (rootState: RootState) => IClientSlice = (rootState) =>
  rootState.clients;
const clientsListSelector = (rootState: RootState) =>
  stateSelector(rootState).clientList;

// --------------------- exports ------------------------

const { actions } = Slice;
export { actions };
export const ClientsSelectors = {
  clientsListSelector,
};

export default Slice.reducer;
