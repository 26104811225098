import { TABS } from '@/utils/types/enums';
import ChatView from './chat/ChatView';
import ParticipantsView from './participants/ParticipantsView';
import { useEffect, useState, useCallback, useMemo } from 'react';
import DailyIframe from '@daily-co/daily-js';
import { useDaily, useLocalParticipant } from '@daily-co/daily-react';
import CallSettings from '@/features/dailyVideoApp/components/CallSetttings/CallSettings';
import { useVideoCall } from '@/features/call/useVideoCall';
import { Box } from '@chakra-ui/react';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import useMeetingParticipants from '@/features/meeting/hooks/useMeetingParticipantsList';
import useChatMessages from '@/features/call/useChatMessages';

type Props = {
  currentTab: string;
  siderCollapsed: boolean;
  closeMeetingSider: () => void;
  useChatMessageUtils: ReturnType<typeof useChatMessages>;
};

const LiveMeetingSider = ({
  currentTab,
  siderCollapsed,
  closeMeetingSider,
  useChatMessageUtils,
}: Props) => {
  const { user: currentUser } = useUserAuthenticationContext();
  const { meeting: currentMeeting } = useCurrentMeetingDataContext();
  const { state, initializeCall } = useVideoCall();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [hasJoined, setHasJoined] = useState<boolean>(false);
  const participant = useLocalParticipant();
  const prejoinMeeting = useDaily();
  // const participants = useParticipantIds({ filter: 'remote' });
  const meetingParticipantList = useMeetingParticipants(currentMeeting?.id);
  const sortedParticipants = useMemo(() => {
    return meetingParticipantList.sort((a, b) => {
      return a.email.localeCompare(b.email);
    });
  }, [meetingParticipantList]);

  const userID = currentUser?.id;
  const hostID = currentMeeting?.hostID;
  // TODO: find a way to make video meetings uniq, here if we split a meeting we might have duplicate
  const videoMeetingId = currentMeeting?.id.slice(
    0,
    currentMeeting?.id.length - 8
  );

  const closeSettingsPopUp = () => setShowSettings(false);

  const startPrejoin = useCallback(async () => {
    if (!prejoinMeeting) return;
    const newCallObject = DailyIframe.createCallObject({
      dailyConfig: { experimentalChromeVideoMuteLightOff: true },
    });
    await prejoinMeeting.startCamera();
    return newCallObject;
  }, []);

  const joinCall = useCallback(() => {
    console.log(`VIDEO CHAT STATE CHANGE:${state}`);
    if (!currentUser) return undefined;
    if (state === 'loaded' && videoMeetingId) {
      initializeCall(videoMeetingId, currentUser?.email as string);
      setShowSettings(false);
      setHasJoined(true);
    }
  }, [state, currentUser, videoMeetingId]);

  const handleGoBack = () => {
    setShowSettings(false);
    setHasJoined(false);
    closeSettingsPopUp();
    closeMeetingSider();
  };

  // effect to show settings if we click on video tab
  useEffect(() => {
    if (currentTab == TABS.VIDEO && !hasJoined) {
      setShowSettings(true);
      startPrejoin();
    }
  }, [currentTab]);

  // effect to leave meeting if we use the goback button
  useEffect(() => {
    async function leaveMeeting() {
      await prejoinMeeting?.leave();
    }
    if (!showSettings && !hasJoined && participant) leaveMeeting();
  }, [showSettings, hasJoined, participant]);

  if (showSettings) {
    return (
      <CallSettings
        isOpen={showSettings}
        onClose={closeSettingsPopUp}
        handleGoBack={handleGoBack}
        joinCall={joinCall}
      />
    );
  }

  return (
    <Box
      h="full"
      position="relative"
      zIndex="50"
      width={350}
      minWidth={350}
      background="livelinx.purple50"
      shadow="dark-lg"
    >
      {currentTab === TABS.USERS && hostID && userID && (
        <ParticipantsView
          participantsList={sortedParticipants}
          hostID={hostID}
          currentUserID={userID}
        />
      )}
      {currentTab === TABS.CHAT && (
        <ChatView
          useChatMessageUtils={useChatMessageUtils}
          isVisible={!siderCollapsed}
        />
      )}
      {/* {currentTab === TABS.VIDEO && <DailyVideoApp />}
      {hasJoined &&
        currentTab !== TABS.VIDEO &&
        participants.map((el) => (
          <Fragment key={el}>
            <DailyAudioTrack sessionId={el} />
          </Fragment>
        ))} */}
    </Box>
  );
};

export default LiveMeetingSider;
