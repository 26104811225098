import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { FaStar } from 'react-icons/fa';

type Props = {
  i: number;
  onRateClick: (rate: number) => void;
  rating: number;
  hoverRating: number;
  onMouseOut: () => void;
  onMouseOver: (value: number) => void;
};

const Rate = ({
  i,
  rating,
  hoverRating,
  onMouseOver,
  onMouseOut,
  onRateClick,
}: Props) => {
  const fill = useMemo(() => {
    const index = i + 1;
    if (hoverRating >= index || (!hoverRating && rating >= index))
      return '#ffea00';

    return '#d8d8d8';
  }, [rating, hoverRating, i]);
  return (
    <Box
      cursor="pointer"
      onMouseOver={() => onMouseOver(i + 1)}
      onMouseOut={onMouseOut}
      onClick={() => onRateClick(i + 1)}
    >
      <FaStar fill={fill} style={{ width: '8rem', height: '8rem' }} />
    </Box>
  );
};
export default Rate;
