import { Select, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (value: string) => void;
  options: string[];
};

const DEFAULT_VALUE = 'Select';

const SearchableSelectFilter = ({ onChange, options }: Props) => {
  const { t } = useTranslation();
  return (
    <VStack justifyContent="left" alignItems="flex-start">
      <Text>{t('tables.filters.searchableSelect.options.title')}</Text>
      <Select
        data-test="filter-searchable-select-input"
        placeholder={DEFAULT_VALUE}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </VStack>
  );
};

export default SearchableSelectFilter;
