import { ReactNode } from 'react';
import DownArrow from '@/assets/images/DownArrow.svg';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Box,
} from '@chakra-ui/react';
type Options = {
  value: string;
  label: string;
};
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  icon?: ReactNode;
  options?: Options[];
  disabled?: boolean;
};
export default function Select({
  value,
  onChange,
  icon,
  options,
  disabled,
}: Props) {
  return (
    <Menu>
      <MenuButton
        // whiteSpace="normal"
        disabled={disabled}
        width="220px"
        background="#FFF"
        border="1px solid"
        borderColor="livelinx.lightgray"
        borderRadius="100px"
        as={Button}
        position="relative"
        rightIcon={<DownArrow />}
      >
        <Box position="absolute" top="10px">
          {icon}
        </Box>
        <Text
          color="livelinx.purple200"
          fontSize="15px"
          lineHeight="20px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          overflow="hidden"
          marginLeft="24px"
        >
          {value}
        </Text>
      </MenuButton>
      <MenuList>
        {options?.map((data) => (
          <MenuItem
            minH="48px"
            key={`${data.label}-555-${data.value}`}
            onClick={() => onChange && onChange(data.value as string)}
          >
            <Text color="livelinx.purple200">{data.label}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
