import { Flex, FlexProps } from '@chakra-ui/react';

type Props = {
  isCurrentSelectedItem: boolean;
  onClick: () => void;
  children: React.ReactNode;
} & FlexProps;

export default function OptionContainer({
  isCurrentSelectedItem,
  onClick,
  children,
  ...rest
}: Props) {
  return (
    <Flex
      w="250px"
      h="100px"
      paddingX="20px"
      paddingY="10px"
      borderRadius="10px"
      border="2px solid"
      borderColor={isCurrentSelectedItem ? 'livelinx.purple100' : 'transparent'}
      boxShadow="lg"
      onClick={onClick}
      opacity={isCurrentSelectedItem ? 1 : 0.8}
      transition="all 0.3s ease-out"
      cursor="pointer"
      justifyContent="space-between"
      alignItems="center"
      flexDir="column"
      backgroundColor="white"
      marginY="5px"
      {...rest}
    >
      {children}
    </Flex>
  );
}
