import { AppDispatch } from '@/store/Store';
import { GraphQLResult } from '@aws-amplify/api';
import { deleteCaseCluster } from '@/services/graphql/mutations';
import { fetchCaseClusterList } from './fetchCaseClusterList';
import { actions } from '@/store/slices/caseCluster';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { DeleteCaseClusterMutation } from '@/services/API';

export function deleteACaseCluster(caseClusterID: string) {
  return async (dispatch: AppDispatch) => {
    const input = {
      id: caseClusterID,
    };
    try {
      await callGraphQLApi<GraphQLResult<DeleteCaseClusterMutation>>(
        deleteCaseCluster,
        {
          input,
        }
      );
      dispatch(fetchCaseClusterList());
    } catch (error) {
      dispatch(actions.setError(error));
    }
  };
}
