import { actions } from '@/store/slices/caseCluster';
import { AppDispatch } from '@/store/Store';
import { createCaseCluster } from '@/services/graphql/mutations';
import {
  CreateCaseClusterInput,
  CreateCaseClusterMutation,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export function createACaseCluster(name: string, cases: string[]) {
  return async (dispatch: AppDispatch) => {
    const input: CreateCaseClusterInput = {
      name,
      cases,
    };
    try {
      await callGraphQLApi<GraphQLResult<CreateCaseClusterMutation>>(
        createCaseCluster,
        { input }
      );
    } catch (error) {
      dispatch(actions.setError(error));
    }
  };
}
