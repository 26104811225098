import { Box, Icon, Text } from '@chakra-ui/react';
import ChunkContainer from './ChunkContainer';
import HalfCircleProgressBar from './HalfCircularProgressBar';
import getSmileyIcon from '../utils/getSmileyIcon';
import getColor from '../utils/getColor';
import { PROGRESS_SIZE_X, SMILEY_SIZE } from '../utils/constants';
import Title from './Title';

type Props = {
  percentage: number;
  title: string;
  onClick?: () => void;
};

export default function PercentageChunk({ percentage, title, onClick }: Props) {
  return (
    <ChunkContainer
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={onClick ? { transform: 'scale(1.05)' } : {}}
      transition={onClick ? 'transform 0.2s' : ''}
    >
      <Title text={title} />
      <Box
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HalfCircleProgressBar
          color={getColor(percentage)}
          bgColor="#FFFEFE"
          progress={percentage}
          progressSize={PROGRESS_SIZE_X}
        />
        <Icon
          zIndex="1"
          marginTop="50px"
          boxSize={`${SMILEY_SIZE}px`}
          as={getSmileyIcon(percentage)}
        />
        <Text
          zIndex="1"
          fontSize="65px"
          textAlign="center"
          fontFamily="poppins-bold"
          color="livelinx.purple200"
        >
          {percentage}%
        </Text>
      </Box>
    </ChunkContainer>
  );
}
