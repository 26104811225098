import { ROUTES } from '@/routes/Routes';
import { OnDeleteMeetingSubscription } from '@/services/API';
import { onDeleteMeeting } from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type Args = {
  meetingID: string | null | undefined;
};

export default function useNavigateHomeOnMeetingDeletion({ meetingID }: Args) {
  const navigate = useNavigate();

  const onMeetingDeletion = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  const subscriptions = useMemo(() => {
    if (!meetingID) return [];

    return [
      {
        query: onDeleteMeeting,
        variables: {},
        callback: (data: OnDeleteMeetingSubscription) => {
          if (data.onDeleteMeeting?.id === meetingID) onMeetingDeletion();
        },
      },
    ];
  }, [meetingID, onMeetingDeletion]);

  useNewSubscriptions(subscriptions, 'useNavigateHomeOnMeetingDeletion');
}
