import APP_CONSTANTS from '@/utils/constants/app.constants';
import { parseCaseAssets } from '@/utils/helpers/parseCaseAssets';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import axios from 'axios';
// import getParsedPatientCases from './getParsedPatientCases';

export async function fetchCaseData(
  caseIds: string[] | null,
  casePaths: string[] | null
): Promise<NewIPatientCase[]> {
  if (
    !casePaths ||
    casePaths.length === 0 ||
    !caseIds ||
    caseIds.length === 0
  ) {
    return [];
  }

  const timeStamp = new Date().getTime();
  const promises = casePaths.map(async (path) => {
    return axios.get<NewIPatientCase>(
      `${APP_CONSTANTS.PATIENT_CASES_REPO_PATH}${path}?t=${timeStamp}`
    );
  });
  const result = (await Promise.all(promises)).map((elem) => elem.data);

  const filtered = result.filter((item) => caseIds.includes(item.id));
  const parsedCases: NewIPatientCase[] = filtered.map((item) =>
    parseCaseAssets(item)
  );
  return parsedCases;
}
