import { z } from 'zod';
import { CaseSelectorItemOverrideType } from '../enums';

export const caseSelectorItemOverrideSchema = z.object({
  type: z.literal(CaseSelectorItemOverrideType.TEXT),
  title: z.string(),
  // backgroundColor: z.string(),
});

export type CaseSelectorItemOverride = z.infer<
  typeof caseSelectorItemOverrideSchema
>;
