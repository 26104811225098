import { pureCreateMeeting } from '@/backend/pureCreateMeeting';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import { CreateMeetingInput } from '@/services/API';
import { getMeetingDetails } from '../../utils/getMeetingDetails';

export default async function initializeMockMeeting(
  testID: number,
  caseClusterID: string,
  hostID: string
) {
  //create the meeting
  const meetingInput: CreateMeetingInput = {
    name: `meeting mocking - Test ${testID}`,
    caseClusterID: caseClusterID,
    hostID: hostID,
    eventDate: new Date().toISOString(),
    clientIDs: [],
    isMock: true,
    eventLocation: 'UZA Antwerpen',
    purchaseOrderNbr: 'PO123456789',
    isArchived: false,
    meetingNotes: '',
    currentSlide: 0,
    isInTesting: false,
    meetingType: MeetingTypes.LIVE,
  };
  const meetingID = await pureCreateMeeting(meetingInput);

  //get the meeting details such as the caseStats ids
  const meetingDetails = await getMeetingDetails(meetingID ?? '');

  return meetingDetails;
}
