import { Meeting, User } from '@/services/API';
import { motion, AnimatePresence } from 'framer-motion';

import { getUserType } from '@/utils/helpers/getUserType';
import shouldActivatePIPFeature from '@/utils/helpers/meeting/shouldActivatePIPFeature';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import logo from '@/assets/images/logo_main.png';
import useConditionalTimeout from '@/services/hooks/useConditionalTimeout';
import { MotionBox } from '@/ui/Motion/MotionBox';

type Props = {
  meeting: Meeting | undefined;
  user: User | undefined | null;
  isInPipMode: boolean;
  isHost: boolean;
  isAdmin: boolean;
};

const INTRO_DURATION = 6000;

function PictureInPictureFeature({
  meeting,
  user,
  isInPipMode,
  isHost,
  isAdmin,
}: Props) {
  const { t } = useTranslation();
  const [isMuted, setIsMuted] = useState(false);
  const activated = shouldActivatePIPFeature(
    meeting,
    getUserType(isHost, user?.isTemporary || false, isAdmin)
  );
  const [showIntroScreen, setShowIntroScreen] = useState(true);

  const streamUrl = meeting?.streamUrl;
  const shouldShow = useMemo(
    () => !!streamUrl && isInPipMode && activated,
    [streamUrl, isInPipMode, activated]
  );

  useConditionalTimeout(
    () => {
      setShowIntroScreen(false);
    },
    INTRO_DURATION,
    shouldShow
  );

  useEffect(() => {
    if (!shouldShow) setShowIntroScreen(true);
  }, [shouldShow]);

  if (!shouldShow || !streamUrl) {
    return null;
  }

  return (
    <Flex position="absolute">
      <Draggable bounds=".pip-container">
        {/* NOTE: This is the draggable object*/}
        <Flex
          height="30vh"
          aspectRatio={16 / 9}
          borderRadius={10}
          overflow="hidden"
          position="relative"
          cursor="pointer"
        >
          {/* ----------------- Controls -------- */}
          {
            <Button
              position="absolute"
              variant="icon"
              zIndex={10}
              right={0}
              onClick={() => setIsMuted(!isMuted)} // TODO: this not working on touch screens..
            >
              {isMuted ? (
                <BsFillVolumeMuteFill color="white" size={24} />
              ) : (
                <BsFillVolumeUpFill color="white" size={24} />
              )}
            </Button>
          }

          {/* ----------------- Player -------------- */}
          {/* NOTE: This is so that the pointer events would go to the draggable object and not the react player*/}
          <Flex width="100%" height="100%" pointerEvents="none">
            <ReactPlayer
              url={streamUrl}
              playing={true}
              width="100%"
              height="100%"
              muted={isMuted || showIntroScreen}
            />
          </Flex>

          {/* ----------------- stream messages -------- */}

          <AnimatePresence>
            {showIntroScreen && (
              <MotionBox
                display="flex"
                flexDir="column"
                gap={4}
                position="absolute"
                width="100%"
                height="100%"
                bg="black"
                alignItems="center"
                justifyContent="center"
                p={4}
                zIndex={1000}
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, scale: 2, transition: { duration: 0.5 } }}
              >
                <Image alt="Livelinx logo" width="100px" src={logo} />
                <Text
                  as={motion.div}
                  color="white"
                  animate={{
                    // scale: [1, 0.9, 1],
                    opacity: [1, 0.5, 1],
                    transition: {
                      duration: 2,
                      ease: 'linear',
                      repeat: Infinity,
                      repeatType: 'mirror',
                    },
                  }}
                >
                  {t('pip.streamDown.message')}
                </Text>
              </MotionBox>
            )}
          </AnimatePresence>
        </Flex>
      </Draggable>
    </Flex>
  );
}

export default PictureInPictureFeature;
