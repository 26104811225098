import { SlideItemType } from '@/utils/types/enums';
import { InfoSlideType } from '@/utils/types/zod/slideTypes/infoSlideSchema';
import ItemContainer from './ItemContainer';
import ItemListContainer from './ItemListContainer';
import InfoItem from './items/InfoItem';
import { NewIAsset } from '@/utils/types/zod/assetSchema';

type Props = {
  slide: InfoSlideType;
  assetList: NewIAsset[];
  currentHostAssetID: string | null | undefined;
  onShowAssets: (assetRefs: string[]) => void;
};

function InfoSlide(props: Props) {
  return (
    <>
      <ItemListContainer>
        {props.slide.items.map((item, itemIndex: number) => (
          <ItemContainer key={itemIndex} slide={props.slide}>
            {item.type === SlideItemType.INFO && (
              <InfoItem
                onMoreInfoCallback={() => {
                  if (item.assetRefs.length > 0) {
                    props.onShowAssets(item.assetRefs);
                  }
                }}
                item={item}
                assetList={props.assetList}
                containsHostAsset={item.assetRefs.includes(
                  props.currentHostAssetID ?? ''
                )}
              />
            )}
          </ItemContainer>
        ))}
      </ItemListContainer>
    </>
  );
}

export default InfoSlide;
