import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import {
  composeQuestionID,
  mutationCreateAnswer,
} from '@/features/meeting/answer/answerHelper';
import { CreateCaseAssetViewMutation, Meeting } from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { pureJoinAMeeting } from '@/store/thunk/meeting';
import { SlideItemType, SlideType } from '@/utils/types/enums';
import getSlideWithIDOrThrow from '@/utils/helpers/getSlideWithID';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { mutationCreateCaseAssetView } from '../../graphql/create';
import { mutationUpdateCaseSlideStats } from '../../graphql/update';
import { SlideItem } from '@/utils/types/zod/slideTypes/slideItemTypes/slideItem';
import fillQuestionIdToOptions from '../utils/fillQuestionIdToOptions';

export class MockUser {
  private questionIdToOptions = new Map<string, string[]>();
  private caseIdToCaseStatsId: Record<string, string> = {};
  public slideIdToSlideStatsIdPerCase: Record<string, Record<string, string>> =
    {};

  constructor(
    public id: string,
    meeting: Meeting,
    public meetingID: string,
    public caseMap: CaseMap
  ) {
    this.parseQuestionSlides(meetingID, caseMap);
    this.caseIdToCaseStatsId =
      meeting.caseStats?.items.reduce((acc, item) => {
        if (!item) return acc;
        acc[item.caseId] = item.id;
        return acc;
      }, {} as Record<string, string>) || {};
    this.parseSlideStatsIds(meeting);
  }

  parseSlideStatsIds(meeting: Meeting) {
    for (const caseId in this.caseMap) {
      const caseData = this.caseMap[caseId];
      if (!caseData.slides) {
        throw new Error(`Case ${caseId} has no slides`);
      }
      const caseStatsForCase = meeting.caseStats?.items.find(
        (item) => item?.caseId === caseId
      );
      if (!caseStatsForCase) {
        console.error(meeting.caseStats?.items);
        console.error(this.caseMap);
        throw new Error(`No caseStats found for case ${caseId}`);
      }
      const slideStatsIdPerSlide =
        caseStatsForCase.slides?.items.reduce((acc, item) => {
          if (!item) return acc;
          acc[item.slideId] = item.id;
          return acc;
        }, {} as Record<string, string>) ?? {};
      this.slideIdToSlideStatsIdPerCase[caseId] = slideStatsIdPerSlide;
    }
  }

  parseQuestionSlides(meetingID: string, caseMap: CaseMap) {
    for (const caseId in caseMap) {
      const caseData = caseMap[caseId];
      if (!caseData.slides) {
        throw new Error(`Case ${caseId} has no slides`);
      }
      fillQuestionIdToOptions(
        caseData,
        meetingID,
        caseId,
        this.questionIdToOptions
      );
    }
  }

  async joinMeeting() {
    await pureJoinAMeeting(this.id, this.meetingID, false);
  }

  async interactWithSlide(currentCaseID: string, slideId: string) {
    const currentCase = this.caseMap[currentCaseID];
    const slides = currentCase.slides;

    if (!slides) {
      throw new Error('No slide found');
    }
    const slideType = getSlideWithIDOrThrow(slides, slideId).type;

    if (slideType === SlideType.QUESTION_SINGLE) {
      await this.answerQuestion(currentCaseID, slideId);
    }
    await this.watchAssets(currentCaseID, slideId);
  }

  /*
  TODO: make them watch multiple times the same asset
  */
  private async watchAssets(currentCaseID: string, slideId: string) {
    const currentCase = this.caseMap[currentCaseID];
    const slides = currentCase.slides;

    if (!slides) {
      throw new Error('No slide found');
    }
    const currentSlide = getSlideWithIDOrThrow(slides, slideId);

    //MEETING_STATS_TODO: Uncomment this but it means we need to fix the ts error

    //check if there are library assets on the slide
    const promises: Promise<CreateCaseAssetViewMutation | undefined>[] = [];
    if (currentSlide.libraryRefs && currentSlide.libraryRefs.length > 0) {
      //watch all assets
      for (const assetID of currentSlide.libraryRefs as unknown as string[]) {
        // console.log('trying library asset view with asset:', assetID);
        promises.push(
          mutationCreateCaseAssetView(
            this.caseIdToCaseStatsId[currentCaseID],
            assetID,
            this.id,
            Math.round(Math.random() * 9)
          )
        );
      }
    }

    currentSlide.items.forEach((item: SlideItem) => {
      if (item.type !== SlideItemType.INFO) return;
      const refs = item.assetRefs;
      refs.forEach((ref) => {
        if (!ref) {
          return;
        }
        // console.log('sending asset view with id', ref, item);
        promises.push(
          mutationCreateCaseAssetView(
            this.caseIdToCaseStatsId[currentCaseID],
            ref,
            this.id,
            Math.round(Math.random() * 9)
          )
        );
      });
    });
    await Promise.all(promises);
  }

  private async answerQuestion(currentCaseID: string, slideId: string) {
    const currentCase = this.caseMap[currentCaseID];
    const slides = currentCase.slides;

    if (!slides) {
      throw new Error('No slide found');
    }
    const currentSlide = getSlideWithIDOrThrow(slides, slideId);

    const questionID = composeQuestionID(
      this.meetingID,
      currentCaseID,
      currentSlide.id
    );

    if (!this.questionIdToOptions.has(questionID)) {
      return;
    }
    //answer a random answer
    const questionOptions = this.questionIdToOptions.get(
      questionID
    ) as string[];
    const answerIndex = Math.floor(Math.random() * questionOptions.length);

    //NIKOTODO MOCKING MULTIVOTE
    await mutationCreateAnswer(
      this.id,
      this.meetingID,
      questionID,
      [questionOptions[answerIndex]],
      []
    );
  }
}

export class MockHost extends MockUser {
  constructor(
    public id: string,
    meeting: Meeting,
    public meetingID: string,
    public caseMap: CaseMap
  ) {
    super(id, meeting, meetingID, caseMap);
  }

  async switchCase(newCaseID: string) {
    await callGraphQLApi(updateMeeting, {
      input: {
        id: this.meetingID,
        currentCase: newCaseID,
        currentSlide: 0,
      },
    });
  }

  async switchSlide(
    currentCaseId: string,
    newslideId: string,
    newSlideIndex: number
  ) {
    await mutationUpdateCaseSlideStats(
      this.slideIdToSlideStatsIdPerCase[currentCaseId][newslideId.toString()],
      Math.round(Math.random() * (3 * 60) + 3 * 60) //between 3 and 6 minutes
    );
    await callGraphQLApi(updateMeeting, {
      input: {
        id: this.meetingID,
        currentSlide: newSlideIndex,
      },
    });
  }
}
