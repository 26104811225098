//TODO: change to theme colors
export default function getColor(percentage: number) {
  if (percentage < 25) {
    return '#FF7F68';
  }
  if (percentage < 50) {
    return '#FCBD53';
  }
  return '#4B75FF';
}

//TODO: When I use this it doesn't work, I guess it's because HalfCircularProgressBar isn't from chakraUI and uses a raw canvas

// export default function getColor(percentage: number) {
//   if (percentage < 25) {
//     return 'livelinx.orange200';
//   }
//   if (percentage < 50) {
//     return 'livelinx.yellow200';
//   }
//   return "livelinx.blue300";
// }
