import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Box, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { PADDING_X_DESKTOP, PADDING_X_MOBILE } from '../utils/constants';
import ChunkContainer from './ChunkContainer';
import Title from './Title';
import { ParsedSlideStats } from '../utils/parseSlideStatsPerCase';

type Props = {
  caseIds: string[];
  caseToTop4Slides: Record<string, ParsedSlideStats[]>;
  caseTitles: Record<string, string>;
  currentCase?: string;
};

type Line = {
  title: string;
  slideIndex: string;
  noBestSlide: boolean;
};

export default function BestSlide({
  caseIds,
  caseToTop4Slides,
  caseTitles,
  currentCase,
}: Props) {
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useDeviceSize();

  let lines: Line[] = [];
  if (!currentCase) {
    lines =
      caseIds.map((c) => {
        return caseToTop4Slides[c].length > 0
          ? {
              title: caseTitles[c],
              slideIndex: caseToTop4Slides[c][0].index.toString(),
              noBestSlide: false,
            }
          : {
              title: caseTitles[c],
              slideIndex: '',
              noBestSlide: true,
            };
      }) ?? [];
  } else {
    lines =
      caseToTop4Slides[currentCase].length > 0
        ? caseToTop4Slides[currentCase].map((item) => {
            return {
              title: item.title,
              slideIndex: item.index.toString(),
              noBestSlide: false,
            };
          })
        : [
            {
              title: caseTitles[currentCase],
              slideIndex: '',
              noBestSlide: true,
            },
          ];
  }

  return (
    <ChunkContainer>
      <Title text={t('meetings.stats.bestSlide.title')} />
      <Box
        marginY="10px"
        marginBottom="15px"
        width="100%"
        paddingX={isDesktopOrLaptop ? PADDING_X_DESKTOP : PADDING_X_MOBILE}
      >
        {lines.map((line, idx) => (
          <Box
            key={line.title + idx}
            display="flex"
            flexDir="row"
            justifyContent="space-between"
            // paddingLeft="15px"
            // paddingRight="15px"
            paddingTop="5px"
            paddingBottom="5px"
            alignItems="center"
            marginBottom="10px"
            gap="15px"
          >
            {
              <>
                <Text
                  variant="graySubTitle"
                  noOfLines={1}
                  //This is so that it would not push the 'slide xx' texts when the text is too long
                  flexShrink={9999}
                  wordBreak="break-all"
                >
                  <Trans>
                    {line.noBestSlide
                      ? `No best slide for case ${idx + 1}`
                      : line.title}
                  </Trans>
                </Text>
                <Text
                  fontSize="17px"
                  fontFamily="nunito-bold"
                  color="livelinx.purple200"
                  noOfLines={1}
                  // flexShrink={1}
                >
                  Slide {line.slideIndex.padStart(2, '0')}
                </Text>
              </>
            }
          </Box>
        ))}
      </Box>
    </ChunkContainer>
  );
}
