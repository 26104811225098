import { Table } from '@tanstack/react-table';
import {
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { flexRender } from '@tanstack/react-table';
import HeaderElement from './HeaderElement';
import { useTranslation } from 'react-i18next';

type Props<TData> = {
  table: Table<TData>;
};

export default function TanstackTableView<TData>({ table }: Props<TData>) {
  const tableRef = useRef<HTMLTableElement>(null);
  const { t } = useTranslation();

  return (
    <>
      <TableContainer
        bg="white"
        borderRadius={10}
        width="100%"
        maxW="100%"
        display="block"
        border="1px solid rgba(0, 0, 0, 0.1)"
        ref={tableRef}
      >
        <ChakraTable
          width={table.getTotalSize()}
          variant="simple"
          bg="white"
          borderRadius={4}
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr
                  key={`headerGroup ${headerGroup.id}`}
                  display="flex"
                  width="fit-content"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <HeaderElement
                        key={`header ${header.id}`}
                        header={header}
                      />
                    );
                  })}
                  <Th
                    w="auto"
                    display="flex"
                    overflow="hidden"
                    paddingX="1rem"
                    paddingY="0.5rem"
                    _hover={{
                      background: 'gray.100',
                    }}
                  ></Th>
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={`row ${row.id}`} display="flex" width="fit-content">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={`cell ${cell.id}`}
                        overflow="hidden"
                        padding="1rem"
                        w={cell.column.getSize()}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
            {table.getRowModel().rows.length === 0 && (
              <Tr>
                <Td colSpan={table.getAllColumns().length} textAlign="center">
                  {t('tables.noData')}
                </Td>
              </Tr>
            )}
          </Tbody>
        </ChakraTable>
      </TableContainer>
    </>
  );
}
