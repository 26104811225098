import getErrorMessage from '@/utils/helpers/getErrorMessage';
import React, { useEffect, useRef } from 'react';

// https://benestudio.co/guide-to-access-previous-props-or-state-in-react-hooks/
export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * https://dev.to/vinodchauhan7/react-hooks-with-async-await-1n9g
 * use async hook
 */
export function useAsyncFetch(urlToFetch: string, jsonResult = true) {
  const [result, setResult] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadError, setLoadError] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    async function fetchURL() {
      try {
        setLoading(true);
        const response = await fetch(urlToFetch);
        // console.warn(`useAsyncFetch RESPONSE: ${response}`);
        const resultData = jsonResult
          ? await response.json()
          : await response.text();
        // console.warn(`useAsyncFetch SUCCESS: ${resultText}`);
        setResult(resultData);
        setLoading(false);
      } catch (error) {
        console.warn(`useAsyncFetch ERROR (${urlToFetch}) : ${error}`);
        setLoading(false);
        setLoadError(getErrorMessage(error));
      }
    }

    if (urlToFetch !== '') {
      fetchURL();
    }
  }, [urlToFetch]);

  return [result, loading, loadError];
}
