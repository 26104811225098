import { useQuestionContext } from '@/components/CaseSlide/QuestionSlide/context/QuestionContext';
import { TextAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/textAnswerSlideItemSchema';
import { Select } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type Props = {
  item: TextAnswerSlideItemType;
  isHost: boolean;
  options: string[];
  onChange: (value: string) => void;
};

function SelectItemAnswer({ item, isHost, options, onChange }: Props) {
  //This is the local state, we need this for the debouncing
  const { currentTextAnswers, requestTextAnswerIndex } = useQuestionContext();
  const savedValue = currentTextAnswers[requestTextAnswerIndex(item.id)];
  const [value, setValue] = useState<string>('');

  //load the database state into the local state
  useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  return (
    <Select
      value={value}
      width="100%"
      isDisabled={isHost}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => {
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      })}
    </Select>
  );
}

export default SelectItemAnswer;
