import {
  clearAllMockCaseClusters,
  clearAllMockMeetings,
} from '../meetingstats.delete';
import { clearAllMockUsers } from '../mockUser/users.delete';

export default async function clearAllMockingData() {
  console.log('Started clearing mock data...');
  await clearAllMockCaseClusters();
  await clearAllMockMeetings();
  await clearAllMockUsers();
  console.log('Finished clearing mock data.');
}
