import { theme } from '@/theme/theme';
import { createTheme } from '@aws-amplify/ui-react';

// by default, createTheme extends the defaultTheme.
const AuthTheme = createTheme({
  name: 'CustomAmplifyTheme',
  tokens: {
    fonts: {
      default: {
        variable: { value: theme.fonts.body },
        static: { value: theme.fonts.body },
      },
    },

    components: {
      authenticator: {
        router: {
          borderColor: { value: '#e0e0e0' },
        },
      },
      tabs: {
        item: {
          _active: {
            borderColor: { value: theme.colors.livelinx.blue300 },
          },
        },
      },
      button: {
        primary: {
          backgroundColor: {
            value: theme.colors.primary,
          },
          _hover: {
            backgroundColor: {
              value: theme.colors.livelinx.blue300,
            },
          },
        },
        borderRadius: { value: '5px' },
        color: { value: 'black' },
      },
    },

    colors: {
      font: {
        primary: { value: '{colors.neutral.100.value}' },
      },
    },
  },
});

export default AuthTheme;
