import {
  CreateUserClientMutation,
  ListUserClientsQuery,
  ListUserClientsQueryVariables,
} from '@/services/API';
import { createUserClient } from '@/services/graphql/mutations';
import { listUserClients } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export function addUserToClient(userID: string, clientID: string) {
  return new Promise<void>((resolve, reject) => {
    const input = {
      userID,
      clientID,
    };
    const variables: ListUserClientsQueryVariables = {
      filter: {
        userID: {
          eq: userID,
        },
        clientID: {
          eq: clientID,
        },
      },
    };
    callGraphQLApi<GraphQLResult<ListUserClientsQuery>>(
      listUserClients,
      variables
    )
      .then((isUserAlreadyTakenResp: GraphQLResult<ListUserClientsQuery>) => {
        if (isUserAlreadyTakenResp.data?.listUserClients?.items?.length !== 0) {
          reject(new Error('User is already in client'));
          return;
        }
        callGraphQLApi<GraphQLResult<CreateUserClientMutation>>(
          createUserClient,
          {
            input,
          }
        )
          .then((_: GraphQLResult<CreateUserClientMutation>) => resolve())
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}
