import {
  UpdateCaseSlideStatsInput,
  UpdateCaseSlideStatsMutation,
  UpdateMeetingStatsInput,
  UpdateMeetingStatsMutation,
} from '@/services/API';
import {
  updateCaseSlideStats,
  updateMeetingStats,
} from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export async function mutationUpdateCaseSlideStats(id: string, time: number) {
  const input: UpdateCaseSlideStatsInput = {
    id,
    time,
  };
  const result = await callGraphQLApi<
    GraphQLResult<UpdateCaseSlideStatsMutation>
  >(updateCaseSlideStats, { input });
  return result.data;
}

export async function mutationUpdateMeetingStats(
  input: UpdateMeetingStatsInput
) {
  const result = await callGraphQLApi<
    GraphQLResult<UpdateMeetingStatsMutation>
  >(updateMeetingStats, { input });
  return result.data;
}
