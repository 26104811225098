import { Meeting } from '@/services/API';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/StoreHooks';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/routes/Routes';
import mutationArchiveMeeting from '@/pages/admin/graphql/mutationArchiveMeeting';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { deleteAMeeting } from '@/store/thunk/meeting';
import {
  BarChartOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderOpenOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ConfirmModal from '@/ui/ConfirmModal/ConfirmModal';

type Props = {
  meeting: Meeting;
  nonDeletableMeetings: string[];
  onDupeMeeting: (meeting: Meeting) => void;
  onEditMeeting: (meeting: Meeting) => void;
  onUserAccess: (meeting: Meeting) => void;
};

export const AdminManageMeetingMenu = ({
  meeting,
  onDupeMeeting,
  onEditMeeting,
  onUserAccess,
}: Props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const archiveDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();

  const handleMeetingStats = (meetingID: string) => {
    navigate(`${ROUTES.MEETING_STATS}/${meetingID}`);
  };

  const handleArchiveMeeting = (meetingID: string) => {
    mutationArchiveMeeting(meetingID).catch((err) => {
      captureSentry({
        title: 'Error archiving meeting',
        error: err instanceof Error ? err : undefined,
        detail: {
          meetingID,
        },
      });
      toast({
        title: 'Error archiving meeting',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  const handleDeleteMeeting = (meetingID: string) => {
    dispatch(deleteAMeeting(meetingID));
  };

  return (
    <>
      <ConfirmModal
        title={
          <div>
            {t('admin.meeting.archive.confirmText')}
            <br />
            {t('meeting.header.confirmRestart')}
          </div>
        }
        onConfirm={() => handleArchiveMeeting(meeting.id)}
        okText={t('common.yes') as string}
        cancelText={t('common.no') as string}
        disclosure={archiveDisclosure}
      />
      <ConfirmModal
        title={
          <div>
            {t('admin.meeting.delete.confirmText')}
            <br />
            {t('meeting.header.confirmRestart')}
          </div>
        }
        onConfirm={() => handleDeleteMeeting(meeting.id)}
        okText={t('common.yes') as string}
        cancelText={t('common.no') as string}
        disclosure={deleteDisclosure}
      />
      <Menu>
        <MenuButton
          as={Button}
          size="xs"
          variant="secondary"
          borderRadius={4}
          aria-label="Options"
        >
          <Text>Manage</Text>
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<BarChartOutlined />}
            onClick={() => handleMeetingStats(meeting.id)}
          >
            {t('admin.meetings.list.viewStats')}
          </MenuItem>
          <MenuItem
            icon={<CopyOutlined />}
            onClick={() => onDupeMeeting(meeting)}
          >
            {t('admin.meetings.list.duplicate')}
          </MenuItem>

          <MenuItem
            icon={<UserOutlined />}
            onClick={() => onUserAccess(meeting)}
          >
            {t('admin.meetings.list.userAccess')}
          </MenuItem>
          <MenuItem
            icon={<EditOutlined />}
            onClick={() => onEditMeeting(meeting)}
          >
            {t('common.edit')}
          </MenuItem>

          <MenuItem
            icon={<FolderOpenOutlined />}
            onClick={() => archiveDisclosure.onToggle()}
            isDisabled={meeting.isArchived}
          >
            {t('common.archive')}
          </MenuItem>
          <MenuItem
            icon={<DeleteOutlined />}
            onClick={() => deleteDisclosure.onToggle()}
          >
            {t('common.delete')}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default AdminManageMeetingMenu;
