import { callGraphQLApi } from '@/utils/graphQLAPI';
import { ListUsersQuery, ListUsersQueryVariables } from '../API';
import { GraphQLResult } from '@aws-amplify/api';
import { listUsers } from './queries';

export default async function queryUserList() {
  const variables: ListUsersQueryVariables = {};
  const response = await callGraphQLApi<GraphQLResult<ListUsersQuery>>(
    listUsers,
    variables
  );

  let nextToken = response.data?.listUsers?.nextToken;

  //Note: Since there can be a huge amount of users ( > 100)
  //we're implementing this logic to get all answers
  while (nextToken) {
    const variables2: ListUsersQueryVariables = {
      nextToken,
    };
    const additionalData = await callGraphQLApi<GraphQLResult<ListUsersQuery>>(
      listUsers,
      variables2
    );
    if (
      !response.data?.listUsers?.items ||
      !additionalData.data?.listUsers?.items
    )
      break;
    response.data.listUsers.items = [
      ...response.data.listUsers.items,
      ...additionalData.data.listUsers.items,
    ];
    nextToken = additionalData.data?.listUsers?.nextToken;
  }
  return response;
}
