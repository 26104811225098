import { Meeting } from '@/services/API';

const getNonDeletableMeetings = (meetingList: Meeting[]): string[] => {
  const nonDeletableMeetings: string[] = [];

  meetingList.forEach((meeting) => {
    if (meeting.guestUrlCode && meeting.guestUrlCode !== '')
      nonDeletableMeetings.push(meeting.id);
  });
  return nonDeletableMeetings;
};

export default getNonDeletableMeetings;
