export function IsMobile() {
  const isMobile =
    /Android|webOS|Mobile|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    ) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // ipad > ios 13

  return isMobile;
  // return true; // TODO: change this, override for now for switzerland
}
