import {
  MeetingStatsByMeetingQuery,
  MeetingStatsByMeetingQueryVariables,
} from '@/services/API';
import { meetingStatsByMeeting } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { mutationCreateMeetingStats } from '../../graphql/create';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';

export default function useMeetingStatsSanityCheck(
  meetingID: string | null | undefined
) {
  const { isAdmin } = useUserAuthenticationContext();
  // NOTE: Checks meetingStats only
  const query = useQuery({
    queryKey: ['useMeetingStatsSanityCheck', meetingID],
    refetchOnWindowFocus: false,
    enabled: !!meetingID && isAdmin,
    queryFn: async () => {
      if (!meetingID) return false;
      const variables: MeetingStatsByMeetingQueryVariables = {
        meetingID: meetingID,
      };
      const result = await callGraphQLApi<
        GraphQLResult<MeetingStatsByMeetingQuery>
      >(meetingStatsByMeeting, variables);
      const thisMeetingStats = result.data?.meetingStatsByMeeting?.items[0];

      if (!thisMeetingStats) {
        const res = await mutationCreateMeetingStats(meetingID);
        return res;
      }
      return thisMeetingStats;
    },
  });

  return { isLoading: isAdmin && query.isLoading };
}
