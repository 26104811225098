import { IconButton, IconButtonProps } from '@chakra-ui/react';

type Props = {
  icon: React.ReactElement;
};

const ButtonCircle = ({ icon, ...args }: Props & IconButtonProps) => (
  <IconButton
    size="sm"
    background="livelinx.purple50"
    color="livelinx.grey200"
    borderRadius="50%"
    border="none"
    icon={icon}
    {...args}
  />
);

export default ButtonCircle;
