import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@/store/StoreHooks';
import {
  onCreateClient,
  onUpdateClient,
  onDeleteClient,
} from '../graphql/subscriptions';
import { fetchClientList } from '@/store/thunk/clients';
import useNewSubscriptions from './useNewSubscriptions';
import {
  OnCreateClientSubscription,
  OnDeleteClientSubscription,
  OnUpdateClientSubscription,
} from '../API';

// --------------------- hook ------------------------

export default function useAllClientsSubscription() {
  const [refreshTs, setRefreshTs] = useState(Date.now());
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchClientList());
  }, [refreshTs]);

  // --------------------- subscription effect ------------------------

  const subscriptions = useMemo(() => {
    return [
      {
        query: onCreateClient,
        variables: {},
        callback: (_result: OnCreateClientSubscription) => {
          setRefreshTs(Date.now());
        },
      },
      {
        query: onUpdateClient,
        variables: {},
        callback: (_result: OnUpdateClientSubscription) => {
          setRefreshTs(Date.now());
        },
      },
      {
        query: onDeleteClient,
        variables: {},
        callback: (_result: OnDeleteClientSubscription) => {
          setRefreshTs(Date.now());
        },
      },
    ];
  }, []);

  useNewSubscriptions(subscriptions, 'useAllClientsSubscription');

  return {
    lastRefreshTime: refreshTs,
  };
}
