import {
  OnCreateUserMeetingJoinSubscription,
  OnUpdateUserMeetingJoinSubscription,
  User,
  UserMeetingJoin,
  UserMeetingsByMeetingIDQuery,
  UserMeetingsByMeetingIDQueryVariables,
} from '@/services/API';
import { userMeetingsByMeetingID } from '@/services/graphql/queries';
import {
  onCreateUserMeetingJoin,
  onUpdateUserMeetingJoin,
} from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import APP_CONSTANTS from '@/utils/constants/app.constants';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { GraphQLResult } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

const isInMeeting = (
  isCurrentlyInMeeting: boolean,
  currentPingDate: Date,
  lastPingDate: Date
) => {
  const result =
    isCurrentlyInMeeting &&
    currentPingDate.getTime() - lastPingDate.getTime() <
      APP_CONSTANTS.USER_MEETING_PING_INTERVAL * 2 * 1000;
  return result;
};

const transformDate = (date: string | null | undefined): Date => {
  return new Date(date || new Date());
};

export default function useMeetingParticipants(
  meetingID: string | null | undefined
): User[] {
  const [meetingParticipants, setMeetingParticipants] = useState<
    UserMeetingJoin[]
  >([]);

  // NOTE: Responsible for adding or removing participants from the list
  // without duplicates and always keeping the latest userMeetingJoin information
  // Why did we do this ? Because it's possible that a websocket receives data before the initial query
  // finishes loading, so we need to make sure that we don't have duplicates
  const safelyUpdateParticipantsConnectionState = (
    userMeetingJoins: UserMeetingJoin[]
  ) => {
    setMeetingParticipants((prev) => {
      const latestUserMeetingJoin = userMeetingJoins.map((userMeetingJoin) => {
        const objInList = prev.find(
          (elem) => elem.userID === userMeetingJoin.userID
        );
        if (!objInList) return userMeetingJoin;
        return transformDate(objInList.lastPingDate) >
          transformDate(userMeetingJoin.lastPingDate)
          ? objInList
          : userMeetingJoin;
      });
      const userIDs = latestUserMeetingJoin.map((elem) => elem.userID);
      const newObj = prev.filter(
        (elem) =>
          !userIDs.includes(elem.userID) &&
          isInMeeting(
            elem.isCurrentlyInMeeting || false,
            new Date(),
            new Date(elem.lastPingDate || new Date())
          )
      );

      latestUserMeetingJoin.forEach((userMeetingJoin) => {
        if (
          isInMeeting(
            userMeetingJoin.isCurrentlyInMeeting || false,
            new Date(),
            // NOTE: Needs to be 0 in case the lastPingDate is null because
            // the user could have closed his browser before the first ping
            new Date(userMeetingJoin.lastPingDate || 0)
          )
        ) {
          // console.log(
          //   'DEBUG >> safelyUpdateParticipantsConnectionState add ',
          //   userMeetingJoin.user.email,
          //   userMeetingJoin.isCurrentlyInMeeting, userMeetingJoin.lastPingDate
          // );
          newObj.push(userMeetingJoin);
        }
      });

      return newObj;
    });
  };

  useQuery({
    queryKey: ['getPreviousQuestionSlideUserAnswers'],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      if (!meetingID) return [];
      const variables: UserMeetingsByMeetingIDQueryVariables = {
        meetingID,
      };
      const meetingParticipants = await callGraphQLApi<
        GraphQLResult<UserMeetingsByMeetingIDQuery>
      >(userMeetingsByMeetingID, variables);
      if (!meetingParticipants.data?.userMeetingsByMeetingID?.items) {
        captureSentry({
          title:
            'useMeetingParticipantsList: meetingParticipants.data?.userMeetingsByMeetingID?.items is undefined',
          detail: {
            meetingParticipants: meetingParticipants.data,
            meetingID,
          },
        });
        return;
      }
      return (meetingParticipants.data?.userMeetingsByMeetingID?.items ||
        []) as UserMeetingJoin[];
    },
    onSuccess: (data: UserMeetingJoin[]) => {
      safelyUpdateParticipantsConnectionState(data);
    },
  });

  const subscriptions = useMemo(() => {
    if (!meetingID) return [];
    return [
      {
        query: onCreateUserMeetingJoin,
        variables: {},
        callback: (result: OnCreateUserMeetingJoinSubscription) => {
          if (result.onCreateUserMeetingJoin?.meetingID !== meetingID) return;
          if (!result.onCreateUserMeetingJoin.user) return;
          safelyUpdateParticipantsConnectionState([
            result.onCreateUserMeetingJoin as UserMeetingJoin,
          ]);
        },
      },
      {
        query: onUpdateUserMeetingJoin,
        variables: {},
        callback: (result: OnUpdateUserMeetingJoinSubscription) => {
          if (result.onUpdateUserMeetingJoin?.meetingID !== meetingID) return;
          if (!result.onUpdateUserMeetingJoin.user) return;
          safelyUpdateParticipantsConnectionState([
            result.onUpdateUserMeetingJoin as UserMeetingJoin,
          ]);
        },
      },
    ];
  }, [meetingID]);

  useNewSubscriptions(subscriptions, 'useMeetingParticipantsList');

  const currentParticipants = useMemo(() => {
    return Array.from(meetingParticipants)
      .map((elem) => elem.user)
      .sort((a, b) => {
        return a.email.localeCompare(b.email);
      });
  }, [meetingParticipants]);

  return currentParticipants;
}
