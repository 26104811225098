import ClientTag from '@/components/ClientTag/ClientTag';
import { User } from '@/services/API';
import useUserListClients from '@/services/hooks/useUserListClients';
import dateInRangeFilterFn from '@/ui/TanstackTable/Filter/date/dateInRangeFilterFn';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import { parseDate } from '@/utils/helpers/parseDate';
import { Flex, IconButton, Tag, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { HiOutlineDotsVertical } from 'react-icons/hi';

const columnHelper = createColumnHelper<User>();

export const getColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  userListToClientNames: ReturnType<
    typeof useUserListClients
  >['userListToClientNames'],
  setSelectedUser: (user: User | null) => void
) => {
  const columns = [
    columnHelper.accessor('email', {
      header: 'Email',
      enableSorting: true,
      cell: (props) => <Text>{props.getValue()}</Text>,
    }),
    //NOTE: if we want to filter users by client, we need to add
    // the UserClient mapping to the user schema in
    // order to use it as an accessor here
    columnHelper.display({
      id: 'clients',
      header: 'Clients',
      cell: (props) => (
        <Flex flexWrap="wrap" gap="2px">
          {userListToClientNames[props.row.original.id]?.map((c) => (
            <ClientTag key={c} client={c} />
          ))}
        </Flex>
      ),
    }),
    columnHelper.display({
      id: 'currentMeetingID',
      header: 'Running meeting',
      cell: (props) => {
        if (
          props.row.original.currentMeetingID &&
          props.row.original.currentMeetingID != NULL_ID_VALUE
        )
          return (
            <Tag bgColor="primary" color="white">
              {props.row.original.currentMeeting?.name ||
                props.row.original.currentMeetingID}
            </Tag>
          );
        return <Text>no active meeting</Text>;
      },
    }),
    columnHelper.accessor('last_seen', {
      header: 'Last seen',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    columnHelper.accessor('id', {
      header: 'Id',
      enableSorting: false,
      cell: (props) => <Text>{props.row.original.id}</Text>,
    }),
    columnHelper.accessor('isTemporary', {
      header: 'Temp',
      size: 20,
      cell: (props) => (
        <Text color={props.row.original.isTemporary ? 'red' : 'black'}>
          {props.row.original.isTemporary ? 'yes' : 'no'}
        </Text>
      ),
    }),
    columnHelper.accessor('createdAt', {
      header: 'Joined',
      filterFn: dateInRangeFilterFn,
      cell: (props) => <Text>{parseDate(props.getValue() || '')}</Text>,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      size: 85,
      cell: (props) => (
        <Flex alignItems="center" justifyContent="center">
          <IconButton
            size="xs"
            aria-label="manage"
            bg="transparent"
            _hover={{
              transform: 'scale(1.1)',
              transition: 'all 0.2s ease-out',
            }}
            onClick={() => setSelectedUser(props.row.original)}
            icon={<HiOutlineDotsVertical />}
          />
        </Flex>
      ),
    }),
  ];

  return columns;
};
