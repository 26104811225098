import { IconButton, HStack } from '@chakra-ui/react';
import { MdLensBlur, MdOutlineBlurOff } from 'react-icons/md';

export type BlurOption = 'medium' | 'large' | 'none';

type Props = {
  blur: BlurOption;
  onBlurChange: (blur: BlurOption) => void;
};

export default function VideoBlurOptionBar({
  blur = 'none',
  onBlurChange,
}: Props) {
  const handleBlurChange = (newBlur: BlurOption) => {
    onBlurChange(newBlur);
  };

  const selectionStyle = {
    border: '3px solid',
    borderColor: 'primary',
  };

  return (
    <HStack>
      <IconButton
        rounded="100%"
        aria-label="No Camera Blur"
        icon={<MdOutlineBlurOff style={{ width: '1.5em', height: '1.5em' }} />}
        onClick={() => handleBlurChange('none')}
        {...(blur === 'none' ? selectionStyle : {})}
      />
      <IconButton
        rounded="100%"
        aria-label="Medium Camera Blur"
        icon={<MdLensBlur style={{ width: '1.1em', height: '1.1em' }} />}
        onClick={() => handleBlurChange('medium')}
        {...(blur === 'medium' ? selectionStyle : {})}
      />
      <IconButton
        rounded="100%"
        aria-label="Full Camera Blur"
        icon={<MdLensBlur style={{ width: '1.5em', height: '1.5em' }} />}
        onClick={() => handleBlurChange('large')}
        {...(blur === 'large' ? selectionStyle : {})}
      />
    </HStack>
  );
}
