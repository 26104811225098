import { CaseStats } from '@/services/API';
import { useState, useCallback, useEffect } from 'react';
import { mutationCreateCaseAssetView } from '../meeting-stats/graphql/create';
import { useUserAuthenticationContext } from '../userAuth/context/UserAuthenticationContext';
import { trackEvent } from './trackingHelpers';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import { mutationUpdateHostAsset } from './graphql/mutationUpdateHostAsset';

type TrackAssetSwitchProps = {
  newAssetID: string;
  assetName: string;
  isLibraryAsset: boolean;
} | null;

export default function useMeetingTrackingAssetStats(
  currentMeetingID: string | undefined | null,
  currentCaseId: string | undefined | null,
  caseStatsDetails: Record<string, CaseStats>,
  isUserHost: boolean
) {
  //this is the time that the user started watching the asset
  const [startWatchTime, setStartWatchTime] = useState<number>(0);
  const [currentAssetID, setCurrentAssetID] = useState<string | null>(null);
  const { user } = useUserAuthenticationContext();

  const userId = user?.id;

  const trackAssetOpen = useCallback(
    (assetID: string, assetName: string, _isLibraryAsset = false) => {
      trackEvent('Asset', assetName);

      console.log('Stats | asset opened:', assetID);
      setStartWatchTime(Date.now());
      setCurrentAssetID(assetID);
    },
    [currentAssetID]
  );

  const trackAssetClose = async () => {
    if (
      !currentCaseId ||
      !caseStatsDetails ||
      !caseStatsDetails[currentCaseId]
    ) {
      console.warn('Couldnt createAssetView statistic');
      captureSentry({
        title: 'MeetingTrackingAssetStats: Couldnt createAssetView statistic',
        detail: {
          currentCaseId: currentCaseId,
          caseStatsDetails: caseStatsDetails,
        },
      });
      return;
    }
    const secondsWatched = Math.round((Date.now() - startWatchTime) / 1000);
    console.log('Stats | asset closed:', currentAssetID, secondsWatched);
    await mutationCreateCaseAssetView(
      caseStatsDetails[currentCaseId].id,
      currentAssetID || '',
      userId || '',
      secondsWatched
    );
    setCurrentAssetID(null);
  };

  const trackAssetSwitch = async (args: TrackAssetSwitchProps) => {
    if (!currentMeetingID) {
      captureSentry({
        title:
          'MeetingTrackingAssetStats: trackAssetSwitch currentMeetingID is undefined',
        detail: {
          currentMeetingID,
          args,
        },
      });
      return;
    }
    //you can not look at multiple assets at the same time
    if (currentAssetID) {
      await trackAssetClose();
      //only set it to null if there was an asset before
      if (!args) {
        if (isUserHost) await mutationUpdateHostAsset(currentMeetingID, null);
      }
    }
    if (args) {
      trackAssetOpen(args.newAssetID, args.assetName);
      if (isUserHost)
        await mutationUpdateHostAsset(currentMeetingID, args.newAssetID);
    }
  };

  /* NOTE: This is so that if the host leaves/crashes and comes back, it will reset the host asset to null*/
  useEffect(() => {
    if (!currentMeetingID) return;
    if (isUserHost) mutationUpdateHostAsset(currentMeetingID, null);
  }, [currentMeetingID]);

  return {
    trackAssetSwitch,
  };
}
