import { RootState } from '@/store/Store';
import { createSlice } from '@reduxjs/toolkit';
import { CaseCluster } from '../../services/API';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';

// --------------------- State ------------------------

type ICaseClusterSlice = {
  caseClusterList: CaseCluster[] | null;
  caseList: UnloadedIPatientCase[] | null;
  isLoading: boolean;
  error: string | null;
};

const initialState: ICaseClusterSlice = {
  caseClusterList: null,
  caseList: null,
  isLoading: false,
  error: null,
};

// --------------------- State ------------------------

export const Slice = createSlice({
  name: 'caseCluster',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.message;
      console.error('Error in caseClusterSlice: ', action.payload);
      // useToast({
      //   status: 'error',
      //   title: state.error as string,
      // });
    },
    setCaseClusterList: (state, action: PayloadAction<Array<CaseCluster>>) => {
      state.caseClusterList = action.payload;
    },
    setCaseList: (
      state,
      action: PayloadAction<Array<UnloadedIPatientCase>>
    ) => {
      state.caseList = action.payload;
    },
  },
});

// --------------------- selectors ------------------------

const stateSelector: (rootState: RootState) => ICaseClusterSlice = (
  rootState
) => rootState.caseCluster;
const caseClusterListSelector = (rootState: RootState) =>
  stateSelector(rootState).caseClusterList;
const caseListSelector = (rootState: RootState) =>
  stateSelector(rootState).caseList;

// const stateSelector:(rootState:any)=> MeetingState = (rootState) => rootState.meeting;
// const getMeetingList = (rootState) => selectState(rootState).meetingList;
// const getCurrentMeetingID = (rootState) => selectState(rootState).currentMeetingID;
// const getMeetingByID = (rootState, meetingID) => selectState(rootState).meetingList?.find((meeting) => meeting.id === meetingID);
// const getCurrentMeeting = createSelector(
//   getMeetingList,
//   getCurrentMeetingID,
//   (meetingList, meetingID) => meetingList?.find((meeting) => meeting.id === meetingID),
// );

// --------------------- exports ------------------------

const { actions } = Slice;
export { actions };
export const CaseClusterSelectors = {
  caseClusterListSelector,
  caseListSelector,
};

export default Slice.reducer;
