import { Text } from '@chakra-ui/react';
import { ColumnFilter } from '@tanstack/react-table';
import { Column } from 'react-table';
import BasicStringFilter from './string/BasicStringFilter';
import BasicBooleanFilter from './bool/BasicBooleanFilter';
import SearchableSelectFilter from './string/SearchableSelectFilter';
import DateInRangeFilter from './date/DateInRangeFilter';

export enum FilterType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  SEARCHABLE_SELECT = 'searchable_select',
  UNSUPPORTED = 'unsupported',
}

type Props = {
  column: Column<any>;
  filterType: FilterType;
  columnFilters: ColumnFilter[];
  setColumnFilters: (filters: ColumnFilter[]) => void;
  possibleValues?: string[];
};

function UnsupportedFilter() {
  return <Text>Unsupported filter type</Text>;
}

export default function NewFilterComonent({
  column,
  filterType,
  columnFilters,
  setColumnFilters,
  possibleValues,
}: Props) {
  const updateFilter = (value: unknown) => {
    const newValue = columnFilters.filter((elem) => elem.id !== column.id);
    if (
      (filterType !== FilterType.STRING &&
        filterType !== FilterType.SEARCHABLE_SELECT) ||
      value !== ''
    ) {
      newValue.push({
        id: column?.id || 'SHOULD_NOT_HAPPEN',
        value,
      });
    }
    setColumnFilters(newValue);
  };

  switch (filterType) {
    case FilterType.STRING:
      return <BasicStringFilter onChange={updateFilter} />;
    case FilterType.BOOLEAN:
      return <BasicBooleanFilter onChange={updateFilter} />;
    case FilterType.SEARCHABLE_SELECT:
      return possibleValues ? (
        <SearchableSelectFilter
          onChange={updateFilter}
          options={possibleValues}
        />
      ) : (
        <UnsupportedFilter />
      );
    case FilterType.DATE:
      return <DateInRangeFilter onChange={updateFilter} />;
    default:
      return <UnsupportedFilter />;
  }
}
