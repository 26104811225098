import { ParsedSlideStats } from '../parseSlideStatsPerCase';
import calculateLinearScore from './calculateLinearScore';

/*
Optimal Value: 0
Worst Value: 1

Score Explanation:
  the sum of each absolute difference with each value from the mean
*/
export default function calculateConsistencyRate(
  parsedSlideStats: ParsedSlideStats[]
): number {
  const mean =
    parsedSlideStats.reduce((acc, cur) => acc + cur.time, 0) /
    parsedSlideStats.length;
  const variancePerSlide = parsedSlideStats.map((slide) =>
    Math.abs(slide.time - mean)
  );
  const totalVariance = variancePerSlide.reduce((acc, cur) => acc + cur, 0);
  const score = calculateLinearScore(totalVariance, 0, 0, 0, 30);
  return score;
}
