import { Meeting } from '@/services/API';
import { UserType } from '@/utils/types/types';

export default function shouldActivatePIPFeature(
  meeting: Meeting | undefined,
  userType: UserType
) {
  if (!meeting?.streamUrl) return false;
  if (userType === UserType.ADMIN || userType === UserType.GUEST) return true;
  return false;
}
