import { SearchIcon } from '@chakra-ui/icons';
import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
} & InputProps;

export const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  ...rest
}: Props) => {
  const [value, setValue] = useState<string>(globalFilter);
  const debouncedOnChange = useMemo(
    () => debounce(setGlobalFilter, 300),
    [setGlobalFilter]
  );
  const { t } = useTranslation();
  return (
    <InputGroup {...rest}>
      <InputLeftElement pointerEvents="none">
        <Icon as={SearchIcon} color="gray" />
      </InputLeftElement>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          debouncedOnChange(e.target.value);
        }}
        placeholder={t('tables.globalSearch.placeholder') || ''}
      />
    </InputGroup>
  );
};

export default GlobalFilter;
