import React, { ReactNode } from 'react';
import { Image, Flex, Text } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
};
export default function Empty({ children }: Props) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Image
        src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
        width="8rem"
      />
      <Text>{children}</Text>
    </Flex>
  );
}
