import { useAnswersBySlide } from '@/services/hooks/useAnswersBySlide';
import { lorealTitlesFR, lorealTitlesNL } from '../CustomSlide3/CustomSlide3';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { useMemo, useState } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Th,
  Thead,
  Flex,
  Text,
  Stack,
} from '@chakra-ui/react';
import ProgressBar from '@/features/meeting-stats/components/ProgressBar';
import { clamp } from 'lodash';
import { AppSpinner } from '@/ui/AppSpinner';
import { SelectableAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/selectableAnswerSlideItemSchema';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';

type Props = {
  patientCase?: NewIPatientCase;
  meetingID?: string;
};

function TitleItem({ title }: { title: string }) {
  return (
    <Flex
      width="100%"
      background="livelinx.purple100"
      border="1px solid"
      borderColor="livelinx.purple100"
      color="white"
      borderRadius="10px"
      height="100px"
      alignItems="center"
      justifyContent="center"
      padding="20px"
    >
      <Text textAlign="center" fontSize="24px">
        {title}
      </Text>
    </Flex>
  );
}

function useParseStats(
  index: number,
  meetingID?: string,
  patientCase?: NewIPatientCase
) {
  const { answers: slideAnswers } = useAnswersBySlide(
    index,
    false,
    meetingID,
    patientCase
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  //NOTE: We know that the select options are of even indexes
  // const selectAnswers = useMemo(() => {
  //   const result = slideAnswers.flatMap((answer) => {
  //     return answer.answerTexts
  //       .map((answerText, idx) => {
  //         if (idx % 2 === 0) {
  //           return answerText;
  //         }
  //       })
  //       .filter(isTruthy)
  //       .filter((txt) => txt !== selectOptions[0]);
  //   });
  //   return result;
  // }, [slideAnswers, selectOptions]);

  const selectAnswers = useMemo(() => {
    return slideAnswers.flatMap((answer) => {
      return answer.answerIndexes;
    });
  }, [slideAnswers]);

  const parsedStats = useMemo(() => {
    //for each selectOption I want to see how many answers
    setLoading(false);
    return selectAnswers.reduce((acc, curr) => {
      if (acc.has(curr)) {
        acc.set(curr, acc.get(curr)! + 1);
      } else {
        acc.set(curr, 1);
      }
      return acc;
    }, new Map<string, number>());
  }, [selectAnswers]);

  return {
    totalSelectAnswers: selectAnswers.length,
    amountPerSelectOption: parsedStats,
    isLoading: isLoading,
  };
}

function LorealPromoterStats(props: Props) {
  const { currentCase } = useCurrentMeetingDataContext();
  const patientCase = props.patientCase || currentCase;
  const meetingID = props.meetingID || undefined;
  const language = patientCase?.language || 'fr';

  const idBefore = 'D3-05-7A';
  const idDuring = '2F-8A-6D';
  const idAfter = '9B-0D-3C';

  const selectOptions = patientCase?.slides.find(
    (slide) => slide.id === idBefore
  )!.items as SelectableAnswerSlideItemType[];
  const selectOptions5 = patientCase?.slides.find(
    (slide) => slide.id === idDuring
  )!.items as SelectableAnswerSlideItemType[];
  const selectOptions6 = patientCase?.slides.find(
    (slide) => slide.id === idAfter
  )!.items as SelectableAnswerSlideItemType[];

  const titles = language === 'fr' ? lorealTitlesFR : lorealTitlesNL;
  const beforeStats = useParseStats(
    patientCase!.slides.findIndex((elem) => elem.id === idBefore),
    meetingID,
    patientCase
  );
  const duringStats = useParseStats(
    patientCase!.slides.findIndex((elem) => elem.id === idDuring),
    meetingID,
    patientCase
  );
  const afterStats = useParseStats(
    patientCase!.slides.findIndex((elem) => elem.id === idAfter),
    meetingID,
    patientCase
  );

  return (
    <TableContainer width="100%" className="promoter-stats-table">
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th w="20%"></Th>
            <Th w="25%">
              <TitleItem title={titles[0]} />
            </Th>
            <Th w="25%">
              <TitleItem title={titles[1]} />
            </Th>
            <Th w="25%">
              <TitleItem title={titles[2]} />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {selectOptions.map((option, idx) => {
            const beforeProgess = Math.round(
              ((beforeStats.amountPerSelectOption.get(option.id) || 0) /
                (beforeStats.totalSelectAnswers || 1)) *
                100
            );
            const duringProgress = Math.round(
              ((duringStats.amountPerSelectOption.get(selectOptions5[idx].id) ||
                0) /
                (duringStats.totalSelectAnswers || 1)) *
                100
            );
            const afterProgress = Math.round(
              ((afterStats.amountPerSelectOption.get(selectOptions6[idx].id) ||
                0) /
                (afterStats.totalSelectAnswers || 1)) *
                100
            );
            return (
              <Tr key={option.id} color="livelinx.purple100">
                <Td color="black">
                  {idx + 1}. {option.title}
                </Td>
                <Td>
                  {beforeStats.isLoading ? (
                    <AppSpinner size="sm" />
                  ) : (
                    <Stack flexDir="row">
                      <ProgressBar
                        progress={clamp(beforeProgess, 0, 100)}
                        width="90%"
                        height="20px"
                        color="livelinx.purple100"
                        bgColor="#2A2E3422"
                      />
                      <Text>{beforeProgess}%</Text>
                    </Stack>
                  )}
                </Td>
                <Td>
                  {duringStats.isLoading ? (
                    <AppSpinner size="sm" />
                  ) : (
                    <Stack flexDir="row">
                      <ProgressBar
                        progress={clamp(duringProgress, 0, 100)}
                        width="90%"
                        height="20px"
                        color="livelinx.purple100"
                        bgColor="#2A2E3422"
                      />
                      <Text>{duringProgress}%</Text>
                    </Stack>
                  )}
                </Td>
                <Td>
                  {afterStats.isLoading ? (
                    <AppSpinner size="sm" />
                  ) : (
                    <Stack flexDir="row">
                      <ProgressBar
                        progress={clamp(afterProgress, 0, 100)}
                        width="90%"
                        height="20px"
                        color="livelinx.purple100"
                        bgColor="#2A2E3422"
                      />
                      <Text>{afterProgress}%</Text>
                    </Stack>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default LorealPromoterStats;
