import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { fetchCaseClusterList } from '@/store/thunk/caseCluster';
import { CaseClusterSelectors } from '@/store/slices/caseCluster';
function useCaseClusterList() {
  const caseClusterList = useAppSelector(
    CaseClusterSelectors.caseClusterListSelector
  );
  const dispatch = useAppDispatch();

  // fetch caseCluster list if not yet done
  useEffect(() => {
    if (!caseClusterList) {
      dispatch(fetchCaseClusterList());
    }
  }, [caseClusterList, dispatch]);

  return caseClusterList;
}

export default useCaseClusterList;
