import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { updateUser } from '@/services/graphql/mutations';
import { UpdateUserInput, UpdateUserMutation } from '@/services/API';
import { GraphQLResult } from '@aws-amplify/api';
import Cookies from 'js-cookie';
import getTemporaryUserMail from '@/utils/helpers/getTemporaryUserMail';
import { useUserAuthenticationContext } from '../userAuth/context/UserAuthenticationContext';
import { callGraphQLApi } from '@/utils/graphQLAPI';

type FormData = {
  username: string;
};

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function GuestSignInForm({ isOpen, setIsOpen }: Props) {
  const { user, refetchUser } = useUserAuthenticationContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    if (!user) return;
    //update the user's username in the database
    const input: UpdateUserInput = {
      id: user?.id as string,
      firstname: values.username,
      email: getTemporaryUserMail().replace('guest', values.username),
    };
    await callGraphQLApi<GraphQLResult<UpdateUserMutation>>(updateUser, {
      input,
    });
    refetchUser();
    Cookies.set('guestUsername', values.username);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box p="20px" gap="10px">
            <Heading size="sm" pb="5">
              {t('meeting.signin.form.title')}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack gap="10px" pb="10px">
                <FormControl isRequired isInvalid={Boolean(errors.username)}>
                  <FormLabel fontSize="sm" fontWeight="normal" mb="1">
                    {t('meeting.signin.form.username.label')}
                  </FormLabel>
                  <Input
                    type="text"
                    {...register('username', { required: true })}
                    placeholder="Guest1423"
                  />
                  {errors.username && (
                    <FormHelperText color="red">
                      {t('meeting.signin.form.username.error')}
                    </FormHelperText>
                  )}
                </FormControl>

                <Spacer h="5px" />

                <Button
                  onClick={handleSubmit(onSubmit)}
                  p="2"
                  width="full"
                  background="blue.400"
                  color="white"
                  variant="solid"
                >
                  {t('meeting.signin.form.submit.label')}
                </Button>
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
