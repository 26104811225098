import { z } from 'zod';
import { SlideType } from '../../enums';
import { baseSlideSchema } from './baseSlideSchema';
import { anamneseItemSchema } from './slideItemTypes/anamneseItemSchema';

export const anamneseSlideSchema = baseSlideSchema.and(
  z.object({
    type: z.literal(SlideType.ANAMNESE),

    items: z.array(anamneseItemSchema),
  })
);

export type AnamneseSlideType = z.infer<typeof anamneseSlideSchema>;
