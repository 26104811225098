import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export type DateFilterValue = [Date | null, Date | null];

type Props = {
  onChange: (value: DateFilterValue) => void;
};

function roundDate(value: Date, direction: 'up' | 'down') {
  const date = new Date(value);
  if (direction === 'up') {
    date.setHours(23, 59, 59, 999);
  } else {
    date.setHours(0, 0, 0, 0);
  }
  return date;
}

const DateInRangeFilter = ({ onChange }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  return (
    <Flex justifyContent="center">
      <DatePicker
        selected={startDate}
        onChange={(dates) => {
          const [start, end] = dates;
          const roundedStartDate = start ? roundDate(start, 'down') : null;
          const roundedEndDate = end ? roundDate(end, 'up') : null;
          setStartDate(roundedStartDate);
          setEndDate(roundedEndDate);
          onChange([roundedStartDate, roundedEndDate]);
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        dateFormat="yyyy-MM-dd"
      />
    </Flex>
  );
};

export default DateInRangeFilter;
