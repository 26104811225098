import useMeetingInteractionFunctions from '@/features/meeting/hooks/useMeetingInteractionFunctions';
import { Meeting } from '@/services/API';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import { captureSentry } from '@/utils/helpers/sentryHelper';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import NPSConfirmationLiveMeetingTypeModal from './NPSConfirmationLiveMeetingTypeModal';
import { useTranslation } from 'react-i18next';

type Props = {
  disclosure: ReturnType<typeof useDisclosure>;
  meeting: Meeting;
};

export const NPSConfirmationModal = ({ disclosure, meeting }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  //TODO: make sure I can just hard code false here
  const meetingInteractions = useMeetingInteractionFunctions(meeting, false);
  const onConfirm = () => {
    meetingInteractions
      .showNPSScoreView(meeting, NULL_ID_VALUE)
      .then(() => {
        toast({
          title: 'NPS score view opened',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        captureSentry({
          title: 'Error opening NPS score view',
          error: err,
          detail: {
            meetingID: meeting.id,
          },
        });
        toast({
          title: 'Error opening NPS score view',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  if (meeting.meetingType === MeetingTypes.LIVE) {
    return (
      <NPSConfirmationLiveMeetingTypeModal
        meeting={meeting}
        disclosure={disclosure}
        onConfirm={onConfirm}
      />
    );
  }
  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="scale"
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
    >
      <ModalOverlay bgColor="blackAlpha.800" />
      <ModalContent borderRadius={4} boxShadow="dark-lg">
        <ModalHeader>{t('npsConfirmationModal.title')}</ModalHeader>
        <ModalBody>
          <Box>
            {t('liveMeetingInteractions.nps.confirmText')}
            <br />
            {t('meeting.header.confirmRestart')}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={disclosure.onClose}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="blue"
              onClick={() => {
                onConfirm();
                disclosure.onClose();
              }}
            >
              {t('common.confirm')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
        <ModalCloseButton color="black" />
      </ModalContent>
    </Modal>
  );
};
