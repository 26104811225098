import PageLayout from '@/ui/Layout/PageLayout';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import clearAllMockingData from '@/features/meeting-stats/mocking-tool/general/clearAllMockingData';
import { startMock } from '@/features/meeting-stats/mocking-tool';
import {
  ListMeetingStatsQuery,
  ListMeetingStatsQueryVariables,
  MeetingStats,
  UpdateMeetingStatsMutationVariables,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { listMeetingStats } from '@/services/graphql/queries';
import { migrateMeetingStatsV101 } from '@/backend/migrations/meetingStatsMigrations';
import { updateMeetingStats } from '@/services/graphql/mutations';

async function handleMeetingStatsMigration() {
  const variables: ListMeetingStatsQueryVariables = {};
  const meetingStatsResponse = await callGraphQLApi<
    GraphQLResult<ListMeetingStatsQuery>
  >(listMeetingStats, variables);
  const meetingStats = meetingStatsResponse.data?.listMeetingStats
    ?.items as MeetingStats[];

  if (!meetingStats) {
    console.error('No meeting stats found');
    return;
  }
  await Promise.all(
    meetingStats.map(async (meetingStat) => {
      const migratedMeetingStat = await migrateMeetingStatsV101(meetingStat);
      if (migratedMeetingStat) {
        const variables: UpdateMeetingStatsMutationVariables = {
          input: {
            id: meetingStat.id,
            version: migratedMeetingStat.version,
            casePresentationHistory:
              migratedMeetingStat.casePresentationHistory,
          },
        };
        await callGraphQLApi(updateMeetingStats, variables);
      }
    })
  );

  console.log('done migrating meeting stats');
}

function AdminTestPage() {
  return (
    <PageLayout>
      <Flex alignItems="center" justifyContent="center" gap="20px">
        <VStack maxWidth="300px">
          <Text fontSize="30px" color="livelinx.purple200">
            Meeting mocking
          </Text>
          <Button
            width="full"
            variant="primary"
            onClick={() => startMock(true)}
          >
            Create a new mock meeting
          </Button>

          <Button width="full" variant="primary" onClick={clearAllMockingData}>
            Delete mocking data
          </Button>

          <Button
            width="full"
            variant="primary"
            onClick={handleMeetingStatsMigration}
          >
            Migrate meeting stats
          </Button>
        </VStack>
      </Flex>
    </PageLayout>
  );
}

export default AdminTestPage;
