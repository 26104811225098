import { Client } from '@/services/API';
import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';

const useAdminClientTableStore = createTanstackTableDataStore<Client>({
  storeName: 'admin-client-table-store',
  defaultColumnSizing: {},
  defaultColumnVisibility: {},
});

export default useAdminClientTableStore;
