import { mutationDeleteAllAssetViews } from '@/features/meeting-stats/graphql/delete';
import { customGetCaseStats } from '@/features/meeting-stats/graphql/gql';
import { DeleteCaseAssetViewMutation } from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import mutationResetSlideStats from './mutationResetSlideStats';

/*
Deletes all assetViews and resets the slideStats
*/
export default async function mutationResetCaseStats(caseStatsID: string) {
  //TODO: I don't want an any here, but I don't know how to type this since it's a custom query.
  //I guess I should write my own types for this.
  const caseStatsData: any = await callGraphQLApi<GraphQLResult<any>>(
    customGetCaseStats,
    {
      id: caseStatsID,
    }
  );

  // Delete all assetViews
  const assetViewDeletionsPromises: Promise<DeleteCaseAssetViewMutation>[] =
    await mutationDeleteAllAssetViews(
      caseStatsID,
      caseStatsData.data.getCaseStats?.assetViews?.items,
      caseStatsData.data.getCaseStats?.assetViews?.nextToken
    );

  // Reset slideStats
  const slideStatsResetPromises = [];

  for (const slideStats of caseStatsData.data.getCaseStats?.slides?.items ??
    []) {
    slideStatsResetPromises.push(mutationResetSlideStats(slideStats.id));
  }

  await Promise.all(assetViewDeletionsPromises);
  await Promise.all(slideStatsResetPromises);
}
