import {
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
} from '@chakra-ui/react';
import { FaRegEye } from 'react-icons/fa';
import { SearchIcon } from '@chakra-ui/icons';
import { ReactNode, useState } from 'react';
import { Table } from '@tanstack/react-table';

type Props<TData> = {
  table: Table<TData>;
};

function ColumnVisibility<TData>({ table }: Props<TData>) {
  const [currentSearch, setCurrentSearch] = useState<string>('');

  return (
    <Menu isLazy closeOnSelect={false}>
      <MenuButton
        as={IconButton}
        icon={<FaRegEye />}
        aria-label="column visibility"
        bgColor="gray.200"
      />
      <MenuList p={2}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray" />
          </InputLeftElement>
          <Input
            value={currentSearch}
            onChange={(e) => setCurrentSearch(e.target.value)}
            placeholder="Search for column name"
          />
        </InputGroup>

        <Divider my={2} />

        {table
          .getAllColumns()
          .filter(
            (c) =>
              !currentSearch ||
              c.id.toLowerCase().includes(currentSearch.toLowerCase())
          )
          .map((c) => {
            return (
              <MenuItem key={c.id} gap={2} onClick={() => c.toggleVisibility()}>
                <HStack width="full" justifyContent="space-between">
                  <Text>{c.columnDef.header as ReactNode}</Text>
                  <Switch
                    colorScheme="blue"
                    isChecked={c.getIsVisible()}
                    pointerEvents="none"
                  ></Switch>
                </HStack>
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
}

export default ColumnVisibility;
