export default function calculateLinearScore(
  value: number,
  min: number,
  minOptimal: number,
  maxOptimal: number,
  max: number
): number {
  if (value >= minOptimal && value <= maxOptimal) return 1;
  if (value <= min || value >= max) return 0;
  if (value < minOptimal) return (value - min) / (minOptimal - min);
  if (value > maxOptimal) return (max - value) / (max - maxOptimal);
  return 1;
}
