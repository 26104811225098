import { useTranslation } from 'react-i18next';
import getColumns from './columns';
import useAdminClickupTableStore from './AdminClickupListTableStore';
import { useState } from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fuzzyFilter } from '@/utils/helpers/fuzzyFilter';
import { Box, Flex, HStack, Spacer, VStack, chakra } from '@chakra-ui/react';
import ColumnVisibility from '@/ui/TanstackTable/Columns/ColumnVisibility';
import Filters from '@/ui/TanstackTable/Filter/Filters';
import GlobalFilter from '@/ui/TanstackTable/Filter/GlobalFilter/GlobalFilter';
import ActiveFilters from '@/ui/TanstackTable/Filter/ActiveFilters/ActiveFilters';
import Pagination from '@/ui/TanstackTable/Pagination';
import TanstackTableView from '@/ui/TanstackTable/TanstackTableView';
import { FilterType } from '@/ui/TanstackTable/Filter/NewFilterComponent';
import { ClickupTask } from './clickup.types';

type Props = {
  data: ClickupTask[];
};

export default function AdminClickupListView({ data }: Props) {
  const { t } = useTranslation();

  const columns = getColumns(data);
  const [
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
  ] = useAdminClickupTableStore((state) => [
    state.columnFilters,
    state.setColumnFilters,
    state.columnVisibility,
    state.setColumnVisibility,
    state.columnSizing,
    state.setColumnSizing,
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const table = useReactTable({
    data: data,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      columnSizing,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: (updater) => {
      const newVisibility =
        typeof updater === 'function' ? updater(columnVisibility) : updater;
      setColumnVisibility(newVisibility);
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      setColumnSizing(newSizing);
    },
    defaultColumn: {},
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
  });
  const filterTypeMappings: Partial<Record<keyof ClickupTask, FilterType>> = {};
  const possibleFilterOptions = {};

  return (
    <Box maxW="full" p={{ base: 3, md: 3 }}>
      <HStack justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          {t('admin.clickup.title')}
        </chakra.h3>
      </HStack>

      <>
        <Flex gap="20px" justifyContent="center" alignItems="center" mb="4">
          <VStack w="full">
            <HStack w="full">
              <ColumnVisibility table={table} />
              <Filters
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                table={table}
                filterTypeMapping={filterTypeMappings}
                filterOptionsMapping={possibleFilterOptions}
              />
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                maxWidth="250px"
              />
              <ActiveFilters
                columnFilters={columnFilters}
                removeItemFunc={(elem) => {
                  setColumnFilters(
                    columnFilters.filter((f) => f.id !== elem.id)
                  );
                }}
                filterTypeMapping={filterTypeMappings}
              />
              <Spacer />
              <Pagination table={table} />
            </HStack>
          </VStack>
        </Flex>

        <TanstackTableView table={table} />
      </>
    </Box>
  );
}
