import { ROUTES } from '@/routes/Routes';
import PageLayout from '@/ui/Layout/PageLayout';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

type Props = {
  openDate: Date;
};

function MeetingNotStartedView({ openDate }: Props) {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        gap="5px"
      >
        <Text fontSize="20px" variant="purpleTitleBold">
          This meeting has not started yet
        </Text>
        <Text variant="graySubTitle" marginBottom="10px">
          It will start{' '}
          {openDate.toLocaleDateString('en-US', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
          })}
        </Text>
        <Button onClick={() => navigate(ROUTES.HOME)} variant="primary">
          Home
        </Button>
      </Flex>
    </PageLayout>
  );
}

export default MeetingNotStartedView;
