import {
  UpdateCaseSlideStatsInput,
  UpdateCaseSlideStatsMutation,
} from '@/services/API';
import { updateCaseSlideStats } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export default async function mutationResetSlideStats(id: string) {
  const input: UpdateCaseSlideStatsInput = {
    id,
    time: 0,
  };
  const result = await callGraphQLApi<
    GraphQLResult<UpdateCaseSlideStatsMutation>
  >(updateCaseSlideStats, {
    input,
  });
  return result;
}
