import { Flex, FlexProps, Text } from '@chakra-ui/react';

type Props = {
  title: string;
  subtitle: string;
} & FlexProps;

export default function StepIndicator({ subtitle, title, ...rest }: Props) {
  return (
    <Flex
      w="250px"
      h="100px"
      paddingX="20px"
      paddingY="10px"
      borderRadius="10px"
      border="1px solid gray"
      boxShadow="lg"
      transition="all 0.3s ease-out"
      alignItems="center"
      flexDir="column"
      marginY="5px"
      {...rest}
    >
      <Text fontWeight="bold" color="livelinx.purple200" fontSize="20px">
        {title}
      </Text>
      <Text>{subtitle}</Text>
    </Flex>
  );
}
