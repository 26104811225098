import ItemContainer from '@/components/CaseSlide/ItemContainer';
import ItemListContainer from '@/components/CaseSlide/ItemListContainer';
import TextAnswerItem from '@/components/CaseSlide/QuestionSlide/components/TextAnswerItem';
import { useQuestionContext } from '@/components/CaseSlide/QuestionSlide/context/QuestionContext';
import usePreviousQuestionSlideUserAnswer from '@/customSlides/hooks/usePreviousQuestionSlideUserAnswer';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { AppSpinner } from '@/ui/AppSpinner';
import { SlideItemType } from '@/utils/types/enums';
import { CustomSlideType } from '@/utils/types/zod/slideTypes/customSlideSchema';

type Props = {
  slide: CustomSlideType;
};

function CustomSlide2({ slide }: Props) {
  const { onTextAnswerChange } = useQuestionContext();
  const { isUserHost, meeting, currentCaseId, currentCase } =
    useCurrentMeetingDataContext();
  const { userID } = useUserAuthenticationContext();

  const { isLoading, data } = usePreviousQuestionSlideUserAnswer(
    meeting,
    currentCaseId,
    currentCase,
    '92-BE-C1',
    userID
  );

  if (isLoading) {
    return (
      <ItemListContainer>
        <AppSpinner />
      </ItemListContainer>
    );
  }
  return (
    <ItemListContainer>
      {slide.items.map((item, itemIndex) => {
        if (item.type === SlideItemType.ANSWER_TEXT) {
          let title: string | null = 'Loading...';
          if (!isLoading) {
            if (!data) {
              title = null;
            } else {
              title = data[itemIndex]?.title;
            }
          }
          return (
            <ItemContainer key={itemIndex} slide={slide}>
              <TextAnswerItem
                titleOverride={title}
                key={item.id}
                item={item}
                isHost={isUserHost}
                onTextChange={(text: string) => {
                  onTextAnswerChange([{ answerID: item.id, text }]);
                }}
              />
            </ItemContainer>
          );
        }
      })}
    </ItemListContainer>
  );
}

export default CustomSlide2;
