import {
  UpdateMeetingStatsMutation,
  UpdateMeetingStatsMutationVariables,
} from '@/services/API';
import { updateMeetingStats } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

const mutationUpdateMeetingAmountParticipants = async (
  meetingID: string,
  amt: number
) => {
  const variables: UpdateMeetingStatsMutationVariables = {
    input: {
      id: meetingID,
      amountParticipants: amt,
    },
  };
  const result = await callGraphQLApi<
    GraphQLResult<UpdateMeetingStatsMutation>
  >(updateMeetingStats, variables);

  return result;
};

export default mutationUpdateMeetingAmountParticipants;
