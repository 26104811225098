import { CaseCluster } from '@/services/API';

export const GetCaseIndexByCaseID = (
  caseID: string | null | undefined,
  caseCluster: CaseCluster | null | undefined
) => {
  if (caseCluster && caseCluster.cases && caseID)
    return caseCluster.cases.indexOf(caseID);
  return -1;
};

export const GetCaseIdByCaseIndex = (
  caseIndex: number,
  caseCluster: CaseCluster | null | undefined
) => {
  if (caseCluster && caseCluster.cases && caseIndex > -1)
    return caseCluster.cases[caseIndex] || undefined;
  return undefined;
};
