import { Text } from '@chakra-ui/react';

type Props = {
  text: string;
};

export default function Title({ text }: Props) {
  return (
    <Text variant="meetingReportChunkTitle" marginTop="25px">
      {text}
    </Text>
  );
}
