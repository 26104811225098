import {
  Answer,
  AnswersByQuestionIDQuery,
  AnswersByQuestionIDQueryVariables,
  ModelSortDirection,
} from '@/services/API';
import { answersByQuestionID } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';

export async function listAnswersByQuestionID(
  questionID: string
): Promise<Answer[] | undefined> {
  const variables: AnswersByQuestionIDQueryVariables = {
    questionID,
    sortDirection: ModelSortDirection.ASC,
  };
  const response = await callGraphQLApi<
    GraphQLResult<AnswersByQuestionIDQuery>
  >(answersByQuestionID, variables);
  const items = response?.data?.answersByQuestionID?.items as
    | Answer[]
    | undefined;

  return items;
}
