import useDeviceSize from '@/services/hooks/useDeviceSize';
import clamp from '@/utils/helpers/clamp';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PADDING_X_DESKTOP, PADDING_X_MOBILE } from '../utils/constants';
import getColor from '../utils/getColor';
import ChunkContainer from './ChunkContainer';
import ProgressBar from './ProgressBar';
import Title from './Title';

type StatsDisplayWithProgressBarProps = {
  progress: number;
  color: string;
  title: string;
  textOnTheRight: string;
};

function StatsDisplayWithProgressBar({
  progress,
  color,
  title,
  textOnTheRight,
}: StatsDisplayWithProgressBarProps) {
  const { isDesktopOrLaptop } = useDeviceSize();
  return (
    <Box
      paddingX={isDesktopOrLaptop ? PADDING_X_DESKTOP : PADDING_X_MOBILE}
      width="100%"
      marginBottom="25px"
    >
      <Box display="flex" flexDir="row" justifyContent="space-between">
        <Text fontSize="17px" fontFamily="poppins-medium" variant="purpleTitle">
          {title}
        </Text>
        <Text fontSize="17px" fontFamily="poppins-medium" variant="purpleTitle">
          {textOnTheRight}
        </Text>
      </Box>
      <ProgressBar
        progress={clamp(progress, 0, 100)}
        width="100%"
        height="10px"
        color={color}
        bgColor="livelinx.grey300"
      />
    </Box>
  );
}

export type EngagementContributorsOptions = {
  title: string;
  value: number;
  maxValue: number;
  textOnTheRight: string;
};

type Props = {
  options: EngagementContributorsOptions[];
};

export default function EngagementContributors({ options }: Props) {
  const { t } = useTranslation();

  return (
    <ChunkContainer>
      <Title text={t('meetings.stats.engagementContributors.title')} />
      <Box width="100%" marginTop="20px">
        {options.map((option) => {
          return (
            <StatsDisplayWithProgressBar
              key={option.title}
              progress={(option.value / option.maxValue) * 100}
              color={getColor((option.value / option.maxValue) * 100)}
              title={option.title}
              textOnTheRight={option.textOnTheRight}
            />
          );
        })}
      </Box>
    </ChunkContainer>
  );
}
