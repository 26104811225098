import { Flex } from '@chakra-ui/react';
import { AppSpinner } from './AppSpinner';

export const PageLoading = () => {
  return (
    <Flex h="100vh">
      <AppSpinner />
    </Flex>
  );
};
