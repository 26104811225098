import { ButtonProps, IconButton, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCopy } from 'react-icons/fa';

type Props = {
  text: string;
} & ButtonProps;

const CopyButton = ({ text, ...rest }: Props) => {
  const toast = useToast();
  const { t } = useTranslation();

  function handleCopy() {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: t('textCopier.title'),
        description: t('textCopier.description').replace('{text}', text),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  }

  return (
    <IconButton
      icon={<FaCopy />}
      aria-label="Copy text"
      size="sm"
      onClick={handleCopy}
      colorScheme="gray"
      {...rest}
    />
  );
};

export default CopyButton;
