import { ROUTES } from '@/routes/Routes';
import {
  GetMeetingQuery,
  GetMeetingQueryVariables,
  Meeting,
} from '@/services/API';
import { getMeeting } from '@/services/graphql/queries';
import { PageLoading } from '@/ui/PageLoading';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { assertIsTruthy } from '@/utils/types/type-assertion/generic/assertIsTruthy';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import PreviewMeeting from './PreviewMeeting';
import { actions } from '@/store/slices/meeting';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import { useAppDispatch } from '@/store/StoreHooks';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';

/*
NOTE: responsible for making sure the user is allowed to preview the meeting
*/
function PreviewMeetingPermissions() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const meetingID = new URLSearchParams(location.search).get('meeting');
  const previewCode = new URLSearchParams(location.search).get('previewCode');
  const { createUserIfNecessary } = useUserAuthenticationContext();

  const meetingQuery = useQuery({
    queryKey: ['meeting', meetingID],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      assertIsTruthy(meetingID);
      const variables: GetMeetingQueryVariables = {
        id: meetingID,
      };
      const result = await callGraphQLApi<GraphQLResult<GetMeetingQuery>>(
        getMeeting,
        variables
      );
      return result.data?.getMeeting as Meeting;
    },
  });

  // NOTE: A user account is required
  useEffect(() => {
    createUserIfNecessary();
  }, [createUserIfNecessary]);

  // Case: has missing arguments
  useEffect(() => {
    if (!meetingID || !previewCode) {
      navigate(ROUTES.HOME, { replace: true });
      return;
    }
  }, [meetingID, previewCode]);

  // Case: Has not the right code
  useEffect(() => {
    if (meetingQuery.isLoading) return; // wait for the query to finish
    setIsLoading(false);
    if (!meetingQuery.data) {
      navigate(ROUTES.HOME, { replace: true });
      return;
    }
    if (meetingQuery.data.previewCode !== previewCode) {
      navigate(ROUTES.HOME, { replace: true });
      return;
    }
    //generate a meeting in memory and set is as the current meeting
    const newMeeting: Meeting = {
      ...meetingQuery.data,
      //NOTE: This is important that this stays '', because !!meetingID is false
      id: '',
      currentSlide: 0,
      currentCase: NULL_ID_VALUE,
      name: `Preview >> ${meetingQuery.data.name}`,
      isInTesting: false,
    };
    dispatch(actions.newLocalMeeting(newMeeting));
    dispatch(actions.updateCurrentMeetingInfo(''));
    setHasPermission(true);
  }, [dispatch, previewCode, meetingQuery]);

  if (isLoading) {
    console.log('PreviewMeetingPermissions: Loading...');
    return <PageLoading />;
  }

  if (!hasPermission) {
    return <Navigate to={ROUTES.HOME} replace={true} state={{}} />;
  }

  return <PreviewMeeting />;
}

export default PreviewMeetingPermissions;
