// import pureRestartMeeting from '@/backend/pureRestartMeeting';
import pureRestartMeeting from '@/backend/pureRestartMeeting';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { ROUTES } from '@/routes/Routes';
import {
  Meeting,
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
} from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

async function handleDisableTestingMode(
  meeting: Meeting | undefined,
  navigate: NavigateFunction
) {
  if (!meeting) {
    console.warn('you are not in a meeting, you can not disable testing mode');
    return;
  }

  navigate(ROUTES.HOME);
  await removeTestingModeFromMeeting(meeting.id);
  await pureRestartMeeting(meeting.id);
}

async function removeTestingModeFromMeeting(meetingID: string) {
  const variables: UpdateMeetingMutationVariables = {
    input: {
      id: meetingID,
      isInTesting: false,
    },
  };
  await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(
    updateMeeting,
    variables
  );
}

type Props = {
  isHost: boolean;
};

export default function MeetingTestingModeWarning({ isHost }: Props) {
  const [currentColor, setCurrentColor] = useState<string>('red');
  const { meeting } = useCurrentMeetingDataContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentColor((prevColor) =>
        prevColor === 'red' ? 'livelinx.yellow200' : 'red'
      );
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Box
        position="fixed"
        zIndex={10}
        top="0px"
        left="0" // Center horizontally
        right="0"
        textAlign="center"
        py="3px"
        px="5px"
        background="white"
        borderRadius="6px"
        borderColor="livelinx.orange200"
        borderStyle="dashed"
        borderWidth="2px"
        margin="5px auto"
        width="fit-content"
      >
        <Flex flexDir="row" alignItems="center">
          <WarningTwoIcon
            transition="color 0.1s ease-in-out"
            w={6}
            h={6}
            color={currentColor}
            marginRight="10px"
          />
          <Text variant="purpleTitleBold" marginRight="12px" fontSize="12px">
            SandBox mode is ACTIVE
          </Text>
          {isHost && (
            <Button
              size="md"
              variant="primary"
              onClick={() => handleDisableTestingMode(meeting, navigate)}
            >
              {t('common.disable')}
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
}
