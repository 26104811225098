import createTanstackTableDataStore from '@/ui/TanstackTable/createTanstackTableDataStore';
import { AdminCaseClusterListItemType } from './columns';

const useAdminCaseClusterTableStore =
  createTanstackTableDataStore<AdminCaseClusterListItemType>({
    storeName: 'admin-caseCluster-table-store',
    defaultColumnSizing: {},
    defaultColumnVisibility: {},
  });

export default useAdminCaseClusterTableStore;
