import { Input, Text, VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (value: string) => void;
};

const BasicStringFilter = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const [newStringFilterValue, setStringNewFilterValue] = useState<string>('');
  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);
  return (
    <VStack justifyContent="left" alignItems="flex-start">
      <Text>{t('tables.filters.string.title')}</Text>
      <Input
        value={newStringFilterValue}
        onChange={(e) => {
          setStringNewFilterValue(e.target.value);
          debouncedOnChange(e.target.value);
        }}
      />
    </VStack>
  );
};

export default BasicStringFilter;
