import { Box, Flex, Icon, Text, Th, useDisclosure } from '@chakra-ui/react';
import { Header } from '@tanstack/react-table';
import { ReactNode, useRef } from 'react';
import SortIcon from './SortIcon';
import { ArrowLeftIcon } from '@chakra-ui/icons';

type Props<TData> = {
  header: Header<TData, unknown>;
};

function HeaderElement<TData>({ header }: Props<TData>) {
  const contextMenuDisclosure = useDisclosure();
  const width = header.getSize();
  const ref = useRef(null);
  return (
    <>
      <Th
        ref={ref}
        w={width}
        className="group"
        position="relative"
        display="flex"
        overflow="hidden"
        paddingX="1rem"
        paddingY="0.5rem"
        _hover={{
          background: 'gray.100',
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          contextMenuDisclosure.onOpen();
        }}
      >
        <Flex
          onClick={
            header.column.getCanSort()
              ? header.column.getToggleSortingHandler()
              : undefined
          }
          cursor={header.column.getCanSort() ? 'pointer' : undefined}
        >
          <Text userSelect="none">
            {header.column.columnDef.header as ReactNode}
          </Text>
          {header.column.getCanSort() && (
            <Icon as={SortIcon} mx={3} fontSize={14} cursor="pointer" />
          )}
          {
            {
              default: '',
              asc: <ArrowLeftIcon transform="rotate(90deg)" />,
              desc: <ArrowLeftIcon transform="rotate(-90deg)" />,
            }[header.column.getIsSorted() || 'default']
          }
        </Flex>
        <Box
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          position="absolute"
          opacity={0}
          top={0}
          right={0}
          h="100%"
          w="5px"
          bg="primary"
          cursor={header.column.getCanResize() ? 'col-resize' : 'not-allowed'}
          userSelect="none"
          borderRadius="6px"
          _groupHover={{
            opacity: 1,
          }}
        />
      </Th>
    </>
  );
}

export default HeaderElement;
