import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@/ui/Select/Select';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Text,
  Stack,
  Avatar,
  Box,
  ScaleFade,
  AspectRatio,
  HStack,
} from '@chakra-ui/react';
import {
  useDevices,
  useLocalParticipant,
  useVideoTrack,
  StatefulDevice,
  useDaily,
  useInputSettings,
} from '@daily-co/daily-react';

import VideoIcon from '@/assets/images/Video.svg';
import MicrophoneIcon from '@/assets/images/Microphone.svg';
import SpeakerIcon from '@/assets/images/Speaker.svg';
import { AppSpinner } from '@/ui/AppSpinner';
import VideoBlurOptionBar, { BlurOption } from './VideoBlurOptionBar';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  joinCall?: () => void;
  handleGoBack?: () => void;
};

export default function CallSettings({
  isOpen,
  onClose,
  joinCall,
  handleGoBack,
}: Props) {
  const {
    microphones,
    camState,
    speakers,
    cameras,
    setMicrophone,
    setCamera,
    setSpeaker,
  } = useDevices();
  const { inputSettings } = useInputSettings();
  const { t } = useTranslation();
  const callObject = useDaily();
  const localParticipant = useLocalParticipant();
  const videoTrack = useVideoTrack(localParticipant?.session_id as string);
  const videoElement = useRef<HTMLVideoElement>(null);
  const [microPhoneValue, setMicroPhoneValue] = useState<string>();
  const [speakerValue, setSpeakerValue] = useState<string>();
  const [cameraValue, setCameraValue] = useState<string>();
  const { email } = useUserAuthenticationContext();
  const username = email && email.split('@')[0];

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoElement?.current) {
      videoElement.current.srcObject =
        videoTrack.persistentTrack &&
        new MediaStream([videoTrack?.persistentTrack]);
    }
  }, [videoTrack?.persistentTrack, videoElement.current]);

  useEffect(() => {
    if (!microphones) return undefined;
    const micValue = microphones.filter((el) => el.selected)[0]?.device.label;
    setMicroPhoneValue(micValue);
  }, [microphones]);
  useEffect(() => {
    if (!speakers) return undefined;
    const speakerValue = speakers.filter((el) => el.selected)[0]?.device.label;
    setSpeakerValue(speakerValue);
  }, [speakers]);
  useEffect(() => {
    if (!cameras) return undefined;
    const cameraValue = cameras.filter((el) => el.selected)[0]?.device.label;
    setCameraValue(cameraValue);
  }, [cameras]);

  const handleJoinOrContinueCall = () => {
    if (!joinCall) {
      {
        onClose();
      }
    } else {
      joinCall();
    }
  };
  const goBack = () => {
    if (!handleGoBack) {
      // if we are on existing meeting we dont want to "kill" the call object, we just close the popup
      onClose();
    } else {
      // close poup + kill call object
      handleGoBack();
    }
  };

  const formatDropdownOptions = useCallback((options: StatefulDevice[]) => {
    return options.map((data) => {
      return {
        value: data.device.deviceId,
        label: data.device.label,
      };
    });
  }, []);

  const handleBackgroundBlurChange = (blurValue: BlurOption) => {
    if (!callObject) return;
    callObject.updateInputSettings({
      video: {
        processor: {
          type: blurValue !== 'none' ? 'background-blur' : 'none',
          config:
            blurValue !== 'none'
              ? { strength: blurValue === 'medium' ? 0.3 : 1 }
              : undefined,
        },
      },
    });
  };

  const currentBlur: BlurOption = useMemo(() => {
    const isBlur = inputSettings?.video?.processor?.type == 'background-blur';
    const blurConfig = inputSettings?.video?.processor?.config;
    if (isBlur && blurConfig) {
      return (blurConfig as any).strength == 1 ? 'large' : 'medium';
    }
    return 'none';
  }, [inputSettings?.video?.processor]);

  const microPhoneOptions = formatDropdownOptions(microphones);
  const cameraOptions = formatDropdownOptions(cameras);
  const speakersOptions = formatDropdownOptions(speakers);
  console.log(videoTrack, 'videoTrack');
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxWidth="45rem">
        <ModalBody padding="25px">
          <AspectRatio background="black" ratio={16 / 9} userSelect="none">
            {videoElement &&
            (cameras?.length < 1 || !videoTrack.persistentTrack) ? (
              <Stack>
                {camState !== 'pending' && cameras?.length < 1 ? (
                  <Text color="red">There is no camera available</Text> // TODO: translate
                ) : videoTrack.off?.byUser ? (
                  <Text color="red">Camera is off</Text>
                ) : (
                  <AppSpinner size="md" />
                )}
              </Stack>
            ) : (
              <ScaleFade
                initialScale={0.9}
                in={videoTrack.persistentTrack ? true : false}
              >
                <video autoPlay muted playsInline ref={videoElement} />
              </ScaleFade>
            )}
          </AspectRatio>

          <Box position="relative" w="full">
            <HStack
              w="full"
              position="absolute"
              bottom="0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p="10px"
            >
              <HStack gap="0">
                <Avatar
                  name={username}
                  size="sm"
                  background="livelinx.blue300"
                  color="#FFF"
                />
                <Text
                  color="#FFF"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="20px"
                >
                  {username}
                </Text>
              </HStack>

              {videoTrack.persistentTrack && (
                <VideoBlurOptionBar
                  blur={currentBlur}
                  onBlurChange={handleBackgroundBlurChange}
                />
              )}
            </HStack>
          </Box>
          <SimpleGrid spacing="20px" columns={3} paddingTop="20px">
            <Select
              value={microPhoneValue}
              options={microPhoneOptions}
              onChange={(value) => {
                setMicrophone(value);
              }}
              icon={<MicrophoneIcon />}
            />
            <Select
              disabled={!speakerValue}
              value={speakerValue}
              icon={<SpeakerIcon />}
              options={speakersOptions}
              onChange={(value) => {
                setSpeaker(value);
              }}
            />
            <Select
              disabled={!cameraValue}
              value={cameraValue}
              icon={<VideoIcon />}
              options={cameraOptions}
              onChange={(value) => setCamera(value)}
            />
          </SimpleGrid>
        </ModalBody>

        <ModalFooter justifyContent="center" gap="8px">
          <Button
            variant="secondary"
            width="212px"
            height="50px"
            onClick={goBack}
          >
            <Text>{t('common.cancel')}</Text>
          </Button>
          <Button
            variant="primary"
            width="212px"
            height="50px"
            size="md"
            onClick={handleJoinOrContinueCall}
          >
            <Text>
              {joinCall
                ? t('videoCallSettings.button.joinCall')
                : t('common.save')}
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
