import { Flex, Tag, TagCloseButton, TagLabel, Text } from '@chakra-ui/react';
import FilterValue from './FilterValue';
import { FilterType } from '../NewFilterComponent';
import { ColumnFilter } from '@tanstack/react-table';

type Props<TData> = {
  columnFilters: ColumnFilter[];
  hiddenFilters?: (keyof TData)[];
  removeItemFunc: (item: ColumnFilter) => void;
  filterTypeMapping: Partial<Record<keyof TData, FilterType>>;
};

function ActiveFilters<TData>({
  columnFilters,
  hiddenFilters,
  removeItemFunc,
  filterTypeMapping,
}: Props<TData>) {
  return (
    <Flex gap={1}>
      {columnFilters.map((filter) => {
        if (hiddenFilters?.some((key) => filter.id === key)) {
          return null;
        }
        return (
          <Tag key={filter.id} bgColor="primary" color="white">
            <TagLabel display="flex">
              <Text>{filter.id}: </Text>
              <FilterValue
                value={filter.value}
                filterType={
                  filterTypeMapping[filter.id as keyof TData] ||
                  FilterType.STRING
                }
              />
            </TagLabel>
            <TagCloseButton onClick={() => removeItemFunc(filter)} />
          </Tag>
        );
      })}
    </Flex>
  );
}

export default ActiveFilters;
