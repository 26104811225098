import { z } from 'zod';
import { textTopRightSchema } from './topRightTypes/textTopRightSchema';
import { questionAnswerTopRightSchema } from './topRightTypes/questionAnswerTopRightSchema';

export const topRightObjectSchema = z.union([
  textTopRightSchema,
  questionAnswerTopRightSchema,
]);

export type TopRightItemType = z.infer<typeof topRightObjectSchema>;
