import { Tag } from '@chakra-ui/react';

type Props = {
  client: string;
};

export default function ClientTag({ client }: Props) {
  return (
    <Tag ml={2} colorScheme="blue" boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)">
      {client}
    </Tag>
  );
}
