import { TextAnswerSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/answerSlideItemTypes/textAnswerSlideItemSchema';
import {
  BoxProps,
  Flex,
  Heading,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { useQuestionContext } from '../context/QuestionContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import ProgressBar from '@/features/meeting-stats/components/ProgressBar';

type Props = {
  titleOverride?: string | undefined | null;
  item: TextAnswerSlideItemType;
  onTextChange: (value: string) => void;
  isHost: boolean;
  nbRows?: number;
  isDisabled?: boolean;
} & BoxProps;

function TextAnswerItem({
  item,
  onTextChange,
  isHost,
  titleOverride,
  isDisabled,
  nbRows = 4,
  ...rest
}: Props) {
  //This is the local state, we need this for the debouncing
  const { currentTextAnswers, requestTextAnswerIndex } = useQuestionContext();
  const { connectedUserList } = useCurrentMeetingDataContext();
  const savedText = currentTextAnswers[requestTextAnswerIndex(item.id)];
  const [text, setText] = useState<string>(savedText);

  const debounced = debounce(onTextChange, 1000);
  const debouncedOnChange = useCallback(debounced, []);

  const { hostStats: allAnswersHostStats } = useQuestionContext();
  const hostStats = allAnswersHostStats.textAnswers.get(item.id);

  // TODO: NIKO: this is a bug, we have multiple text answers for the same user happening..
  // we need to filter it to be uniq
  if (hostStats?.userNames)
    hostStats.userNames = [...new Set(hostStats.userNames)];

  // TODO: NIKO: Critical!:  another bug is that we sometimes have "guest" users here even with no guest users in the meeting
  const totalUsers =
    connectedUserList.filter((val) => !val.firstname.includes('guest'))
      ?.length - 1; // -1 to remove hosts from the count

  const votingPercentage =
    hostStats && totalUsers && hostStats.userNames.length > 0
      ? Math.min(
          Math.floor((hostStats.userNames.length / totalUsers) * 100),
          100
        )
      : 0;

  const voteLabel = isHost
    ? `${hostStats?.userNames.length} / ${totalUsers} answers (${votingPercentage}%)`
    : `${votingPercentage}%`;

  const showStats = hostStats && isHost;

  //load the database state into the local state
  useEffect(() => {
    setText(savedText);
  }, [savedText]);

  return (
    <VStack flexGrow={1} maxWidth="500px" {...rest}>
      {(titleOverride || item.title) && (
        <Heading
          textAlign="left"
          width="100%"
          color="livelinx.purple200"
          fontSize="1.2em"
          className="title"
          marginBottom="10px"
        >
          {titleOverride ? titleOverride : item.title}
        </Heading>
      )}
      {isHost ? (
        <Text width="100%" fontSize="lg" color="livelinx.grey200">
          {item.placeholder}
        </Text>
      ) : (
        <Textarea
          value={text}
          rows={nbRows}
          resize="none"
          width="100%"
          border="1px solid"
          borderColor="livelinx.lightgray100"
          borderRadius="10px"
          background="transparent"
          padding="2vmin"
          disabled={isHost || isDisabled}
          placeholder={item.placeholder}
          onChange={(e) => {
            const text = e.target.value;
            if (text.length > item.maxChars) return;
            setText(e.target.value);
            debouncedOnChange(e.target.value);
          }}
        />
      )}

      <Flex
        width="100%"
        justifyContent="right"
        alignItems="center"
        userSelect="none"
      >
        {/* Answer stats */}
        {showStats && (
          <VStack w="full" alignItems="left">
            <ProgressBar
              progress={votingPercentage}
              width="80%"
              height="10px"
              color="livelinx.purple100"
              bgColor="#2A2E3422"
            />
            <Text
              fontSize="lg"
              noOfLines={1}
              wordBreak="break-all"
              color="livelinx.purple200"
            >
              {voteLabel}
            </Text>
          </VStack>
        )}

        {/*  Max chars */}
        {!isHost && (
          <Flex
            // width="100%"
            flexGrow={1}
            justifyContent="right"
            alignItems="center"
          >
            <Text color="livelinx.purple100" fontWeight="bold">
              {text ? text.length : 0}
            </Text>
            <Text marginX="5px" color="livelinx.black100">
              /
            </Text>
            <Text color="livelinx.black100">{item.maxChars}</Text>
          </Flex>
        )}
      </Flex>
    </VStack>
  );
}

export default TextAnswerItem;
