import { actions } from '@/store/slices/clients';
import { AppDispatch } from '@/store/Store';
import { createClient } from '@/services/graphql/mutations';
import { CreateClientInput, ListClientsQuery } from '@/services/API';
import { listClients } from '@/services/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export function createAClient(clientName: string) {
  return async (dispatch: AppDispatch) => {
    const input: CreateClientInput = {
      clientName,
    };
    try {
      const response = await callGraphQLApi<GraphQLResult<ListClientsQuery>>(
        listClients,
        {
          filter: {
            clientName: {
              eq: clientName,
            },
          },
        }
      );

      if (response.data?.listClients?.items?.length) {
        dispatch(actions.setError({ message: 'Client already exists' }));
        return;
      }
      await callGraphQLApi(createClient, { input });
    } catch (error) {
      dispatch(actions.setError(error));
    }
  };
}
