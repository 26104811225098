import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import {
  CaseAssetView,
  CaseStats,
  Meeting,
  OnCreateCaseAssetViewSubscription,
} from '@/services/API';
import { onCreateCaseAssetView } from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { NewIAsset } from '@/utils/types/zod/assetSchema';
import { UseQueryResult } from '@tanstack/react-query';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { getCaseIdFromCaseStatsList } from '../utils/getCaseIdFromCaseStatsList';
import { parseAssetViewsPerCase } from '../utils/parseAssetViewsPerCase';

export default function useMeetingAssetStats(
  activated: boolean,
  meetingID: string | undefined,
  meetingDetails: UseQueryResult<Meeting, unknown>,
  isCaseLoading: boolean,
  caseMap: CaseMap,
  caseIds: string[]
) {
  const [assetViewsPerCase, setAssetViewsPerCase] = useState<
    Record<string, CaseAssetView[]>
  >({});

  const onNewAssetViewCallBack = useCallback(
    (data: OnCreateCaseAssetViewSubscription) => {
      console.log('onCreateCaseAssetView', data);
      //adding the new asset view to the list to trigger a reparsing of the assets
      if (
        !data.onCreateCaseAssetView ||
        !meetingDetails.data?.caseStats?.items
      ) {
        console.warn('Error while receiving new asset view');
        return;
      }
      const caseId = getCaseIdFromCaseStatsList(
        data.onCreateCaseAssetView.caseStatsAssetViewsId,
        meetingDetails.data?.caseStats?.items as CaseStats[]
      );
      if (!caseId) {
        console.warn(
          'Could not find caseId for the detected new asset view (this is probably data from another meeting)'
        );
        return;
      }
      console.log('Updating asset views list');
      setAssetViewsPerCase((prev) => {
        const newObj = { ...prev };
        newObj[caseId] = [
          ...prev[caseId],
          data.onCreateCaseAssetView as CaseAssetView,
        ];
        return newObj;
      });
    },
    [meetingDetails.data?.caseStats?.items]
  );

  const parsedAssetViews = useMemo(() => {
    //we don't want to continue if anything needed is still loading
    if (
      isCaseLoading ||
      !caseMap ||
      !activated ||
      Object.keys(assetViewsPerCase).length !== caseIds.length
    )
      return undefined;
    console.log('Recomputing parsedAssetViews');
    const caseIdToAssetList: Record<string, NewIAsset[]> = {};
    for (const caseId of caseIds) {
      caseIdToAssetList[caseId] = caseMap[caseId].assets || [];
    }
    return parseAssetViewsPerCase(
      caseIds,
      caseIdToAssetList,
      assetViewsPerCase
    );
  }, [caseIds, assetViewsPerCase, isCaseLoading, activated]);

  const subscription = useMemo(() => {
    if (!meetingID) return [];
    return [
      {
        query: onCreateCaseAssetView,
        variables: {},
        callback: onNewAssetViewCallBack,
      },
    ];
  }, [meetingID, onNewAssetViewCallBack]);

  useNewSubscriptions(subscription, 'useMeetingAssetStats');

  //populate the asset views for the first time
  useEffect(() => {
    if (!meetingDetails.data?.caseStats?.items || !activated) return;
    const newAssetViews =
      meetingDetails.data?.caseStats?.items.reduce((acc, cur) => {
        if (!cur || !cur.assetViews?.items) return acc;
        const caseId = cur.caseId;
        if (!caseId) return acc;
        acc[caseId] = cur.assetViews.items as CaseAssetView[];
        return acc;
      }, {} as Record<string, CaseAssetView[]>) ?? {};
    setAssetViewsPerCase(newAssetViews);
  }, [meetingDetails.data?.caseStats?.items, activated]);

  return {
    parsedAssetViews,
  };
}
