import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { CaseStats, CaseStatsByMeetingQuery } from '@/services/API';
import { useEffect, useState } from 'react';
import {
  mutationCreateCaseSlideStats,
  mutationCreateCaseStats,
} from '../../graphql/create';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { parseCaseMapFromCaseCluster } from '@/features/caseCluster/helpers/createCaseMapAccordingToCaseCluster';
import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { caseStatsByMeeting } from '@/services/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';

export default function useMeetingCaseStatsSanityCheck(
  meetingID: string | null | undefined,
  caseClusterID: string | null | undefined
) {
  const caseList = useCaseList();
  const [didSlideStatsCheck, setDidSlideStatsCheck] = useState(false);
  const { isAdmin } = useUserAuthenticationContext();

  const caseMapQuery: UseQueryResult<CaseMap> = useQuery({
    queryKey: ['caseMap', caseClusterID],
    refetchOnWindowFocus: false,
    enabled: !!caseClusterID && isAdmin,
    queryFn: async () => {
      if (!caseClusterID) return null;
      return await parseCaseMapFromCaseCluster({
        caseClusterID: caseClusterID,
        caseCluster: undefined,
      });
    },
  });

  const caseStatsQuery: UseQueryResult<CaseStats[]> = useQuery({
    queryKey: ['caseStats', caseClusterID, caseMapQuery.data],
    refetchOnWindowFocus: false,
    enabled: !!caseMapQuery.data && isAdmin,
    queryFn: async () => {
      if (!caseMapQuery.data) return [];
      //NOTE: list all caseStats for this meeting
      const caseStatsCall = await callGraphQLApi<
        GraphQLResult<CaseStatsByMeetingQuery>
      >(caseStatsByMeeting, {
        meetingID: meetingID,
      });
      const caseStats = caseStatsCall.data?.caseStatsByMeeting?.items || [];
      // NOTE: check if they are all there
      const allCaseStats = await Promise.all(
        Object.keys(caseMapQuery.data).map(async (key) => {
          const patientCase = caseMapQuery.data[key];
          const foundItem = caseStats.find(
            (el) => el?.caseId === patientCase.id
          );
          // NOTE: if one is missing, create it
          if (!foundItem) {
            const res = await mutationCreateCaseStats(
              meetingID || '',
              patientCase.id,
              patientCase
            );
            return res.data?.createCaseStats as CaseStats;
          }
          return Promise.resolve(foundItem);
        })
      );
      return allCaseStats;
    },
  });

  useEffect(() => {
    async function checkCaseStats() {
      if (
        !isAdmin ||
        !caseStatsQuery.data ||
        !caseMapQuery.data ||
        !caseList ||
        caseList?.length === 0 ||
        didSlideStatsCheck
      )
        return;
      console.log('DEBUG >> started sanity check of stats');

      // NOTE: For each CaseStats, load the patientCase data
      // const patientCaseData = await Promise.all(
      //   caseStatsQuery.data.map(async (elem) => {
      //     const res = await fetchCaseData(
      //       [elem.caseId],
      //       [caseList.find((el) => el.id === elem.caseId)!.path]
      //     );
      //     return res[0];
      //   })
      // );

      // NOTE: For each patientCase data, check if all slides have an associated caseSlideStats
      const promises = Object.keys(caseMapQuery.data).map(async (key) => {
        const patientCase = caseMapQuery.data[key];
        const caseStatsElem = caseStatsQuery.data.find(
          (el) => el.caseId === patientCase.id
        );
        await Promise.all(
          patientCase.slides.map((slide) => {
            const foundMatchingSlideStats = !!caseStatsElem?.slides?.items.find(
              (el) => el?.slideId === slide.id
            );
            if (!foundMatchingSlideStats) {
              console.log('DEBUG >> have to fix some stats');
              return mutationCreateCaseSlideStats(
                caseStatsElem?.id || '',
                slide.id,
                0
              );
            }
          })
        );
      });

      await Promise.all(promises);
      setDidSlideStatsCheck(true);
      console.log('DEBUG >> finished sanity check of stats');
    }
    checkCaseStats();
  }, [
    isAdmin,
    didSlideStatsCheck,
    caseStatsQuery.data,
    caseMapQuery.data,
    caseList,
  ]);

  return { isLoading: isAdmin && !didSlideStatsCheck };
}
