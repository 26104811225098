import { SlideItemType } from '@/utils/types/enums';
import { ImageSlideType } from '@/utils/types/zod/slideTypes/imageSlideSchema';
import ImageItem from './items/ImageItem';
import { Flex } from '@chakra-ui/react';

type Props = {
  slide: ImageSlideType;
  casePath: string;
};

function ImageSlide({ slide, casePath }: Props) {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      {slide.items.map((item, idx) => {
        switch (item.type) {
          case SlideItemType.IMAGE:
            return (
              <ImageItem
                key={`imageSlideItem-${idx}`}
                item={item}
                casePath={casePath}
              />
            );
        }
      })}
    </Flex>
  );
}

export default ImageSlide;
