import getNonDeletableMeetings from '@/features/meeting/helpers/getNonDeletableMeetings';
import CreateMeetingForm from '@/pages/admin/components/CreateMeetingForm/CreateMeetingForm';
import {
  Button as ChakraButton,
  chakra,
  Modal,
  Flex,
  Heading,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Box,
  VStack,
  HStack,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseCluster, Client, Meeting, User } from '@/services/API';
import getColumns, { AdminMeetingListItemType } from './columns';
import {
  ColumnFilter,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fuzzyFilter } from '@/utils/helpers/fuzzyFilter';
import Filters from '@/ui/TanstackTable/Filter/Filters';
import Pagination from '@/ui/TanstackTable/Pagination';
import TanstackTableView from '@/ui/TanstackTable/TanstackTableView';
import ColumnVisibility from '@/ui/TanstackTable/Columns/ColumnVisibility';
import { FilterType } from '@/ui/TanstackTable/Filter/NewFilterComponent';
import useAdminMeetingTableStore from './AdminMeetingTableStore';
import ActiveFilters from '@/ui/TanstackTable/Filter/ActiveFilters/ActiveFilters';
import GlobalFilter from '@/ui/TanstackTable/Filter/GlobalFilter/GlobalFilter';
import UserAccessList from '../UserAccessList/UserAccessList';

type Props = {
  data: AdminMeetingListItemType[];
  caseClusterList: CaseCluster[];
  userList: User[];
  user: User;
  clientsList: Client[];
};

const AdminMeetingListView = ({
  data,
  userList,
  user,
  caseClusterList,
  clientsList,
}: Props) => {
  const { t } = useTranslation();

  const nonDeletableMeetings = getNonDeletableMeetings(data);
  const [showMeetingForm, setShowMeetingForm] = useState<boolean>(false);
  const [isDupingMeeting, setIsDupingMeeting] = useState<boolean>(false);
  const [meetingData, setMeetingData] = useState<Meeting | undefined>(
    undefined
  );
  const [managedMeetingID, setManagedMeetingID] = useState<string | undefined>(
    undefined
  );
  const managedMeeting = data.find((m) => m.id === managedMeetingID)!;
  const [showAccessFormMeeting, setShowAccessFormMeeting] =
    useState<boolean>(false);
  const [
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
    columnSorting,
    setColumnSorting,
  ] = useAdminMeetingTableStore((state) => [
    state.columnFilters,
    state.setColumnFilters,
    state.columnVisibility,
    state.setColumnVisibility,
    state.columnSizing,
    state.setColumnSizing,
    state.columnSorting,
    state.setColumnSorting,
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const handleDupeMeeting = (meeting: Meeting) => {
    setShowMeetingForm(true);
    setMeetingData(meeting);
    setIsDupingMeeting(true);
  };

  const handleEditMeeting = (data: Meeting) => {
    setShowMeetingForm(true);
    setMeetingData(data);
  };
  const columns = getColumns({
    nonDeletableMeetings,
    onDupeClick: handleDupeMeeting,
    onEditClick: handleEditMeeting,
    onUserAccess: (meeting: Meeting) => {
      setManagedMeetingID(meeting.id);
      setShowAccessFormMeeting(true);
    },
  });
  const table = useReactTable({
    data,
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    state: {
      sorting: columnSorting,
      columnSizing,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: (updater) => {
      const newSorting =
        typeof updater === 'function' ? updater(columnSorting) : updater;
      setColumnSorting(newSorting);
    },
    onColumnVisibilityChange: (updater) => {
      const newVisibility =
        typeof updater === 'function' ? updater(columnVisibility) : updater;
      setColumnVisibility(newVisibility);
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      setColumnSizing(newSizing);
    },
    defaultColumn: {},
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
    autoResetPageIndex: false,
  });
  const filterTypeMappings: Partial<
    Record<keyof AdminMeetingListItemType, FilterType>
  > = {
    host: FilterType.SEARCHABLE_SELECT,
    purchaseOrderNbr: FilterType.SEARCHABLE_SELECT,
    eventDate: FilterType.DATE,
    isInTesting: FilterType.BOOLEAN,
    isArchived: FilterType.BOOLEAN,
    isDemo: FilterType.BOOLEAN,
    clients: FilterType.SEARCHABLE_SELECT,
  };
  const possibleFilterOptions = useMemo(() => {
    const hostOptions = data.reduce((acc, curr) => {
      if (!curr.host?.email) return acc;
      acc.add(curr.host?.email);
      return acc;
    }, new Set<string>());
    const purchaseOrderOptions = data.reduce((acc, curr) => {
      if (!curr.purchaseOrderNbr) return acc;
      acc.add(curr.purchaseOrderNbr);
      return acc;
    }, new Set<string>());

    const result: Partial<Record<keyof AdminMeetingListItemType, string[]>> = {
      host: Array.from(hostOptions),
      purchaseOrderNbr: Array.from(purchaseOrderOptions),
      clients: clientsList.map((c) => c.clientName),
    };
    return result;
  }, [data]);

  // --------------------- handlers ------------------------

  const handleCloseForm = useCallback(() => {
    setMeetingData(undefined);
    setIsDupingMeeting(false);
    setShowMeetingForm(false);
  }, []);

  const handleOnShowArchivedChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.checked;
      const newFilters = columnFilters.filter((el) => el.id !== 'isArchived');

      newFilters.push({
        id: 'isArchived',
        value: newValue,
      });
      setColumnFilters(newFilters);
    },
    [columnFilters]
  );

  // --------------------- render ------------------------

  return (
    <Box maxW="full" p={{ base: 3, md: 3 }}>
      {managedMeeting && showAccessFormMeeting && (
        <UserAccessList
          meeting={managedMeeting}
          onClose={() => {
            setShowAccessFormMeeting(false);
          }}
        />
      )}
      <Flex justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          Meetings
        </chakra.h3>

        <ChakraButton
          background="blue.400"
          color="white"
          size="md"
          data-test="create-meeting-button-popUp"
          onClick={() => setShowMeetingForm(true)}
        >
          {t('admin.meeting.button.createnewMeeting')}
        </ChakraButton>

        <Modal isOpen={showMeetingForm} onClose={() => handleCloseForm()}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box p="20px" gap="10px">
                <Heading size="sm" pb="5">
                  {t(
                    meetingData?.name
                      ? isDupingMeeting
                        ? 'admin.meeting.button.duplicateMeeting'
                        : 'admin.meeting.button.updateMeeting'
                      : 'admin.meeting.button.createnewMeeting'
                  )}
                </Heading>

                <CreateMeetingForm
                  user={user}
                  userList={userList}
                  caseClusterlist={caseClusterList}
                  closeMeetingForm={handleCloseForm}
                  meeting={meetingData}
                  isDuplicateMeeting={isDupingMeeting}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      {data && (
        <>
          <Flex gap="20px" justifyContent="center" alignItems="center" mb="4">
            <VStack w="full">
              <HStack w="full">
                <ColumnVisibility table={table} />
                <Filters
                  columnFilters={columnFilters}
                  hiddenFilters={['isArchived']}
                  setColumnFilters={setColumnFilters}
                  table={table}
                  filterTypeMapping={filterTypeMappings}
                  filterOptionsMapping={possibleFilterOptions}
                />
                <GlobalFilter
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  maxWidth="250px"
                />
                <ActiveFilters
                  columnFilters={columnFilters}
                  hiddenFilters={['isArchived']}
                  removeItemFunc={(elem) => {
                    setColumnFilters(
                      columnFilters.filter((f) => f.id !== elem.id)
                    );
                  }}
                  filterTypeMapping={filterTypeMappings}
                />
                <Spacer />
                <Flex
                  direction="row"
                  userSelect="none"
                  bgColor="gray.200"
                  p="10px"
                  borderRadius="20px"
                  align="center"
                  gap="5px"
                  h="fit-content"
                  whiteSpace="nowrap"
                >
                  <Switch
                    size="lg"
                    isChecked={
                      columnFilters.find(
                        (el: ColumnFilter) => el.id === 'isArchived'
                      )?.value === true
                    }
                    onChange={handleOnShowArchivedChange}
                  />
                  <Text display="inline-block">
                    Show Only Archived meetings
                  </Text>
                </Flex>
                <Pagination table={table} />
              </HStack>
            </VStack>
          </Flex>

          <TanstackTableView table={table} />
        </>
      )}
    </Box>
  );
};

export default AdminMeetingListView;
