import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MeetingStatsViewContext } from '../../context/MeetingStatsViewContext';
import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Button, ButtonProps, Icon, Text } from '@chakra-ui/react';
import carretLeft from '@/assets/images/CaretLeft.svg';

type Props = ButtonProps;

export default function BackButton({ ...rest }: Props) {
  const { t } = useTranslation();
  const { setCurrentCase } = useContext(MeetingStatsViewContext);
  const { isDesktopOrLaptop } = useDeviceSize();
  return (
    <Button
      variant="meetingStats"
      paddingLeft="10px"
      paddingRight="30px"
      paddingTop="5px"
      paddingBottom="5px"
      marginBottom={isDesktopOrLaptop ? '' : '40px'}
      onClick={() => setCurrentCase('')}
      {...rest}
    >
      <Icon as={carretLeft} boxSize="20px" marginRight="10px" />
      <Text fontSize="15px" fontFamily="nunito-semi-bold" color="white">
        {t('meetings.stats.cases.back.title')}
      </Text>
    </Button>
  );
}
