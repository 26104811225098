import React, { useContext } from 'react';
import LorealPromoterStats from '../../LorealPromoterStats/LorealPromoterStats';
import { MeetingStatsViewContext } from '@/features/meeting-stats/context/MeetingStatsViewContext';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import { PageLoading } from '@/ui/PageLoading';

function ChannelDistributionPage() {
  const { meetingID } = useParams();
  const { currentCase } = useContext(MeetingStatsViewContext);
  const { caseMap, isCaseLoading } = useCaseContent([currentCase]);

  if (isCaseLoading) {
    return <PageLoading />;
  }
  return (
    <Flex width="100%" marginTop="20px">
      <LorealPromoterStats
        patientCase={caseMap[currentCase]}
        meetingID={meetingID}
      />
    </Flex>
  );
}

export default ChannelDistributionPage;
