import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store/StoreHooks';
import { useForm } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { createAClient } from '@/store/thunk/clients';

type Props = {
  closeForm: () => void;
};

type FormData = {
  clientName: string;
};

export default function CreateClientForm({ closeForm }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const [clientNameInput, setClientNameInput] = useState<string>('');

  const {
    register,
    handleSubmit,
    reset,
    formState: formState,
  } = useForm<FormData>();

  // --------------------- handlers ------------------------

  const onSubmit = (values: FormData) => {
    dispatch(createAClient(values.clientName));
    closeForm();
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    //reset  fields when component unmounts
    return () => reset();
  }, [closeForm]);

  // --------------------- render ------------------------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* CLIENT NAME */}
      <FormControl isRequired isInvalid={Boolean(formState.errors.clientName)}>
        <FormLabel fontSize="sm" fontWeight="normal" mb="1">
          {t('admin.clients.createForm.field.clientname')}:
        </FormLabel>
        <Input
          type="text"
          {...register('clientName', { required: true })}
          placeholder="Name"
        />
        {formState.errors.clientName && (
          <FormHelperText color="red">
            {t('admin.clients.createForm.field.clientname.error')}
          </FormHelperText>
        )}
      </FormControl>

      {/* SUBMIT */}
      <Button
        onClick={handleSubmit(onSubmit)}
        marginTop="20px"
        padding="20px"
        background="blue.400"
        color="white"
        variant="solid"
      >
        {t('common.create')}
      </Button>
    </form>
  );
}
