import { OnCreateMeetingNPSScoreSubscription } from '@/services/API';
import { onCreateMeetingNPSScore } from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import { useMemo, useState, useEffect } from 'react';
import { queryListNpsScoresForMeeting } from '../graphql/queries';
import { captureSentry } from '@/utils/helpers/sentryHelper';

export default function useMeetingNPSScores(
  meetingID: string | undefined,
  live: boolean
) {
  const [npsScores, setNpsScores] = useState<number[]>([]);
  const npsAverage = useMemo(() => {
    if (npsScores.length === 0) {
      return 1;
    }
    return (
      npsScores.reduce((prev, curr) => prev + curr, 0) / npsScores.length / 10
    ); //Note: divide by 10 which is max score
  }, [npsScores]);

  // useEffect(() => {
  //   console.log('new avg: ', npsAverage)
  // }, [npsAverage])

  //loading for the first time
  useEffect(() => {
    if (!meetingID) return;
    queryListNpsScoresForMeeting(meetingID)
      .then((res) => {
        const scoresList =
          res.data?.npsScoresByMeeting?.items?.map((item) => {
            if (!item?.scores) return [];
            return item.scores;
          }) || [];
        const scores = scoresList.reduce(
          (prev, curr) => [...prev, ...curr],
          []
        );
        setNpsScores(scores);
      })
      .catch((error) => {
        captureSentry({
          title: 'error getting nps score',
          error: error instanceof Error ? error : undefined,
          detail: {
            meetingID,
            npsScores,
          },
        });
      });
  }, [meetingID]);

  if (live) {
    const subscription = useMemo(() => {
      if (!meetingID) return [];
      return [
        {
          query: onCreateMeetingNPSScore,
          variables: {
            //MEETING_STATS_TODO: why doesn't this filter work ? Just like the messages in chat per channel
            // filter: {
            //   meetingID: {
            //     eq: meetingID,
            //   },
            // },
          },
          callback: (data: OnCreateMeetingNPSScoreSubscription) => {
            console.log('got new npsScores');
            if (data.onCreateMeetingNPSScore?.meetingID !== meetingID) {
              console.log('data was irrelevant to this meeting');
              return;
            }
            setNpsScores((prev) => [
              ...prev,
              ...(data.onCreateMeetingNPSScore?.scores || []),
            ]);
          },
        },
      ];
    }, [meetingID]);
    useNewSubscriptions(subscription, 'useMeetingNPSScores');
  }

  return {
    npsScores,
    npsAverage,
  };
}
