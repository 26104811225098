import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const imageSlideItemSchema = z.object({
  type: z.literal(SlideItemType.IMAGE),
  url: z.string().min(1, { message: 'url must be non empty' }),
  alt: z.string().min(1, { message: 'alt must be non empty' }),
});

export type ImageSlideItemType = z.infer<typeof imageSlideItemSchema>;
